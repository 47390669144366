import { JudgePopupTypeTemplate } from './judgePopupTypeTemplate'
import { PopTypeAfterLogin, PopTypeBeforeLogin } from './enum'
import { useEventStore } from '@/views/event/store'
import { useNewCutOnceStore } from '@/views/event/store/cutOnce'

/** 抽奖助力活动弹窗的判断类型 */
export class JudgePopupTypeCutOnce extends JudgePopupTypeTemplate {
  protected getStatusList() {
    return []
  }

  /** 每日一次 */
  protected getIsHaveOpenedToday(): boolean {
    const { isCutOnceHaveOpenedToday } = useEventStore()
    return isCutOnceHaveOpenedToday
  }

  /** 只弹窗一次 */
  protected getIsHaveOpenedEverMapper(): boolean {
    const { isCutOnceHaveOpenedEver } = useEventStore()
    return isCutOnceHaveOpenedEver
  }

  /** 登录前弹窗类型 */
  protected getBeforeLoginType(): PopTypeBeforeLogin {
    const { cutOnceBeforeLoginPopType } = useEventStore()
    return cutOnceBeforeLoginPopType
  }

  /** 登录后弹窗类型 */
  protected getAfterLoginType(): PopTypeAfterLogin {
    const { cutOnceAfterLoginPopType } = useEventStore()
    return cutOnceAfterLoginPopType
  }

  protected customTypeFun = {
    [PopTypeAfterLogin.constantly]: () => {
      return true
    }
  }
}

/** 新砍一刀活动弹窗的判断类型 */
export class JudgePopupTypeNewCutOnce extends JudgePopupTypeTemplate {
  protected getStatusList() {
    return []
  }

  /** 每日一次 */
  protected getIsHaveOpenedToday(): boolean {
    const { isCutOnceHaveOpenedToday } = useNewCutOnceStore()
    return isCutOnceHaveOpenedToday
  }

  /** 只弹窗一次 */
  protected getIsHaveOpenedEverMapper(): boolean {
    const { isCutOnceHaveOpenedEver } = useNewCutOnceStore()
    return isCutOnceHaveOpenedEver
  }

  /** 登录前弹窗类型 */
  protected getBeforeLoginType(): PopTypeBeforeLogin {
    const { cutOnceBeforeLoginPopType } = useNewCutOnceStore()
    return cutOnceBeforeLoginPopType
  }

  /** 登录后弹窗类型 */
  protected getAfterLoginType(): PopTypeAfterLogin {
    const { cutOnceAfterLoginPopType } = useNewCutOnceStore()
    return cutOnceAfterLoginPopType
  }

  protected customTypeFun = {
    [PopTypeAfterLogin.constantly]: () => {
      return true
    }
  }
}
