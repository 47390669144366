import { TaskCategoryApiCodeEnum } from '../../api/type'
import { TaskPageStore } from '.'
import { TaskRule, TaskRuleStatusEnum } from '../../class/taskRule'
import { merge } from 'lodash'

/** 更新rules状态筛选的条件,都属于筛选条件 */
export type PatchRulesFilter = {
  /** 仅更新该apiCode分类下的Rules */
  apiCode?: TaskCategoryApiCodeEnum
  /** 仅更新该id的Rule */
  ruleId?: number
  /** 仅更新旧的状态值是这样的值 */
  oldStatus?: TaskRuleStatusEnum
  /** 还有特殊条件判断 */
  otherCondition?: (rule: TaskRule) => boolean
}
/** 支持更新的字段(原意是支持更新所有,但暴露太多显得太杂(增加理解成本),实际需要的时候一点一点增加) */
export type PatchData = Partial<Pick<TaskRule, 'status' | 'receiveDuration'>>
/** 更新rules状态的函数类型
 * @param patchData 补丁数据
 * @param filter 筛选条件,只有满足条件的rules才会被更新
 */
export const proxyPatchRules = (
  store: TaskPageStore,
  patchData: PatchData,
  filter?: PatchRulesFilter
) => {
  // 根据条件筛选出需要的数据
  const { apiCode, ruleId, oldStatus, otherCondition } = filter || {}
  // 取得所有规则
  let ruleList = Object.values(store.webIdToRuleMapper)
  // 依次过滤
  if (ruleId || ruleId === 0) {
    ruleList = ruleList.filter((rule) => rule.ruleid === ruleId)
  }
  if (apiCode || apiCode === 0) {
    ruleList = ruleList.filter((rule) => rule.apiCode === apiCode)
  }
  if (oldStatus || oldStatus === 0) {
    ruleList = ruleList.filter((rule) => rule.status === oldStatus)
  }
  if (otherCondition) {
    ruleList = ruleList.filter(otherCondition)
  }
  // 然后修改筛选出来数据的状态
  ruleList.forEach((rule) => {
    merge(rule, patchData)
  })
}
