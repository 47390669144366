import {
  ApiUserVipDetailsResponse,
  ApiVipDetailsResponese,
  ApiVipInfoResponese
} from '../mixins/type'
import { AxiosError } from 'axios'
import { apiVipDetails, apiVipInfo } from '@/api/vip'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { useMainStore } from '@/store/index'
import to from 'await-to-js'

export const useVipStore = defineStore('vip', {
  state: () => ({
    userDetails: {} as ApiUserVipDetailsResponse,
    vipdetails: {} as ApiVipDetailsResponese,
    vipInfo: {} as ApiVipInfoResponese,
    axiosError: {
      userDetailsErr: null as Error | null,
      vipDetailsErr: null as null | AxiosError
    },
    timeoutLoading: {
      userDetailLoading: false
    }
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.vip',
        storage: createMyPersistedStorage(),
        paths: ['userDetails', 'vipdetails', 'vipInfo']
      }
    ]
  },
  actions: {
    setUserDetails(data: ApiUserVipDetailsResponse) {
      this.userDetails = data
    },
    setVipdetails(data: ApiVipDetailsResponese) {
      this.vipdetails = data
    },
    setVipInfo(data: ApiVipInfoResponese) {
      this.vipInfo = data
    },

    async getUserDetails() {
      const { updateUserVipInfos } = useMainStore()
      const [err, data] = await to(updateUserVipInfos())
      if (err) {
        this.axiosError.userDetailsErr = err
      }
      if (data) {
        this.userDetails = data
      }
    },

    async getVipInfo() {
      const [err, data = {}] = await to(apiVipInfo())
      this.axiosError.vipDetailsErr = err as AxiosError
      this.vipInfo = data
    },

    async getVipDetail() {
      const data = await apiVipDetails()
      this.vipdetails = data
    },

    /**设置useData 超时loading状态 */
    setUserDataTimeoutLoading(data: boolean) {
      this.timeoutLoading.userDetailLoading = data
    }
  }
})
