import { Base, Component } from '@/vue-property-decorator'
import { useGameStore } from '@/views/game/store'
import { useMainStore } from '@/store/index'
import ComponentsManager, {
  ConfigType,
  ThemeComponentType
} from '@/context/components-manager'
import Skin from '@/controller/Skin'

@Component<SubGamePage>({
  name: 'SubGamePage',
  components: (() => {
    return {
      ...ComponentsManager.register({
        namespace: ConfigType.Theme,
        moduleType: ThemeComponentType.SubGamePage
      })
    }
  })()
})
export default class SubGamePage extends Base {
  mainStore = useMainStore()
  gameStore = useGameStore()

  /**
   * 动态计算当前渲染的组件名
   */
  private get renderPageComponentsName() {
    return ComponentsManager.getName({
      namespace: ConfigType.Theme,
      moduleType: ThemeComponentType.SubGamePage,
      key: Skin.skinType
    })
  }

  render() {
    return (
      <this.renderPageComponentsName
        {...{
          attrs: {
            'data-components-name': this.renderPageComponentsName
          }
        }}
      />
    )
  }
}
