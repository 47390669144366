import {
  ApiEventGet,
  LimitTypeEnum,
  RedPackContent,
  RedPackContentLimitListItem,
  RedPackContentReceiveRule,
  RedPackContentRuleType
} from '@/api/event/type'
import { Base, Component, Prop } from '@/vue-property-decorator'
import { ComTableColumnType } from '@/components/business-components/table/type'
import { CommonTable } from '@/components/business-components'
import { RedPackTypeEnum } from '@/views/event/store/type'
import { VNode } from 'vue'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { isString } from 'lodash'
import { useI18n } from '@/i18n'
import { useRedPocketStore } from '@/views/redPocket/store'
import style from './style.module.scss'

interface Props {
  /**
   * 活动详情数据
   */
  event: ApiEventGet
}

/**
 * 活动奖励列表（充值活动、打码活动）
 */
@Component<EventItemRedPack>({ name: 'EventItemRedPack' })
export default class EventItemRedPack extends Base<unknown, Props> {
  /**
   * 活动详情数据
   */
  @Prop({ required: true })
  event!: Props['event']

  private get isNoContent() {
    return (
      this.redContent.receive_rule === RedPackContentReceiveRule.Unconditional
    )
  }

  async mounted() {
    await useRedPocketStore().getRedListData()
  }

  private get redContent() {
    return (
      this.event.activeData && isString(this.event.activeData)
        ? JSON.parse(this.event.activeData)
        : {}
    ) as RedPackContent
  }

  private get limitList() {
    if (this.redContent.red_pack_type === RedPackTypeEnum.Send) {
      return (this.redContent.sendRuleList
        ? this.redContent.sendRuleList.sort(
            (a, b) => Number(a.ruleValue) - Number(b.ruleValue)
          )
        : []
      ).map((i) => ({
        limit_value: i.ruleValue,
        limit_num:
          i.minShowAmount !== i.maxShowAmount
            ? `${brazilianCurrencyFormatter(
                i.minShowAmount
              )}-${brazilianCurrencyFormatter(i.maxShowAmount)}`
            : brazilianCurrencyFormatter(i.maxShowAmount)
      })) as unknown as RedPackContentLimitListItem[]
    }

    return (this.redContent.limit_list
      ? this.redContent.limit_list.sort(
          (a, b) => Number(a.limit_value) - Number(b.limit_value)
        )
      : []) as unknown as RedPackContentLimitListItem[]
  }

  private get columns() {
    const { t } = useI18n()
    // 当日充值 抢红包上限
    const clumnType1 = [
      {
        title: t('lobby.reward.vip.table.dayDeposit'),
        dataIndex: 'limit_value',
        key: 'limit_value',
        customRender: (value: number) => {
          return <span>≥{brazilianCurrencyFormatter(value)}</span>
        }
      },
      {
        title: t('lobby.event.redPocket.grabPocketAwarMaxLimit'),
        dataIndex: 'limit_num',
        key: 'limit_num',
        customRender: (value: number) => {
          return <span>{t('lobby.event.redPocket.times', { x: value })}</span>
        }
      }
    ] as Array<ComTableColumnType>

    // 当日打码 抢红包上限
    const clumnType2 = [
      {
        title: t('lobby.reward.vip.table.dayBet'),
        dataIndex: 'limit_value',
        key: 'limit_value',
        customRender: (value: number) => {
          return <span>≥{brazilianCurrencyFormatter(value)}</span>
        }
      },
      {
        title: t('lobby.event.redPocket.grabPocketAwarMaxLimit'),
        dataIndex: 'limit_num',
        key: 'limit_num',
        customRender: (value: number) => {
          return <span>{t('lobby.event.redPocket.times', { x: value })}</span>
        }
      }
    ] as Array<ComTableColumnType>

    const { red_pack_type, limit_type, rule_type } = this.redContent

    let sendRedPockLimitValueCloumn = ''
    switch (rule_type) {
      case RedPackContentRuleType.SendTodayRecharge:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.todayPay'
        ) as string
        break
      case RedPackContentRuleType.SendTodayDaMa:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.todayBet'
        ) as string
        break
      case RedPackContentRuleType.SendTodayMaxRecharge:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.yesterdayOnePay'
        ) as string
        break
      case RedPackContentRuleType.SendTodayMaxDaMa:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.yesterdayOneBet'
        ) as string
        break
      case RedPackContentRuleType.SendYesterdayRecharge:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.yesterdayPay'
        ) as string
        break
      case RedPackContentRuleType.SendYesterdayMaxDaMa:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.yesterdayBet'
        ) as string
        break
      case RedPackContentRuleType.SendYesterdayMaxRecharge:
        sendRedPockLimitValueCloumn = t(
          'lobby.event.redPocket.yesterdayLoss'
        ) as string
        break
    }

    // 当日单笔充值 红包金额
    const clumnType3 = [
      {
        title: sendRedPockLimitValueCloumn,
        dataIndex: 'limit_value',
        key: 'limit_value',
        customRender: (value: number) => {
          return <span>≥{brazilianCurrencyFormatter(value)}</span>
        }
      },
      {
        title: t('lobby.event.redPocket.redPocketAmount'),
        dataIndex: 'limit_num',
        key: 'limit_num',
        customRender: (value: number) => {
          return <span>{value}</span>
        }
      }
    ] as Array<ComTableColumnType>

    const columns = new Map([
      [
        red_pack_type === RedPackTypeEnum.Grab &&
          limit_type === LimitTypeEnum.RechargeOnDay,
        clumnType1
      ],
      [
        red_pack_type === RedPackTypeEnum.Grab &&
          limit_type === LimitTypeEnum.CodeOnDay,
        clumnType2
      ],
      [red_pack_type === RedPackTypeEnum.Send, clumnType3]
    ]).get(true)

    return columns ?? []
  }

  private get renderTip() {
    const { t } = useI18n()
    const { red_pack_type, receive_rule } = this.redContent

    let receiveRuleTip = ''
    let limitTip = ''
    // 当日充值{x}可参与, 已充值{y}
    if (
      receive_rule === RedPackContentReceiveRule.RechargeOnDay &&
      red_pack_type !== RedPackTypeEnum.Send
    ) {
      receiveRuleTip = t('lobby.event.redPocket.payTip', {
        x: `<span>${this.redContent.receive_limit}</span>`,
        y: `<span>${brazilianCurrencyFormatter(
          this.redContent.totalPay
        )}</span>`
      }) as string
    }
    // 当日打码{x}可参与, 已打码{y}
    if (
      receive_rule === RedPackContentReceiveRule.CodeOnDay &&
      red_pack_type !== RedPackTypeEnum.Send
    ) {
      receiveRuleTip = t('lobby.event.redPocket.betTip', {
        x: `<span>${this.redContent.receive_limit}</span>`,
        y: `<span>${brazilianCurrencyFormatter(
          this.redContent.totalBet
        )}</span>`
      }) as string
    }

    const { limit_num, total_limit_num, todayCount } = this.redContent
    // 总上限{x}次, 今日抢红包上限{y}(还剩{z}次)
    if (
      red_pack_type === RedPackTypeEnum.Grab &&
      this.redContent.total_limit_num > 0 &&
      total_limit_num &&
      limit_num
    ) {
      limitTip = t('lobby.event.redPocket.grabTip1', {
        x: total_limit_num,
        y: limit_num,
        z: limit_num - todayCount
      }) as string
    }
    // 今日抢红包上限{x}(还剩{y}次)
    else if (
      red_pack_type === RedPackTypeEnum.Grab &&
      this.redContent.total_limit_num === 0 &&
      limit_num
    ) {
      limitTip = t('lobby.event.redPocket.grabTip2', {
        x: limit_num,
        y: limit_num - todayCount
      }) as string
    }
    // 抢红包上限{x}次(还剩{y}次)
    else if (red_pack_type === RedPackTypeEnum.Send && total_limit_num > 0) {
      const count = limit_num - todayCount
      limitTip = t('lobby.event.redPocket.sendRedPockTip', {
        x: total_limit_num,
        y: count < 0 ? 0 : count
      }) as string
    }

    console.log('limitTip:', limitTip)

    return {
      receiveRuleTip,
      limitTip: ''
    }
  }

  /**
   * 渲染奖励设置
   * @param item  奖励条目
   * @param index 数组索引
   * @private
   */
  private renderRewardsTable(list: RedPackContentLimitListItem[]) {
    return (
      <CommonTable
        query={async () => {
          return {
            data:
              list?.map((row, idx) => ({
                ...row,
                rowKey: idx
              })) ?? []
          }
        }}
        class={[style.table]}
        columns={this.columns}
        rowKey={'rowKey'}
        emptyHeight={'auto'}
        isHiddenPagination={true}
      />
    )
  }

  render(): VNode {
    // console.log(
    //   'xxxxxxx red-pack',
    //   JSON.parse(JSON.stringify(this.event)),
    //   JSON.parse(JSON.stringify(this.redContent))
    // )
    return (
      <div class={{ [style.main]: true, [style.noContent]: this.isNoContent }}>
        {/* 渲染 条件 提示 */}
        {this.renderTip.receiveRuleTip && (
          <inner-html
            type={'div'}
            class={style.receiveRuleTip}
            text={this.renderTip.receiveRuleTip}
          />
        )}
        {/* 渲染表格 */}
        {((this.limitList.length > 0 && this.columns?.length > 0) ||
          this.renderTip.limitTip) && (
          <div class={style.content}>
            {/* 限制次数提示 */}
            {this.renderTip.limitTip && (
              <inner-html
                type={'div'}
                class={style.limitTip}
                text={this.renderTip.limitTip}
              />
            )}
            {this.limitList.length > 0 &&
              this.columns?.length > 0 &&
              this.renderRewardsTable(this.limitList)}
          </div>
        )}
      </div>
    )
  }
}
