import { useMainStore } from '@/store/index'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

/** 创建同步localstore的对象,每当值改变的时候,自动同步localStore */
// eslint-disable-next-line @typescript-eslint/ban-types
const createSyncLocalStoreObject = <T extends Record<Any, Any> = {}>(
  data: T,
  localStoreKey: string
) => {
  if (typeof data !== 'object') {
    throw new Error('data must be "object" in createSyncLocalStoreObject')
  }
  /** 添加代理,每当值修改的时候,将对象缓存到localStore */
  const handle = {
    get(target: T, property: keyof T): T[keyof T] {
      const ret = Reflect.get(target, property)
      if (typeof ret === 'object') {
        return new Proxy(ret, handle) as T[keyof T]
      }
      return ret
    },
    set(target: T, property: keyof T, value: T[keyof T]) {
      const isSuccess = Reflect.set(target, property, value)
      localStorage.setItem(localStoreKey, JSON.stringify(syncLocalData))
      return isSuccess
    }
  }
  // 生成同步对象
  const syncLocalData: T = new Proxy(data, handle)
  return syncLocalData
}

/** 生成用户同步store的对象的入参 */
type CreateUserSaveInStoreParams<T> = {
  /** 存在于store中的key */
  storeKey: string
  /** 生成默认值 */
  createDefault: () => T
  /** 未登录的场景是否需要持久化 */
  isPersistUnLogin?: boolean
}

/** 用户数据同步到store中,注意必须是对象形式的用户数据,基础值不可以这样搞
 * 注: 切换store的时候不需要建该类
 * 1.因为在调用 [get] 时会调用 [maybeUpdateUserDataMapper];
 * 2.[maybeUpdateUserDataMapper] 中有替换对象的操作,会让vue重新对其建立响应关系.
 */
export class UserDataSyncInStore<T extends Any = Any> {
  constructor(params: CreateUserSaveInStoreParams<T>) {
    const { storeKey, createDefault, isPersistUnLogin = false } = params
    this.storeKey = storeKey
    this.createDefault = createDefault
    this.isPersistUnLogin = isPersistUnLogin
    this.init()
  }
  /** 存在于store中的key */
  private storeKey: string
  /** 生成默认值 */
  private createDefault: () => T
  /** 未登录的场景是否需要持久化 */
  private isPersistUnLogin: boolean
  /** 用户同步数据的mapper */
  private userDataMapper: Record<string, T> = {}

  /** 取得数据 不传入参数,就是取得整个对象(或基础属性) */
  public get(): T
  public get<Key extends keyof T = keyof T>(key: Key): T[Key]
  public get(key?: Any) {
    /** 用户标识(一定存在,未登录就是unlogin) */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    // 可能更新用户记录
    this.maybeUpdateUserDataMapper(userSign)
    if (key) {
      return (this.userDataMapper[userSign] as T)[key as keyof T]
    }
    return this.userDataMapper[userSign]
  }
  /** 设置数据 只传入第一个参数不传入第二个参数,就表示是在设置一个基础值 */
  public set(value: T): void
  public set<Key extends keyof T = keyof T>(key: Key, value: T[Key]): void
  public set(keyOrVal: Any, value?: Any) {
    /** 用户标识(一定存在,未登录就是unlogin) */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    // 可能更新用户记录
    this.maybeUpdateUserDataMapper(userSign)
    if (typeof keyOrVal !== 'undefined' && typeof value !== 'undefined') {
      ;(this.userDataMapper[userSign] as T)[keyOrVal as keyof T] = value
      return
    }
    this.userDataMapper[userSign] = keyOrVal
  }

  /** 初始化数据 */
  private init() {
    const { storeKey } = this
    const dataStr = localStorage.getItem(storeKey) || '{}'
    /** 用户记录映射 */
    this.userDataMapper = createSyncLocalStoreObject(
      JSON.parse(dataStr) as Record<string, T>,
      storeKey
    )
    /** 用户标识(一定存在,未登录就是unlogin) */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    // 可能更新用户记录
    this.maybeUpdateUserDataMapper(userSign)
  }

  /** 上一次的用户id,如果用户标记改变 */
  private lastUserSign = ''
  /** 可能更新用户数据存储的mapper
   * 调用时机:
   * 1.初始化时.
   * 2.登录账号切换后.(由get和set函数触发,为了让vue2能监听到新对象的响应效果).
   */
  private maybeUpdateUserDataMapper(userSign: string) {
    // 如果id未改变,则啥也不做
    if (userSign === this.lastUserSign) {
      return
    }
    // 如果本次是未登录状态,并且不持久化未登录的状态,则将未登录状态更新为初始值
    if (userSign === 'unlogin' && !this.isPersistUnLogin) {
      this.userDataMapper[userSign] = this.createDefault()
    }
    // 如果当前数据没有缓存,则表示登录了新账号,则需要新建一个默认值
    if (!this.userDataMapper[userSign]) {
      this.userDataMapper[userSign] = this.createDefault()
    }
    // 重新生成一个完整的对象,这是为了让Vue对其重新建立响应效果
    this.userDataMapper = createSyncLocalStoreObject(
      { ...this.userDataMapper },
      this.storeKey
    )
    // 更新用户标记
    this.lastUserSign = userSign
  }
}
