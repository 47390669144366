import { Base, Component, Watch } from '@/vue-property-decorator'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { GlobalEvents } from '@/context'
import { HAS_JACKPOT } from './config'
import { RouteName } from '@/router/config'
import { SelfSportId } from '@/views/selfoperated-games/sports/const'
import { apiGetSportsToken } from '@/api/selfoperated-games/sports'
import { useEventStore } from '@/views/event/store'
import { useGameStore } from '@/views/game/store'
import { useJackPotStore } from '../../components/jackpotCard/store'
import { useMainStore } from '@/store/index'
import { useSportsStore } from '@/views/selfoperated-games/sports/store'
import ComponentsManager, {
  ConfigType,
  ThemeComponentType
} from '@/context/components-manager'
import Skin from '@/controller/Skin'
import SuggestDownload from '@/layouts/components/suggestDownload'
import style from './style.module.scss'

@Component<HomePage>({
  name: 'HomePage',
  components: (() => {
    return {
      ...ComponentsManager.register({
        namespace: ConfigType.Theme,
        moduleType: ThemeComponentType.HomePage
      })
    }
  })()
})
export default class HomePage extends Base {
  mainStore = useMainStore()
  gameStore = useGameStore()

  /**
   * 动态计算当前渲染的组件名
   */
  private get renderPageComponentsName() {
    return ComponentsManager.getName({
      namespace: ConfigType.Theme,
      moduleType: ThemeComponentType.HomePage,
      key: Skin.skinType
    })
  }

  /**
   * 当前游戏分类id
   */
  private get currentGameCategoryId(): number {
    return Number(this.$route.query.gameCategoryId)
  }

  private jumpDefaultCategoryId() {
    // 欧美版在此处回显旧的ID
    const cateId = this.gameStore.dynamicGameCategoryId
    this.$router.push({
      name: RouteName.GAME,
      query: { gameCategoryId: String(cateId) }
    })
  }
  get gameCategoriesAvailable() {
    return useGameStore().gameCategoriesAvailable
  }

  get activeGameCategoryId() {
    return useGameStore().activeGameCategoryId
  }

  get sabaUrl() {
    return useSportsStore().sabaUrl
  }

  private handleSabaLogin() {
    const SportId = GameCategoryIds.Sports
    if (
      ![this.currentGameCategoryId, this.activeGameCategoryId].includes(SportId)
    ) {
      return
    }
    // 体育游戏分类
    const sportGameCategory = this.gameCategoriesAvailable.find(
      (gameCategory) => {
        if (gameCategory.gameCategoryId === SportId) {
          return true
        }
      }
    )
    if (!sportGameCategory) {
      return
    }
    // WG体育游戏
    const WGSportGame = sportGameCategory.list.find((platformCategory) => {
      if (platformCategory.gameId === SelfSportId) {
        return true
      }
    })
    if (!WGSportGame) {
      return
    }
    // WG体育处于维护状态
    if (WGSportGame.maintainStatus) {
      return
    }
    apiGetSportsToken()
  }

  /**监听游戏列表，并且当前的游戏列表为体育时候，需要请求saba登录 */
  @Watch('gameCategoriesAvailable', { immediate: true })
  @Watch('gameCategoryId', { immediate: true })
  @Watch('activeGameCategoryId', { immediate: true })
  private onHandleSabaLogin() {
    this.handleSabaLogin()
  }

  /**
   * 处理所有版式默认选中的菜单分类
   */
  private initGameCategoryId() {
    const { isEuropeanAmerican, isClassicStyle, isCyanBlue, isHK } =
      Skin.currentTheme()
    const { setActiveTabkey, setActiveGameCategoryId } = this.gameStore

    setActiveGameCategoryId(this.currentGameCategoryId)
    // gameCategoryId不存在我们菜单内都强制跳转回热门
    if (!Object.values(GameCategoryIds).includes(this.currentGameCategoryId)) {
      this.jumpDefaultCategoryId()
      if (!isEuropeanAmerican) {
        setActiveTabkey(GameCategoryIds.Hot)
      }
      return
    }

    if (isClassicStyle || isCyanBlue || isHK) {
      //如果是最近|收藏经典版式强制跳转回热门
      if (
        [GameCategoryIds.Recent, GameCategoryIds.Collenct].includes(
          this.currentGameCategoryId
        )
      ) {
        this.jumpDefaultCategoryId()
      }
      setActiveTabkey(GameCategoryIds.Hot)
    }
  }

  created() {
    this.initGameCategoryId()
    this.initJackpotData()
    const { freshEventCategoryAndActivity, setFirstLoaded } = useEventStore()
    freshEventCategoryAndActivity()
    setFirstLoaded(false)
  }
  mounted(): void {
    this.sendIndicatorA()
  }
  private sendIndicatorA() {
    if (sessionStorage.getItem('IndicatorA')) {
      return
    }
    sessionStorage.setItem('IndicatorA', 'true')
    setTimeout(() => {
      GlobalEvents.dispatch({
        type: 'MONITOR_EVENT',
        eventName: 'IndicatorA',
        payload: {}
      })
    }, 10000)
  }
  private initJackpotData() {
    // 指定版需要开启彩金池轮询
    if (HAS_JACKPOT.includes(Skin.skinType)) {
      //触发虚拟彩金池
      const { getVirtualBonusPool } = useJackPotStore()
      getVirtualBonusPool()
    }
  }

  /**
   * 是否显示顶部的下载横幅,特殊模式,与青蓝,有特殊处理方式,不在此处显示.
   */
  get showTopSuggestDownload() {
    const { isCyanBlue } = Skin.currentTheme()
    // 青蓝版的,有另外写,不显示在此处
    if (isCyanBlue) return false
    // 其它情况,顶部就是显示在此处
    return true
  }

  render() {
    return (
      <div>
        {this.showTopSuggestDownload && <SuggestDownload position={'pc_top'} />}
        <div
          class={[
            style.home,
            'home-page',
            this.currentGameCategoryId === GameCategoryIds.Hot
              ? style.hotHome
              : ''
          ]}
        >
          <this.renderPageComponentsName
            {...{
              attrs: {
                'data-components-name': this.renderPageComponentsName
              }
            }}
          />
        </div>
        <SuggestDownload
          position={'pc_bottom'}
          class={style['suggest-download']}
        />
      </div>
    )
  }
}
