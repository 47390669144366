import {
  PendingReward,
  PopWaitingGetWeb
} from '../../api/apiGetPopWaitingGetReward/class/popWaitingGet'
import { PopTypeBeforeLogin } from '../../../common/util/judgePopupType'
import { ReceiveLogPopSwitchEnum } from '@/api/event/type'
import { useMainStore } from '@/store/index'
import { userRecord } from './userOpeningWaitingGetRecord'

/** 首充弹窗的State */
export class waitingGetState {
  constructor() {
    const { isHaveOpenedEver, isHaveOpenedToday } =
      userRecord.getUserOpeningRecord()
    this.isHaveOpenedEver = isHaveOpenedEver
    this.isHaveOpenedToday = isHaveOpenedToday
  }
  /** 今天是否已经打开过弹窗 */
  isHaveOpenedToday = false
  /** 曾经是否已经打开过弹窗 */
  isHaveOpenedEver = false
  /** 后台是否配置的禁用弹窗 */
  isServerDisabled = false
  /** 用户是否勾选了永久禁用 0:启用 1:禁用 */
  isNeverRemind = false
  /** 用户是否勾选了本次不再弹出 */
  isThisTimeNoRemind = false
  /** 曾经是否领取过(任务未完成时弹出的配置下,如果领取过,就不能再弹出了) */
  isReceivedEver = false
  /** 登录前弹窗状态  */
  beforeLoginPopType = PopTypeBeforeLogin.constantly
  /** 登录后弹窗状态  */
  afterLoginPopType = ReceiveLogPopSwitchEnum.OnlyPop
  /** 今日待领取奖励列表 */
  pendingRewardList: PendingReward[] = []
  /** 明日可领取奖励列表 */
  collectTomorrowList: PendingReward[] = []
  /** 今日已派发奖励列表 */
  receivedTodayList: PendingReward[] = []

  /** 注入接口返回的已转换成web的数据 */
  public static immitPopWaitingGetWeb: (
    target: waitingGetState,
    data: PopWaitingGetWeb
  ) => waitingGetState
  /** 重置state */
  public static resetState: (target: waitingGetState) => waitingGetState
}

/** 注入接口返回的已转换成web的数据 */
waitingGetState.immitPopWaitingGetWeb = function (
  target = new waitingGetState(),
  data: PopWaitingGetWeb
) {
  // 试玩账号,永久不禁止(10月2号:522业主强烈要求,Life和Marshall提出前端做此兼容)
  // PS:在取得数据处修改,而未在判断处修改,是因为,用户本次如何勾选了,永不提示,那至少在他刷新页面以前,不要再重复弹窗了
  target.isNeverRemind = !useMainStore().hasLogined ? false : data.isNeverRemind
  target.isServerDisabled = data.isServerDisabled
  target.afterLoginPopType = data.afterLoginPopType
  target.beforeLoginPopType = data.beforeLoginPopType
  target.pendingRewardList = data.pendingRewardList
  target.collectTomorrowList = data.collectTomorrowList
  target.receivedTodayList = data.receivedTodayList
  target.isReceivedEver = data.isReceivedEver
  return target
}
/** 重置state */
waitingGetState.resetState = function (target: waitingGetState) {
  //PS:就目前的代码来看,其实就是重新new了一个对象
  const { isHaveOpenedEver, isHaveOpenedToday } =
    userRecord.getUserOpeningRecord()
  target.isHaveOpenedEver = isHaveOpenedEver
  target.isHaveOpenedToday = isHaveOpenedToday
  target.isNeverRemind = false
  target.isThisTimeNoRemind = false
  target.isServerDisabled = false
  target.isReceivedEver = false
  target.beforeLoginPopType = PopTypeBeforeLogin.constantly
  target.afterLoginPopType = ReceiveLogPopSwitchEnum.OnlyPop
  target.pendingRewardList = []
  target.collectTomorrowList = []
  target.receivedTodayList = []
  return target
}
/** 初始化 state 若切换了多语言,所有接口也是需要重新请求的,所以,所有数据都需要重新初始化一遍 */
export const initState = (): Omit<waitingGetState, 'immitPopWaitingGetWeb'> => {
  return new waitingGetState()
}
