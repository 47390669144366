import {
  SeekApiCode,
  proxyOpenSequentiallyTaskModal
} from './proxyOpenSequentiallyTaskModal'
import { State, initState, resetState } from './initState'
import { TaskCategoryApiCodeEnum as TCACE } from '../../api/type'
import { TaskRuleConstantMysterySpecial } from '../../class/TaskRuleConstantMysterySpecial'
import { UserOpeningTaskModalRecord } from './initState/userOpeningTaskModalRecord'
import { apiConstantTaskPopCluster } from '../../api/apiConstantTaskPop'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { merge } from 'lodash'
import { useMainStore } from '@/store/index'
import autoModalManager from '@/context/autoModalManager'
//eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 静态store的类型守卫 */
export const isTaskModalConstant = (data: Any): data is TaskModalConstant => {
  return data.$id === 'taskModalConstant'
}
/** 本store的状态 */
export type TaskModalConstant = ReturnType<typeof useTaskModalConstant>
/** 静态任务弹框的store */
export const useTaskModalConstant = defineStore('taskModalConstant', {
  state: initState as () => State,
  getters: {
    /** 是否所有任务规则全都为空 */
    isAllTaskRulesEmpty(): boolean {
      // 数据全都为空,则返回true
      return this.codeCategoryOrder.every(
        (apiCode) => this.codeRuleConstantsMapper[apiCode].length === 0
      )
    }
  },
  actions: {
    /** 刷新常量数据 */
    async freshConstantData(
      apiCode: TCACE[] = [TCACE.xrfl, TCACE.mrrw, TCACE.mzrw, TCACE.smrw]
    ) {
      const mapper = await apiConstantTaskPopCluster(...apiCode)
      /** 会员层级,如果未登录,则默认填入1 */
      const memberLevel = useMainStore().userInfos?.member_level || 1
      for (const key in mapper) {
        const apiCode = Number(key) as TCACE
        const {
          beforeLoginPopType,
          afterLoginPopType,
          rules,
          userLevel,
          specialSupplements
        } = mapper[apiCode]
        // 对任务规则列表进行赋值操作
        this.codeRuleConstantsMapper[apiCode] = rules
        // 新人福利要特殊一点,它如果过期的话,就算有值也不能取,直接给个空数组
        if (apiCode === TCACE.xrfl) {
          /** 新人福利是否过期 */
          const isExpired = TaskRuleConstantMysterySpecial.judgeSrflIsExpired(
            specialSupplements?.limitDay || 0
          )
          if (isExpired) {
            this.codeRuleConstantsMapper[apiCode] = []
          }
        }
        // 匹配用户层级,如果该任务允许的用户层级[不为空|不包含0],则说明它并不是全层级匹配
        if (userLevel[0] !== undefined && !userLevel.includes(0)) {
          // 如果与当前用户层级匹配不上,则清空列表
          if (!userLevel.includes(memberLevel)) {
            this.codeRuleConstantsMapper[apiCode] = []
          }
        }

        this.codePopBefLoginMapper[apiCode] = beforeLoginPopType
        this.codePopAftLoginMapper[apiCode] = afterLoginPopType
        // 如果是新人福利或者神秘任务.则提取出特别的规则项,神秘任务或者新人福利它们都仅会返回与自己相关的那部分值,所以直接merge混合在一起
        if ([TCACE.xrfl, TCACE.smrw].includes(apiCode)) {
          this.specialConfig = merge(
            new TaskRuleConstantMysterySpecial(),
            this.specialConfig,
            specialSupplements
          )
        }
      }
    },
    /** 打开任务弹框队列, 根据参数apiCode匹配不同类型的任务弹窗 */
    openSequentiallyTaskModal(param: SeekApiCode, modalName: string): void {
      proxyOpenSequentiallyTaskModal(this, param).then(() => {
        autoModalManager.onModalClose(modalName)
      })
    },
    /** 设置关闭模态框和队列的函数 */
    setStopModalAndQueue(stop: null | (() => void)) {
      this.stopModalAndQueue = stop
    },
    /** 更新今日不再提醒 */
    switchTodayNotReminderMapper(apiCode: TCACE, status: boolean) {
      this.codeTodayNotReminderMapper.set(apiCode, status)
    },
    /** 更新是否永远不再提醒 */
    switchNeverReminder(apiCode: TCACE, status: boolean) {
      this.codeNeverReminderMapper.set(apiCode, status)
    },
    /** 记录用户打开弹窗记录 */
    recordUserOpeningRecord(apiCode: TCACE) {
      const userRecord = UserOpeningTaskModalRecord.getInstance()
      this.codeIsHaveOpenedTodayMapper[apiCode] = true
      this.codeIsHaveOpenedEverMapper[apiCode] = true
      userRecord.recordUserOpeningRecord(apiCode)
    },
    /** 重置state */
    myRest() {
      resetState(this)
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.taskModalConstant',
        paths: [
          'codeThisTimeNoReminderMapper',
          'codeCategoryMapper',
          'codeRuleConstantsMapper'
        ],
        storage: createMyPersistedStorage()
      }
    ]
  }
})
