import { FinanceSharedState } from './type'
import { apiFinanceGetOnlinePayBankList } from '@/api/finance'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { useMainStore } from '@/store/index'
import to from 'await-to-js'

const useFinanceShareStore = defineStore('finance/shared', {
  state: (): FinanceSharedState => {
    return {
      onlinePayBankList: [],
      currency: ''
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.withdrawBank',
        storage: createMyPersistedStorage(),
        paths: ['onlinePayBankList', 'currency']
      }
    ]
  },

  getters: {
    mainStore() {
      return useMainStore()
    },

    userCurrency() {
      return useMainStore().userInfos?.currency ?? 'CNY'
    }
  },

  actions: {
    /**
     * 初始化银行卡列表信息[网银充值]
     * @returns
     */
    async initOnlinePayBankList(merchCode: string, currency: string) {
      const siteCode = this.mainStore.siteCode

      const [err, data] = await to(
        apiFinanceGetOnlinePayBankList({
          merchCode,
          siteCode,
          currency
        })
      )
      if (err) {
        console.error('init bank list failed:', err)
        return
      }
      this.onlinePayBankList = data.data.data ?? []
    }
  }
})

export { useFinanceShareStore }
