import { JudgeIsModalOpenable } from './judgeIsModalOpenable'
import { SeekApiCode } from '.'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { TaskModalConstant } from '../index'
import { TaskModelCloseEmitParams } from '@/store/index/type'
import { useMainStore } from '@/store/index'
import Modal from '@/controller/Modal'

/** apiCode与modalName之间的映射 */
const apiCodeModalNameMapper = {
  [TCACE.xrfl]: 'newBenefitsModal',
  [TCACE.mrrw]: 'tasksDaily',
  [TCACE.mzrw]: 'tasksWeekly',
  [TCACE.smrw]: 'tasksEvery3Day'
} as const

/** 队列执行计数器返回内容 */
type QueueCountingRet = {
  /** 全部执行完成后resolve的promise */
  allExePromise: Promise<unknown>
  /** 队列每一项执行完成后,需要调用的回调 */
  exeOne: () => void
}
/** 创建任务弹框队列
 * @param store
 * @param params 需要被打开的弹框的code,支持传入apiCode大于1的任意个
 * @param exeOneCallback 当队列每一项执行完成时的回调函数
 * @returns 任务弹框打开队列
 */
export const createTaskModalOpeningQueue = (
  store: TaskModalConstant,
  param: SeekApiCode,
  exeOneCallback: QueueCountingRet['exeOne']
) => {
  /** 关闭弹框,中止队列 */
  const stop = () => proxyStop()
  /** 代理关闭函数(实际关闭函数) */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let proxyStop = () => {}
  /** 是否继续执行下一个环节,弹框在某些特定情况下关闭后,不再需要执行后面的环节,将该值改为 false */
  let isContinueNext = true
  /** 当前登录状态 */
  const curLoginStatus = useMainStore().hasLogined
  /** 遍历的数组  将原param转换成可以便利的队列数组*/
  let traverseArr: TCACE[]
  if (Array.isArray(param.apiCode)) {
    traverseArr = param.apiCode
  } else {
    traverseArr = [param.apiCode]
  }
  /** 打开任务弹窗队列 */
  const openingQueue = traverseArr.map((apiCode) => {
    return () =>
      new Promise(async (resolve) => {
        // 判断是否可以打开弹窗
        const isOpenable =
          await JudgeIsModalOpenable.getInstance().judgeConstant({
            store,
            apiCode,
            ignorePageAddress: param.ignorePageAddress,
            isLoginTrigger: param.isLoginTrigger
          })
        // 当前的登录状态改变了，则不再执行后面的任务队列，故意将该判断放在请求后，因为判断行为是异步的，此时用户可能会有别的操作
        if (curLoginStatus !== useMainStore().hasLogined) {
          isContinueNext = false
        }
        if (!isOpenable || !isContinueNext) {
          resolve(undefined)
          exeOneCallback()
          return
        }
        const modelName = apiCodeModalNameMapper[apiCode]
        proxyStop = () => {
          Modal.close(modelName)
          isContinueNext = false
        }
        Modal.open(modelName).then((context) => {
          //重点,环境监听,只能使用$once,不然会重复监听越来越多的内容
          context.$once('modal:close', (event?: TaskModelCloseEmitParams) => {
            // 当点击【前往】按钮时，将不会继续后面的操作。 因为点击后肯定有打开其它的弹窗，或是到达别的路由页面
            if (event?.trigger === 'forward') {
              isContinueNext = false
            }
            resolve(undefined)
            exeOneCallback()
          })
        })
      })
  })
  return { openingQueue, stop }
}

/** 创建一个队列计数的promise
 * @param length 队列长度
 * @returns {QueueCountingRet} [allExePromise:全部执行完成后resolve的promise]   [exeOne: 队列每一项执行完成后,需要调用的回调]
 */
export const createQueueCountingPromise = (
  length: number
): QueueCountingRet => {
  let allExeFinish: (...params: unknown[]) => void
  const exeOne = () => {
    length--
    if (length <= 0) {
      allExeFinish()
    }
  }
  const allExePromise = new Promise((resolve) => {
    allExeFinish = resolve
  })
  return {
    allExePromise,
    exeOne
  }
}

/** 获得弹窗队列的长度,最长4,最短1  */
export const getModalQueueLength = (param: SeekApiCode) => {
  let queueLength = 1
  if (Array.isArray(param.apiCode)) {
    queueLength = param.apiCode.length
  }
  return queueLength
}
