import { ActivityConfig } from './activityConfig'
import { FirstChargeCondition } from '../../api/apiConstantFirstCharge'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '../../../common/util/judgePopupType'
import { UserDataSyncInStore } from '../../../common/util/userDataSyncInStore'
import { userRecord } from './userOpeningFirstChargeRecord'

/** 首充弹窗的State */
export class FisrtChageConstantState {
  constructor() {
    const { isHaveOpenedEver, isHaveOpenedToday } =
      userRecord.getUserOpeningRecord()
    this.isHaveOpenedEver = isHaveOpenedEver
    this.isHaveOpenedToday = isHaveOpenedToday
  }
  /** 今天是否已经打开过弹窗 */
  isHaveOpenedToday = false
  /** 曾经是否已经打开过弹窗 */
  isHaveOpenedEver = false
  /** 后台是否配置的禁用弹窗 */
  isServerDisabled = false
  /** 用户是否勾选了永久禁用 0:启用 1:禁用 */
  isNeverRemind = new UserDataSyncInStore({
    createDefault: () => false,
    storeKey: 'firstChargeAllUserCheckNeverReminderMapper',
    isPersistUnLogin: true
  })
  /** 用户是否勾选了本次不再弹出 */
  isTodayNotRemind = new UserDataSyncInStore({
    createDefault: () => false,
    storeKey: 'firstChargeAllUserCheckTodayReminderMapper',
    isPersistUnLogin: true
  })
  /** 曾经是否领取过(任务未完成时弹出的配置下,如果领取过,就不能再弹出了) */
  isReceivedEver = false
  /** 登录前弹窗状态  */
  beforeLoginPopType = PopTypeBeforeLogin.constantly
  /** 登录后弹窗状态  */
  afterLoginPopType = PopTypeAfterLogin.constantly
  /** 活动配置 */
  activityConfig = new ActivityConfig()
  /** 奖项条件列表 */
  rewardConditionList: FirstChargeCondition[] = []
}

/** 初始化 state 若切换了多语言,所有接口也是需要重新请求的,所以,所有数据都需要重新初始化一遍 */
export const initState = (): Omit<
  FisrtChageConstantState,
  'immitPopFirstChargeWeb'
> => {
  return new FisrtChageConstantState()
}
