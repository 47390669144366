import { CustomCategoryId } from '../../consts/categorys'
import {
  GameCategoriesAvailable,
  GameCategoryWithExtLink
} from '@/api/common/type'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { SvgSprite } from '@/components/icon-sprite'
import { useGameStore } from '@/views/game/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import GameMenuActionHandler from './actionHandler'
import HallCustomization from '@/controller/hallCustomization'
import Resource from '@/context/resource'

export default class _GameCategoryDataBuilder {
  /**
   * 处理游戏分类列表。包含基础游戏分类和传入的额外分类。
   * @param {GameCategoriesAvailable[]} extraCategories 额外的游戏分类
   */
  private static _processGameCategories(
    extraCategories: GameCategoriesAvailable[]
  ): GameCategoriesAvailable[] {
    const { t } = useI18n()
    const { gameCategoriesAvailable } = useGameStore()
    const { gameIconMap, gameIconSelectMap } =
      HallCustomization.useGameShowImage()
    const { hasLogined } = useMainStore()

    const processedCategories = [...gameCategoriesAvailable].reduce(
      (
        accumulator: GameCategoriesAvailable[],
        current: GameCategoriesAvailable
      ) => {
        // 是否为已枚举游戏分类id，避免脏数据
        const isValidCategoryId = Object.values(GameCategoryIds).includes(
          current.gameCategoryId
        )
        // 登录状态过滤试玩类别
        if (
          !(current.gameCategoryId === GameCategoryIds.Demo && hasLogined) &&
          current.display &&
          isValidCategoryId
        ) {
          const modifiedCategory: GameCategoriesAvailable = {
            ...current,
            gameCategoryName: t(
              `lobby.gameMenu.menu${current.gameCategoryId}`
            ) as string
          }
          accumulator.push(modifiedCategory)
        }
        return accumulator
      },
      []
    )

    return [
      {
        gameCategoryId: GameCategoryIds.Hot,
        gameCategoryName: t('lobby.gameMenu.menu0'),
        display: true,
        customizeIcon: gameIconMap.get(GameCategoryIds.Hot),
        customizeIconActive: gameIconSelectMap.get(GameCategoryIds.Hot)
      },
      // 处理后的基础游戏分类
      ...processedCategories,
      // 额外的游戏分类
      ...extraCategories
    ]
  }

  /**
   * 生成最近和收藏分类列表
   * @param {boolean} includeRecentAndCollect 是否包含最近和收藏类别，默认为 false
   */
  private static _generateExtraCategories(includeRecentAndCollect = false) {
    const { t } = useI18n()
    return includeRecentAndCollect
      ? [
          {
            gameCategoryName: t('lobby.gameMenu.loadingRecent'),
            gameCategoryId: GameCategoryIds.Recent
          },
          {
            gameCategoryName: t('lobby.gameMenu.loadingCollect'),
            gameCategoryId: GameCategoryIds.Collenct
          }
        ]
      : []
  }

  /** 生成三方菜单外链 */
  private static _generateExternalLinks(): GameCategoryWithExtLink[] {
    const { gameCategorieExtLink } = useGameStore()
    return gameCategorieExtLink.map((item, index) => ({
      gameCategoryName: item.linkName,
      gameCategoryId: CustomCategoryId + index + gameCategorieExtLink.length,
      customizeIcon: item.icon,
      ...item
    }))
  }
  // /**
  //  * 定制的游戏分类列表。包含基础分类 + 可选的最近和收藏分类 + 外链分类
  //  * @param {boolean=} includeRecentAndCollect 是否包含最近和收藏类别，默认为 false
  //  */
  // static generateCustomCategories(
  //   includeRecentAndCollect = false
  // ): GameCategoryWithExtLink[] {
  //   const { gameCategorieExtLink } = useGameStore()
  //   const gameCategories = this._processGameCategories([
  //     ...this._generateExtraCategories(includeRecentAndCollect)
  //   ]) as GameCategoryWithExtLink[]
  //   const externalLinks: GameCategoryWithExtLink[] = gameCategorieExtLink.map(
  //     (item, index) => ({
  //       gameCategoryName: item.linkName,
  //       gameCategoryId: CustomCategoryId + index,
  //       customizeIcon: item.icon,
  //       ...item
  //     })
  //   )
  //   return [
  //     ...gameCategories,
  //     // 自定义外链类别列表
  //     ...externalLinks
  //   ]
  // }

  /** 基础的定制游戏分类列表，不包含最近和收藏分类 */
  public static get basicCustomCategories(): GameCategoryWithExtLink[] {
    const gameCategories = this._processGameCategories(
      []
    ) as GameCategoryWithExtLink[]
    const externalLinks = this._generateExternalLinks()

    return [...gameCategories, ...externalLinks]
  }

  /**  完整的定制游戏分类列表，包含最近和收藏分类 */
  public static get completeCustomCategories(): GameCategoryWithExtLink[] {
    const gameCategories = this._processGameCategories([
      ...this._generateExtraCategories(true)
    ]) as GameCategoryWithExtLink[]
    const externalLinks = this._generateExternalLinks()

    return [...gameCategories, ...externalLinks]
  }

  /** 特殊 icon，仅使用svg icon，如stake中部导航 */
  public static listUseSvgIcon(
    list: GameCategoryWithExtLink[],
    key: 'normal' | 'active'
  ): GameCategoryWithExtLink[] {
    return list.map((item: GameCategoryWithExtLink) => {
      if (!GameMenuActionHandler.isExternalLinkOrJumpPosition(item)) {
        item.icon = (item.customizeIcon ??
          Resource.gameMenu[item.gameCategoryId][key]) as
          | string
          | (SvgSprite & string)
          | undefined
        item.iconActive =
          item.customizeIconActive ??
          Resource.gameMenu[item.gameCategoryId][key]
      }
      return item
    })
  }

  /** 获取游戏导航icon  */
  public static getGameIcon(
    item: GameCategoryWithExtLink | GameCategoriesAvailable,
    active = false
  ): string | SvgSprite {
    let icon = active ? item.iconActive : item.icon
    if (!icon) {
      icon = active
        ? Resource.gameMenu[item.gameCategoryId].active
        : Resource.gameMenu[item.gameCategoryId].normal
    }
    return icon
  }
  // /**
  //  * 游戏分类列表。包含基础分类 + 可选的最近和收藏分类
  //  * @param {boolean=} includeRecentAndCollect 是否包含最近和收藏类别，默认为 false
  //  */
  // public static getGameCategories(
  //   includeRecentAndCollect = false
  // ): GameCategoriesAvailable[] {
  //   const extraCategories = this._generateExtraCategories(
  //     includeRecentAndCollect
  //   )
  //   return this._processGameCategories(extraCategories)
  // }

  /** 完整的游戏分类列表。包含基础分类 + 最近和收藏分类 */
  public static get completeGameCategories(): GameCategoriesAvailable[] {
    return this._processGameCategories([...this._generateExtraCategories(true)])
  }

  /** 基础的游戏分类列表。不包含最近和收藏分类 */
  public static get basicGameCategories(): GameCategoriesAvailable[] {
    return this._processGameCategories([])
  }

  /** 三方菜单外链 */
  public static get externalLinks(): GameCategoryWithExtLink[] {
    return this._generateExternalLinks()
  }
}
