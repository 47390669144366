import { BetType, HandicapAreas, MarketType, TabType } from '../const'
import {
  Market,
  MarketSelection,
  MatchEvent,
  SportsEvents,
  queryTime
} from '@/api/selfoperated-games/sports/type'
import { MarketKey, MarketMap } from '../types'
import { RenderItem } from '@/components/business-components/tabs'
import { clone } from 'lodash'
import { isGreaterThan } from '@/utils/MathUtils'
import { useI18n } from '@/i18n'
import { useSportsStore } from '../store'
import DateUtils from '@/utils/DateUtils'
/** 让球 */
export const HandicapBetTpes = [
  BetType.Handicap,
  BetType.OneXHandicap,
  BetType.TwoXHandicap,
  BetType.MatchPointHandicap
]
/** 大小盘 */
export const OverUnderBetTypes = [
  BetType.OverUnder,
  BetType.OneXOverUnder,
  BetType.TwoXOverUnder
]
/** 胜平负 */
export const WinDrawLoseBetTypes = [
  BetType.FTp1X2,
  BetType.OneFtp1x2,
  BetType.TwoFtp1x2
]
/** 胜负盘 */
export const MoneyLine = [BetType.MoneyLine, BetType.OneMoneyLine]

/** markets 数据根据 eventId关联到 events */
export const appendMarketsToEvent = (data: SportsEvents) => {
  const events: MatchEvent[] = data.events
  const markets = data?.markets
  events.forEach((event: MatchEvent) => {
    event.markets = markets?.filter((market: Market) => {
      return event.eventId === market.eventId
    })
  })
  return events
}

/**
 * 获取联赛列表
 * 获取赛事列表+盘口
 * 获取赛事数据-球类
 * 因为是根据本地时间来的，可能不同时区的用户获取的数量不一样
 * @param type
 * @returns
 */
export const getDefualtQueryTime = (type: MarketKey): queryTime => {
  const translateTime = (time: Date) => {
    return DateUtils.moment(time).format('YYYY-MM-DDTHH:mm:ss')
  }
  switch (type) {
    // from:开始时间
    // until:向后30天
    case TabType.Champion:
    case TabType.Morning:
      return {
        from: translateTime(DateUtils.moment().toDate()),
        until: translateTime(DateUtils.moment().add(30, 'day').toDate())
      }
    // from:昨天18点
    // until:今日23:59:59
    case TabType.Rolling:
    case TabType.Today:
      return {
        from: translateTime(
          DateUtils.moment().startOf('day').subtract(6, 'hour').toDate()
        ),
        until: translateTime(DateUtils.moment().endOf('day').toDate())
      }
    // from: 当前时间往前6小时
    // until: 往后30天
    case TabType.PassLeve:
      return {
        from: translateTime(DateUtils.moment().subtract(6, 'hour').toDate()),
        until: translateTime(DateUtils.moment().add(30, 'day').toDate())
      }
    // from: 当前时间往前30天
    // until: 往后30天
    // 默认是关注
    default:
      return {
        from: translateTime(DateUtils.moment().subtract(30, 'day').toDate()),
        until: translateTime(DateUtils.moment().add(30, 'day').toDate())
      }
  }
}

/** 格式化时间为 MM:SS */
export const formatSecondsToMMSS = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

/**
 * 三方体育专用，三方传回的时间字符串(时区GMT+0)
 * @param text 时间字段值 2023-07-08T12：12：12.026
 * @returns 按时区转换为用户本地时间
 */
export const toLocalDateTime = (
  text: string,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const mom = DateUtils.moment.utc(text, 'YYYY-MM-DDTHH:mm:ss.SSS')
  const dateStr = mom.local().format(format)
  return dateStr
}

/**
 * 将UTC时间字符串转化本地时间搓
 * @param text
 * @returns
 */
export const stringToUnix = (text: string) => {
  const mom = DateUtils.moment.utc(text).locale()
  return mom.valueOf()
}

/** 重复分类放到一起 */
export const mergeMarket = (markets: Market[]) => {
  const desMarkets: Market[] = []
  const sourceMarket = clone(markets)
  sourceMarket?.forEach((item, idx) => {
    // 选中状态时使用
    item.selections.forEach((element) => {
      element.marketId = item.marketId
      element.eventId = item.eventId
    })
    if (idx === 0) {
      desMarkets.push(item)
    } else {
      const tempList = desMarkets.filter((markets) => {
        if (item.betType === markets.betType) {
          // 相同投注类型合并
          markets.selections = markets.selections.concat(item.selections)
          // 去重相同 point并且key相同
          markets.selections = markets.selections.filter(
            (selection, index, array) => {
              return (
                array.findIndex(
                  (obj) =>
                    obj.point === selection.point && obj.key === selection.key
                ) === index
              )
            }
          )
          return true
        }
      })
      if (tempList.length === 0) {
        desMarkets.push(item)
      }
    }
  })
  return desMarkets
}

/** 获取投注头部盘口列表 */
export const getMarketTabs = (markets: Market[]) => {
  const ids: number[] = []
  const tabs: RenderItem[] = []
  markets.forEach((market) => {
    if (!ids.includes(market.betType)) {
      ids.push(market.betType)
      tabs.push({
        value: market.betType.toString(),
        label: getMarket(market.betType)?.name || market.betTypeName
      })
    }
  })
  return tabs
}

/**
 *
 * @param selection 盘口赔率项目列表
 * @returns
 */
export const getCurrentPrice = (selection: MarketSelection) => {
  const { handicapAreasKey } = useSportsStore()
  const newPrice =
    selection.oddsPrice[
      handicapAreasKey === HandicapAreas.Europe
        ? 'decimalPrice'
        : 'hongKongPrice'
    ]

  return Number(newPrice.toFixed(2))
}

/**
 * @param point 让球球头
 * @returns 让球存在0.25或0.75的情况，需处理为半球后返回
 */
export const getPoint = (point: number): string => {
  let balls = ''
  if (point?.toString().includes('.25') || point?.toString().includes('.75')) {
    balls = `${Math.abs(point) - 0.25}/${Math.abs(point) + 0.25}`
    if (point < 0) {
      balls = '-' + balls
    }
  } else {
    balls = point?.toString() || ''
  }
  return balls
}
/** 三方名称转换为我方名 */
export const getMarket = (betType: number): MarketMap | undefined => {
  const markets = getMarketsMap()
  const res = markets.find((item) => {
    if (typeof item.thirdKey === 'number') {
      return item.thirdKey === betType
    } else {
      const keys = item.thirdKey.split('-')
      return keys[0] === betType.toString() || keys[1] === betType.toString()
    }
  })
  return res
}

/** 我方与三方转换名称，以此为依据判断有 11种盘口投注展示类型 */
export const getMarketsMap = (): MarketMap[] => {
  const { t } = useI18n()
  return [
    {
      thirdKey: BetType.Handicap,
      name: t('lobby.selfoperatedGames.markets.handicap'),
      key: MarketType.HANDICA
    },
    {
      thirdKey: BetType.OneXHandicap,
      name: t('lobby.selfoperatedGames.markets.handicapUp'),
      key: MarketType.HANDICA
    },
    {
      thirdKey: BetType.TwoXHandicap,
      name: t('lobby.selfoperatedGames.markets.handicapDown'),
      key: MarketType.HANDICA
    },
    {
      thirdKey: BetType.OverUnder,
      name: t('lobby.selfoperatedGames.markets.bigsmall'),
      key: MarketType.OverUnder
    },
    {
      thirdKey: BetType.OneXOverUnder,
      name: t('lobby.selfoperatedGames.markets.bigsmallUp'),
      key: MarketType.OverUnder
    },
    {
      thirdKey: BetType.TwoXOverUnder,
      name: t('lobby.selfoperatedGames.markets.bigsmallDown'),
      key: MarketType.OverUnder
    },
    {
      thirdKey: BetType.FTp1X2,
      name: t('lobby.selfoperatedGames.markets.winDrawLose'),
      key: MarketType.SINGLE_DOUBLE
    },
    {
      thirdKey: BetType.OneFtp1x2,
      name: t('lobby.selfoperatedGames.markets.winDrawLoseUp'),
      key: MarketType.SINGLE_DOUBLE
    },
    {
      thirdKey: BetType.TwoFtp1x2,
      name: t('lobby.selfoperatedGames.markets.winDrawLoseDown'),
      key: MarketType.SINGLE_DOUBLE
    },
    {
      thirdKey: BetType.score,
      name: t('lobby.selfoperatedGames.markets.score'),
      key: MarketType.SCORE
    },
    {
      thirdKey: BetType.scoreUp,
      name: t('lobby.selfoperatedGames.markets.scoreUp'),
      key: MarketType.SCORE
    },
    {
      thirdKey: BetType.scoreDown,
      name: t('lobby.selfoperatedGames.markets.scoreDown'),
      key: MarketType.SCORE
    },
    {
      thirdKey: BetType.singleDouble,
      name: t('lobby.selfoperatedGames.markets.singleDouble'),
      key: MarketType.SINGLE_DOUBLE
    },
    {
      thirdKey: BetType.singleDoubleUp,
      name: t('lobby.selfoperatedGames.markets.singleDoubleUp'),
      key: MarketType.SINGLE_DOUBLE
    },
    {
      thirdKey: BetType.singleDoubleDown + '-' + BetType.singleDoubleDown2,
      name: t('lobby.selfoperatedGames.markets.singleDoubleDown'),
      key: MarketType.SINGLE_DOUBLE
    },
    {
      thirdKey: BetType.doubleChance,
      name: t('lobby.selfoperatedGames.markets.doubleChance'),
      key: MarketType.DOUBLE_CHANCE
    },
    {
      thirdKey: BetType.doubleChanceUp + '-' + BetType.doubleChanceUp2,
      name: t('lobby.selfoperatedGames.markets.doubleChanceUp'),
      key: MarketType.DOUBLE_CHANCE
    },
    {
      thirdKey: BetType.doubleChanceDown + '-' + BetType.doubleChanceDown2,
      name: t('lobby.selfoperatedGames.markets.doubleChanceDown'),
      key: MarketType.DOUBLE_CHANCE
    },
    {
      thirdKey: BetType.totalGoal,
      name: t('lobby.selfoperatedGames.markets.totalGoal'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.totalGoalUp,
      name: t('lobby.selfoperatedGames.markets.totalGoalUp'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.rightTotalGoal,
      name: t('lobby.selfoperatedGames.markets.rightTotalGoal'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.rightHomeTotalGoal,
      name: t('lobby.selfoperatedGames.markets.rightHomeTotalGoal'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.rightAwayTotalGoal,
      name: t('lobby.selfoperatedGames.markets.rightAwayTotalGoal'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.rightTotalGoalUp,
      name: t('lobby.selfoperatedGames.markets.rightTotalGoalUp'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.rightTotalGoalDown,
      name: t('lobby.selfoperatedGames.markets.rightTotalGoalDown'),
      key: MarketType.TOTAL_GOAL
    },
    {
      thirdKey: BetType.zeroLose,
      name: t('lobby.selfoperatedGames.markets.zeroLose'),
      key: MarketType.ZERO_LOSE
    },
    {
      thirdKey: BetType.zeroLoseWinner,
      name: t('lobby.selfoperatedGames.markets.zeroLoseWinner'),
      key: MarketType.ZERO_LOSE
    },
    {
      thirdKey: BetType.halfAll,
      name: t('lobby.selfoperatedGames.markets.halfAll'),
      key: MarketType.HALF_ALL
    },
    {
      thirdKey: BetType.special,
      name: t('lobby.selfoperatedGames.markets.special'),
      key: MarketType.SPECIAL
    },
    {
      thirdKey: BetType.winLose,
      name: t('lobby.selfoperatedGames.markets.winLose'),
      key: MarketType.WIN_LOSE
    }
  ]
}

type numbers = Array<Array<number>>

/**
 * 生成一组数字的全组合列表,组合数至少包含2名成员
 * @param nums
 * @example
 * nums:[1，2，3]
 * numbers: [[1,2][1,3],[2,3],[1,2,3]]
 * @returns
 */
export const generateFullCombinations = (nums: Array<number>): numbers => {
  const results: Array<Array<number>> = []
  const currentSubset: Array<number> = []

  function backtrack(start: number, subsetSize: number) {
    if (subsetSize >= 2) {
      results.push(currentSubset.slice())
    }

    for (let i = start; i < nums.length; i++) {
      currentSubset.push(nums[i])
      backtrack(i + 1, subsetSize + 1)
      currentSubset.pop()
    }
  }

  backtrack(0, 0)
  return results
}

/**
 * 生成一组数字n个种选择出来m
 * @param nums
 * @example
 * n:[1，2，3]
 * m:2
 * numbers: [[1,2][1,3],[2,3]]
 * @returns
 */
export const generatePartialCombinations = (
  n: number[],
  m: number
): numbers => {
  const results: number[][] = []
  function backtrack(start: number, subset: number[]): void {
    if (subset.length === m) {
      results.push(subset.slice())
    }

    for (let i = start; i < n.length; i++) {
      subset.push(n[i])
      backtrack(i + 1, subset)
      subset.pop()
    }
  }
  backtrack(0, [])
  return results
}

export const StartNotZeroReg = /^0/

/**
 * 当前数值是否大于0
 * @param number 当前数值
 * @returns
 */
export const isGreaterThanZero = (number: number): boolean =>
  isGreaterThan(number, 0)
