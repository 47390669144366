import { TabsTypeEnum } from '../tabList/type'
import { VNode } from 'vue'
import VueI18n from 'vue-i18n'
export enum BooleanType {
  OPEN = '1',
  CLOSE = '0'
}
/**
 * table渲染类型
 */
export enum TableType {
  /**
   * 正常模式
   */
  Normal,
  /**
   * 人数
   */
  People,

  /**
   * 金额
   */
  Amount,
  /**
   * 金钱 带单位
   */
  Money,
  /**
   * 自定义金额
   */
  CustomAmount,
  /**
   * 自定义前后缀
   */
  CustomNormal,
  /**
   * 日期
   */
  Date,
  /**
   * 时间
   */
  Time,
  /**
   * 小数
   * */
  Decimal = 7,
  /**
   * 单位换算
   */
  UnitFormat
}

export type CustomerRenderParams = {
  /**
   * 文本
   */
  text: string | number
  /**
   * 行数据
   */
  record?: Record<string, unknown>
  /**
   * 渲染类型
   */
  type?: TableType
  index?: number
  config?: Omit<RenderAmountParams, 'amount'>
  axiosError?: Error | null
  retryLoading?: boolean
  onRetry?: () => void
}

export type TextNodeType = string | VueI18n.TranslateResult

export type RenderAmountParams = {
  amount: number | string
  symbol?: TextNodeType
  isYellow?: boolean
  isGreen?: boolean
  isRed?: boolean
  suffix?: TextNodeType
  precision?: number
  sign?: boolean
  unitSize?: number
  withZero?: boolean
}

export type RenderTitleParams = {
  title: TextNodeType
  linkTo: TabsTypeEnum
  slot?: () => VNode
}

export type RenderContentParams = { title: TextNodeType } & CustomerRenderParams

export type AgentModeGetterType = Record<
  | 'isInfinite'
  | 'isFirstLevel'
  | 'isNetProfit'
  | 'isThreeNetProfix'
  | 'isNational',
  boolean
>
