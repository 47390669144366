import { JudgeIsForbidPopByPageAddress } from '../../../../../common/util/judgeIsForbidPopByPageAddress'
import { JudgeIsForbidPopByUserChecked } from './judgeIsForbidPopByUserChecked'
import { JudgePopupType } from '@/views/global-modal/modal/common/util/judgePopupType'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../../api/type'
import { type TaskModalConstant } from '../../index'
import { collectLogs } from '../../../../util/collectLogs'
import { useMainStore } from '@/store/index'
import type { TaskModalStore } from '../../../task-modal-store'
//eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 静态store的类型守卫 */
const isTaskModalConstant = (data: Any): data is TaskModalConstant => {
  return data.$id === 'taskModalConstant'
}
/** 静态部分数据store环境 */
type ConstantContext = {
  store: TaskModalConstant
  apiCode: TCACE
  /** 忽略页面地址 [若为true,打开弹窗前则不进行页面判断] */
  ignorePageAddress?: boolean
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}

/** 判断弹窗是否能够打开 */
export class JudgeIsModalOpenable {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  private static instance: JudgeIsModalOpenable
  public static getInstance() {
    if (!this.instance) {
      this.instance = new JudgeIsModalOpenable()
    }
    return this.instance
  }

  /** 判断用户是否勾选了禁用弹窗 */
  private judgeIsForbidPopByUserChecked = new JudgeIsForbidPopByUserChecked()
  /** 判断是否禁止弹出,通过页面地址判判断 */
  private judgeIsForbidPopByPageAddress = new JudgeIsForbidPopByPageAddress()

  /** 判断静态版的逻辑:新版的 */
  public async judgeConstant(context: ConstantContext) {
    const { apiCode, store, ignorePageAddress, isLoginTrigger } = context
    //1.判断后台总配置是否允许弹窗
    let isForbidPop = this.judgeIsForbidPopByServeConfig()
    collectLogs('isForbidPop-1', isForbidPop)
    if (isForbidPop) {
      return false
    }
    //5.判断用户勾选的是否允许弹窗
    isForbidPop = this.judgeIsForbidPopByUserChecked.judgeConstant({
      apiCode,
      store,
      isLoginTrigger
    })
    collectLogs('isForbidPop-5', isForbidPop)
    if (isForbidPop) {
      return false
    }
    //6.判断当前任务规则是否为空,若为空,则不进行弹窗
    isForbidPop = this.judgeIsFobidPopByRulesEmpty(store, apiCode)
    collectLogs('isForbidPop-6', isForbidPop)
    if (isForbidPop) {
      return false
    }
    //7.判断当前弹窗的列表状态是否如预期,若不为预期,则不进行弹窗
    isForbidPop = !JudgePopupType.task.run({
      store,
      apiCode,
      isLoginTrigger: Boolean(isLoginTrigger)
    })
    collectLogs('isForbidPop-7', isForbidPop)
    if (isForbidPop) {
      return false
    }
    //4.判断当前页面是否允许弹窗
    isForbidPop = this.judgeIsForbidPopByPageAddress.run(ignorePageAddress)
    collectLogs('isForbidPop-4', isForbidPop)
    if (isForbidPop) {
      return false
    }
    return true
  }

  /** 判断后台配置上有没有禁用任务弹窗 */
  private judgeIsForbidPopByServeConfig() {
    const isShowTaskModal = useMainStore().getActivesAuthInfos.taskCount
    return !isShowTaskModal
  }

  /** 判断是否禁止打开弹窗,通过规则列表是否为空 */
  private judgeIsFobidPopByRulesEmpty(
    store: TaskModalConstant | TaskModalStore,
    apiCode: TCACE
  ) {
    let isRuleEmpty: boolean
    /** 判断当前任务规则是否为空,若为空,则不进行弹窗 */
    if (isTaskModalConstant(store)) {
      isRuleEmpty = store.codeRuleConstantsMapper[apiCode].length === 0
    } else {
      isRuleEmpty = store.codeRulesMapper[apiCode].length === 0
    }
    return isRuleEmpty
  }
}
