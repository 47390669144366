import { Component } from '@/vue-property-decorator'
import { EventShowTimeEnum } from '@/api/event/type'
import { useI18n } from '@/i18n'
import PublicIntroduction from '@/views/event/item/component/public-introduction/index'
import PublicIntroductionStyle from '@/views/event/item/component/public-introduction/style.module.scss'
import Style from './style.module.scss'

enum IndexStrEnum {
  firstIndex,
  secondIndex,
  thirdIndex,
  fourIndex
}

@Component<DiscountCodeIntroduction>({ name: 'DiscountCodeIntroduction' })
export default class DiscountCodeIntroduction extends PublicIntroduction {
  render() {
    const data = this.eventData
    const { t } = useI18n()
    let renderFnList: ((
      indexStr: string
    ) => VueTsxSupport.JSX.Element | null | undefined)[] = []
    if (data?.isShowDetailTime === EventShowTimeEnum.Show)
      renderFnList = [this.renderIntroductionTime]
    if (data.activeCondition) renderFnList.push(this.renderActiveCondition)
    if (data.conditionText) renderFnList.push(this.renderConditionText)
    if (data.content) renderFnList.push(this.renderContent)

    return (
      <div class={[PublicIntroductionStyle.introduction]}>
        <div
          class={[
            PublicIntroductionStyle.introductionInner,
            Style.discountCodeIntroduction
          ]}
        >
          {renderFnList
            .filter((i) => !!i)
            .map((item, index) => {
              if (renderFnList.length <= 1) {
                return item(t('', this.getLang) as string)
              }
              return item(
                t(`lobby.event.${IndexStrEnum[index]}`, this.getLang) as string
              )
            })}
        </div>
      </div>
    )
  }
}
