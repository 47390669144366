import { JudgePopupTypeFirstCharge } from './judgePopupTypeFirstCharge'
import { JudgePopupTypeTask } from './judgePopupTypeTask'
import { JudgePopupTypeTemplate } from './judgePopupTypeTemplate'
import { JudgePopupTypeWaitingGet } from './judgePopupTypeWaitingGet'
//模板的静态方法上挂载 实现子类
JudgePopupTypeTemplate.firstCharge = new JudgePopupTypeFirstCharge()
JudgePopupTypeTemplate.task = new JudgePopupTypeTask()
JudgePopupTypeTemplate.waitingGet = new JudgePopupTypeWaitingGet()
// 导出模板
export const JudgePopupType = JudgePopupTypeTemplate
// 导出相应的枚举
export { PopTypeAfterLogin, PopTypeBeforeLogin } from './enum'
