/** 电子类别下的常用品牌平台id */
export enum ElectronicPlatforms {
  PG = 14,
  PG_SL = 200,
  JILI = 40,
  PP = 37,
  JDB = 5,
  MG = 7,
  WG = 13
}

/** 自定义全部平台id(二级页面) */
export const CustomPlatformId = 999

/** 自营WG游戏平台 */
export const SelfOpertedId = 13

// /** 自定义最近类别id */
// export const CustomRecentId = 1000

// /** 自定义收藏类别id */
// export const CustomCollectionId = 1001

/** 棋牌类别下的常用品牌平台id */
export enum ChessPlatforms {
  KY = 2,
  JDB = 5,
  WG = 13
}
