import { TaskPageStore } from '.'
import { freshCategoryUsable } from './common/freshCategoryUsable'
import { useMainStore } from '@/store/index'

/** 刷新tabs任务[类型的可用状态]和[规则列表] */
export const proxyFreshCategoriesUsableAndRules = async (
  store: TaskPageStore,
  isForceFreshUsable?: boolean
) => {
  store.setIsLoading(true)
  // 如果未刷新过是否可用,则需要刷新一下(此处的调用相比单个分类的调用区别,此处可以设置强制刷新,单个调用中没有强制刷新)
  await freshCategoryUsable(store, isForceFreshUsable)
  /** 刷新tabs中的任务列表 */
  const apiCodes = store.codeCategoryOrder
  const freshAll = apiCodes.map((code) => {
    return store.freshInTabsCategoryDataByCode(code)
  })
  await Promise.all(freshAll)
  // 加载完成后，需要标记store加载过了
  store.isFetched = true
  store.setIsLoading(false)
  // 当前加载的数据所属语言
  store.fetchedDataLanguage = useMainStore().language
  store.fetchedDataUserId = String(useMainStore().userInfos?.username)
}
