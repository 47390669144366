import {
  ActivityTemplate,
  EventGiveTypeEnum,
  ReceiveStatusEnum,
  orderDetail
} from '@/api/event/type'
import { Base, Component, Prop } from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { GlobalManager } from '@/context'
import { RouteName } from '@/router/config'
import { VNode } from 'vue'
import {
  activeReceiveOne,
  canReceiveReward,
  getMosaicValidTips,
  showSrviceModal
} from '@/views/event/item/utils'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AutoShrinkText from '@/components/auto-shrink-text'
import Modal from '@/controller/Modal'
import QRCode from '@/components/business-components/qr-code'
import style from './style.module.scss'

interface Props {
  /**
   * 活动id
   */
  activeId: number
  /**
   * 活动类型
   */
  template?: number
  /**
   * 奖励id
   */
  receiveId?: number
  /**
   * 奖励状态
   */
  receiveStatus?: number
  /**
   * 当前金额
   */
  currentAmount?: number
  /**
   * 可领设备
   */
  receiveDeviceType?: string
  /**
   * 领取方式
   */
  giveType?: number
  /**
   * 充值活动 每笔奖励 充值单号
   */
  orderNo: string
  /**
   * 充值活动 是否开启每笔奖励 0 | 1
   */
  rechargeEachOrder: number
  /**
   * 可领取时刻
   */
  canReceiveTime?: number
  /**
   * 倒计时
   */
  receiveDuration: number
  /**
   * 领取提示文案
   */
  receiveTipsText: string
  /**
   * 领取成功的回调（用来刷新数据）
   */
  callback?: () => void
  forwardCallback?: () => void
  items: orderDetail
  receiveTimeType: number
}

interface State {
  processing: boolean
}

/**
 * 领取活动奖励的按钮
 */
@Component<EventItemListedRewardsButton>({
  name: 'EventItemListedRewardsButton'
})
export default class EventItemListedRewardsButton extends Base<State, Props> {
  /**
   * 活动id
   */

  @Prop()
  activeId!: Props['activeId']

  /**
   * 奖励id
   */
  @Prop()
  receiveId!: Props['receiveId']

  /**
   * 奖励状态
   */
  @Prop()
  receiveStatus!: Props['receiveStatus']

  /**
   * 领取方式
   */
  @Prop()
  giveType!: Props['giveType']

  /**
   * 领取成功的回调（用来刷新数据）
   */
  @Prop()
  callback?: Props['callback']
  /**
   * 前往按钮的回调
   */
  @Prop()
  forwardCallback?: Props['forwardCallback']

  /**
   * 当前金额（用来判断文字）
   */
  @Prop()
  currentAmount!: Props['currentAmount']

  /**
   * 可领取时刻
   */
  @Prop()
  canReceiveTime?: Props['canReceiveTime']

  @Prop()
  receiveDeviceType!: Props['receiveDeviceType']

  /**
   * 领取成功的回调（用来刷新数据）
   */
  @Prop()
  template!: Props['template']
  /**
   * 充值活动 每笔奖励 充值单号
   */
  @Prop()
  orderNo!: Props['orderNo']
  /**
   * 充值活动 是否开启每笔奖励 0 | 1
   */
  @Prop()
  rechargeEachOrder!: Props['rechargeEachOrder']

  @Prop()
  receiveDuration!: Props['receiveDuration']

  @Prop()
  receiveTipsText!: Props['receiveTipsText']

  @Prop()
  items!: Props['items']

  @Prop()
  receiveTimeType!: Props['receiveTimeType']

  state: State = {
    processing: false
  }

  /**
   * 展示app下载二维码
   */
  private renderQRCode(): VNode {
    const { errorMessage } = getMosaicValidTips(this.receiveDeviceType + '')
    return (
      <div>
        <div>{errorMessage}</div>
        <QRCode />
      </div>
    )
  }

  /**
   * 领取奖励
   */
  @Bind
  @Debounce()
  async handleCollect(isGiveType?: boolean): Promise<void> {
    const { t } = useI18n()
    // const nowTime = new Date().getTime()
    // if (this.canReceiveTime && nowTime < this.canReceiveTime * 1000) {
    //   showSrviceModal(
    //     t('lobby.event.listedRewards.canReceiveTip', {
    //       day: BusinessUtils.formatSiteOffsetTime(this.canReceiveTime, 'ymdhms')
    //     }) as string
    //   )
    //   return
    // }

    // 如果倒计时小于零则弹出提示
    if (this.receiveDuration && this.receiveTipsText) {
      showSrviceModal(this.receiveTipsText)
      return
    }

    if (!canReceiveReward(this.receiveDeviceType, this.renderQRCode)) {
      return
    }

    this.setState({
      processing: true
    })

    activeReceiveOne({
      params: {
        receiveId: `${this.receiveId}`,
        activeId: this.activeId
      },
      onSuccess: () => {
        GlobalManager.Modal.message({
          type: 'success',
          content: isGiveType
            ? t('lobby.event.invest.applySuccess')
            : t('lobby.event.invest.receiveSuccess')
        })

        this.callback && this.callback()
      },
      judgeResBefore: () => {
        this.setState({
          processing: false
        })
      }
    })
  }

  handleForward() {
    const { hasLogined } = useMainStore()
    this.forwardCallback?.()
    if (!hasLogined) {
      Modal.openLoginRegisterModal({ accountRegister: true })
      return
    }

    if (this.template === ActivityTemplate.Deposit) {
      GlobalManager.Modal.open('payModal')
      return
    }

    if (this.template === ActivityTemplate.Wager) {
      this.$router.push({
        name: RouteName.GAME
      })
    }
  }

  render(): VNode {
    const { t } = useI18n()
    const isProgress = this.currentAmount! > 0
    switch (this.receiveStatus) {
      /**
       * 进行中
       */
      case ReceiveStatusEnum.Processing:
        return (
          <Button.Tsx
            class={{
              [style.btn]: true,
              [style.processing]: isProgress,
              [style.goto]: !isProgress
            }}
            onClick={() => {
              this.handleForward()
            }}
          >
            <AutoShrinkText
              text={
                isProgress
                  ? t('lobby.modal.task.processing').toString()
                  : t('lobby.modal.task.forward').toString()
              }
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 领取
       */
      case ReceiveStatusEnum.CanReceive:
        return (
          <Button.Tsx
            class={style.btn}
            onClick={async () => {
              await this.handleCollect()
            }}
            loading={this.state.processing}
          >
            <AutoShrinkText
              text={t('lobby.modal.task.collect') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      case ReceiveStatusEnum.HasReceive:
        /**
         * 已领取
         */
        return (
          <Button.Tsx class={style.btn} disabled>
            <AutoShrinkText
              text={t('lobby.modal.task.collected') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 已过期
       */
      case ReceiveStatusEnum.Expired:
        return (
          <Button.Tsx class={style.btn} disabled>
            <AutoShrinkText
              text={t('lobby.modal.task.expired') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 已派发
       */
      case ReceiveStatusEnum.Distributed:
        return (
          <Button.Tsx class={style.btn} disabled>
            <AutoShrinkText
              text={t('lobby.modal.task.distributed') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 已取消
       */
      case ReceiveStatusEnum.Cancel:
        return (
          <Button.Tsx class={style.btn} disabled>
            <AutoShrinkText
              text={t('lobby.modal.task.cancel') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 待审核
       */
      case ReceiveStatusEnum.PendingCheck:
        if (this.giveType === EventGiveTypeEnum.ManualDistribution) {
          /**
           * 已申请
           */
          return (
            <Button.Tsx class={style.btn} disabled>
              <AutoShrinkText
                text={t('lobby.modal.task.applied') as string}
              ></AutoShrinkText>
            </Button.Tsx>
          )
        }
        return (
          <Button.Tsx
            class={style.btn}
            onClick={() => {
              showSrviceModal(t('lobby.event.verifyTips') as string)
            }}
          >
            <AutoShrinkText
              text={t('lobby.modal.task.collect') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 待申请
       */
      case ReceiveStatusEnum.PendingApply:
        return (
          <Button.Tsx
            class={style.btn}
            onClick={async () => {
              await this.handleCollect(true)
            }}
          >
            <AutoShrinkText
              text={t('lobby.modal.task.applyAward') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      /**
       * 已拒绝
       */
      case ReceiveStatusEnum.Rejected:
        return (
          <Button.Tsx class={style.btn} disabled>
            <AutoShrinkText
              text={t('lobby.records.rejected') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )

      default:
        return (
          <Button.Tsx class={style.btn} disabled>
            <AutoShrinkText
              text={t('lobby.modal.task.collect') as string}
            ></AutoShrinkText>
          </Button.Tsx>
        )
    }
  }
}
