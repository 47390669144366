import { useMainStore } from '@/store/index'

/** 所有用户打开过的状态,含今天和曾经 */
type AllUserOpenedDate = {
  /** 用户id对应它的弹窗日期 */
  [key: string]: number
}

/** store中,用户首充弹窗打开记录缓存 */
class UserOpeningWaitingGetRecord {
  constructor() {
    const allUserIsHaveOpenedStr =
      localStorage.getItem(UserOpeningWaitingGetRecord.storeKey) || '{}'
    this.allUserIsHaveOpenedData = JSON.parse(
      allUserIsHaveOpenedStr
    ) as AllUserOpenedDate
  }
  /** 静态属性,存储的store键名 */
  private static readonly storeKey = 'allUserOpeningWaitingGetRecord'
  /** 用户本地记录 */
  private allUserIsHaveOpenedData: AllUserOpenedDate
  /** 获取用户打开记录 */
  public getUserOpeningRecord() {
    /** 用户标识,如果未登录用户就是 'unlogin' */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    const ret = {
      isHaveOpenedToday: false,
      isHaveOpenedEver: false,
      isCheckDayNoOpen: false
    }
    const recordDate = this.allUserIsHaveOpenedData[userSign]
    const recordCheckDate = this.allUserIsHaveOpenedData[`isCheck${userSign}`]
    // 日期存在,则以前打开过
    ret.isHaveOpenedEver = Boolean(recordDate)
    // 日期相同,则今日打开过
    const curDate = new Date().getDate()
    if (recordDate === curDate) {
      ret.isHaveOpenedToday = true
    }
    if (recordCheckDate === curDate) {
      ret.isCheckDayNoOpen = true
    }
    return ret
  }
  /** 记录用户打开记录 */
  public recordUserOpeningRecord() {
    /** 用户标识,如果未登录用户就是 'unlogin' */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    // 记录用户打开的日期
    this.allUserIsHaveOpenedData[userSign] = new Date().getDate()
    const allUserIsHaveOpenedStr = JSON.stringify(this.allUserIsHaveOpenedData)
    localStorage.setItem(
      UserOpeningWaitingGetRecord.storeKey,
      allUserIsHaveOpenedStr
    )
  }
  /** 记录当前用户是否勾选了今日不再弹出 */
  public recordUserCheckDayOpen(isCheck: boolean) {
    /** 用户标识,如果未登录用户就是 'unlogin' */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    // 记录当前用户是否勾选了今日不在弹出
    this.allUserIsHaveOpenedData[`isCheck${userSign}`] = isCheck
      ? new Date().getDate()
      : 0
    const allUserIsHaveOpenedStr = JSON.stringify(this.allUserIsHaveOpenedData)
    localStorage.setItem(
      UserOpeningWaitingGetRecord.storeKey,
      allUserIsHaveOpenedStr
    )
  }
}
/** 实例化,用户首充弹窗打开记录缓存 */
export const userRecord = new UserOpeningWaitingGetRecord()
