import { Base, Component, Prop, Watch } from '@/vue-property-decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { CPFDateConditionEnum, CustomValueType } from './type'
import { CPFStatus } from '@/api/rechargeFund/type'
import { CommonPageLayout } from '@/components/business-components'
import { RouteName } from '@/router/config'
import { TranslateResult } from 'vue-i18n'
import { VNode } from 'vue'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { omit } from 'lodash'
import { useBottomTabbarActive } from '@/utils/business-utils/useBottomTabbarActive'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AccumulHistory from './components/AccumulHistory'
import AutoShrinkText from '@/components/auto-shrink-text'
import BetHistory from './components/BetHistory'
import BusinessUtils from '@/utils/business-utils'
import CommonTabs from '@/components/business-components/tabs-h5'
import Modal from '@/controller/Modal'
import ModalDetail from './components/modalDetail'
import NetRetry, {
  DisplayType
} from '@/components/business-components/net-retry'
import RulesDesc from './components/RulesDesc'
import TooltipsComp from '@/components/business-components/tooltips'
import WithdrawDonwTime from './components/WithdrawDonwTime'
import style from './style.module.scss'
import useRechargeFundStore from './store'
type TabItemType = {
  value: number
  label: TranslateResult
  component: VNode
}

type State = {
  value: string
  activeTab: number
  showAnimated: boolean
  initAnimated: boolean
}

interface Props {
  /** 是弹窗引用 */
  isModal?: boolean
  /** 充值后弹窗 */
  isPayAfter?: boolean
}
@Component<RechargeFund>({ name: 'RechargeFund' })
export default class RechargeFund extends Base<State, Props> {
  state: State = {
    value: '1',
    activeTab: 1,
    showAnimated: false,
    initAnimated: false
  }

  @Prop()
  isModal: Props['isModal']
  @Prop()
  isPayAfter: Props['isPayAfter']

  get isWeb() {
    return useMainStore().isWeb
  }
  get hasLogin() {
    return useMainStore().hasLogined
  }
  get language() {
    return useMainStore().language
  }

  @Watch('hasLogin')
  private hasLoginChange(oldV: boolean, newV: boolean) {
    if (oldV !== newV && this.hasLogin) {
      this.$router.push({
        name: RouteName.GAME
      })
    }
  }
  // 当多语言切换时,需要更新list
  @Watch('language')
  languageChange() {
    useRechargeFundStore().getRechargeSetting()
  }

  mounted() {
    if (!this.isModal) {
      useRechargeFundStore().initRecharge()
    }
    this.state.showAnimated = true
    if (!this.isPayAfter) {
      useBottomTabbarActive()
    }
  }

  get tabsList(): Array<TabItemType> {
    const { t } = useI18n()
    return [
      {
        value: CustomValueType.ACCUMUL_HISTORY,
        label: t('lobby.cpf.accumulHistory'),
        component: <AccumulHistory />
      },
      {
        value: CustomValueType.BET_HISTORY,
        label: t('lobby.cpf.betHistory'),
        component: <BetHistory />
      },
      {
        value: CustomValueType.RULES_DESC,
        label: t('lobby.cpf.rulesDesc'),
        component: <RulesDesc />
      }
    ]
  }

  handleOpenModal = () => {
    const { hasLogined } = useMainStore()
    if (!hasLogined) {
      Modal.openLoginRegisterModal({ accountRegister: true })
    } else {
      ;(this.$refs['modalDetail'] as ModalDetail)?.open()
    }
  }

  handleRecharge = () => {
    if (this.hasLogin) {
      Modal.open('payModal')
    } else {
      Modal.openLoginRegisterModal({ accountRegister: true })
    }
  }

  get timeStamp() {
    const { currentPeriodReward } = useRechargeFundStore()
    if (!currentPeriodReward?.fund_period_end_time) {
      return 0
    }
    const time =
      currentPeriodReward?.fund_period_end_time -
      Math.round(new Date().getTime() / 1000)
    if (time > 15 * 24 * 60 * 60) {
      return 0
    }
    return time
  }
  /** 外层取出按钮禁用状态 */
  get getOutState2() {
    const { currentPeriodReward } = useRechargeFundStore()
    return (
      (currentPeriodReward?.receive_log_client_status !==
        CPFStatus.CanReceive &&
        currentPeriodReward?.receive_log_client_status !== CPFStatus.Init) ||
      !currentPeriodReward?.fund_amount
    )
  }
  /** 计算按钮文字 */
  get btnText() {
    const { currentPeriodReward } = useRechargeFundStore()
    const { t } = useI18n()
    let text = t('lobby.cpf.withdraw')
    switch (currentPeriodReward?.receive_log_client_status) {
      case CPFStatus.Received:
        text = t('lobby.cpf.hasWithdraw')
        break
      case CPFStatus.Send:
        text = t('lobby.cpf.hasSend')
        break
      case CPFStatus.Cancel:
        text = t('lobby.cpf.hasCancel')
        break
    }
    return text
  }

  /** 当前周期信息 */
  get currentPeriodReward() {
    return useRechargeFundStore().currentPeriodReward
  }
  /** 赠送金额文本 */
  getSendNum(value = 0) {
    const { t } = useI18n()
    const text = currencyFormatter(value, {
      code:
        this.currentPeriodReward?.currency ||
        useMainStore().currentTryCurrency?.currencyCode,
      precision: 0,
      symbol: ''
    })
    return value ? text : t('lobby.cpf.noCap')
  }
  /** 赠送次数文本 */
  getSendAmout(value: number | string = 0, maxValue = 0) {
    const { t } = useI18n()
    const text = value + '/' + maxValue
    return maxValue ? text : t('lobby.cpf.noCap')
  }
  /** 开启金币动画 */
  openAanimation() {
    this.state.initAnimated = true
    this.state.showAnimated = true
  }
  /**
   * 刷新数据
   */
  private refresh() {
    useRechargeFundStore().updateData(CPFDateConditionEnum.LAST_THIRTY_DAY)
  }
  /**
   * 领取动画
   */
  renderAnimated() {
    if (!this.state.showAnimated) {
      return <template></template>
    }
    return (
      <div
        class={[
          style.moneyAnimated,
          this.state.initAnimated ? '' : style.initAnimated
        ]}
      >
        <apng-canvas
          src={'/lobby_asset/common/web/animated/apng_lingqu_2.png'}
          numPlays={1}
          width={683}
          height={992}
          onEnd={() => {
            this.setState({
              showAnimated: false
            })
          }}
          class="animated-img"
        />
      </div>
    )
  }
  render() {
    const { t } = useI18n()
    const {
      rechargeSetting,
      currentPeriodReward,
      updateTamp,
      isRefresh,
      settingError,
      rewardError
    } = useRechargeFundStore()
    const currentTryCurrencyCode =
      useMainStore().currentTryCurrency?.currencyCode

    return (
      <CommonPageLayout class={style.main} isShadowContent={false}>
        {this.renderAnimated()}
        <section class={[style.cpf, 'theme-pickup-record']}>
          <div class={style.baseInfo}>
            <div class={style.card}>
              <div class={style.label}>
                {t('lobby.cpf.totalRechargeAmount')}
              </div>
              <div class={style.value}>
                <NetRetry
                  loading={isRefresh}
                  axiosError={rewardError}
                  displayType={DisplayType.InsufficientSpace}
                  onRetry={this.refresh}
                >
                  {currencyFormatter(
                    (currentPeriodReward?.total_recharge_amount as number) || 0,
                    {
                      code:
                        currentPeriodReward?.currency || currentTryCurrencyCode,
                      symbol: ''
                    }
                  )}
                </NetRetry>
              </div>
              <Button.Tsx
                class={style.topUp}
                nativeOnClick={this.handleRecharge}
              >
                <AutoShrinkText text={t('lobby.cpf.recharge') as string} />
                <TooltipsComp
                  class={style.toolTips}
                  left={BusinessUtils.px2rem(20)}
                  top={BusinessUtils.px2rem(-10)}
                  text={
                    t('lobby.cpf.give') +
                    String(rechargeSetting?.add_fund_rate || 0) +
                    '%'
                  }
                  charge={1.1}
                />
              </Button.Tsx>
              <div class={style.rewardInfo}>
                <div class={style.item}>
                  {t('lobby.cpf.maxLimitFundAmount')}
                  <span class={style.num}>
                    <NetRetry
                      class={style.miniTry}
                      loading={isRefresh}
                      axiosError={settingError}
                      displayType={DisplayType.InsufficientSpace}
                      onRetry={this.refresh}
                    >
                      {this.getSendNum(rechargeSetting?.max_limit_fund_amount)}
                    </NetRetry>
                  </span>
                </div>
                <div class={style.item}>
                  {t('lobby.cpf.maxLimitAddTimes')}
                  <span class={style.num}>
                    <NetRetry
                      class={style.miniTry}
                      loading={isRefresh}
                      axiosError={rewardError || settingError}
                      displayType={DisplayType.InsufficientSpace}
                      onRetry={this.refresh}
                    >
                      {this.getSendAmout(
                        currentPeriodReward?.add_fund_times,
                        rechargeSetting?.max_limit_add_times
                      )}
                    </NetRetry>
                  </span>
                </div>
              </div>
            </div>
            <span class={style.divider} />
            <div class={style.card}>
              <div class={style.label}>{t('lobby.cpf.cpfName')}</div>
              <div class={[style.value, style.cpfValue]}>
                <NetRetry
                  loading={isRefresh}
                  axiosError={rewardError}
                  displayType={DisplayType.InsufficientSpace}
                  onRetry={this.refresh}
                >
                  {currencyFormatter(
                    (currentPeriodReward?.fund_amount as number) || 0,
                    {
                      code:
                        currentPeriodReward?.currency || currentTryCurrencyCode,
                      symbol: ''
                    }
                  )}
                </NetRetry>
              </div>
              <Button.Tsx
                class={style.withdraw}
                disabled={this.hasLogin && this.getOutState2}
                nativeOnClick={this.handleOpenModal}
              >
                <AutoShrinkText text={this.btnText as string} />
              </Button.Tsx>
              <NetRetry
                class={style.smallTry}
                loading={isRefresh}
                axiosError={rewardError}
                displayType={DisplayType.SufficientSpace}
                onRetry={this.refresh}
              >
                <WithdrawDonwTime
                  donwTimestamp={this.timeStamp}
                  onCountDownEnd={() => updateTamp()}
                />
              </NetRetry>
            </div>
          </div>
          <div class={[style.moreInfo, 'moreInfo']}>
            <CommonTabs
              soleId={'rechargeFundTabs'}
              persistedActiveName={'rechargeFunCurrent'}
              class={style.tabsList}
              tabsNavHiddenForOne={true}
              renderList={this.tabsList.map((it) => omit(it, ['component']))}
              v-model={this.state.activeTab}
              isScrollX={true}
              tabsTransparent={true}
              itemHeight={this.isWeb ? '70px' : '80px'}
              tabsLeftMargin={30}
              scopedSlots={{
                default: ({ value }) => {
                  switch (value) {
                    case CustomValueType.ACCUMUL_HISTORY:
                      return <AccumulHistory />
                    case CustomValueType.BET_HISTORY:
                      return <BetHistory />
                    case CustomValueType.RULES_DESC:
                      return <RulesDesc />
                  }
                }
              }}
            />
          </div>
          <ModalDetail
            ref={'modalDetail'}
            onOpenAanimation={this.openAanimation}
          />
        </section>
      </CommonPageLayout>
    )
  }
}
