import { _State as State, initState } from './initState'
import { apiGetActivityChestList } from '../../api/apiGetActivityChestList'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import moment from 'moment'
import to from 'await-to-js'
export type { State }
/** 活跃度宝箱的store */
export type ActivityChestStore = ReturnType<typeof useActivityChestStore>

/** 活跃度宝箱的store */
export const useActivityChestStore = defineStore('activityChestStore', {
  state: () => initState() as State,
  getters: {
    /** 重置时间的数字 */
    resetTime(): number {
      return this.taskChestConfig.loopEndTime
    },
    /** 获取活跃度重置时间 */
    resetTimeMapper(): ActivityResetTime {
      const duration = moment.duration(this.taskChestConfig.loopEndTime * 1000)
      return {
        d: duration.days(),
        h: duration.hours(),
        m: duration.minutes(),
        s: duration.seconds()
      }
    }
  },
  actions: {
    /** 刷新活跃度宝箱信息 */
    async freshChestInfo() {
      this.setIsLoading(true)
      const [err, resData] = await to(apiGetActivityChestList())
      // 接口报错了，就显示超时提示
      this.setIsTimeoutError(!!err)
      if (err) {
        console.error('chest fetch err:', err)
        this.setIsLoading(false)
        return
      }
      this.setIsLoading(false)
      this.activityChestList = resData.box
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { box, ...rest } = resData
      this.taskChestConfig = rest
      this.isFetched = true
    },
    /** 活跃度重置时间倒计时,倒计时成功或失败 */
    donwResetTime() {
      const config = this.taskChestConfig
      if (config.loopEndTime > 0) {
        config.loopEndTime -= 1
        return true
      } else {
        return false
      }
    },
    /** 更新重置时间 */
    updateResetTime(num: number) {
      this.taskChestConfig.loopEndTime = num
    },
    setIsTimeoutError(isTimeoutError: boolean) {
      this.isTimeoutError = isTimeoutError
    },
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.activityChest',
        paths: ['isFetched', 'taskChestConfig', 'activityChestList'],
        storage: createMyPersistedStorage()
      }
    ]
  }
})

/** 活跃值重置时间 */
export interface ActivityResetTime {
  /** 天 */
  d: number
  /** 时 */
  h: number
  /** 分 */
  m: number
  /** 秒 */
  s: number
}
