import { AgentModeDataResponse } from '@/api/common/type'
import { AxiosError } from 'axios'
import { CalcPerformance, ProportionItem } from '@/api/promote/proportion/type'
import {
  EUserStatusType,
  StatusType
} from '../components/tabList/external-use-type'
import { GameCateEnum } from '@/api/promote/commission/type'
import { NewDirectAccountSettingResponse } from '@/api/promote/createAccount/type'
import {
  PromoteConfigResponse,
  PublicityResponse,
  ReportAgentDataResponse,
  SensitiveDataConfigResponse
} from '@/api/promote/myPromote/type'
import { apiGetNewDirectAccountSetting } from '@/api/promote/createAccount'
import { apiGetPromoteAdvertise } from '@/api/common'
import {
  apiGetPromoteConfigIndex,
  apiGetPublicity,
  apiGetReportAgentData,
  apiGetSensitiveDataConfig
} from '@/api/promote/myPromote'
import { apiGetProportionIntroduce } from '@/api/promote/proportion'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { find } from 'lodash'
import { isEmpty } from 'lodash'
import { useI18n } from '@/i18n'
import to from 'await-to-js'
type ResDataType = {
  game_cateid: GameCateEnum
  list: Array<ProportionItem>
}
type AxiosErrorType = {
  agentModeError: AxiosError | null
}
type TimeoutLoadingType = {
  agentModeLoading: boolean
}
/**定义超时loading枚举 */
enum timeoutLoadingEnum {
  AgentModeLoading = 'agentModeLoading'
}

type State = {
  promoteConfig: Partial<PromoteConfigResponse>
  agentData: Partial<ReportAgentDataResponse>
  publicityOptions: Partial<PublicityResponse>
  proportionCondition: {
    game_cateid: ProportionItem['game_cateid']
  }
  // 结果转为1维数组，方便使用
  proportionData: Array<ResDataType>
  valid_num_deposit: number
  second_comm_rate: number
  third_comm_rate: number
  minValidBet: number
  minValidDeposit: number
  rebateFrontDisplayFormat: number
  createAccountConfig: Partial<NewDirectAccountSettingResponse>
  agentModeData: Partial<AgentModeDataResponse>
  calcPerformance: CalcPerformance
  sensitiveDataConfig: Partial<SensitiveDataConfigResponse>
  lowDisplay: Partial<
    Pick<SensitiveDataConfigResponse, 'showType' | 'showName'>
  >
  axiosError: AxiosErrorType
  timeoutLoading: TimeoutLoadingType
}

const usePromoteStore = defineStore('promote', {
  state: (): State => {
    return {
      promoteConfig: {},
      agentData: {},
      proportionCondition: {
        game_cateid: GameCateEnum.Chess
      },
      proportionData: [],
      publicityOptions: {},
      valid_num_deposit: 0,
      second_comm_rate: 0,
      third_comm_rate: 0,
      minValidBet: 0,
      minValidDeposit: 0,
      rebateFrontDisplayFormat: 0,
      calcPerformance: CalcPerformance.NetProfit,
      createAccountConfig: {
        show_email: 0,
        show_phone: 0,
        show_real_name: 0
      },
      agentModeData: {},
      sensitiveDataConfig: {
        dataList: []
      },
      lowDisplay: {},
      /**是否超时错误*/
      axiosError: {
        agentModeError: null
      },
      /**超时刷新 */
      timeoutLoading: {
        agentModeLoading: false
      }
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.promote',
        storage: createMyPersistedStorage(),
        paths: [
          'agentModeData',
          'promoteConfig',
          'publicityOptions',
          'agentData',
          'sensitiveDataConfig'
        ]
      }
    ]
  },
  actions: {
    /**
     * @description 我的推广首页数据
     */
    initPromoteIndex(refresh = false) {
      /**
       * 非强制刷新store时，当有值时不再二次请求
       */
      if (
        !refresh &&
        !isEmpty(this.promoteConfig) &&
        !isEmpty(this.agentData)
      ) {
        return
      }
      /**
       * 我的推广首页配置数据-静态化
       */
      apiGetPromoteConfigIndex()
        .then((data) => {
          this.promoteConfig = data
        })
        .catch((err) => {
          console.error('get promoteIndex failed:', err)
        })
      /**代理个人业务数据 */
      apiGetReportAgentData()
        .then((data) => {
          this.agentData = data
        })
        .catch((err) => {
          console.error('get promoteIndex failed:', err)
        })
      /**
       * 获取开户设置
       */
      apiGetNewDirectAccountSetting().then((response) => {
        this.createAccountConfig = response
      })
    },
    getSensitiveData() {
      /**
       * 获取敏感数据列表
       */
      apiGetSensitiveDataConfig().then((res) => {
        this.sensitiveDataConfig = res
        this.lowDisplay = { showType: res.showType, showName: res.showName }
      })
    },
    async getPromoteImage(data: { link: string }) {
      /**
       * 获取宣传图配置
       */
      apiGetPublicity(data)
        .then((result) => {
          this.publicityOptions = result ?? {}
        })
        .catch((err) => {
          console.error('get publicity failed:', err)
        })
    },
    /**
     * 返佣比例数据
     */
    async initProportionData() {
      const { proportionData, proportionCondition } = this
      /**
       * 如果含有数据，跟进条件取对应的数据
       */
      if (!!proportionData.length) {
        const current = find(proportionData, {
          game_cateid: proportionCondition.game_cateid
        }) as ResDataType

        return {
          data: current?.list ?? []
        }
        /**
         * 无数据走请求逻辑
         */
      } else {
        const listData = await apiGetProportionIntroduce()
        const parseResData = this.getParseResponseData(listData.list)
        const [firstData] = parseResData

        this.proportionData = parseResData
        this.proportionCondition = { game_cateid: firstData?.game_cateid }
        this.valid_num_deposit = listData.valid_num_deposit
        this.second_comm_rate = listData.second_comm_rate
        this.third_comm_rate = listData.third_comm_rate
        this.minValidBet = listData.minValidBet
        this.minValidDeposit = listData.minValidDeposit
        this.rebateFrontDisplayFormat = listData.rebateFrontDisplayFormat
        this.calcPerformance = listData.calcPerformance

        return {
          data: firstData?.list ?? []
        }
      }
    },
    /**
     * 将二维数组转换为一维度数据
     */
    getParseResponseData(list: Array<ProportionItem[]>): Array<ResDataType> {
      const resData: Array<ResDataType> = []
      list.forEach((item) => {
        if (item && Array.isArray(item) && item.length) {
          resData.push({
            game_cateid: item[0].game_cateid,
            list: item.map((i, index) => ({ ...i, index }))
          })
        }
      })

      return resData
    },
    /** 获取推广赚钱广告语/自定代理mode */
    async getPromoteAdvertise() {
      const [err, data] = await to(apiGetPromoteAdvertise())
      if (data) {
        this.agentModeData = data as AgentModeDataResponse
      }
      this.axiosError.agentModeError = err as AxiosError
      this.timeoutLoading.agentModeLoading = false
    },
    clearPromoteAdvertise() {
      this.agentModeData = {}
    },
    /**设置超时重试*/
    handleTimeoutRetry(key: timeoutLoadingEnum) {
      this.timeoutLoading[key] = true
    }
  },
  getters: {
    /**
     * 获取
     */
    getPromoteData(state) {
      return Object.assign({}, state.promoteConfig, state.agentData)
    },
    getAgentModeData(state) {
      return state.agentModeData
    },
    /**
     * 自动计算当前选择的返佣类型
     */
    getCurrentProportionData(state) {
      const current = state.proportionData.find((el) => {
        return el.game_cateid == state.proportionCondition.game_cateid
      })
      return current?.list ?? []
    },
    getStatusTypeTransform() {
      const { t } = useI18n()
      return [
        { value: StatusType.NO, label: t('lobby.promote.no') },
        { value: StatusType.YES, label: t('lobby.promote.yes') }
      ]
    },
    getUserStatusTypeTransform() {
      const { t } = useI18n()
      return [
        {
          value: EUserStatusType.ALL,
          label: t('lobby.game.all')
        },
        {
          value: EUserStatusType.NORMAL,
          label: t('lobby.promote.userStatusType.normal')
        },
        {
          value: EUserStatusType.FREEZE_MANUALLY,
          label: t('lobby.promote.userStatusType.freezeManually')
        },
        {
          value: EUserStatusType.ABNORMAL_FREEZING,
          label: t('lobby.promote.userStatusType.abnormalFreezing')
        },
        {
          value: EUserStatusType.PROHIBITION_OF_RECEIVING_DISCOUNTS,
          label: t(
            'lobby.promote.userStatusType.prohibitionOfReceivingDiscounts'
          )
        },
        {
          value: EUserStatusType.BLACKLIST,
          label: t('lobby.promote.userStatusType.blacklist')
        },
        {
          value: EUserStatusType.DISABLED,
          label: t('lobby.promote.userStatusType.disabled')
        },
        {
          value: EUserStatusType.WITHDRAWAL_PROHIBITED,
          label: t('lobby.promote.userStatusType.withdrawalProhibited')
        },
        {
          value: EUserStatusType.FORBIDDEN_TO_ENTER_THE_GAME,
          label: t('lobby.promote.userStatusType.forbiddenToEnterTheGame')
        },
        {
          value: EUserStatusType.RETURN_THE_PRINCIPAL,
          label: t('lobby.promote.userStatusType.returnThePrincipal')
        }
      ]
    },
    /** 各个二级页面底部数据展示开关 */
    getPageSensitiveConfig():
      | { [name: string]: { [name: string]: string } }
      | undefined {
      const pageConfigs = this.sensitiveDataConfig.dataList?.map((el) => {
        const result: { [name: string]: string } = {}
        for (const obj of el.data) {
          const { name, value } = obj
          result[name] = value
        }
        return { name: el.name, result }
      })

      const resultConfig: { [name: string]: { [name: string]: string } } = {}

      for (const item of pageConfigs || []) {
        const { name, result: itemResult } = item
        resultConfig[name] = itemResult
      }

      return resultConfig
    },
    getLowDisplay(): State['lowDisplay'] {
      return this.lowDisplay
    }
  }
})

export default usePromoteStore
