import { defineStore } from 'pinia'

export interface FeedbackState {
  // 未读取的已经采纳的数量
  unRead: number
  isInnerDetail: boolean
  availableRewards: number
  /** 有创建新的反馈*/
  hasCreateNewFeedback: boolean
}

export const useFeedbackStore = defineStore('feedback', {
  state: (): FeedbackState => ({
    hasCreateNewFeedback: false,
    isInnerDetail: false,
    availableRewards: 0,
    unRead: 0
  }),
  getters: {},
  actions: {
    setHasCreateNewFeedback(hasCreateNewFeedback: boolean) {
      this.hasCreateNewFeedback = hasCreateNewFeedback
    },
    setUnRead(unRead: number) {
      this.unRead = unRead
    },
    setAvailableRewards(availableRewards: number) {
      this.availableRewards = availableRewards
    }
  }
})
