import { Base, Component } from '@/vue-property-decorator'
import { useI18n } from '@/i18n'
import config from './config'

import style from './style.module.scss'

@Component<AccessRestricted>({
  name: 'AccessRestricted'
})
export default class AccessRestricted extends Base {
  render() {
    const { t } = useI18n()
    return (
      <div class={style.AccessRestrictedWrap}>
        <img src={config.imgPath} alt="" />
        <h1>{t('lobby.selfoperatedGames.sports.accessTitle')}</h1>
        <p>{t('lobby.selfoperatedGames.sports.accessContent')}</p>
      </div>
    )
  }
}
