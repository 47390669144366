/** 弹窗弹出时机类型(登录前) */
export enum PopTypeBeforeLogin {
  /** 不弹框 */
  never = 0,
  /** 每日一次 */
  onceDay = 1,
  /** 高频 */
  constantly = 2
}

/** 弹窗弹出时机类型(登录后) */
export enum PopTypeAfterLogin {
  /** 不弹框 */
  never = 0,
  /** 每日一次 */
  onceDay = 1,
  /** 高频 */
  constantly = 2,
  /** (仅登录后才有该项)任务未完成时弹窗 */
  waitingComplete = 3,
  /** (仅登录后才有该项)奖励待领取时弹窗 */
  waitingReceive = 4,
  /** (仅登录后才有该项)永久只弹一次 */
  onceForever = 5,
  /** (仅登录后才有该项)每次登录 */
  everyLogining = 6
}
