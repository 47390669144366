import { GlobalService } from '@/context'
import { merge } from 'lodash'
import siteConfig from '@/config/site.config'

/**  获取任务活跃度宝箱
 * 接口文档地址: https://docs.cg.xxx/web/#/40/2834
 */
export const apiGetActivityChestList = (): Promise<TaskChestGather> =>
  GlobalService.post<TaskChestGather>({
    url: '/activetask/vitality/boxs',
    data: { siteCode: siteConfig.siteCode }
  }).then((res) => {
    const oriData = (undefined || res.data.data) as TaskChestGather
    if (!oriData) {
      throw new Error('"activetask/vitality/boxs" response is not valid')
    }
    // 为宝箱扩展图标
    const { box, ...reset } = oriData
    const newBox =
      box?.map((acChest, index) =>
        merge(
          new ActivityChest(),
          acChest,
          getChestIcons(index as 0 | 1 | 2 | 3)
        )
      ) || []
    /** 根据活跃值排序的宝箱(因为后台返回的宝箱顺序,可能是乱的.) */
    newBox.sort((chestA, chestB) => {
      return chestA.activity - chestB.activity
    })
    const retData = {
      ...reset,
      box: newBox
    }
    return retData
  })

/** 宝箱的可用状态 */
export enum ChestUsableStatus {
  /** 可用的 */
  Usabled = 0,
  /** 禁用的 */
  Forbid = 1
}
/** 任务活跃度宝箱列表 */
export class TaskChestGather {
  /** 循环方式  */
  loopType: Recursive = Recursive.daily
  /** 宝箱状态 [0:开启] [1:关闭] PS:注释没有写错,确实是 [0:开启] */
  status: ChestUsableStatus = ChestUsableStatus.Usabled
  /** 用户活跃值 */
  userActivity = 0
  /** 循环倒计时 */
  loopEndTime = 0
  /** 宝箱列表 */
  box: ActivityChest[] = []
}

/** 活跃度宝箱 */
export class ActivityChest {
  /** 主键 */
  id = 0
  /** 宝箱名称 */
  name = ''
  /** 累计活跃度 */
  activity = 0
  /** 奖励类型(1固定,2随机) */
  awardType: ChestRewardTypeEnum = ChestRewardTypeEnum.fixed
  /** 奖励金额下限 */
  awardMinGold = 0
  /** 奖励金额上限 */
  awardMaxGold = 0
  /** 宝箱状态 */
  status: ChestStatusEnum = ChestStatusEnum.canReceive
  /** 已领取金额 */
  gold = 0
  /** 稽核倍数 */
  audit = 0
  /** 未激活图标 */
  icon1 = ''
  /** 激活待领取图标 */
  icon2 = ''
}

/** 宝箱图标对象 */
type ChestIcons = {
  icon1: string
  icon2: string
}
/** 获取宝箱图标对象
 * @param index 宝箱编号索引
 * @returns {ChestIcons}
 */
let getChestIcons = (index: 0 | 1 | 2 | 3): ChestIcons => {
  const chestIconMapper = [
    {
      icon1: '/lobby_asset/common/common/task/img_rwbx_1c.png',
      icon2: '/lobby_asset/common/common/task/img_rwbx_1.png'
    },
    {
      icon1: '/lobby_asset/common/common/task/img_rwbx_2c.png',
      icon2: '/lobby_asset/common/common/task/img_rwbx_2.png'
    },
    {
      icon1: '/lobby_asset/common/common/task/img_rwbx_3c.png',
      icon2: '/lobby_asset/common/common/task/img_rwbx_3.png'
    },
    {
      icon1: '/lobby_asset/common/common/task/img_rwbx_4c.png',
      icon2: '/lobby_asset/common/common/task/img_rwbx_4.png'
    }
  ]
  getChestIcons = (_ind: 0 | 1 | 2 | 3) => chestIconMapper[_ind]
  return getChestIcons(index)
}

/** 活跃宝箱状态 */
export enum ChestStatusEnum {
  /** 未领取,可领取 */
  canReceive = 1,
  /** 已领取 */
  alreadyReceived = 2,
  /** 不可领取 */
  canNotReceive = 0
}
/** 宝箱奖励类型枚举 */
export enum ChestRewardTypeEnum {
  /** 固定的 */
  fixed = 1,
  /** 随机的 */
  random = 2
}
/** 循环方式 */
export enum Recursive {
  /** 每日 */
  daily = 1,
  /** 每周 */
  weekly = 2
}
