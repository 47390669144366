import {
  SmartPayContentResponse,
  SmartPaySettingAndSlogansResponse
} from '@/api/finance/type'

export enum SmartPayScene {
  LowBalance = 1,
  LoginAndHasRecharge = 2,
  NoRecharge = 3
}

export interface StoreState {
  username?: number
  loading: boolean
  /* runtime 充值次数 */
  depositeCount: number
  timer?: NodeJS.Timeout
  /* 此次触发的场景列表 */
  triggerScenes: SmartPayScene[]
  /*  上一次弹窗触发的场景 */
  lastModalScene?: SmartPayScene
  /* 已首充每天登录后，已首充每天登录后当天只弹窗一次； */
  lastTimeShowScene2?: {
    userId: number
    timestamp: number
  }
  titleConfig?: Record<
    SmartPayScene,
    {
      index: number
      list: string[]
    }
  >
  modalContent?: SmartPayContentResponse

  config?: {
    publicSetting: SmartPaySettingAndSlogansResponse['publicSetting']
    scene1Setting?: Record<string, string>
  }
}
