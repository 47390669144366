import { RouteName } from '@/router/config'
import router from '@/router'

/** (类)判断是否禁止弹出,通过页面地址判判断 */
export class JudgeIsForbidPopByPageAddress {
  /** 判断是否禁止弹出,通过页面地址判判断
   * @param ignorePageAddress 是否忽略地址,不忽略地址就仅会在限制的页面(如首页)弹出,比如个人中心触发新人福利弹窗就算是忽略了地址
   */
  public run(ignorePageAddress = false) {
    // 如果没有忽略地址的校验，则需要在打开弹框前验证当前页面地址是否正确
    if (!ignorePageAddress) {
      // 允许的特定路由
      const allowRoutes = [RouteName.GAME, RouteName.ROOT]
      // 如果不在指定的路由下,则直接禁止弹窗
      if (!allowRoutes.includes(router.currentRoute.name as RouteName)) {
        return true
      }
    }
    return false
  }
}
const instance = new JudgeIsForbidPopByPageAddress()

/** (函数)判断是否禁止弹出,通过页面地址判判断 */
export const judgeIsForbidPopByPageAddress = instance.run.bind(instance)
