import { JudgePopupType } from '../../common/util/judgePopupType'
import { judgeIsForbidPopByPageAddress } from '../../common/util/judgeIsForbidPopByPageAddress'

interface IUserDataSyncInStore {
  get: {
    (): boolean
    <Key extends 'valueOf' = 'valueOf'>(key: Key): boolean[Key]
  }
  set: {
    (value: boolean): void
    <Key extends 'valueOf' = 'valueOf'>(key: Key, value: boolean[Key]): void
  }
}

export type OpenModalParams = {
  /** 是否登录触发 */
  isLoginTrigger: boolean
  isNeverRemind: IUserDataSyncInStore
  isTodayNotRemind: IUserDataSyncInStore
  isServerDisabled: boolean
}
/** 判断是否可以打开首充弹窗 */
export const proxyPreJudgeOpenFirstCharge = (params: OpenModalParams) => {
  const { isLoginTrigger, isNeverRemind, isTodayNotRemind, isServerDisabled } =
    params
  // 先判断用户是否勾选了禁用弹框
  // 勾选永不提示|勾选本次不提示|后台禁用本项,业务层面要求只弹一次,任何一个为true则不再弹框
  if (isNeverRemind.get() || isTodayNotRemind.get() || isServerDisabled) {
    return false
  }
  // 根据后台配置的弹窗类型来判断当前是否弹出
  let isForbid = !JudgePopupType.firstCharge.run({ isLoginTrigger })
  if (isForbid) {
    return false
  }
  // 根据当前页面地址判断是否禁用弹窗
  isForbid = judgeIsForbidPopByPageAddress(isLoginTrigger)
  if (isForbid) {
    return false
  }

  return true
}
