import { GameItemInter } from '@/service/game/entity/gameItem'
import { useMainStore } from '@/store/index'

/** 通用过滤,主播账号只显示主播游戏 */
export const generalFilteredByAnchor = (oriList: GameItemInter[]) => {
  // 主播号还要进行一次过滤
  const { isDemoMode } = useMainStore()
  // 主播号只展示支持的游戏列表
  if (isDemoMode) {
    oriList = oriList.filter((item: GameItemInter | null) => {
      // 如果是null,仍要保留它,有些场景是需要它的.
      if (item === null) {
        return true
      }
      // 如果是游戏数据,则判断一下它是否支持主播游戏
      return item.anchorSupport
    })
  }
  return oriList
}
