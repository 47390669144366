import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { VNode } from 'vue'
import { createSvgSprite } from '@/utils/business-utils/svg'
import { useI18n } from '@/i18n'
import BusinessUtils from '@/utils/business-utils'
import style from './style.module.scss'

interface Props {
  refreshing?: boolean
  showText?: boolean
  marginLeft?: number
  size?: number
}

/**
 * 刷新按钮
 */
@Component<EventRefreshIcon>({ name: 'EventRefreshIcon' })
export default class EventRefreshIcon extends Base<Props> {
  // 刷新状态
  @Prop({ default: false })
  private refreshing: Props['refreshing']

  // 是否显示文案
  @Prop({ default: false })
  private showText: Props['showText']

  // 左侧间距
  @Prop({ default: false })
  private marginLeft: Props['marginLeft']

  // 图标大小
  @Prop({ default: false })
  private size: Props['size']

  render(): VNode {
    const { t } = useI18n()
    const animatedClasses = [
      GlobalConst.CssClassName.Animate.Spin,
      GlobalConst.CssClassName.Animate.Repeat.infinite
    ]
    return (
      <span class={style.refreshIcon}>
        {this.showText && <span>{t('lobby.error.networkTimeout3')}</span>}
        <icon-sprite
          class={[
            {
              [GlobalConst.CssClassName.Animate.Animated]: !!this.refreshing
            },
            ...animatedClasses
          ]}
          style={{
            fontSize: BusinessUtils.px2rem(this.size || 20),
            marginLeft: BusinessUtils.px2rem(this.marginLeft || 0)
          }}
          sprite={createSvgSprite('comm_icon_retry')}
        />
      </span>
    )
  }
}
