/* eslint-disable @typescript-eslint/no-explicit-any*/
import { useMainStore } from '@/store/index'

/** 神秘任务的最终大奖项配置和新人福利过期时间配置 */
export class TaskRuleConstantMysterySpecial {
  /** 判断新人福利是否过期 [xrfl:新人福利] */
  public static judgeSrflIsExpired(limitDay: number) {
    //未登录的状态永不过期(试玩账号永不过期)
    if (!useMainStore().hasLogined) {
      return false
    }
    /** 截止日期 */
    const deadlineDay = limitDay || 0
    //如果截止日期是0,则永不过过期
    if (deadlineDay <= 0) {
      return false
    }
    /** 用户注册时间: 此处可能还未添加该字段声明,此处先any使用 */
    const userRegTime: number =
      (useMainStore().userInfos as any).register_time || 0
    /** 用户过期时间戳 */
    const userDeadlineTimestamp = userRegTime + 3600 * 24 * deadlineDay
    // 当前时间小于过期时间,则表示未过期
    if (Date.now() / 1000 < userDeadlineTimestamp) {
      return false
    } else {
      return true
    }
  }

  /** (神秘任务最终大奖)是否禁用 */
  disableLastProfit?: boolean = false
  /** (神秘任务最终大奖)奖金最大值 */
  maxAmount?: number = 0
  /** (神秘任务最终大奖)奖金最小值 */
  minAmount?: number = 0
  /** (新人福利)是否限时0，不限制，>0表示当前时间 必须小于会员注册+xx天数，新人福利 */
  limitDay?: number = 0
}
