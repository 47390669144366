import { ThemeSkinType } from '@/controller/Skin'

export const HAS_JACKPOT: ThemeSkinType[] = [
  ThemeSkinType.EUROPEAN_AMERICAN,
  ThemeSkinType.NORMAL,
  ThemeSkinType.STAKE,
  ThemeSkinType.DQ,
  ThemeSkinType.THEME_14,
  ThemeSkinType.THEME_15,
  ThemeSkinType.THEME_17,
  ThemeSkinType.THEME_16,
  ThemeSkinType.CYAN_BLUE,
  ThemeSkinType.THEME_13,
  ThemeSkinType.THEME_12,
  ThemeSkinType.THEME_27,
  ThemeSkinType.THEME_21,
  ThemeSkinType.THEME_20,
  ThemeSkinType.THEME_24,
  ThemeSkinType.THEME_34,
  ThemeSkinType.THEME_33,
  ThemeSkinType.THEME_25,
  ThemeSkinType.THEME_31,
  ThemeSkinType.THEME_19,
  ThemeSkinType.THEME_36,
  ThemeSkinType.THEME_10
]
