import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '../../../common/util/judgePopupType'
import { TaskCategoryApiCodeEnum as TCAE } from '../type'
import { TaskRuleConstant } from '../../class/TaskRuleConstant'
import { TaskRuleConstantMysterySpecial } from '../../class/TaskRuleConstantMysterySpecial'
import { merge } from 'lodash'

/** 任务规则静态内容响应数据 */
export class _TaskRuleConstantWebRes {
  /** 给对象注入response的值 */
  public static immitServerResponse: (
    target: _TaskRuleConstantWebRes,
    data: _TaskRuleConstantServerRes,
    apiCode: TCAE
  ) => _TaskRuleConstantWebRes
  /** 本规则所属于的类型 */
  apiCode!: TCAE
  /** 登录前弹窗状态  */
  beforeLoginPopType = PopTypeBeforeLogin.never
  /** 登录后弹窗状态  */
  afterLoginPopType = PopTypeAfterLogin.never
  /** 模板类型 */
  template: null | 1 | 2 | 3 | 4 = null
  /** 用户层级(后端约定 0或空数组,表示全部) */
  userLevel: number[] = []
  /** 规则 */
  rules: Array<TaskRuleConstant> = []
  /** 特别补充: 神秘任务一条特殊规则 | 新人福利的过期天数限制*/
  specialSupplements?: TaskRuleConstantMysterySpecial
}

/** 任务规则静态内容响应数据 */
_TaskRuleConstantWebRes.immitServerResponse = (
  target: _TaskRuleConstantWebRes,
  data: _TaskRuleConstantServerRes,
  apiCode: TCAE
) => {
  if (!data) {
    return target
  }
  target = merge(target, data)
  target.apiCode = apiCode
  // 如果有值,则拆分,无值,直接返回空数组
  target.userLevel = Boolean(data.userLevel)
    ? data.userLevel.split(',').map(Number)
    : []
  // 如果有补充内容则进行一下特殊处理
  if (data.taskData) {
    const extraData = data.taskData
    // 如果是新人福利,则要将过期限制返回
    if (apiCode === TCAE.xrfl) {
      target.specialSupplements = { limitDay: extraData.limitDay }
    }
    // 如果是神秘任务,则需要特殊处理一下特别的补充参数返回
    if (apiCode === TCAE.smrw) {
      target.specialSupplements = {
        disableLastProfit: Boolean(extraData.disableLastProfit),
        maxAmount: extraData.maxAmount,
        minAmount: extraData.minAmount
      }
    }
  }

  return target
}

/** 数据库返回的原始数据 */
export type _TaskRuleConstantServerRes = Omit<
  _TaskRuleConstantWebRes,
  'userLevel'
> & {
  /** 用户层级字符串 */
  userLevel: string
  /** 神秘任务的特殊奖项 */
  taskData: TaskRuleConstantMysterySpecial
}
