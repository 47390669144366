import { TaskCategoryApiCodeEnum } from '../../api/type'
import { TaskPageStore } from './index'
import { TaskRuleStatusEnum } from '../../class/taskRule'
import { judgeUserIsAllolwPop } from './common/judgeUserIsAllolwPop'
import { useMainStore } from '@/store/index'

/** 判断弹框是否可以打开 通过用户勾选的checked和rules数据
 *  用户未勾选禁用,并存在需要展示的任务,则需要弹出
 * @param store
 * @param apiCode 当前任务code
 * @param ruleIds 规则id列表
 * @param isFreshData 是否刷新store中的数据
 * @returns  {Boolean} [true:可以打开] [false:不能打开]
 */
export const proxyJudgeIsOpenableByPendingReceiveRules = async (
  store: TaskPageStore,
  apiCode: TaskCategoryApiCodeEnum,
  ruleIds: number[] | number,
  isFreshData: boolean
) => {
  /** 后台总配置是否显示任务弹框 */
  const isShowTaskModal = useMainStore().getActivesAuthInfos.taskCount
  if (!isShowTaskModal) {
    return false
  }
  /** 是否用户允许弹窗  */
  const isUserAllowPop = await judgeUserIsAllolwPop({
    store,
    apiCode,
    isFreshData
  })
  if (!isUserAllowPop) {
    return false
  }
  // 转化ruleIds的格式必须为数组
  let localRuleIds: number[]
  if (typeof ruleIds === 'number') {
    localRuleIds = [ruleIds]
  } else {
    localRuleIds = ruleIds
  }
  /** 本次需要判断的任务列表 */
  const ruleList = store.codeRulesMapper[apiCode].filter((rule) =>
    localRuleIds.includes(rule.ruleid)
  )
  /** 是否需要展示 */
  const hasPendingReceive = ruleList.some((rule) => {
    if (rule.status === TaskRuleStatusEnum.PendingReceive) {
      return true
    } else {
      return false
    }
  })
  if (!hasPendingReceive) {
    return false
  }
  return true
}
