import {
  BalanceSwitchEnum,
  CaptchaSwitchEnum,
  PayChannelMergeEnum,
  PayEmailEnum,
  PayModalConfigManager,
  PayPhoneEnum,
  PayWalletAddressEnum,
  RealNameSwitchEnum,
  RechargePaymentType,
  SpecialCPFSwitchEnum
} from '../config'
import {
  BindQingNengPayload,
  CustomerServicePayData,
  FinanceGetAccountInfoPayload,
  FinanceGetNoUserInfoPayload,
  FinanceGetUPAYUserInfoPayload,
  FinanceListData,
  FinanceNoWalletBuyPayload,
  FinancePayListDataWrapper,
  GetFinancePayListData,
  OnlinePayData,
  QianNengMsgCodePayload,
  SubmitLinkOpenWay,
  TransferPayData,
  bobiData
} from '@/api/finance/type'
import {
  BindTypeEnum,
  ChannelCodeEnum,
  FinancePayState,
  RootCategoryEnum
} from './type'
import { CommonSwitchStatus, CurrencyEnum } from '@/api/common/type'
import { GlobalConst, GlobalService } from '@/context'
import {
  apiFinanceGetExchangeRateByCurrencyCode,
  apiFinanceGetPayListV4,
  apiFinanceGetPayOrderFee,
  apiFinanceGetPayPlatformList,
  apiFinanceGetPayTypeV4,
  apiFinanceNoWalletBuy
} from '@/api/finance'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { getCurrentDevice } from '@/utils/Device'
import { ping } from '@/utils/Tool'
import { toString } from '@/utils/MathUtils'
import { trace } from '@/context/tacker'
import { useFinanceShareStore } from '@/views/finance/shared/store/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import { windowConfig } from '@/utils/window'
import DateUtils from '@/utils/DateUtils'
import FinanceUtils from '@/utils/business-utils/finance'
import Modal from '@/controller/Modal'
import axios, { AxiosError, AxiosResponse } from 'axios'
import to from 'await-to-js'
import useGoubaoPayStore from './goubao'

const useFinancePayStore = defineStore('finance/pay', {
  state: (): FinancePayState => {
    return {
      /**
       * 本次是否下单成功
       */
      isPaySuccess: false,

      /**
       * 内层loading
       */
      loading: false,
      /**
       * 支付方式
       */
      rootCategoryList: [],

      // 当前选择的充值分类下的支付平台
      getPayPlatformList: [],

      getPayPlatform: {
        name: '',
        icon: '',
        type: 0,
        charge_rate: 0,
        list: [],
        weight: 0,
        payCurrency: []
      },

      onlineChannelList: [],

      transferChannelList: [],
      /**
       * 当前选择的支付方式[在线客服 人工充值 转账]
       */
      rootCategoryIndex: RootCategoryEnum.UNKNOWN,
      /**
       * 当前激活的支付平台的父级信息[payListV3 接口中list > list 的数据]
       */
      activePlatformParent: undefined,
      /**
       * 当前激活的支付平台
       */
      activePlatformCategory: undefined,
      /**
       * 当前激活的支付平台选择的支付方式
       */
      activePlatformMethod: undefined,
      /**
       * 在线充值/转账充值金额
       */
      money: undefined,

      // 钱能钱包账户
      qianNengAccountDetail: {
        success: false,
        data: {
          errcode: -1,
          data: undefined
        }
      },
      // 波币钱包
      bobiAccountDetail: {
        success: false,
        data: {
          code: -1
        }
      },
      bindQianNengWalletResult: {
        errcode: -1,
        errmsg: ''
      },

      // 会员绑定No钱包余额信息
      memberBalanceDetail: undefined,

      // 是否绑定upay 钱包
      isBindUPAYWallet: false,
      // UPAY 钱包详情
      UPAYBalanceDetail: undefined,

      bindUPAYWalletUrl: '',
      /**
       * 需要校验的表单数据
       */
      validateForm: {
        /**
         * 在线充值/转账真实姓名
         */
        realName:
          useMainStore().realnameValue || useMainStore().userInfos?.realname,
        /**
         * 在线充值/转账 钱包地址[可选]
         */
        walletAddress: undefined,

        /**
         * 在线充值 钱包密码[可选]
         */
        walletPassword: undefined,
        /**
         * 在线充值/转账 邮箱[可选]
         */
        email: undefined,
        /**
         * 在线充值/转账 手机号[可选]
         */
        phone: undefined,

        /**
         * 卡号 刮刮卡模式表单[在线充值模式专属]
         */
        cardNumber: undefined,
        /**
         * 卡密 刮刮卡模式表单[在线充值模式专属]
         */
        cardPassword: undefined,
        /**
         * 运营商 刮刮卡模式表单[在线充值模式专属]
         */
        networkMerch: undefined,
        /**
         * 银行系统编码 网银模式表单[在线充值模式专属]
         */
        bankSysCode: undefined,
        /**
         * cpf账号
         */
        cpf: useMainStore().userInfos?.cpf
      },
      /**
       * 签名使用key
       */
      signKey: undefined,

      // no钱包 直接购买-购买跳转url
      buyUrl: '',

      /**
       * 订单号[充值记录详情使用跨组件通信数据]
       */
      orderNo: undefined,
      /**
       * 弹窗控制[充值记录详情使用跨组件通信数据]
       */
      visible: false,
      /**
       * 开始计时[充值记录详情使用跨组件通信数据]
       */
      startCount: false,
      /**
       * 倒计时时常[充值记录详情使用跨组件通信数据]
       */
      countdown: 0,
      /**
       * 触发表格查询
       */
      tableQueryTrigger: 0,
      /**
       * 当前汇率
       */
      exchangeRate: '1',
      /**
       * 当前充值币种分类
       */
      payCurrencyCategory: '',
      /**
       * 输入金额所属币种
       */
      activeCurrencyCode: useMainStore().userInfos?.currency ?? 'CNY',
      /**
       * 特殊币种切换[是否处于上分模式]
       */
      isScoreMode: false,
      /**
       * 手续费减免数值
       */
      fee: {
        realFee: '',
        deduction: ''
      },

      // ping拿到的最快的充值域名
      fastestDomain: '',

      /**
       * 获取大类空白界面原因
       */
      payListV4Error: undefined,

      activePlatformId: undefined,
      activeChannelId: undefined,

      // 平台是否展开
      isPlatformExpand: false,
      // 通道是否展开
      isChannelExpand: false,

      // 请求失败
      fetchError: false,

      // 记录接口请求状态
      fetchStatus: {
        noBalance: {
          loading: false,
          timeout: false
        },
        exchangeRate: {
          loading: false,
          timeout: false
        }
      }
    }
  },

  actions: {
    /**
     * 充值大类展开
     * @param bol
     */
    setPlatformExpand(bol: boolean) {
      this.isPlatformExpand = bol
    },

    /**
     * 支付通道展开
     * @param bol
     */
    setChannelExpand(bol: boolean) {
      this.isChannelExpand = bol
    },

    /**
     * @description 初始化支付配置
     * @returns
     */
    async initPayConfig() {
      if (!this.rootCategoryList.length) {
        this.loading = true
      }

      this.fetchError = false
      const [err, data] = await to(
        apiFinanceGetPayListV4(this.payCurrencyCategory)
      )
      this.loading = false

      if (err) {
        // 不是后端返回的错误，统一按照超时处理
        this.fetchError = !(
          err as unknown as AxiosResponse<{ errorCode: string }>
        ).data?.errorCode
        this.rootCategoryList = []
        console.error('init pay config failed:', err)

        const requestId = (err as AxiosError).config.headers?.[
          'x-request-id'
        ] as string
        const traceId = (err as AxiosError).request.headers?.[
          'x-trace-id'
        ] as string

        this.payListV4Error = {
          code: '1004',
          requestId,
          traceId
        }

        trace(
          'recharge-fetch-error',
          {
            url: '/pay/payListV4',
            responseUrl: (err as AxiosError).request?.responseURL,
            id: useMainStore().userInfos?.username,
            isWgPackage: windowConfig.isWgPackage,
            requestId,
            traceId,
            errorMessage: err.message
          },
          true
        )
        return
      }

      const { sign_key, emailVerify, mobileVerify, list } = data.data
        .data as GetFinancePayListData

      if (!list?.length) {
        const requestId = data.config.headers?.['x-request-id'] as string
        const traceId = data.request.headers?.['x-trace-id'] as string
        // 通过返回时间对比判断是否来自缓存, 大于30s，则来自缓存
        const now = Date.now()
        const time = data.data.time ? data.data.time * 1000 : now
        const code = now - time > 1000 * 30 ? '1002' : '1001'

        this.payListV4Error = {
          code,
          requestId,
          traceId
        }
      }

      // 基础信息
      if (!this.signKey) {
        this.signKey = sign_key
      }
      this.validateForm.email = emailVerify
      this.validateForm.phone = mobileVerify
      // 支付方式集合数组
      this.rootCategoryList = list
      // 支付方式type集合
      const keys = this.getRootCategoryListTypes
      if (keys.length > 0) {
        // 默认为keys[0]，否则为自身
        this.rootCategoryIndex =
          this.rootCategoryIndex === RootCategoryEnum.UNKNOWN
            ? keys[0] // 默认取第一项
            : this.rootCategoryIndex

        await this.getPayType(this.rootCategoryIndex)
      }
    },

    async getPayType(type: number) {
      // 切换支付方式的时候，不在type集合，不需要获取大类
      if (!this.getRootCategoryListTypes.includes(type)) return

      const [err, data] = await to(apiFinanceGetPayTypeV4(type))
      if (err) {
        console.error('init pay config failed:', err)
        return
      }
      this.getPayPlatform = data.data.data?.payKind as FinancePayListDataWrapper
      const list = data.data.data?.payKind.list
      if (list?.length) {
        this.getPayPlatformList = list
        let platformIndex = 0
        if (this.activePlatformId) {
          const index = list.findIndex(
            (item) => item.payplatformid === this.activePlatformId
          )
          platformIndex = Math.max(0, index)
        }
        const platform = list[platformIndex]
        this.setActivePlatformCategory(platform)
      }
    },
    /**
     * @description 设置当前激活平台
     * @param id
     * @returns
     */
    async setActivePlatformCategory(info: FinanceListData) {
      this.activePlatformParent = info
      const { payKind, payplatformid } = info
      this.activePlatformId = payplatformid
      // 根据大类所在位置，判断是否需要展开
      const platformCardIndex = this.getPayPlatformList.findIndex(
        (p) => p.payplatformid === payplatformid
      )
      if (platformCardIndex + 1 >= this.PAY_PLAT_FORM_CATEGORY_CARD_LIMIT) {
        this.setPlatformExpand(true)
      }

      const [err, data] = await to(
        apiFinanceGetPayPlatformList({
          payplatformid,
          payCurrency: this.payCurrencyCategory, // 通道币种  当选择全部时，该值传空
          payKind
        })
      )
      if (err) {
        const requestId = (err as AxiosError).config.headers?.[
          'x-request-id'
        ] as string
        const traceId = (err as AxiosError).request.headers?.[
          'x-trace-id'
        ] as string

        trace(
          'recharge-fetch-error',
          {
            url: '/pay/payplatformlistV3',
            id: useMainStore().userInfos?.username,
            responseUrl: (err as AxiosError).request?.responseURL,
            isWgPackage: windowConfig.isWgPackage,
            requestId,
            traceId,
            errorMessage: err.message
          },
          true
        )
        console.error('get pay config failed:', err)
        return
      }
      // console.info('set setActivePlatformCategory data:', data.data.data)
      this.activePlatformCategory = data.data.data

      // 银商充值，不需要管支付通道
      if (data.data.data?.payline_type === RootCategoryEnum.SILVERMERCHANT) {
        return
      }

      if (this.activePlatformCategory!.list.length > 0) {
        let channelIndex = 0
        if (this.activeChannelId) {
          const index = this.activePlatformCategory!.list.findIndex((item) => {
            const channelId = [
              RootCategoryEnum.ONLINE,
              RootCategoryEnum.CRYPTO
            ].includes(this.rootCategoryIndex)
              ? (item as OnlinePayData).payplatformid
              : (item as TransferPayData).id
            return channelId === this.activeChannelId
          })
          channelIndex = Math.max(0, index)
        }
        // 当前在线支付方式下 app_type有值的支付通道
        this.onlineChannelList = this.activePlatformCategory!.list.filter(
          (item) => item.app_type
        ) as Array<OnlinePayData>

        // 当前转账支付方式下符合app type的支付通道
        this.transferChannelList = this.activePlatformCategory!.list.filter(
          (item) => item.app_type
        ) as Array<TransferPayData>

        // 根据通道所在位置，判断是否需要展开
        if (channelIndex + 1 >= this.CHANNEL_LIMIT) {
          this.setChannelExpand(true)
        }
        const channel = this.onlineChannelList[channelIndex]
        await this.setActivePayPlatformMethodCondition(channel)
      }
    },

    /**
     * @description 设置当前平台-渠道下指定支付方式的方式条件[channel]
     * @param state
     */
    async setActivePayPlatformMethodCondition(
      info: OnlinePayData | TransferPayData | CustomerServicePayData
    ) {
      this.activeChannelId =
        'payplatformid' in info ? info.payplatformid : info.id

      this.activePlatformMethod = info
      const { isUserVND, isUserIDR, userCurrencyCode } =
        this.multipleCurrencyCondition

      const { isOnlineBank, showRealNameInput, realNameInputAuto } =
        this.getConditionInfo

      // VDN 和 IDR 币种特殊处理,默认展示上分模式,隐藏输入切换按钮
      if (isUserVND || isUserIDR) {
        this.activeCurrencyCode = userCurrencyCode
        this.isScoreMode = true
      } else {
        // 重置当前激活币种为通道币种
        this.activeCurrencyCode = (
          this.activePlatformMethod as OnlinePayData
        ).payCurrency
      }

      // 购宝钱包
      if (this.isGoubaoWalletChannel) {
        useGoubaoPayStore().getWalletBalance()
      }

      this.isQianNengWalletChannel && this.getQianNengWalletBalance()
      this.isBobiedWalletChannel && this.getBobiBalance()
      this.isUPAYWalletChannel && this.getUPAYWalletDetail()

      if (
        this.isNoWalletDirectPurchaseChannel ||
        this.isNoWalletBalancePayChannel ||
        this.isNoWalletBalancePayChannel2
      ) {
        this.getNoWalletBalance()
      }

      // 更新汇率 人工充值不需要更新汇率
      const index = this.rootCategoryIndex
      if (index != RootCategoryEnum.ARTIFICIAL) {
        await this.initExchangeRate()
      }
      this.validateForm.realName = this.globalRealName
      // 判断如果当前通道的真实姓名为可编辑 则清空真实姓名
      if (showRealNameInput && !realNameInputAuto) {
        this.validateForm.realName = ''
      }

      // 当前通道是否为网银通道需要更新银行列表
      if (isOnlineBank) {
        const { merchCode, payCurrency } = info as OnlinePayData
        useFinanceShareStore().initOnlinePayBankList(merchCode, payCurrency)
      }

      // 如果输入框有金额 则需要换算成分数
      if (this.money && this.isScoreMode) {
        const { userCurrencyGameRate } = this.multipleCurrencyCondition

        if (userCurrencyGameRate !== 0) {
          this.money = FinanceUtils.div(this.money, userCurrencyGameRate ?? 1)
        }

        if (this.exchangeRate !== '0') {
          this.money = FinanceUtils.div(this.money, this.exchangeRate ?? 1)
        }

        this.money = FinanceUtils.getInputMoneyLimit(this.money)
      }
    },

    // no钱包-创建账号和充值
    async getNoWalletBuy() {
      const { activePlatformMethod, signKey } = this
      const { siteCode, userInfos } = useMainStore()
      const channel = activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const noWalletPayload: FinanceNoWalletBuyPayload = {
        siteCode,
        merchantId: String(channel?.merch_agent_id),
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username),
        amount: this.money ?? ''
      }
      noWalletPayload.sign = FinanceUtils.getWalletSign(
        noWalletPayload,
        signKey ?? ''
      )

      const doMain = `${this.walletDomain}/pay/wallet/no/buy`

      const res = await apiFinanceNoWalletBuy(
        doMain,
        noWalletPayload,
        signKey ? signKey.slice(-4) : ''
      ).catch(() => {
        Modal.message({
          type: 'error',
          content: t('lobby.error.networkError')
        })
      })

      if (!res) return

      if (!(res.data as unknown as Record<string, string>)?.success) {
        Modal.message({
          type: 'error',
          content: res.data?.msg
        })
        return
      }

      const buyUrl = res.data?.data?.buyUrl
      if (!buyUrl) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.noBindUrlError')
        })
        return
      }
      this.buyUrl = buyUrl
    },
    // 在线充值-bobi钱包余额
    async getBobiBalance() {
      const channel = this.activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.noBindUrlError')
        })
        return
      }
      const { siteCode, userInfos } = useMainStore()
      const doMain = `${this.walletDomain}/pay/wallet/bobi/getUserInfo`
      const payload: FinanceGetAccountInfoPayload = {
        merchId: String(channel?.merch_agent_id),
        siteCode,
        deviceType: getCurrentDevice().ios() ? 'ios' : 'android',
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username)
      }
      payload.sign = FinanceUtils.getWalletSign(payload, this.signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      await axios
        .post(doMain, payload)
        .then((res: AxiosResponse) => {
          const { data } = res.data
          this.bobiAccountDetail = res.data
          this.bobiAccountDetail.data = JSON.parse(data) as bobiData
        })
        .catch(() => {
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
    },
    // 在线充值-钱能钱包账户余额
    async getQianNengWalletBalance() {
      const channel = this.activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const { siteCode, userInfos } = useMainStore()
      const doMain = `${this.walletDomain}/pay/wallet/qianNeng/getUserInfo`

      const payload: FinanceGetAccountInfoPayload = {
        merchId: String(channel?.merch_agent_id),
        siteCode,
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username)
      }

      payload.sign = FinanceUtils.getWalletSign(payload, this.signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      await axios
        .post(doMain, payload)
        .then((res) => {
          this.qianNengAccountDetail = res.data
        })
        .catch(() => {
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
    },
    // 在线充值-no钱包 直接购买|余额支付 通道 获取用户绑定信息
    async getNoWalletBalance() {
      const channel = this.activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const doMain = `${this.walletDomain}/pay/wallet/no/getUserInfo`

      const { siteCode, userInfos } = useMainStore()
      const payload: FinanceGetNoUserInfoPayload = {
        siteCode,
        merchantMemberNo: String(userInfos?.username),
        merchantId: String(channel?.merch_agent_id),
        time: DateUtils.timestamp()
      }
      payload.sign = FinanceUtils.getWalletSign(payload, this.signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      this.fetchStatus.noBalance.loading = true
      await axios
        .post(doMain, payload)
        .then((res) => {
          this.fetchStatus.noBalance.timeout = false
          if (res.data?.success) {
            this.memberBalanceDetail = res.data.data
            if (!res.data.data) {
              Modal.message({
                type: 'error',
                content: t('lobby.modal.pay.noBindUrlError')
              })
            }
          } else {
            Modal.message({
              type: 'error',
              content: res.data.msg
            })
          }
        })
        .catch((error) => {
          this.fetchStatus.noBalance.timeout =
            GlobalService.isTimeoutError(error)
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
      this.fetchStatus.noBalance.loading = false
    },

    async getUPAYWalletDetail() {
      const channel = this.activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const doMain = `${this.walletDomain}/pay/wallet/UPay/getUserInfo`

      const { siteCode, userInfos } = useMainStore()
      const payload: FinanceGetUPAYUserInfoPayload = {
        siteCode,
        userId: String(userInfos?.username),
        merchId: String(channel?.merch_agent_id),
        time: DateUtils.timestamp()
      }
      payload.sign = FinanceUtils.getWalletSign(payload, this.signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      await axios
        .post(doMain, payload)
        .then((res) => {
          this.isBindUPAYWallet = res.data.success
          if (!res.data.success) {
            Modal.message({
              type: 'error',
              content: res.data.msg
            })
            return
          }

          if (
            res.data.data?.code === GlobalConst.ServiceCode.UPAY_SUCCESS_CODE
          ) {
            this.UPAYBalanceDetail = res.data.data.result
          } else {
            Modal.message({
              type: 'error',
              content: res.data.data.message
            })
          }
        })
        .catch(() => {
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
    },
    // 绑定UPAY
    async bindUPAYWallet() {
      const { activePlatformMethod, activePlatformCategory, signKey } = this
      const channel = activePlatformMethod as OnlinePayData
      const url = activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const { siteCode, userInfos } = useMainStore()
      const doMain = `${this.walletDomain}/pay/wallet/UPay/register`
      const payload: FinanceGetUPAYUserInfoPayload = {
        merchId: String(channel?.merch_agent_id),
        siteCode,
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username)
      }
      payload.sign = FinanceUtils.getWalletSign(payload, signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      await axios
        .post(doMain, payload)
        .then((res: AxiosResponse) => {
          if (
            res.data.data?.code === GlobalConst.ServiceCode.UPAY_SUCCESS_CODE
          ) {
            this.isBindUPAYWallet = res.data.success
            this.UPAYBalanceDetail = res.data.data.result
          } else {
            if (!res.data.success) {
              Modal.message({
                type: 'error',
                content: res.data.msg
              })
              return
            }
            Modal.message({
              type: 'error',
              content: res.data.data.msg
            })
          }
        })
        .catch(() => {
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
    },
    // 购买UPAY
    async payUPAY() {
      const { activePlatformMethod, activePlatformCategory, signKey } = this
      const channel = activePlatformMethod as OnlinePayData
      const url = activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const { siteCode, userInfos } = useMainStore()
      const doMain = `${this.walletDomain}/pay/wallet/UPay/login`
      const payload: FinanceGetUPAYUserInfoPayload = {
        merchId: String(channel?.merch_agent_id),
        siteCode,
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username)
      }
      payload.sign = FinanceUtils.getWalletSign(payload, signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      await axios
        .post(doMain, payload)
        .then((res: AxiosResponse) => {
          if (!res.data.success) {
            Modal.message({
              type: 'error',
              content: res.data.msg
            })
            return
          }

          if (
            res.data.data.code === GlobalConst.ServiceCode.UPAY_SUCCESS_CODE
          ) {
            this.bindUPAYWalletUrl = res.data.data.result.payUrl
          } else {
            Modal.message({
              type: 'error',
              content: res.data.data.message
            })
          }
        })
        .catch(() => {
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
    },
    // 绑定钱能钱包账号
    async bindQianNengWallet(query: {
      realName: string
      phoneNumber: string
      phoneCode: string
    }) {
      const channel = this.activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const { siteCode, userInfos } = useMainStore()
      const doMain = `${this.walletDomain}/pay/wallet/qianNeng/bind`

      const payload: BindQingNengPayload = {
        merchId: String(channel?.merch_agent_id),
        phoneCode: query?.phoneCode,
        phoneNumber: query?.phoneNumber,
        realName: query?.realName,
        siteCode,
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username)
      }

      payload.sign = FinanceUtils.getWalletSign(payload, this.signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      await axios
        .post(doMain, payload)
        .then((res) => {
          this.bindQianNengWalletResult = res.data.data
        })
        .catch(() => {
          Modal.message({
            type: 'error',
            content: t('lobby.error.networkError') + '\n' + doMain
          })
        })
    },

    getQianNengMsgCode(phoneNumber: string) {
      const channel = this.activePlatformMethod as OnlinePayData
      const url = this.activePlatformCategory?.url
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }
      const { siteCode, userInfos } = useMainStore()
      const doMain = `${this.walletDomain}/pay/wallet/qianNeng/getMsgCode`

      const payload: QianNengMsgCodePayload = {
        merchId: String(channel?.merch_agent_id),
        phoneNumber,
        siteCode,
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username)
      }

      payload.sign = FinanceUtils.getWalletSign(payload, this.signKey ?? '')

      // 使用await，是因为在其他地方调用的时候需要等这个请求执行完并赋值成功
      return axios.post(doMain, payload).catch(() => {
        Modal.message({
          type: 'error',
          content: t('lobby.error.networkError') + '\n' + doMain
        })
      })
    },

    /**
     * 初始化汇率信息
     * @returns
     */
    async initExchangeRate() {
      const {
        userCurrencyCode,
        channelCurrencyCode,
        siteCode,
        isActiveCurrencySameWithUserCurrency,
        isUserVND
      } = this.multipleCurrencyCondition

      // 如果站点币种和通道币种一致,则不获取汇率信息
      if (userCurrencyCode === channelCurrencyCode) {
        this.exchangeRate = '0'
        return
      }

      // 越南币只存在会员币种上分
      const baseCurrency =
        isActiveCurrencySameWithUserCurrency || isUserVND
          ? userCurrencyCode
          : channelCurrencyCode
      const exchangeCurrency =
        isActiveCurrencySameWithUserCurrency || isUserVND
          ? channelCurrencyCode
          : userCurrencyCode

      this.fetchStatus.exchangeRate.loading = true
      const [err, data] = await to(
        apiFinanceGetExchangeRateByCurrencyCode({
          siteCode,
          queryRateType: 0
        })
      )
      this.fetchStatus.exchangeRate.loading = false
      if (err) {
        console.error('update exchange rate failed:', err)
        this.fetchStatus.exchangeRate.timeout = GlobalService.isTimeoutError(
          err as unknown as AxiosError
        )
        return
      }
      // 后台是返回map结构 通过基础币种_转换币种_0|1拿到对应值 0充值 1提现
      const rate =
        data.data.data?.[`${baseCurrency}_${exchangeCurrency}`] ?? '0'
      this.exchangeRate = rate
    },
    /**
     * 打开详情弹窗
     */
    openDetailsModal(orderNo: string, countDown: number) {
      this.startCount = countDown !== 0
      this.countdown = countDown
      this.orderNo = orderNo
      this.visible = true
    },
    /**
     * 触发表格查询(record组件监听)
     */
    releaseTableQuery() {
      this.tableQueryTrigger++
    },
    /**
     * 更新刮刮卡模式手续费减免数值
     */
    async updateFeeRateDeduction() {
      if (!this.money) {
        return
      }
      const activeChannel = this.activePlatformMethod as OnlinePayData
      const { paymentid = 0, payplatformid = 0 } = activeChannel
      const [err, data] = await to(
        apiFinanceGetPayOrderFee({
          paymentId: paymentid,
          channelId: payplatformid,
          amount: +this.money
        })
      )

      if (err) {
        console.error('apiFinanceGetPayOrderFee failed:', err)
        return
      }
      this.fee.deduction = data.data.data?.reduceFee ?? ''
      this.fee.realFee = data.data.data?.realFee ?? ''
    },

    // 对指定的多个URL进行ping测试
    async getFastestUrlTime() {
      const mainStore = useMainStore()
      const urls = mainStore.siteURLInfos?.pay_domain

      if (urls && urls.length > 0) {
        let fastestTime = Infinity // 记录最快的响应时间
        let fastestUrl = ''

        for (let i = 0; i < urls.length; i++) {
          const url = urls[i]
          ping(url).then((pingResult) => {
            if (
              pingResult.pingTime < fastestTime &&
              pingResult.pingTime !== 9999
            ) {
              fastestTime = pingResult.pingTime
              fastestUrl = url
            }

            console.info('pay_domains_fastest--->>', fastestUrl, fastestTime)
            this.fastestDomain = fastestUrl
          })

          // 如果不是最后一个URL，那么等待给定的时间间隔
          if (i !== urls.length - 1) {
            await new Promise((resolve) => setTimeout(resolve, 250)) // 每条URL延迟250毫秒
          }
        }
      }
    }
  },
  getters: {
    /**
     * 充值大类超过多少开始折叠
     */
    PAY_PLAT_FORM_CATEGORY_CARD_LIMIT: () => {
      return useMainStore().isWeb ? 8 : 6
    },
    /**
     * 通道超过多少开始折叠
     */
    CHANNEL_LIMIT: (state) => {
      return state.rootCategoryIndex === RootCategoryEnum.ONLINE ? 6 : 4
    },
    /**
     * 根据充值金额获取充值地址
     * 后端的支付地址进行调整，由原来的 pay/paysubmit ，改为dragon/phoenix/,前端调整请求充值路径
     * 输入特殊金额触发这个接口，特殊金额：44.44, 94.44, 144.44, 1114.44 只有输入这4个金额才触发dragon/phoenix/接口
     */
    payUrl(state) {
      if (state.fastestDomain) {
        const path =
          state.money &&
          ['44.44', '94.44', '144.44', '114.44'].includes(state.money)
            ? '/dragon/phoenix/'
            : '/pay/paysubmit/'
        const mainStore = useMainStore()
        return state.fastestDomain + path + mainStore.siteCode
      }

      return ''
    },
    /**
     * 获取充值分类的类型 type 的集合
     * @param state
     * @returns
     */
    getRootCategoryListTypes(state) {
      return state.rootCategoryList.map((item) => item.type)
    },
    /**
     * 获取当前充值分类的类型
     * @param state
     * @returns
     */
    getRootCategoryByType(state) {
      return (type: RootCategoryEnum) => {
        return state.rootCategoryList.find((item) => item.type === type)
      }
    },
    /**
     * 真实姓名
     * @returns
     */
    globalRealName() {
      const mainStore = useMainStore()
      return mainStore.realnameValue || mainStore.userInfos?.realname
    },
    /**
     * 检验金额数据是否正确
     */
    checkMoneyFalse(): boolean {
      return (
        !this.money ||
        +this.money < +this.getPayLimit.min ||
        +this.money > +this.getPayLimit.max
      )
    },

    /**
     * 三方充值地址 是否内嵌打开
     */
    isOpenInIframePopup(state) {
      return (
        (state.activePlatformMethod as OnlinePayData)?.openWay ===
        SubmitLinkOpenWay.Inner
      )
    },

    /**
     * 是否合并通道
     */
    isMerged(state) {
      return (
        !state.activePlatformCategory ||
        state.activePlatformCategory.merge_status === PayChannelMergeEnum.MERGE
      )
    },
    // 是否购宝钱包
    isGoubaoWalletChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return channel?.merchCode === 'goubaowalletpay'
    },
    isBobiedWalletChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.channel_type === 'noBalancePay' &&
        channel?.merchCode === 'cnybobipay'
      )
    },
    // 是否是No钱包
    isNoWallet(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return channel?.merchCode === 'walletpay'
    },
    // 是否是钱能钱包通道
    isQianNengWalletChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.channel_type === 'noBalancePay' &&
        channel?.merchCode === 'qianlengwalletpay'
      )
    },
    // no钱包 余额支付 通道
    isNoWalletBalancePayChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.merchCode === 'walletpay' &&
        Number(channel?.channelCode) === ChannelCodeEnum.BALANCE_PAY
      )
    },
    // no钱包 余额支付 新通道
    isNoWalletBalancePayChannel2(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.merchCode === 'walletpay' &&
        Number(channel?.channelCode) === ChannelCodeEnum.BALANCE_PAY2
      )
    },
    // no钱包 直接购买 通道
    isNoWalletDirectPurchaseChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.merchCode === 'walletpay' &&
        Number(channel?.channelCode) === ChannelCodeEnum.DIRECT_PURCHASE
      )
    },
    // no钱包 扫码支付 通道
    isNoWalletScanCodePayChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.merchCode === 'walletpay' &&
        Number(channel?.channelCode) === ChannelCodeEnum.SCAN_CODE_PAY
      )
    },

    // no钱包 直接购买 通道 里是否绑定过No钱包
    // no钱包 余额支付 通道 里是否绑定过No钱包
    hasNoWallet(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.merchCode === 'walletpay' &&
        [
          ChannelCodeEnum.DIRECT_PURCHASE,
          ChannelCodeEnum.BALANCE_PAY,
          ChannelCodeEnum.BALANCE_PAY2
        ].includes(Number(channel?.channelCode)) &&
        state.memberBalanceDetail?.bind === BindTypeEnum.BOUND
      )
    },
    // 是否是UPAY钱包 大类
    isUPAYWalletChannel(state) {
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      return (
        channel?.channel_type === 'noBalancePay' &&
        channel?.merchCode === 'upaywalletpay'
      )
    },

    /**
     * 当前充值条件的开关以及温馨提示等信息
     */
    getConditionInfo(state) {
      const selfState =
        (state.activePlatformMethod as OnlinePayData & TransferPayData) ?? {}
      const channel = state.activePlatformMethod as OnlinePayData | undefined
      const hasNoWallet =
        channel?.merchCode === 'walletpay' &&
        [ChannelCodeEnum.DIRECT_PURCHASE, ChannelCodeEnum.BALANCE_PAY].includes(
          Number(channel?.channelCode)
        ) &&
        state.memberBalanceDetail?.bind === BindTypeEnum.BOUND

      const {
        balance_switch,
        realNameSwitch,
        payAddressSwitch,
        mobileSupport,
        emailSupport,
        captchaSwitch,
        payment_type,
        specialCPFSwitch
      } = selfState
      /**
       * 94308 充值加赠 增加recommendList
       * 废弃之前的 moneyBtns
       */
      let recommendList = selfState.recommendList
      let remark = selfState.remark

      let showMoneyInput = balance_switch === BalanceSwitchEnum.OPEN
      let showRealNameInput =
        realNameSwitch !== CommonSwitchStatus.CLOSE && !hasNoWallet
      let showRealNameInputFreeze =
        realNameSwitch === RealNameSwitchEnum.AUTOFILL_FREEZE
      const realNameInputAuto = [
        RealNameSwitchEnum.AUTOFILL_EDITOR,
        RealNameSwitchEnum.AUTOFILL_FREEZE
      ].includes(realNameSwitch)

      const showCPFInput = specialCPFSwitch === SpecialCPFSwitchEnum.OPEN
      let payWalletAddressValid =
        payAddressSwitch === PayWalletAddressEnum.VALID
      let showPayWalletAddress = new Set([
        PayWalletAddressEnum.OPEN,
        PayWalletAddressEnum.VALID
      ]).has(payAddressSwitch)

      let payPhoneValid = mobileSupport === PayPhoneEnum.VALID
      let showPhone = new Set([PayPhoneEnum.OPEN, PayPhoneEnum.VALID]).has(
        mobileSupport
      )

      let payEmailValid = emailSupport === PayEmailEnum.VALID
      let showEmail = new Set([PayEmailEnum.OPEN, PayEmailEnum.VALID]).has(
        emailSupport
      )

      let payCaptchaValidateSwitch = captchaSwitch === CaptchaSwitchEnum.OPEN

      //判断是否是支付宝的支付类型
      let isZFBPay = +payment_type === RechargePaymentType.ZFBAPP

      let isWallet = +payment_type === RechargePaymentType.WALLET

      let isSIMCard = +payment_type === RechargePaymentType.SIM

      let isNonePayPage = +payment_type === RechargePaymentType.NONE_PAY_PAGE

      const isOnlineBank = new Set([
        RechargePaymentType.NONE_PAY_PAGE,
        RechargePaymentType.ONLINE_BANK
      ]).has(+payment_type)

      // 是否合并,合并充值取父级
      if (this.isMerged) {
        const parentState = state.activePlatformCategory
        recommendList = parentState?.recommendList ?? []
        showMoneyInput = parentState?.balance_switch === BalanceSwitchEnum.OPEN
        remark = parentState?.remark ?? ''

        showRealNameInput =
          parentState?.realNameSwitch !== CommonSwitchStatus.CLOSE
        showRealNameInputFreeze =
          parentState?.realNameSwitch === RealNameSwitchEnum.AUTOFILL_FREEZE
        showPayWalletAddress = new Set([
          PayWalletAddressEnum.OPEN,
          PayWalletAddressEnum.VALID
        ]).has(parentState?.payAddressSwitch ?? 0)

        payWalletAddressValid =
          parentState?.payAddressSwitch === PayWalletAddressEnum.VALID

        showPhone = new Set([PayPhoneEnum.OPEN, PayPhoneEnum.VALID]).has(
          parentState?.mobileSupport ?? 0
        )
        payPhoneValid = parentState?.mobileSupport === PayPhoneEnum.VALID

        showEmail = new Set([PayEmailEnum.OPEN, PayEmailEnum.VALID]).has(
          parentState?.emailSupport ?? 0
        )
        payEmailValid = parentState?.emailSupport === PayEmailEnum.VALID

        payCaptchaValidateSwitch =
          parentState?.captchaSwitch === CaptchaSwitchEnum.OPEN

        isZFBPay = false
        isWallet = false
        isSIMCard = false
        isNonePayPage = false
      }

      // 是否展示组合验证付款人信息
      const isCombination =
        ((showEmail || showPhone) &&
          (showRealNameInput || showPayWalletAddress)) ||
        isSIMCard ||
        isOnlineBank ||
        showCPFInput

      return {
        recommendList,
        showMoneyInput,
        showRealNameInput,
        showPayWalletAddress,
        remark,
        payWalletAddressValid,
        payCaptchaValidateSwitch,
        showEmail,
        showPhone,
        payEmailValid,
        payPhoneValid,
        realNameInputAuto,
        showRealNameInputFreeze,
        isCombination,
        isZFBPay,
        isWallet,
        isSIMCard,
        isOnlineBank,
        isNonePayPage,
        showCPFInput
      }
    },
    /**
     * 获取多币种数据
     */
    multipleCurrencyCondition(state) {
      const { getCurrencyInfoByCode, userInfos, siteCode } = useMainStore()

      const activeMethod =
        (state.activePlatformMethod as OnlinePayData & TransferPayData) ?? {}

      // 通道币种和币种符号
      const channelCurrencyCode = activeMethod.payCurrency ?? 'CNY'
      const channelCurrencyInfo = getCurrencyInfoByCode(channelCurrencyCode)
      const isChannelVND = PayModalConfigManager.codeIsVND(channelCurrencyCode)

      const channelCurrencySign = channelCurrencyInfo?.currencySign
      const channelCurrencyTypeIsCrypto =
        channelCurrencyInfo?.currencyType === CurrencyEnum.CRYPTO
      const channelCurrencyGameRate = channelCurrencyInfo?.gameRate ?? 1
      const channelCurrencyIsGameRateSpec = channelCurrencyGameRate !== 1

      // 用户币种和币种符号
      const userCurrencyCode = userInfos?.currency ?? ''
      const userCurrencyInfo = getCurrencyInfoByCode(userCurrencyCode)
      // 用户是否是越南盾会员
      const isUserVND = PayModalConfigManager.codeIsVND(userCurrencyCode)
      // 用户是否是印尼盾会员
      const isUserIDR = PayModalConfigManager.codeIsIDR(userCurrencyCode)
      // 用户是否是人民币会员
      const isUserCNY = PayModalConfigManager.codeIsCNY(userCurrencyCode)

      const userMarketCurrencyCode = userCurrencyInfo?.marketCurrencyCode
      const userCurrencySign = userCurrencyInfo?.currencySign
      const userCurrencyTypeIsCrypto =
        userCurrencyInfo?.currencyType === CurrencyEnum.CRYPTO
      const userCurrencyGameRate = userCurrencyInfo?.gameRate ?? 1
      const userCurrencyIsGameRateSpec = userCurrencyGameRate !== 1

      // 通道币种和用户币种对比
      const isCurrencySame =
        userCurrencyCode === channelCurrencyCode ||
        new Set(['0', '1']).has(state.exchangeRate)

      // 当前输入币种是否与会员币种相等
      const isActiveCurrencySameWithUserCurrency =
        userCurrencyCode === state.activeCurrencyCode

      return {
        channelCurrencyCode,
        userCurrencyCode,
        userCurrencySign,
        channelCurrencySign,
        isCurrencySame,
        siteCode,
        isActiveCurrencySameWithUserCurrency,
        channelCurrencyTypeIsCrypto,
        userCurrencyTypeIsCrypto,
        channelCurrencyInfo,
        userCurrencyInfo,
        channelCurrencyGameRate,
        channelCurrencyIsGameRateSpec,
        userCurrencyIsGameRateSpec,
        userCurrencyGameRate,
        userMarketCurrencyCode,
        isUserVND,
        isChannelVND,
        isUserIDR,
        isUserCNY
      }
    },
    /**
     * 处理金额
     * 充值金额换算时,isDeposit = true , 因为充值金额最后计算均为上分金额（会员币种）
     * 其他场景根据通道币种计算
     */
    processMoney() {
      return (value?: string | null, deleteZero = true, isDeposit = false) => {
        if (!value) {
          return value
        }
        const { userCurrencyCode } = this.multipleCurrencyCondition

        let _temp = value
        if (isDeposit) {
          _temp = FinanceUtils.formatMoney(_temp, userCurrencyCode, deleteZero)
        } else {
          _temp = FinanceUtils.formatMoney(
            _temp,
            this.activeCurrencyCode,
            deleteZero
          )
        }
        return _temp
      }
    },
    getPayLimit(): { max: string; min: string } {
      const {
        isActiveCurrencySameWithUserCurrency,
        isCurrencySame,
        userCurrencyIsGameRateSpec,
        userCurrencyGameRate
      } = this.multipleCurrencyCondition
      const state = this.activePlatformMethod as OnlinePayData & TransferPayData
      let max = toString(state?.max_recharge_limit ?? 0)
      let min = toString(state?.min_recharge_limit ?? 0)

      // 合并通道获取支付平台的限额
      if (
        this.activePlatformCategory?.merge_status === PayChannelMergeEnum.MERGE
      ) {
        max = toString(this.activePlatformCategory.max)
        min = toString(this.activePlatformCategory.min)
      }
      /**
       * 当产生汇率,且用户输入是会员币种
       */
      if (isActiveCurrencySameWithUserCurrency && !isCurrencySame) {
        max = FinanceUtils.div(max, Number(this.exchangeRate))
        min = FinanceUtils.div(min, Number(this.exchangeRate))
      }
      // 如果用户币种有比率,需要处理比率
      if (
        isActiveCurrencySameWithUserCurrency &&
        this.isScoreMode &&
        userCurrencyIsGameRateSpec
      ) {
        // 处理无限循环小数
        if (userCurrencyGameRate <= 1) {
          max = FinanceUtils.times(max, Math.round(1 / userCurrencyGameRate))
          min = FinanceUtils.times(min, Math.round(1 / userCurrencyGameRate))
        } else {
          max = FinanceUtils.div(max, userCurrencyGameRate)
          min = FinanceUtils.div(min, userCurrencyGameRate)
        }

        // 分数最小是1
        min = Math.max(1, Number(min.toString())).toString()
      }
      // 大于1处理取整逻辑
      // if (max > 1) {
      //   max = Math.floor(max)
      // }
      // if (min > 1) {
      //   min = Math.ceil(min)
      // }
      if (this.isScoreMode) {
        min = String(Math.ceil(Number(min)))
        max = String(Math.floor(Number(max)))
      }
      return {
        max: this.processMoney(max)!,
        min: this.processMoney(min)!
      }
    },

    /**
     * 钱包请求域名
     */
    walletDomain(state) {
      // 优先轮询域名
      if (state.payUrl) {
        return new URL(state.payUrl).origin
      }

      // 通道配置的域名
      const url = state.activePlatformCategory?.url
      if (url) {
        return new URL(url).origin
      }

      // 当前域名
      return ''
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.pay',
        storage: createMyPersistedStorage(),
        paths: [
          'rootCategoryList',
          'getPayPlatformList',
          'onlineChannelList',
          'transferChannelList',
          'isChannelExpand',
          'isPlatformExpand'
        ]
      }
    ]
  }
})

export default useFinancePayStore
