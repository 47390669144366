import { GameItemInter } from '@/service/game/entity/gameItem'
import { flatten } from 'lodash'

/** 通往分类id匹配的入参 */
export type _FilteredByCategaryParams = {
  /** 过滤匹配的值 */
  matches: number | Array<number>
  /** 过滤匹配的类型 */
  type?: 'gameCategoryId'
}

/** 通过游戏分类id匹配游戏列表 */
export const getFilteredByCategary = (
  oriList: GameItemInter[],
  params: Omit<_FilteredByCategaryParams, 'type'>
) => {
  const matches = Array.isArray(params.matches)
    ? params.matches
    : [params.matches]
  /** 分类id与游戏列表的映射 */
  const cidArrMapper = new Map<number, GameItemInter[]>()
  matches.forEach((match) => {
    cidArrMapper.set(match, [])
  })
  oriList.forEach((game) => {
    // 如果当前游戏的分类id,在mapper中已记录过,则将之存入对应的id数组中
    if (cidArrMapper.has(game.gameCategoryId)) {
      cidArrMapper.get(game.gameCategoryId)!.push(game)
    }
  })
  /** 最终需要的游戏列表 */
  const gameList = flatten(Array.from(cidArrMapper.values()))
  return gameList
}
