import { ApiEventCategory, ApiEventItem } from '@/api/event/type'
import { EventDiscountsRedDot } from '@/api/event/apiEventAndDiscountsRedDot/apiEventAndDiscountsRedDot'

/** 注入活动的小红点 */
export const injectActivityRedDot = (
  activeList: ApiEventItem[],
  eventRedDot: EventDiscountsRedDot | null
) => {
  // 如果没有小红点统计存在,则嘛也不干
  if (!eventRedDot) return
  // 先转map建索引(为了性能)
  const mapperIdToActi = new Map<number, ApiEventItem>()
  activeList.forEach((item) => {
    // 此时要将所有所有单个活动的小红点清空,然后由后边将存在的小红点注入
    item.activeCount = 0
    // item.activeAmount = 0  //金额没有看到使用的地方,暂时不考虑清空,免得出现问题
    return mapperIdToActi.set(item.activeId, item)
  })
  // 循环将小红点注入活动中
  const { activeList: actiDotList } = eventRedDot.activeRedDot
  actiDotList.forEach((actiDot) => {
    const active = mapperIdToActi.get(actiDot.activeId)
    if (!active) return
    active.activeCount = actiDot.activeCount
    active.activeAmount = actiDot.activeAmount
  })
}

/** 注入活动分类的小红点 */
export const injectCategoryRedDot = (
  categories: ApiEventCategory[],
  eventRedDot: EventDiscountsRedDot | null
) => {
  // 如果没有小红点统计存在,则嘛也不干
  if (!eventRedDot) return
  // 先转map建索引(为了性能)
  const mapperIdToActi = new Map<number, ApiEventCategory>()
  categories.forEach((item) => {
    // 此时要将所有所有单个活动的小红点清空,然后由后边将存在的小红点注入
    item.activeCount = 0
    // item.activeAmount = 0  //金额没有看到使用的地方,暂时不考虑清空,免得出现问题
    return mapperIdToActi.set(item.categoryId, item)
  })
  // 循环将小红点注入活动中
  const { categoryList: cateDotList } = eventRedDot.activeRedDot
  cateDotList.forEach((cateDot) => {
    const category = mapperIdToActi.get(cateDot.categoryId)
    if (!category) return
    category.activeCount = cateDot.activeCount
    category.activeAmount = cateDot.activeAmount
  })
}
