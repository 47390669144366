import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
export const xInitValue = Number.MAX_VALUE
export const yInitValue = Number.MAX_VALUE

export interface ServiceState {
  expandQuestionList: number[]
  /**三方客服的标识List */
  thirdServiceMarkList: string[]
  /**是否已经初始化三方客服 */
  hasInitThirdService: boolean
  /**三方客服按钮的x和y坐标值 */
  thirdServiceButtonCoordinate: Record<'x' | 'y', number>
}

export const useServiceStore = defineStore('service', {
  state: (): ServiceState => ({
    thirdServiceButtonCoordinate: {
      x: xInitValue,
      y: yInitValue
    },
    expandQuestionList: [],
    thirdServiceMarkList: [
      '[id="ukefu-point"]',
      /**livechat三方客服 */
      '[id="chat-widget-container"]',
      /**美恰三方客服 */
      '[id="MKEFU-INVITE-BTN"]'
    ],
    hasInitThirdService: false
  }),

  actions: {
    setExpandQuestionList(list: number[]) {
      this.expandQuestionList = list
    },
    setThirdServiceMarkList(thirdServiceMarkList: string[]) {
      this.thirdServiceMarkList = thirdServiceMarkList
    },
    setHasInitThirdService(hasInitThirdService: boolean) {
      this.hasInitThirdService = hasInitThirdService
    },
    setThirdServiceButtonCoordinate(
      thirdServiceButtonCoordinate: Record<'x' | 'y', number>
    ) {
      this.thirdServiceButtonCoordinate = thirdServiceButtonCoordinate
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.service',
        storage: createMyPersistedStorage(),
        paths: ['thirdServiceButtonCoordinate']
      }
    ]
  }
})
