import {
  ActivityTemplate,
  EventShowTimeEnum,
  PublicIntroductionProps as Props
} from '@/api/event/type'
import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { invert } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import DateUtils from '@/utils/DateUtils'
import moment from 'moment'
import style from './style.module.scss'

enum IndexStrEnum {
  firstIndex,
  secondIndex,
  thirdIndex,
  fourIndex
}

@Component<PublicIntroduction>({ name: 'PublicIntroduction' })
export default class PublicIntroduction extends Base<unknown, Props> {
  @Prop({ required: true })
  protected eventData!: Props['eventData']
  @Prop({ default: false })
  protected isShowBottom!: Props['isShowBottom']

  // 获取活动语言类型
  public get getLang() {
    const config = invert(GlobalConst.ServiceLanguageMap)
    const { language } = useMainStore()
    const { isDefaultLangRuleText, translateRuleText } = this.eventData || {}
    if (!isDefaultLangRuleText) return config[translateRuleText!]
    return language
  }

  // 渲染活动规则说明时间
  renderIntroductionTime(indexStr: string) {
    const data = this.eventData
    if (!data) return
    /** 排查 抽奖助力和新版砍一刀 */
    const cutOnces = [ActivityTemplate.CutOnce, ActivityTemplate.NewCutOnce]
    if (
      data?.isShowTime === EventShowTimeEnum.Hide &&
      !cutOnces.includes(data?.template)
    ) {
      return null
    }
    const { t } = useI18n()
    const { siteInfos } = useMainStore()
    const utcOffset = moment()
      .utcOffset(siteInfos?.timeZone ?? '')
      .utcOffset()
    const utcOffsetOfHours = utcOffset / 60
    const formatTime = (time: number) => {
      return BusinessUtils.formatSiteOffsetTime(time)
    }
    return (
      <div>
        <span class={style.textDarken}>
          {indexStr}
          {t('lobby.event.time', this.getLang)}(UTC
          {utcOffsetOfHours >= 0 && '+'}
          {utcOffsetOfHours}):
        </span>
        <div>
          <span>
            {formatTime(data.startTime)} - {formatTime(data.endTime)}
          </span>
          {data.timingList && (
            <div>
              <span>
                {t('lobby.event.validDay.renPocketTime', this.getLang)}:{' '}
              </span>
              {data.timingList.map((time, index: number) => {
                const startTime = DateUtils.getFormatTime(time.timing)
                const endTime = DateUtils.getFormatTime(
                  time.timing + time.duration
                )
                return (
                  <span key={index}>{`${
                    index > 0 ? ',' : ''
                  }${startTime} - ${endTime}`}</span>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  }

  // 渲染活动条件
  renderActiveCondition(indexStr: string) {
    const data = this.eventData
    if (!data) return
    const { t } = useI18n()
    return (
      <div>
        <span class={style.textDarken}>
          {indexStr}
          {t('lobby.event.condition', this.getLang)}:
        </span>
        <div>
          <span>{data.activeCondition}</span>
        </div>
      </div>
    )
  }

  // 渲染指定条件
  renderConditionText(indexStr: string) {
    const data = this.eventData
    if (!data) return
    const { t } = useI18n()
    let conditionLable
    switch (data.template) {
      case ActivityTemplate.Deposit:
        conditionLable = t('lobby.event.conditionDeposit', this.getLang)
        break
      case ActivityTemplate.Wager:
      case ActivityTemplate.Rescue:
      case ActivityTemplate.LuckyWheel:
      case ActivityTemplate.LuckyWheelNew:
      case ActivityTemplate.LuckyBet:
        conditionLable = t('lobby.event.conditionPlatform', this.getLang)
        break
    }
    return (
      <div>
        <span class={style.textDarken}>
          {indexStr}
          {conditionLable}:
        </span>
        <div>
          <span>{data.conditionText}</span>
        </div>
      </div>
    )
  }

  renderContent(indexStr: string) {
    const data = this.eventData
    if (!data) return
    const { t } = useI18n()

    /**
     * 是否需要打码说明
     * 投资、代理、充值、打码、救援金、推广、集字、签到、幸运转盘
     *
     * 代理： 含有每月返奖
     * 充值、打码、代理 含有周返奖
     */
    return (
      <div>
        <span class={style.textDarken}>
          {indexStr}
          {t('lobby.event.content', this.getLang)}:
        </span>
        <inner-html type="div" text={data.content} />
      </div>
    )
  }

  render() {
    const data = this.eventData
    const { t } = useI18n()
    const renderFnList = [this.renderIntroductionTime]
    if (data.activeCondition) renderFnList.push(this.renderActiveCondition)
    if (data.conditionText) renderFnList.push(this.renderConditionText)
    if (data.content) renderFnList.push(this.renderContent)

    return (
      <div
        class={{
          [style.introduction]: true,
          [style.paddingBottom]: this.isShowBottom
        }}
      >
        <div class={style.introductionInner}>
          {renderFnList.map((item, index) => {
            if (data?.isShowTime === 2 && index === 1) {
              return item(t('', this.getLang) as string)
            }

            return item(
              t(`lobby.event.${IndexStrEnum[index]}`, this.getLang) as string
            )
          })}
        </div>
      </div>
    )
  }
}
