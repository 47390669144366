import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { TaskPageStore, useTaskPageStore } from '../index'
import { createAsyncQueue } from '@/utils/Tool'
import {
  createQueueCountingPromise,
  createTaskModalOpeningQueue,
  getModalQueueLength
} from './taskModalQueue'

/** 打开弹窗队列的参数 */
export type OpenSequentiallyTaskModalParams =
  | SeekApiCode
  | SeekPendingReceiveRules
/** 通过类型或Code数组打开弹框 */
export type SeekApiCode = {
  /** 类型Code或Code数组 */
  apiCode: TCACE[] | TCACE
  /** 忽略页面地址（此对象多半是不要忽略页面地址，除首页以外基本不要弹出） */
  ignorePageAddress?: boolean
  /** 显示的声明ruleIds不存在,不然类型守卫上会有些问题(不能有) */
  ruleIds?: never
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}

/** 寻找待领取的规则 */
export type SeekPendingReceiveRules = {
  /** 类型Code */
  apiCode: TCACE
  /** 规则id(单个|列表) */
  ruleIds: number[] | number
  /** 忽略页面地址(此对象多数时候就是要忽略页面地址，在任何地方都有可能会弹出) */
  ignorePageAddress?: boolean
  /** 是否由登录事件触发(不能有) */
  isLoginTrigger?: never
}

/** 类型守卫：是否为【寻找待领取的任务】 */
export function isSeekPendingReceiveRules(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): data is SeekPendingReceiveRules {
  if (typeof data !== 'object') {
    return false
  }
  const keys = ['apiCode', 'ruleIds']
  return keys.every((key) => Reflect.has(data, key))
}

/** 类型守卫：是否为【通过类型或Code数组打开弹框】 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSeekApiCode(data: any): data is SeekApiCode {
  if (typeof data !== 'object') {
    return false
  }
  // 有apiCode但是没有ruleIds则是通过apiCode打开弹窗
  const flag = Reflect.has(data, 'apiCode') && !Reflect.has(data, 'ruleIds')
  return flag
}

/** 依次打开弹框队列
 * @param store
 * @param param 需要依次打开【多个的apiCode】或者是需要针对性的打开某个【分类下的具体任务】
 */
export const proxyOpenSequentiallyTaskModal = (
  store: TaskPageStore,
  param: OpenSequentiallyTaskModalParams
) => {
  const { stopModalAndQueue, setStopModalAndQueue } = useTaskPageStore()
  // 保证每次开启新队列时,将删除掉老队列的内容
  stopModalAndQueue?.()
  setStopModalAndQueue(null)
  const queueLength = getModalQueueLength(param)
  // 创建队列计数器,用来计数当前队列是否全部执行完成
  const { allExePromise, exeOne } = createQueueCountingPromise(queueLength)
  /** 任务弹框打开队列 */
  const { openingQueue, stop } = createTaskModalOpeningQueue(
    store,
    param,
    exeOne
  )
  // 将关闭队列的函数存为静态属性,方便下一次中途开启新队列时关闭本次队列
  setStopModalAndQueue(stop)
  /** 任务弹框队列控制器 */
  const asyncQueueController = createAsyncQueue()
  // 将队列内容装入控制器
  asyncQueueController.push(...openingQueue)
  // 开始挨个执行队列
  asyncQueueController.flush()
  return allExePromise
}
