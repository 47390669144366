import { TaskRuleStatusEnum } from '@/views/global-modal/modal/tasks-modal-dialog/class/TaskRule'

const {
  Expired,
  Finish,
  Goto,
  HasSend,
  PendingApply,
  PendingApprove,
  PendingReceive,
  Processing,
  ServerCancel,
  ServerReject,
  UnOpen
} = TaskRuleStatusEnum
/** 全部状态 */
const allTaskRuleStatus = [
  Expired,
  Finish,
  Goto,
  HasSend,
  PendingApply,
  PendingApprove,
  PendingReceive,
  Processing,
  ServerCancel,
  ServerReject,
  UnOpen
]
/** 获得全部任务的状态 */
export const getAllTaskRuleStatus = () => {
  return [...allTaskRuleStatus]
}
