import { DEFAULT_PAGE_SIZE } from '@/components/business-components/table/config'
import { RechargeItem } from '@/api/rechargeFund/type'
import { useMainStore } from '@/store/index'

/** 数据映射和处理 */
export const rechargeFundQueryFormat = (
  res: {
    data: RechargeItem[]
    total: number
  },
  pageNo: number,
  pageSize: number
) => {
  const { isWeb } = useMainStore()
  // pc端分页切换
  if (isWeb) {
    const start = (pageNo - 1) * pageSize
    const end = pageNo * pageSize
    const tableData =
      res.data?.length > DEFAULT_PAGE_SIZE
        ? res.data?.slice(start, end)
        : res.data
    return {
      data: tableData,
      total: res.total
    }
  }
  return res
}
