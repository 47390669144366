import { ReceiveDeviceTypeStr } from '../../common/type/receiveDeviceType'
import { TaskCategoryApiCodeEnum } from '../api/type'
import { TaskRule } from './TaskRule'
import { cloneDeep, merge } from 'lodash'
import { useI18n } from '@/i18n'

/** 任务名称可能的值 */
export type TaskName = '' | 'xrfl' | 'mrrw' | 'mzrw' | 'smrw'
/** 任务id,但是当前任务id与任务是写死对应的 [0:未赋值] [1:新人福利] [2:每日任务] [3:每周任务] [4:神秘任务] */
export type TaskId = 0 | 1 | 2 | 3 | 4
/** 任务模板类型 [0:未赋值] [1:新人福利] [2:每日任务] [3:每周任务] [4:神秘任务] */
export type Tasktemplate = 0 | 1 | 2 | 3 | 4

/** 领取时间类型 */
export enum ReceiveTimeType {
  /** 立即领取 */
  Immediately = 0,
  /** 次日领取 */
  Tomorrow = 1,
  /** 每周固定某一天领取 */
  WeekOne = 2,
  /** 每月固定某一天能领取 */
  MonthOne = 3,
  /** 下周领取(下周整个周都能领) */
  WeekWhole = 4,
  /** 下月领取(下月整个月都能领)  */
  MonthWhole = 5
}
/** 弹框分类信息 后端接口文档地址: https://docs.cg.xxx/web/#/40/2165 */
export class TaskCategory {
  constructor(code: TaskCategoryApiCodeEnum) {
    this.code = code
    this.getI18nTaskName = getTaskNameI18nFunByCode(code)
    this.taskId = getTaskIdByCode(code)
    this.template = getTemplateIdByCode(code)
  }
  /** 任务名称 */
  get taskName() {
    return this.getI18nTaskName()
  }
  /** 获得任务名称(多语言函数) */
  getI18nTaskName: () => TaskName
  /** 编码code */
  code: TaskCategoryApiCodeEnum
  /** 任务id*/
  taskId: TaskId = 0
  /** 模板id */
  template: Tasktemplate = 0
  /** 用户是否禁止弹框显示 */
  isUserDisabledPop = false
  /** 任务开始时间(时间戳) */
  startTime = 0
  /** 任务结束时间(时间戳) */
  endTime = 0
  /** 任务描述(此处描述可能会很长,有点类似于富文本了) */
  content = ''
  /** 任务规则循环方式(code) [0:无法重复完成]  [1:每天00:00重置] [2:每周一00:00重置] [3:每三日00:00重置(神秘任务)] */
  cycleType: undefined | 0 | 1 | 2 | 3 = undefined
  /** 是否一键领取 */
  oneClick: 0 | 1 = 0
  /** 核倍数 */
  multiple = 0
  /** 规则是否默认 [1:默认] [0:自定义] */
  isDefaultRule: undefined | 0 | 1 = undefined
  /** 规则翻译配置-Json字符串 数据结构例子: [{"lang":["zh"],"name":"aaaaaaa"}] */
  translateRuleText = ''
  /** 任务时间描述 */
  taskTime = ''
  /** 任务条件描述 */
  taskCondition = ''
  /** 出款提示信息 (新人福利才可能有值) */
  withdrawTip = ''
  /** 可以领取的设备类型,多选,逗号隔开 [1:IOS] [2:Android ] [3:H5] [4:Web] */
  receiveDeviceType: ReceiveDeviceTypeStr | '' = ''
  /** 分类下的具体任务规则 */
  rules: TaskRule[] = []
  /** 领取时间类型 */
  receiveTimeType = ReceiveTimeType.Immediately
  /** 领取具体日子. 每周几(1-7),或每月几号(1-31),与receiveTimeType组合使用 */
  receiveTimeDay!: number
  /** 注入后台返回的数据 */
  immitResponse(_data: Partial<TaskCategory>) {
    // 因为,涉及到对该接口的数据进行一些转换,然后接口有合并,所以多次转换同一份数据,就出问题了.因此要克隆一份
    const data = cloneDeep(_data)
    if (this.code === TaskCategoryApiCodeEnum.smrw) {
      // 如果是神秘任务,第四个对象的状态需要特殊处理(此处后端返回错误的数据,我们需要自己将之修正)
      const lastRule = data.rules?.[3]
      if (lastRule) {
        lastRule.progress = 0
        lastRule.max = 0
      }
    }
    // 合并基础属性
    merge(this, data)
    // 如果领取设备,返回为空,则表示所有设备都可以领取.  单子上有后端聊天记录截图: https://www.cg.xxx/feedback-adminView-33141-all.html
    this.receiveDeviceType = data.receiveDeviceType || '1,2,3,4'
    if (data?.rules) {
      // 校正每条规则的状态
      this.rules = data.rules.map((rule) => {
        // 为每一条rule都附加上一些需要的,重要的值
        rule.receiveDeviceType = data.receiveDeviceType as ReceiveDeviceTypeStr
        rule.apiCode = this.code
        rule.isReceiveLoading = false
        rule.webId = TaskRule.generateWebId(rule)
        // 兼容性代码,如果每条规则上有相应的 receiveTimeType和receiveTimeDay ,则以自身的为准,如果没有,则取 data 上的用于保底
        rule.receiveTimeType = (
          rule.receiveTimeType !== undefined
            ? rule.receiveTimeType
            : data.receiveTimeType
        ) as ReceiveTimeType
        rule.receiveTimeDay = (
          rule.receiveTimeDay !== undefined
            ? rule.receiveTimeDay
            : data.receiveTimeDay
        ) as number
        return rule
      })
    }
    return this
  }
}

/** 懒加载,通过code获取 taskName的i18n函数  */
let getTaskNameI18nFunByCode = (
  code: TaskCategoryApiCodeEnum
): (() => TaskName) => {
  const { t } = useI18n()
  const map = {
    [TaskCategoryApiCodeEnum.xrfl]: () =>
      t('lobby.modal.task.newbieBenefit') as 'xrfl',
    [TaskCategoryApiCodeEnum.mrrw]: () => t('lobby.modal.task.daily') as 'mrrw',
    [TaskCategoryApiCodeEnum.mzrw]: () =>
      t('lobby.modal.task.weekly') as 'mzrw',
    [TaskCategoryApiCodeEnum.smrw]: () => t('lobby.modal.task.arcane') as 'smrw'
  } as const
  getTaskNameI18nFunByCode = (code: TaskCategoryApiCodeEnum) => {
    return map[code]
  }
  return getTaskNameI18nFunByCode(code)
}
/** 懒加载,通过code获取 taskId  */
let getTaskIdByCode = (code: TaskCategoryApiCodeEnum): 1 | 2 | 3 | 4 => {
  const map = {
    [TaskCategoryApiCodeEnum.xrfl]: 1,
    [TaskCategoryApiCodeEnum.mrrw]: 2,
    [TaskCategoryApiCodeEnum.mzrw]: 3,
    [TaskCategoryApiCodeEnum.smrw]: 4
  } as const
  getTaskIdByCode = (code: TaskCategoryApiCodeEnum) => {
    return map[code]
  }
  return getTaskIdByCode(code)
}

/** 懒加载,通过code获取 templateId  */
let getTemplateIdByCode = (code: TaskCategoryApiCodeEnum): 1 | 2 | 3 | 4 => {
  const map = {
    [TaskCategoryApiCodeEnum.xrfl]: 1,
    [TaskCategoryApiCodeEnum.mrrw]: 2,
    [TaskCategoryApiCodeEnum.mzrw]: 3,
    [TaskCategoryApiCodeEnum.smrw]: 4
  } as const
  getTemplateIdByCode = (code: TaskCategoryApiCodeEnum) => {
    return map[code]
  }
  return getTemplateIdByCode(code)
}
