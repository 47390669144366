export enum StatusType {
  YES = 1,
  NO = 0
}
export enum EUserStatusType {
  ALL = 0, // 正常
  NORMAL = 1, // 正常
  FREEZE_MANUALLY = 2, // 手动冻结
  ABNORMAL_FREEZING = 3, // 异常冻结
  PROHIBITION_OF_RECEIVING_DISCOUNTS = 4, // 禁止领取优惠
  BLACKLIST = 5, // 黑名单
  DISABLED = 6, // 停用
  WITHDRAWAL_PROHIBITED = 7, // 禁止提款
  FORBIDDEN_TO_ENTER_THE_GAME = 8, // 禁止进入游戏
  RETURN_THE_PRINCIPAL = 9 // 退还本金劝退
}
