import {
  GameCategoryIds,
  GamePlatformCatgory,
  HotRecentCollectionAndDemo
} from '../consts/categorys'

/** 获取游戏列表的路径 */
export const getPathForGameList = (
  gameCategoryId: GameCategoryIds,
  platformId?: number
): string => {
  // 如果是热门最新收藏试玩或平台游戏类别，没有子平台，则路径为游戏类别ID，否则为游戏类别ID.平台ID
  return HotRecentCollectionAndDemo.includes(gameCategoryId) ||
    GamePlatformCatgory.includes(gameCategoryId)
    ? `${gameCategoryId}`
    : `${gameCategoryId}.${platformId}`
}
