/**
 * 游戏菜单分类id
 */
export enum GameCategoryIds {
  /**
   * 默认值 只是占位
   */
  Normal = -1,

  /**
   * 热门
   */
  Hot = 0,

  /**
   * 搜索
   */
  Search = 99,
  /**
   * 最近
   */
  Recent = 100,
  /**
   * 收藏
   */
  Collenct = 101,
  /**
   * 试玩
   */
  Demo = 20,
  /**
   * 俱乐部
   */
  Club = 9,
  /**
   * 朋友局
   */
  ClubFriend = 10,
  /**
   * 全部
   */
  All = 0,
  /**
   * 棋牌
   */
  Chess = 1,
  /**
   * 捕鱼
   */
  Fish = 2,
  /**
   * 电子
   */
  Electronic = 3,
  /**
   * 真人
   */
  Real = 4,
  /**
   * 体育
   */
  Sports = 5,
  /**
   * 斗鸡
   */
  Cockfighting = 6,
  /**
   * 电竞
   */
  Gaming = 7,
  /**
   * 彩票
   */
  Lottery = 8,
  /**
   * 区块链
   */
  BlockChain = 11
}

/** 自定义类别id, 避免和现有类别id冲突 */
export const CustomCategoryId = 999

/**
 * 游戏平台类别真人、体育、彩票、斗鸡、电竞
 * 点击直接跳转游戏的平台
 */
export const GamePlatformCatgory = [
  GameCategoryIds.Real,
  GameCategoryIds.Sports,
  GameCategoryIds.Lottery,
  GameCategoryIds.Cockfighting,
  GameCategoryIds.Gaming
]

/**
 * 平台类别（有子平台列表）棋牌、捕鱼、电子、区块链
 * 点击跳转二级页面的平台
 */
export const PlatformCategories = [
  GameCategoryIds.Chess,
  GameCategoryIds.Fish,
  GameCategoryIds.Electronic,
  GameCategoryIds.BlockChain
]

/** 热门最近收藏 */
export const HotRecentCollection = [
  GameCategoryIds.Hot,
  GameCategoryIds.Recent,
  GameCategoryIds.Collenct
]

/** 热门最近收藏和试玩 */
export const HotRecentCollectionAndDemo = [
  ...HotRecentCollection,
  GameCategoryIds.Demo
]

/** 最近收藏和试玩 */
export const RecentCollectionAndDemo = [
  GameCategoryIds.Recent,
  GameCategoryIds.Collenct,
  GameCategoryIds.Demo
]

/** 基础游戏分类平台 */
export const BaseGameCategoriesPlus = [
  ...PlatformCategories,
  ...GamePlatformCatgory
]

/** 基础游戏分类加热门试玩（不包括俱乐部、朋友局、自定义外链） */
export const BaseGameCategoriesPlusHotAndDemo = [
  ...BaseGameCategoriesPlus,
  GameCategoryIds.Hot,
  GameCategoryIds.Demo
]

export const ClubOrFriend = [GameCategoryIds.Club, GameCategoryIds.ClubFriend]
