import { GameCategoryIds } from '../consts/categorys'
import { apiAddfavorite } from '@/api/game'
import { remove } from 'lodash'
import { useGameStore } from '@/views/game/store'
import { useMainStore } from '@/store/index'
import GameItem from '@/service/game/entity/gameItem'

/** 收藏图标,需要的一些属性 */
type GameInstance = {
  /** 游戏ID */
  gameId: number
  /** 游戏平台ID */
  platformId: number
  /** 游戏类型ID */
  gameCategoryId: number
}

export default class _GameFavorites {
  /** 更新收藏列表 */
  private static _updateFavoriteList(isAdd: boolean, game: GameInstance): void {
    const { gameId } = game
    const { setAllFavoriteList, currentAllFavoriteList } = useGameStore()
    // isAdd === true 用于添加到收藏, isAdd === false 用于取消收藏
    const newFavoriteList: number[] = isAdd
      ? [...currentAllFavoriteList, gameId]
      : remove(currentAllFavoriteList, (i) => i !== gameId)
    setAllFavoriteList(newFavoriteList)
  }

  /** 处理与收藏API相关的操作 */
  private static async _handleFavoriteAPI(
    isAdd: boolean,
    game: GameInstance
  ): Promise<void> {
    const { gameId, platformId, gameCategoryId } = game
    try {
      // isAdd === true 用于添加到收藏, isAdd === false 用于取消收藏
      await apiAddfavorite({ gameId, platformId, gameCategoryId }, isAdd)
    } catch (error) {
      // 若出错, 则回退收藏列表的状态
      this._updateFavoriteList(!isAdd, game)
    }
  }

  /** 处理添加收藏的情况 */
  private static async _handleAddFavorite(game: GameInstance): Promise<void> {
    const { setTryModeCollectGameListData, currentCollectGameList } =
      useGameStore()
    const { isTryMode } = useMainStore()
    if (isTryMode) {
      setTryModeCollectGameListData([
        ...currentCollectGameList(),
        game as GameItem
      ])
    } else {
      this._updateFavoriteList(true, game)
      await this._handleFavoriteAPI(true, game)
    }
  }

  /** 处理移除收藏的情况 */
  private static async _handleRemoveFavorite(
    game: GameInstance
  ): Promise<void> {
    const { gameId } = game
    const {
      getGameListByCategoryAndPlatform,
      setGameCategoryPlatformList,
      setTryModeCollectGameListData,
      currentCollectGameList
    } = useGameStore()
    const { isTryMode } = useMainStore()
    const collectGameListData = getGameListByCategoryAndPlatform({
      gameCategoryId: GameCategoryIds.Collenct
    }).list
    if (isTryMode) {
      setTryModeCollectGameListData(
        remove(currentCollectGameList(), (game) => game.gameId !== gameId)
      )
    } else {
      this._updateFavoriteList(false, game)
      // 如果收藏游戏列表有数据，需要移除游戏id对应数据
      if (collectGameListData.length > 0) {
        setGameCategoryPlatformList({
          gameCategoryId: GameCategoryIds.Collenct,
          gameList: remove(collectGameListData, (i) => i.gameId !== gameId)
        })
      }
      await this._handleFavoriteAPI(false, game)
    }
  }

  /** 收藏或取消收藏游戏
   *  @param _game 暂时先加上Partial,避免报错,因为老版本的入参,有一些字段是可选项,必须报一堆类型错误
   */
  public static async collectGame(_game: Partial<GameInstance>): Promise<void> {
    const game = _game as GameInstance
    const { gameId } = game
    const { currentAllFavoriteList } = useGameStore()
    // 如果游戏已经收藏，则取消收藏
    if (currentAllFavoriteList?.includes(gameId)) {
      await this._handleRemoveFavorite(game)
    } else {
      // 如果游戏未收藏，则添加到收藏
      await this._handleAddFavorite(game)
    }
  }
}
