import { PopTypeBeforeLogin } from '@/views/global-modal/modal/common/util/judgePopupType'
import { ReceiveDeviceTypeStr } from '@/views/global-modal/modal/common/type/receiveDeviceType'
import { ReceiveLogPopSwitchEnum } from '@/api/event/type'
import { useMainStore } from '@/store/index'

/** 领取状态 */
export enum ClaimStatus {
  /* 待领取 */
  Pending = 0,
  /* 已领取 */
  Received = 1,
  /* 已派发 */
  Dispatched = 2,
  /* 已过期派发 */
  ExpiredDispatched = 4
}

/** 待领取弹窗,服务端返回数据 */
export class PopWaitingGetResponse {
  /** 具体弹框信息(具体用法还待定) */
  list?: PendingReward[]
}

/** 首充弹窗,更方便于web端使用的数据格式 */
export class PopWaitingGetWeb {
  /** 用户是否勾选了永久禁用 0:启用 1:禁用 */
  isNeverRemind = false
  /** 服务端是否禁用了弹窗 */
  isServerDisabled = false
  /** 曾经是否领取过(任务未完成时弹出的配置下,如果领取过,就不能再弹出了) */
  isReceivedEver = false
  /** 活动id */
  activityId!: number
  /** 活动名称 */
  activityName = ''
  /** 领取方式	2=玩家自领（到期派送），3=玩家自领（到期作废），4自动派发（立即）,5人工派发 */
  giveType!: 2 | 3 | 4 | 5
  /** 登录前弹窗状态  */
  beforeLoginPopType = PopTypeBeforeLogin.never
  /** 登录后弹窗状态  */
  afterLoginPopType = ReceiveLogPopSwitchEnum.OnlyPop
  /** 今日待领取奖励列表 */
  pendingRewardList: PendingReward[] = []
  /** 明日可领取奖励列表 */
  collectTomorrowList: PendingReward[] = []
  /** 今日已派发奖励列表 */
  receivedTodayList: PendingReward[] = []
  /** 注入response数据到web数据中 */
  public static immitResponse(
    target = new PopWaitingGetWeb(),
    data: PopWaitingGetResponse
  ) {
    // 后台配置的弹出类型
    const { activesAuthInfos } = useMainStore()
    target.afterLoginPopType =
      activesAuthInfos.disableReceiveLogPop ?? ReceiveLogPopSwitchEnum.OnlyPop

    const popData = data.list
    // 今日待领取弹窗数据
    target.pendingRewardList = (popData ?? [])
      ?.filter((item) => {
        return item.status === 0 && item.receiveDuration <= 0
      })
      .map((item) => {
        return {
          ...item,
          receiveStatus: 1,
          receiveDeviceType: item.receiveDeviceType || '1,2,3,4'
        }
      })
    // 明日可领取奖励
    target.collectTomorrowList = (popData ?? [])
      ?.filter((item) => {
        return item.status === 0 && item.receiveDuration > 0
      })
      .map((item) => {
        return { ...item, isShaking: false }
      })
    // 今日已派发奖励
    target.receivedTodayList = (popData || [])?.filter((item) => {
      return item.status === 2
    })

    if (popData) {
      // 数据为空时代表没有奖励
      const isPendingEmpty = popData.length === 0
      target.isReceivedEver = isPendingEmpty
    } else {
      target.isServerDisabled = true
    }
    return target
  }
}

/** 待处理的奖励 文档地址: https://docs.cg.xxx/web/#/40/2249 */
export class PendingReward {
  /** 记录Id */
  id = 0
  /** 旧派发审核奖励ID, 如果值大于0，领取接口是:/active/receivereward */
  oldRewardId = 0
  /** 活动id */
  activeId = 0
  /** 活动名称 */
  activeName = ''
  /** 活动类型 */
  activeType = 0
  /** 活动类型文案 */
  activeTypeText = ''
  /** 来源类型 */
  template = 0
  /** 来源类型文案 */
  templateText = ''
  /** 规则id */
  ruleId = ''
  /** 奖励金额 */
  reward = 0
  /** 活跃度 */
  activity = 0
  /** 倒计时，秒数, 正数为可领取倒计时，负数为过期倒计时 */
  logCreateTime = 0
  /** 奖励说明 */
  receiveRemark = ''
  /** 前台备注 */
  frontRemark = ''
  /** 到账时间 */
  receiveTime = 0
  /** 过期时间 */
  periodTime = 0
  /** 能够领取的时间-时间戳 */
  canReceiveTime = 0
  /** 能够领取倒计时-时间戳 */
  receiveDuration = 0
  status = ClaimStatus.Pending
  /** 领取状态 [0:不可领取]  [1:可以领取] */
  receiveStatus!: 0 | 1
  isShaking!: boolean
  /** 可领取设备类型 (后端返回空,则表示所有可领取) */
  receiveDeviceType: ReceiveDeviceTypeStr = ''
  /** 大类类型 */
  logsCategory = 0
  /** true待领取前往对应页领取 false直接领取 */
  canGo = false
  /** 返水类型时的数据 */
  receiveRemarkJsonFormat = ''
  /** 活动分类 */
  categories = ''
}
