import { Component } from '@/vue-property-decorator'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { createPngSprite, createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import ModalBase from '@/mixins/business/modal/modal-base'
import style from './style.module.scss'

export interface OpenOptions {
  prize?: string | number
  extraPrize?: string | number
  animation?: boolean
  onClose?: () => void
  onOpen?: () => void
}

interface State {
  animation: boolean
}

@Component<SuccessMsssageModal>({
  name: 'SuccessMsssageModal'
})
export default class SuccessMsssageModal extends ModalBase<State> {
  private openOptions: OpenOptions | null = null

  state: State = {
    animation: true
  }

  public async open(options?: OpenOptions) {
    if (options) {
      if (options?.animation) {
        this.setState({ animation: true })
      }

      this.openOptions = options
      options?.onOpen?.()
    }
    this.baseOpen()
  }

  render() {
    const { t } = useI18n()
    const { isWeb } = useMainStore()
    const { animation } = this.state

    return this.renderModal({
      modalConfig: {
        class: [style.extraPrizeModal],
        props: {
          closable: false,
          width: BusinessUtils.px2rem(690)
        }
      },
      renderContent: () => {
        return (
          <div class={style.contentWrapper}>
            {animation ? (
              <apng-canvas
                key={'animation-1'}
                class={style.canvasWraper}
                numPlays={1}
                src={'/lobby_asset/common/web/animated/apng_event_qiandao.png'}
                onEnd={() => {
                  this.setState({
                    animation: false
                  })
                }}
              ></apng-canvas>
            ) : (
              <apng-canvas
                key={'animation-2'}
                class={style.canvasWraper}
                src={'/lobby_asset/common/web/animated/apng_event_qiandao2.png'}
              ></apng-canvas>
            )}
            <inner-html
              v-show={!animation}
              class={style.prizeText}
              text={
                this.openOptions?.extraPrize
                  ? t('lobby.event.sign.extraPrizeSuccessText', {
                      x: `<span>${brazilianCurrencyFormatter(
                        this.openOptions?.extraPrize ?? 0
                      )}</span>`
                    })
                  : t('lobby.event.sign.successText', {
                      x: `<span>${brazilianCurrencyFormatter(
                        this.openOptions?.prize ?? 0
                      )}</span>`
                    })
              }
              onClick={() => {
                this.close()
                this.openOptions?.onClose?.()
              }}
            ></inner-html>
            <div
              class={{
                [style.closeIcon]: true,
                [style.H5CloseIcon]: !isWeb
              }}
              onClick={() => {
                this.close()
                this.openOptions?.onClose?.()
              }}
            >
              {isWeb ? (
                <icon-sprite sprite={createSvgSprite('comm_icon_x')} />
              ) : (
                <icon-sprite
                  sprite={createPngSprite('sprite_main_img_close_s2')}
                  scale={0.68}
                />
              )}
            </div>
          </div>
        )
      }
    })
  }
}
