export enum SettleStatusEnum {
  ALL,
  UNSETTLEMENT,
  SETTLED,
  REVOKE
}

export enum TabsEnum {
  ACCOUNT_DETAILS = '3',
  BETTING_RECORD = '2',
  PERSONAL_STATEMENT = '1'
}
