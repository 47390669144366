import { GameItemInter } from '@/service/game/entity/gameItem'
import { flatten } from 'lodash'

/** 通过名称匹配的入参 */
export type _FilterByNameParams = {
  /** 过滤匹配的值 */
  matches: string | Array<string>
  /** 过滤匹配的类型 */
  type?: 'gameName'
}
/** 通过游戏名称匹配游戏列表
 * 因为匹配出来的结果,还需要是根据提供的matches是有序的,因此正则不能直接拼接成一句正则
 */
export const getFilteredGameListByName = (
  oriList: GameItemInter[],
  params: Omit<_FilterByNameParams, 'type'>
) => {
  /** 匹配入参 */
  const matches = Array.isArray(params.matches)
    ? params.matches
    : [params.matches]
  /** [匹配字符串]与[结果数组]的映射 */
  const strArrMapper = new Map<number | string, Array<GameItemInter>>()
  matches.forEach((match) => {
    strArrMapper.set(match, [])
  })
  /** [匹配字符串]与[正则表达式]映射 */
  const strRegMapper = matches.reduce((pre, cur) => {
    pre[cur] = new RegExp(cur as string, 'i')
    return pre
  }, {} as Record<string, RegExp>)
  /** 执行匹配逻辑 */
  ;(function executeMatch() {
    for (let outInd = 0; outInd < oriList.length; outInd++) {
      /** 单个游戏项 */
      const gameItem = oriList[outInd]
      for (let innerInd = 0; innerInd < matches.length; innerInd++) {
        const matchStr = matches[innerInd]
        const reg: RegExp = strRegMapper[matchStr]
        if (reg.test(gameItem.gameName)) {
          // 正则校验通过后,存入到对应数组中
          strArrMapper.get(matchStr)!.push(gameItem)
          // 必须要主动跳出循环,因为一个对象,只允许匹配一次结果,只能被装入一次数组中
          break
        }
      }
    }
  })()
  /** 最终需要的游戏列表 */
  const gameArr = flatten(Array.from(strArrMapper.values()))
  return gameArr
}
