import { Base, Component, Prop } from '@/vue-property-decorator'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AutoShrinkText from '@/components/auto-shrink-text'
import BusinessUtils from '@/utils/business-utils'
import style from './style.module.scss'
type Props = {
  /** 是否已签到 */
  hasSign: boolean
  /** 宝箱地址 */
  chestUrl: string
}
/** 领取的图案内容 */
@Component<RewardPattern>({
  name: 'RewardPattern'
})
export default class RewardPattern extends Base<unknown, Props> {
  @Prop()
  private readonly hasSign!: Props['hasSign']
  @Prop()
  private readonly chestUrl!: Props['chestUrl']
  render() {
    const { t } = useI18n()
    const { isWeb } = useMainStore()
    return (
      <div class={style['reward-pattern']}>
        {this.hasSign ? (
          <div class={style['received-box']}>
            <icon-sprite
              class={style['bg-receive']}
              sprite={createSvgSprite('comm_img_ylq')}
            />
            <div class={style['has-receive']}>
              <AutoShrinkText
                class={style['text']}
                text={t('lobby.event.sign.hasReceive') as string}
                limitHeight={BusinessUtils.px2rem(isWeb ? 40 : 48)}
              ></AutoShrinkText>
            </div>
          </div>
        ) : (
          <my-img
            useSkeleton
            class={style['picture']}
            src={this.chestUrl}
            alt=""
          />
        )}
      </div>
    )
  }
}
