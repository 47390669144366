import { Base, Component } from '@/vue-property-decorator'
import { createSvgSprite } from '@/utils/business-utils/assets'
// const img_scroll_jt = createSvgSprite('img_scroll_jt')
import style from './style.module.scss'

@Component<GuidingArrow>({
  name: 'GuidingArrow'
})
export default class GuidingArrow extends Base {
  render() {
    return (
      <div class={[style.guiding, 'guiding']}>
        <icon-sprite
          sprite={createSvgSprite('img_scroll_jt')}
          class={style.icon1}
        />
        <icon-sprite
          sprite={createSvgSprite('img_scroll_jt')}
          class={style.icon2}
        />
      </div>
    )
  }
}
