import { GameItemInter } from '@/service/game/entity/gameItem'

/** 通过id匹配的入参 */
export type _FilterByIdParams = {
  /** 过滤匹配的值 */
  matches: number | Array<number>
  /** 过滤匹配的类型 */
  type?: 'gameId'
  /** 返回结果是否需要包含null
   * 如: id匹配[15,24,13,84],若其中2,4,未匹配到游戏
   * true: 返回[game1,null,game3,null]
   * false: 返回[game1,game3]  */
  isContainsNull?: boolean
}
/** id过滤允许返回null */
export type _ByIdContainsNullParams = Omit<
  _FilterByIdParams,
  'isContainsNull'
> & {
  /** 返回结果是否需要包含null
   * 如: id匹配[15,24,13,84],若其中2,4,未匹配到游戏
   * true: 返回[game1,null,game3,null]
   * false: 返回[game1,game3]  */
  isContainsNull: true
}
/** id过滤,不允许返回null */
export type _ByIdNotContainsNullParams = Omit<
  _FilterByIdParams,
  'isContainsNull'
> & {
  /** 返回结果是否需要包含null
   * 如: id匹配[15,24,13,84],若其中2,4,未匹配到游戏
   * true: 返回[game1,null,game3,null]
   * false: 返回[game1,game3]  */
  isContainsNull?: false
}
/** 通过游戏id匹配游戏列表 */
export const getFilteredGameListById = (
  oriList: GameItemInter[],
  params: Omit<_FilterByIdParams, 'type'>
) => {
  const { isContainsNull = false } = params
  const matches = Array.isArray(params.matches)
    ? params.matches
    : [params.matches]
  const gameMapper = new Map<number, GameItemInter | null>()
  matches.forEach((match) => {
    gameMapper.set(match, null)
  })
  oriList.forEach((game) => {
    if (gameMapper.has(game.gameId)) {
      gameMapper.set(game.gameId, game)
    }
  })
  /** 用户指定的游戏列表*/
  const retList = Array.from(gameMapper.values()) as Array<GameItemInter | null>
  if (isContainsNull) {
    return retList
  } else {
    return retList.filter(Boolean)
  }
}
