import { GlobalService } from '@/context'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '@/views/global-modal/modal/common/util/judgePopupType'
import { TaskCategory } from '../class/taskCategory'
import { TaskCategoryApiCodeEnum } from './type'

/** 活动状态返回值 */
export type ActivityStatus = {
  /** 弹框类型 */
  popType: TaskCategoryApiCodeEnum
  /** 具体弹框信息(具体用法还待定) */
  popData?: TaskCategory
  /** 0:启用 1:禁用 */
  disablePop: 0 | 1
  /** 登录前弹窗状态  */
  beforeLoginPopType: PopTypeBeforeLogin
  /** 登录后弹窗状态  */
  afterLoginPopType: PopTypeAfterLogin
}

/**  获取活动任务弹框状态  */
export const apiGetTaskActivityStatus = (
  apiCode: TaskCategoryApiCodeEnum
): Promise<ActivityStatus> =>
  GlobalService.post<ActivityStatus>({
    url: '/active/get_active_pop',
    data: { popType: apiCode }
  }).then((res) => {
    const actStatus = {
      ...res.data.data
    } as ActivityStatus
    // 将它的popData转成一个 TaskCategory
    if (actStatus.popData) {
      actStatus.popData = new TaskCategory(
        apiCode as TaskCategoryApiCodeEnum
      ).immitResponse(actStatus.popData)
    }
    return actStatus
  })
