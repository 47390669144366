import { JackPotJumpEvent } from '@/api/aside/type'
import {
  OpenSequentiallyTaskModalParams,
  proxyOpenSequentiallyTaskModal
} from './proxyOpenSequentiallyTaskModal'
import { PatchData, PatchRulesFilter, proxyPatchRules } from './proxyPatchRules'
import {
  ProxyJudgePopupParams,
  proxyJudgeIsOpenableByCheckedAndRules
} from './proxyJudgeIsOpenableByCheckedAndRules'
import { RouteName } from '@/router/config'
import { State, initState, resetState } from './initState'
import {
  TaskCategoryApiCodeEnum as TCACE,
  TaskCategoryApiCodeEnum
} from '../../api/type'
import { TaskRule } from '../../class/taskRule'
import { UserOpeningTaskModalRecord } from './initState/userOpeningTaskModalRecord'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { proxyCodeOrderRulesMapper } from './proxyCodeOrderRulesMapper'
import { proxyFreshAllCodeCateReceive } from './proxyFreshAllCodeCateReceive'
import { proxyFreshCategoriesUsableAndRules } from './proxyFreshCategoriesUsableAndRules'
import { proxyFreshInPopupCategoryDataByCode } from './proxyFreshInPopupCategoryDataByCode'
import { proxyFreshInTabsCategoryDataByCode } from './proxyFreshInTabsCategoryDataByCode'
import { proxyJudgeIsOpenableByPendingReceiveRules } from './proxyJudgeIsOpenableByPendingReceiveRules'
import { random } from '@/utils/number'
// import { useMainStore } from '@/store/index'
// import { useTaskModalConstant } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant'
import { useMainStore } from '@/store/index'
import { useTaskModalConstant } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant'
import router from '@/router'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** store的类型守卫 */
export const isTaskPageStore = (data: Any): data is TaskPageStore => {
  return data.$id === 'taskPageStore'
}
/** 本store的状态 */
export type TaskPageStore = ReturnType<typeof useTaskPageStore>
/** 任务弹框的store */
export const useTaskPageStore = defineStore('taskPageStore', {
  state: initState as () => State,
  getters: {
    /** [apiCode + ruleid]与rule的映射 */
    apiCodeIdToRuleMapper(state: State) {
      const idRuleMap: Record<`${TCACE}_${string}`, TaskRule> = {}
      for (const apiCode in state.codeRulesMapper) {
        const _apiCode = apiCode as unknown as TCACE
        const rules = state.codeRulesMapper[_apiCode]
        rules.forEach((rule) => {
          idRuleMap[`${_apiCode}_${rule.ruleid}`] = rule
        })
      }
      return idRuleMap
    },
    /** webId(apiCode + ruleid  + receiveLogId)与rule的映射 */
    webIdToRuleMapper(state: State) {
      const idRuleMap: Record<string, TaskRule> = {}
      for (const apiCode in state.codeRulesMapper) {
        const _apiCode = apiCode as unknown as TCACE
        const rules = state.codeRulesMapper[_apiCode]
        rules.forEach((rule) => {
          idRuleMap[rule.webId] = rule
        })
      }
      return idRuleMap
    },
    /** apiCode对应排序后的rulesMapper */
    codeOrderRulesMapper() {
      const orderMapper = proxyCodeOrderRulesMapper(this)
      return orderMapper
    },
    /** 是否显示任务页面入口  */
    isShowTaskPageEntrance() {
      /** 后台配置是否允许开启任务 */
      const isEnableServerConfig = Boolean(
        useMainStore().getActivesAuthInfos?.taskCount
      )
      /** 任务规则不为空 */
      const isTaskRulesNotEmpty = !useTaskModalConstant().isAllTaskRulesEmpty
      return isEnableServerConfig && isTaskRulesNotEmpty
    }
  },
  actions: {
    /** 刷新tabs任务[类型的可用状态]和[规则列表] */
    async freshCategoriesUsableAndRules(isForceFreshUsable?: boolean) {
      await proxyFreshCategoriesUsableAndRules(this, isForceFreshUsable)
    },
    /** 在tabs的层面上刷新类型数据 */
    async freshInTabsCategoryDataByCode(apiCode: TCACE) {
      await proxyFreshInTabsCategoryDataByCode(this, apiCode)
    },
    /** 在弹框的层面上,根据code刷新数据 */
    async freshInPopupCategoryDataByCode(apiCode: TCACE) {
      await proxyFreshInPopupCategoryDataByCode(this, apiCode)
    },
    /** 发送请求->刷新所有的任务信息数据
     * 有对接过,当前后端并未给一个合适的接口,可以直接刷新四个数据,所以需要请求四次.
     */
    async freshAllInPopupCategories() {
      const codes = [TCACE.xrfl, TCACE.mrrw, TCACE.mzrw, TCACE.smrw]
      const freshAll = codes.map((code) =>
        this.freshInPopupCategoryDataByCode(code)
      )
      await Promise.all(freshAll)
    },
    /** 发送请求->刷新任务tabs的[小红点/金额/ruleId]等相关信息 */
    async freshCodeCateReceive(isRefresh = false) {
      await proxyFreshAllCodeCateReceive(this, isRefresh)
    },
    /** 更新请求状态 */
    switchIsFetchingStatus(flag: boolean) {
      this.isFetching = flag
    },
    /** 更新是否本次不再提醒 */
    switchThisTimeNoReminder(apiCode: TCACE, status: boolean) {
      this.codeThisTimeNoReminderMapper[apiCode] = status
    },
    /** 更新是否永远不再提醒 */
    switchNeverReminder(apiCode: TCACE, status: boolean) {
      this.codeNeverReminderMapper[apiCode] = status
    },
    /** 局部更新rules函数类型 */
    patchRules(patchData: PatchData, filter?: PatchRulesFilter) {
      // 代理执行函数(逻辑抽离)
      proxyPatchRules(this, patchData, filter)
    },
    /** 判断弹框是否可以打开 通过用户选择的checked和rules数据 */
    async judgeIsOpenableByCheckedAndRules(
      params: Omit<ProxyJudgePopupParams, 'store'>
    ) {
      return await proxyJudgeIsOpenableByCheckedAndRules({
        ...params,
        store: this
      })
    },
    /** 判断是否可以打开弹框 业务需求逻辑:特定的rule在待领取状态下,可以打开弹框 */
    judgeIsOpenableByPendingReceiveRules(
      apiCode: TCACE,
      ruleIds: number[],
      isFreshData: boolean
    ) {
      const params = [this, apiCode, ruleIds, isFreshData] as const
      return proxyJudgeIsOpenableByPendingReceiveRules(...params)
    },
    /** 打开包含所有任务项汇总的tabs,参数用于指定当前打开的默认任务类型 */
    gotoTaskPage(apiCode?: TaskCategoryApiCodeEnum) {
      if (apiCode) {
        this.curTabApiCode = apiCode
      }
      router.push({
        name: RouteName.TASK,
        query: { curTask: this.curTabApiCode } as Any
      })
    },
    /** 依次打开弹框队列 参数apiCode,或者特殊的ruleId的接收状态匹配
     * @deprecated 非必要情况暂时不要再使用该函数
     * 你可以使用 @/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant/index
     * 中的 openSequentiallyTaskModal 函数进行代替.
     * 如果业务场景不可代替,联系trevor.
     */
    openSequentiallyTaskModal(
      param: OpenSequentiallyTaskModalParams
    ): Promise<unknown> {
      return proxyOpenSequentiallyTaskModal(this, param)
    },
    /** 设置关闭模态框和队列的函数 */
    setStopModalAndQueue(stop: null | (() => void)) {
      this.stopModalAndQueue = stop
    },
    /** 记录用户打开弹窗记录 */
    recordUserOpeningRecord(apiCode: TCACE) {
      const userRecord = UserOpeningTaskModalRecord.getInstance()
      this.codeIsHaveOpenedTodayMapper[apiCode] = true
      this.codeIsHaveOpenedEverMapper[apiCode] = true
      userRecord.recordUserOpeningRecord(apiCode)
    },
    /** 我的重置*/
    myReset() {
      resetState(this)
    },
    /** 刷新视图 */
    freshView() {
      this.dataChangeFreshView = random(1000000)
    },
    setIsTimeoutError(key: TCACE | 'canUsable', isTimeoutError: boolean) {
      this.timeoutErrorMapper[key] = isTimeoutError
    },
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.taskModal',
        paths: [
          'isFetched',
          'fetchedDataLanguage',
          'codeThisTimeNoReminderMapper',
          'codeCategoryMapper',
          'codeRulesMapper'
        ],
        storage: createMyPersistedStorage()
      }
    ]
  }
})

/** 彩金池的codeMapper与apiCode的映射 */
const jackPotCodeApiCodeMapper = {
  [JackPotJumpEvent.NewcomerBenefits]: TaskCategoryApiCodeEnum.xrfl,
  [JackPotJumpEvent.DailyTasks]: TaskCategoryApiCodeEnum.mrrw,
  [JackPotJumpEvent.WeeklyTasks]: TaskCategoryApiCodeEnum.mzrw,
  [JackPotJumpEvent.MysteriousTasks]: TaskCategoryApiCodeEnum.smrw
} as Record<JackPotJumpEvent, TaskCategoryApiCodeEnum>

/** 获得apiCode通过后端返回的彩金池的code */
export const getApiCodeByJackPotCode = (jackPotCode: JackPotJumpEvent) => {
  return jackPotCodeApiCodeMapper[jackPotCode]
}
