/* eslint-disable @typescript-eslint/no-explicit-any*/
import { GlobalService } from '@/context'
import { TaskCategoryApiCodeEnum } from './type'

/**  获取任务分类可使用的信息
 * 接口文档地址: https://docs.cg.xxx/web/#/40/2164
 * 因为每次开启弹窗的时候,可能会需要先请求状态,刷新页面后,第一次打开任务弹窗,也有可能会请求
 * 因此做一个请求合并.避免重复多次的调用.
 */
export const apiGetCategoryUsable = (): Promise<CategoryUsableMapper> =>
  GlobalService.post({
    url: '/activetask/index',
    customParams: {
      cache: 60 * 1000
    }
  }).then((res) => {
    let retData = (res.data.data || []) as any
    // 将原有数据格式转成我们页面需要的格式
    // 原有格式不会在项目中使用,原有类型,需要了解,直接查接口文档 https://docs.cg.xxx/web/#/40/2164
    retData = retData.reduce((pre: any, cur: any) => {
      // 从嵌套的数据中,取到一个对象,如果取不到该对象,则直接跳过该cur,它是无意义的cur
      const curObj = cur.List?.[0]
      if (!curObj) {
        return pre
      }
      // 从curObj中取得 任务id,然后将任务id转换成apiCode,并赋值到usableMapper上
      // 只要taskId存在,则说明该任务是可用,没有返回的,就是CategoryUsableMapper的初始值,默认false
      const apiCode = getCodeByTaskId(curObj.taskId)
      if (Reflect.has(pre, apiCode)) {
        pre[apiCode] = true
      }
      return pre
    }, new CategoryUsableMapper())
    return retData as CategoryUsableMapper
  })

/** 任务分类可使用状态的mapper */
export class CategoryUsableMapper {
  [TaskCategoryApiCodeEnum.xrfl] = false;
  [TaskCategoryApiCodeEnum.mrrw] = false;
  [TaskCategoryApiCodeEnum.mzrw] = false;
  [TaskCategoryApiCodeEnum.smrw] = false
}

/** 懒加载,通过taskId获取apiCode  */
let getCodeByTaskId = (taskId: 1 | 2 | 3 | 4): TaskCategoryApiCodeEnum => {
  const map = {
    1: TaskCategoryApiCodeEnum.xrfl,
    2: TaskCategoryApiCodeEnum.mrrw,
    3: TaskCategoryApiCodeEnum.mzrw,
    4: TaskCategoryApiCodeEnum.smrw
  } as const
  getCodeByTaskId = (taskId: 1 | 2 | 3 | 4) => {
    return map[taskId]
  }
  return getCodeByTaskId(taskId)
}
