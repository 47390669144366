import { JudgePopupTypeTemplate } from './judgePopupTypeTemplate'
import { PopTypeAfterLogin, PopTypeBeforeLogin } from './enum'
import { ReceiveLogPopSwitchEnum } from '@/api/event/type'
import { TaskRuleStatusEnum } from '../../../tasks-modal-dialog/class/TaskRule'
import { useWaitingGetStore } from '../../../waiting-get/store'

/** 首充弹窗的判断类型 */
export class JudgePopupTypeWaitingGet extends JudgePopupTypeTemplate {
  protected getStatusList(): TaskRuleStatusEnum[] {
    // 为pendingRewardList赋值上TaskRuleStatusEnum的值,将不同弹窗项的状态值统一.
    const { PendingReceive } = TaskRuleStatusEnum
    /** 状态枚举mapper,将首统弹窗的状态枚举,转换成弹窗统一使用的状态枚举
     * [0不可领取->Goto前往] [1:可领取->PendingReceive待领取]
     */
    const { pendingRewardList, receivedTodayList, collectTomorrowList } =
      useWaitingGetStore()
    const combinedList = pendingRewardList.concat(
      receivedTodayList,
      collectTomorrowList
    )
    // combinedList有数据就需弹窗 直接写死为PendingReceive
    const statusList = combinedList.map(() => PendingReceive)
    return statusList as TaskRuleStatusEnum[]
  }
  protected getIsHaveOpenedToday(): boolean {
    const { isHaveOpenedToday } = useWaitingGetStore()
    return isHaveOpenedToday
  }
  protected getIsHaveOpenedEverMapper(): boolean {
    const { isHaveOpenedEver } = useWaitingGetStore()
    return isHaveOpenedEver
  }
  protected getBeforeLoginType(): PopTypeBeforeLogin {
    const { beforeLoginPopType } = useWaitingGetStore()
    return beforeLoginPopType
  }
  protected getAfterLoginType(): PopTypeAfterLogin {
    const { afterLoginPopType } = useWaitingGetStore()
    const popTypeMap: Record<ReceiveLogPopSwitchEnum, PopTypeAfterLogin> = {
      [ReceiveLogPopSwitchEnum.NoPop]: PopTypeAfterLogin.never,
      [ReceiveLogPopSwitchEnum.DailyPop]: PopTypeAfterLogin.onceDay,
      [ReceiveLogPopSwitchEnum.OnlyPop]: PopTypeAfterLogin.waitingReceive,
      [ReceiveLogPopSwitchEnum.OncePop]: PopTypeAfterLogin.onceForever,
      [ReceiveLogPopSwitchEnum.LoginPop]: PopTypeAfterLogin.everyLogining
    }
    return popTypeMap[afterLoginPopType]
  }
  protected customTypeFun = {
    [PopTypeAfterLogin.constantly]: () => {
      const { isReceivedEver } = useWaitingGetStore()
      // 高频弹窗也是完成后则不弹窗,从未领取过要弹(任务未完成,任务待领取)
      return !isReceivedEver
    },
    [PopTypeAfterLogin.waitingComplete]: () => {
      const { isReceivedEver, pendingRewardList } = useWaitingGetStore()
      // 从来没有领取过,并且没有可领取的,则(任务未完成要弹),如果待取值有值,则是(任务待领取才弹)
      return !isReceivedEver && pendingRewardList.length === 0
    }
  }
}
