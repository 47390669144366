import { Base, Component, Prop } from '@/vue-property-decorator'
import { occupierReplaceVNode } from '@/utils/occupierReplaceVNode'
import { useI18n } from '@/i18n'
import CountDown from '@/layouts/components/countDown'
import style from '../../style.module.scss'

interface Props {
  donwTimestamp: number
}

/**
 *  @description 领取倒计时
 * */
@Component<WithdrawDonwTime>({ name: 'WithdrawDonwTime' })
export default class WithdrawDonwTime extends Base<unknown, Props> {
  @Prop()
  donwTimestamp!: Required<Props>['donwTimestamp']

  render() {
    const { t } = useI18n()

    return (
      <div
        class={{
          [style.donwTimeWrapper]: true
        }}
      >
        <span>
          {this.donwTimestamp === 0
            ? ''
            : occupierReplaceVNode(
                t('lobby.cpf.afterReset') as string,
                '{x}',
                <CountDown
                  hasDay
                  hasTextBorder={false}
                  duration={this.donwTimestamp}
                  onOver={() => {
                    this.$emit('countDownEnd')
                  }}
                />
              )}
        </span>
      </div>
    )
  }
}
