// import hooks type to enable auto-complete
import 'vue-class-component/hooks'
// Register the router hooks with their names
import Component from 'vue-class-component'
Component.registerHooks([
  'metaInfo',
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])
import * as VueTsx from '@/vue-tsx-support'
import { isPlainObject, get } from 'lodash'
import Vue from 'vue'
import VueUtils from '@/utils/VueUtils'
import { Watch as NormalWatch } from 'vue-property-decorator'
import type { WatchOptions } from 'vue'
export * from 'vue-property-decorator'

export { VueTsx }

export function ConditionalExecution(
  condition: (instance: Base) => boolean = (instance) => !instance.isKeptAlive
) {
  return function (_target: Vue, _key: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value
    descriptor.value = function (this: Base, ...args: unknown[]) {
      if (!condition(this)) {
        return
      }
      try {
        return originalMethod.apply(this, args)
      } catch (error) {
        console.error(`Error executing method ${_key}:`, error)
        throw error
      }
    }
  }
}

export function CustomWatch(expr: string, options?: WatchOptions) {
  return function (target: Vue, key: string, descriptor: PropertyDescriptor) {
    ConditionalExecution()(target, key, descriptor)
    NormalWatch(expr, options)(target, key)
  }
}

@Component
export class Base<
  State = unknown,
  Props = {},
  PrefixedEvents = {},
  ScopedSlotArgs = {}
> extends VueTsx.Component<Props, PrefixedEvents, ScopedSlotArgs> {
  state!: State
  setState(
    updater: ((state: State) => PartialDeep<State>) | PartialDeep<State>,
    callback?: () => void
  ): void {
    const newState = updater instanceof Function ? updater(this.state) : updater
    const isObject = (target: unknown) =>
      target instanceof Object && isPlainObject(target)
    if (isObject(this.state)) {
      const deep = (state: any, pre: any) => {
        Object.keys(state).forEach((key) => {
          if (isObject(state[key]) && isObject(pre[key])) {
            deep(state[key], pre[key])
          } else {
            Vue.set(pre, key, get(state, key))
          }
        })
      }
      deep(newState, this.state)
    } else {
      // Dangerous
      this.state = newState as State
    }
    this.$nextTick(() => {
      callback && callback()
    })
  }
  isKeptAlive = false

  activated() {
    this.isKeptAlive = false
  }

  deactivated() {
    this.isKeptAlive = true
  }
  /**
   * 重置所有 data 的状态
   */
  $reset() {
    Object.assign(
      this.$data,
      (this.$options.data as unknown as () => void).call(this)
    )
  }

  /**
   * 根据条件收集所有符合特征的vue子组件
   */
  getChilds(filter: (context: Vue) => boolean, context: Vue) {
    return VueUtils.getChilds.call(this, filter, context)
  }
}
