import { TaskCategoryApiCodeEnum } from '../../../api/type'
import { TaskPageStore } from '..'
import { apiGetCategoryUsable } from '../../../api/apiGetCategoryUsable'
import { merge } from 'lodash'
import to from 'await-to-js'

/** 刷新分类的可用状态 */
export const freshCategoryUsable = async (
  store: TaskPageStore,
  isForceFreshUsable?: boolean
) => {
  // 如果需要强制刷新usable状态,或者是通过后台接口未刷新过,则刷新它
  if (isForceFreshUsable || !store.categoryUsable.isFechted) {
    const [err, cateUsableMapper] = await to(apiGetCategoryUsable())
    // 接口报错了，就显示超时提示
    store.setIsTimeoutError('canUsable', !!err)
    if (err) {
      console.error('task fetch error:', err)
      return
    }
    merge(store.categoryUsable.usableMapper, cateUsableMapper)
    store.categoryUsable.isFechted = true
    // 如果当前选中的tab项,并未开启,则重新选中排在第一位的tab项
    // 例:登录前有[每日任务],登录后没有[每日任务],则需要自动切换为选中[新人福利]. 若登录后也有[每日任务],则不重新选择
    if (!cateUsableMapper[store.curTabApiCode]) {
      const firstUsableCode = store.codeCategoryOrder.find(
        (code) => cateUsableMapper[code]
      )
      store.curTabApiCode = firstUsableCode as TaskCategoryApiCodeEnum
    }
  }
  return store.categoryUsable.usableMapper
}
