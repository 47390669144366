import { GlobalService } from '@/context'
import { PopWaitingGetResponse, PopWaitingGetWeb } from './class/popWaitingGet'

/**  获取弹窗数据首充奖励  */
export const apiGetPopWaitingGetReward = async () => {
  const res = await GlobalService.post<PopWaitingGetResponse>({
    url: '/active/pop_canReceiveReward'
  }).then((res) => {
    const popRet = {
      ...res.data.data
    } as PopWaitingGetResponse
    return popRet
  })
  const popWaitingGetWeb = PopWaitingGetWeb.immitResponse(
    new PopWaitingGetWeb(),
    res
  )
  return popWaitingGetWeb
}
