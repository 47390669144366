import {
  PopTypeAfterLogin as PTAL,
  PopTypeBeforeLogin as PTBL
} from '../../../../common/util/judgePopupType'
import { State } from './type'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { TaskCategory } from '../../../class/TaskCategory'
import { UserDataSyncInStore } from '../../../../common/util/userDataSyncInStore'
import { UserOpeningTaskModalRecord } from './userOpeningTaskModalRecord'
import type { TaskModalConstant } from '..'
export type { State } from './type'
/** 对应字段codeCategoryMapper */
const codeOrder = [
  TCACE.xrfl,
  TCACE.mrrw,
  TCACE.mzrw,
  TCACE.smrw
] as State['codeCategoryOrder']
/** 创建[apiCode映射今日不再弹窗的] */
const createCodeTodayNotReminderMapper = () => {
  /** 持久化对象 */
  const todayReminderSync = new UserDataSyncInStore({
    createDefault: () => createApiCodeToAnyMapper(false),
    storeKey: 'fourTaskAllUserCheckTodayNotReminderMapper',
    isPersistUnLogin: true
  })
  // 判断今天是否已经打开过,然后改变[今日不再弹出]的勾选状态
  const { todayMapper } =
    UserOpeningTaskModalRecord.getUserOpeningRecordStateMappers(...codeOrder)
  codeOrder.forEach((apiCode) => {
    // 如果今天未打开过,则说明已经到次日了,勾选状态需要重置为false
    if (!todayMapper[apiCode]) {
      todayReminderSync.set(apiCode, false)
    }
  })
  return todayReminderSync
}

/** 创建[apiCode]映射永远不再弹窗 */
const createCodeNeverReminderMapper = () => {
  /** 持久化对象 */
  const neverReminderSync = new UserDataSyncInStore({
    createDefault: () => createApiCodeToAnyMapper(false),
    storeKey: 'fourTaskAllUserCheckNeverReminderMapper',
    isPersistUnLogin: true
  })
  return neverReminderSync
}

/** 初始化state 若切换了多语言,所有接口也是需要重新请求的,所以,所有数据都需要重新初始化一遍 */
export const initState = (): State => {
  const { everMapper, todayMapper } =
    UserOpeningTaskModalRecord.getUserOpeningRecordStateMappers(...codeOrder)
  return {
    isFetching: false,
    codeCategoryOrder: [...codeOrder],
    codeCategoryMapper: createApiCodeToAnyMapper(
      (code) => new TaskCategory(code)
    ),
    codeRuleConstantsMapper: createApiCodeToAnyMapper([]),
    codeTodayNotReminderMapper: createCodeTodayNotReminderMapper(),
    codeNeverReminderMapper: createCodeNeverReminderMapper(),
    codeIsHaveOpenedTodayMapper: todayMapper,
    codeIsHaveOpenedEverMapper: everMapper,
    codePopBefLoginMapper: createApiCodeToAnyMapper(PTBL.constantly),
    codePopAftLoginMapper: createApiCodeToAnyMapper(PTAL.constantly),
    specialConfig: null,
    stopModalAndQueue: null
  }
}

/** 重置数据,并不重置全部数据,而是挑选出部分需要被重置的内容 */
export const resetState = (store: TaskModalConstant) => {
  const { everMapper, todayMapper } =
    UserOpeningTaskModalRecord.getUserOpeningRecordStateMappers(...codeOrder)
  // 重置今日不再弹出和永久不再弹出,这个不直接写一个新值,因为需要在set的时候改变本地缓存信息
  codeOrder.forEach((apiCode) => {
    store.codeTodayNotReminderMapper.set(apiCode, false)
  })
  codeOrder.forEach((apiCode) => {
    store.codeNeverReminderMapper.set(apiCode, false)
  })
  const newState = {
    isFetching: false,
    codeRulesMapper: createApiCodeToAnyMapper([]),
    codeIsHaveOpenedTodayMapper: todayMapper,
    codeIsHaveOpenedEverMapper: everMapper,
    stopModalAndQueue: null
  }
  Object.assign(store, newState)
}

/** 创建apiCode与其它任何值的映射
 * @param {T} defVal 默认值  [基础值:直接赋值成默认值]  [函数:执行函数,将函数的返回值赋值成默认值]
 */
export const createApiCodeToAnyMapper = <T>(
  defVal: T | ((key: TCACE) => T)
) => {
  const keys = [TCACE.xrfl, TCACE.mrrw, TCACE.mzrw, TCACE.smrw]
  return keys.reduce((mapper, key) => {
    if (typeof defVal !== 'function') {
      mapper[key] = defVal as T
    } else {
      mapper[key] = (defVal as (key: TCACE) => T)(key)
    }
    return mapper
  }, {} as Record<TCACE, T>)
}
