export enum CPFDateConditionEnum {
  TODAY = 0,
  YESTERDAY = 1,
  LAST_SEVEN_DAY = 7,
  LAST_FIFTEEN_DAY = 15,
  LAST_THIRTY_DAY = 30,
  LAST_SIXTY_DAY = 60
}

export enum CustomValueType {
  ACCUMUL_HISTORY = 1,
  BET_HISTORY = 2,
  RULES_DESC = 3
}
