import { JudgePopupTypeTemplate } from './judgePopupTypeTemplate'
import { PopTypeAfterLogin, PopTypeBeforeLogin } from './enum'
import { useSignStore } from '@/views/event/item/component/sign/store'

/** 签到活动弹窗的判断类型 */
export class JudgePopupTypeSign extends JudgePopupTypeTemplate {
  protected getStatusList() {
    return []
  }

  /** 每日一次 */
  protected getIsHaveOpenedToday(): boolean {
    const { isSignHaveOpenedToday } = useSignStore()
    return isSignHaveOpenedToday
  }

  /** 只弹窗一次 */
  protected getIsHaveOpenedEverMapper(): boolean {
    const { isSignHaveOpenedEver } = useSignStore()
    return isSignHaveOpenedEver
  }

  /** 登录前弹窗类型 */
  protected getBeforeLoginType(): PopTypeBeforeLogin {
    const { signBeforeLoginPopType } = useSignStore()
    return signBeforeLoginPopType
  }

  /** 登录后弹窗类型 */
  protected getAfterLoginType(): PopTypeAfterLogin {
    const { signAfterLoginPopType } = useSignStore()
    return signAfterLoginPopType
  }

  protected customTypeFun = {
    [PopTypeAfterLogin.constantly]: () => {
      return true
    }
  }
}
