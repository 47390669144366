export enum TabsEnum {
  PHONE,
  CPF, // 葡萄牙语特有的配置
  EMAIL,
  GA,
  LOGIN_PWD,
  WITHDRAWPASS,
  QUESTION,
  THIRD_WAY,
  USER,
  WITHDRAWACCOUNT
}

// 步骤数
export enum StepEnum {
  FIRST_STEP,
  SECOND_STEP,
  THIRD_STEP
}

export enum VerifyStype {
  /** 未添加 */
  NoAdd = 0,
  /** 添加未验证 */
  addButNoVerify = 1,
  /** 已验证绑定 */
  AddAndVerify = 2
}
