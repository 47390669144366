import { ActivityTemplate } from '@/api/event/type'
import {
  ApiRedPocketItem1,
  ApiRedPocketValidation,
  BaseRedPocketItem,
  RedPocketPollingCountEnum,
  RedPocketPollingTimeEnum,
  RedTypeEnum
} from '@/api/redPocket/type'
import { GlobalConst, GlobalManager } from '@/context'
import {
  RedPocketCloseStorages,
  RedPocketHandleCloseStorages,
  RedPocketState
} from './type'
import { RedPocketItemType } from '../components/item/type'
import { Response } from '@/context/service'
import { UserStatusEnum } from '@/api/common/type'
import { apiGetByTemplate } from '@/api/event'
import { apiRedPocket, apiRedPocketValidation } from '@/api/redPocket'
import {
  checkIsClosed,
  compatibleId,
  getHandleClosedKey,
  getRedPocketCloseStorages
} from '../utils'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { useGameStore } from '@/views/game/store'
import { useMainStore } from '@/store/index'
import { useSecurity } from '@/views/center/security/store'
import Site from '@/controller/Site'
import to from 'await-to-js'

/** 红包store */
export const useRedPocketStore = defineStore('redPocketStore', {
  state: (): RedPocketState => ({
    avtiveRedList: [], // 开红包列表
    redList: [], // 抢红包列表
    sendList: [], // 送红包列表
    closeRedList: [], // 已关闭红包ID
    isOpenPocketFinish: false, // 开红包是否执行完成
    isSendPocketFinish: false, // 送红包是否执行完成
    isGrabPocketFinish: false, // 抢红包是否执行完成
    redEnvelopeQueryCount: RedPocketPollingCountEnum.Count, // 红包查询次数
    redPocketPollingTime: RedPocketPollingTimeEnum.Seconds10, // 初始值 10秒轮询一次
    apiLockerGetRedPackage: false,
    redPockedOpening: false,
    redPockedOpenKey: '',
    pinVerify: '',
    homeQueueOpening: false,
    isCollecting: false,
    redPocketClose: [],
    redPocketHandleClose: []
  }),
  getters: {
    /**
     * 获取过滤后的开红包列表
     */
    getAvtiveRedList(): RedPocketState['avtiveRedList'] {
      return this.avtiveRedList.filter(
        (red) => !checkIsClosed(red as unknown as ApiRedPocketItem1)
      )
    },
    /**
     * 获取过滤后的抢红包列表
     * 同时过滤抢红包是否到开启时间
     */
    getRedList(): RedPocketState['redList'] {
      return this.redList.filter(
        (red) =>
          !checkIsClosed(red as unknown as ApiRedPocketItem1) &&
          Site.getServerTime() >= red.time
      )
    },
    /**
     * 获取过滤后的送红包列表
     */
    getSendList(): RedPocketState['sendList'] {
      return this.sendList.filter(
        (red) => !checkIsClosed(red as unknown as ApiRedPocketItem1)
      )
    }
  },

  actions: {
    /** 设置抢红包输入码 */
    setPinVerify(val: string) {
      this.pinVerify = val
    },
    /** 获取抢红包 抢红包 输入码 */
    async getPin(item: RedPocketItemType) {
      const [err, data] = await to(
        apiRedPocketValidation({
          subtaskid: 'subtaskid' in item ? item.subtaskid : 1,
          logId: +item.logId || 0,
          active: item.activeId
        })
      )
      // console.log('xxxxxx fetchPin res', data)
      const { code, data: resData } = (data ??
        {}) as Response<ApiRedPocketValidation>

      /**
       * 红包已抢完 关闭弹窗
       */
      if (code === GlobalConst.ServiceCode.SUCCESSCODE) {
        return false
      }

      if (err || (resData && resData.isValidate !== 1)) {
        return true
      }

      this.setPinVerify(String(resData?.vcode))

      return true
    },
    setIsCollecting(val: boolean) {
      this.isCollecting = val
    },
    /** 设置首页队列打开红包的状态 */
    setHomeQueueOpening(val: boolean) {
      this.homeQueueOpening = val
    },
    /** 设置打开红包弹窗状态 */
    setRedPockedOpening(val: boolean, key: string) {
      this.redPockedOpening = val
      this.redPockedOpenKey = key
    },
    /** 初始化红包 */
    async initRedPocket() {
      await GlobalManager.Polling.create({
        key: GlobalManager.Polling.LobbyPollingType.RED_POCKETS,
        callback: this.fetchRedPocketDataCheck,
        leading: false, // 首次不执行
        interval: RedPocketPollingTimeEnum.Seconds10 // 初始化 10秒轮询一次
      })
    },
    /** 结束红包轮询 */
    destroyRedPocket() {
      GlobalManager.Polling.destroy(
        GlobalManager.Polling.LobbyPollingType.RED_POCKETS
      )
    },
    loginAfterResetRedPocketCloseList() {
      // 登录的时候去除前一个 红包报错缓存关闭记录
      if (useMainStore().hasLogined) {
        this.setRedPocketClose()
      }
    },
    /** 报错导致提示 则保存关闭记录 */
    setRedPocketClose(item?: ApiRedPocketItem1) {
      if (item) {
        const mainStore = useMainStore()
        const platfromid = mainStore.userInfos?.platfromid || ''
        const redPocketCloseStorages = JSON.parse(
          JSON.stringify(getRedPocketCloseStorages())
        ) as Array<RedPocketCloseStorages>
        const redPocketItem = redPocketCloseStorages.find(
          (i) => i.platfromid === platfromid
        )
        // console.log('xxxxxx setClosed 1111', redPocketItem)
        if (redPocketItem) {
          if (!redPocketItem.data) {
            redPocketItem.data = []
          } else {
            redPocketItem.data = redPocketItem.data.filter(
              (i) =>
                i.activeId !== getHandleClosedKey(item as ApiRedPocketItem1)
            )
          }

          redPocketItem.data.push({
            activeId: getHandleClosedKey(item as ApiRedPocketItem1),
            errorMsg: item.errorMsg
          })
        } else {
          redPocketCloseStorages.push({
            platfromid,
            data: [
              {
                activeId: getHandleClosedKey(item as ApiRedPocketItem1),
                errorMsg: item.errorMsg
              }
            ]
          })
        }
        // console.log('xxxxxx setClosed', redPocketCloseStorages)
        this.redPocketClose = redPocketCloseStorages
      } else {
        this.redPocketClose = []
      }
    },
    /** 主动选中关闭按钮 则保存关闭记录 */
    setRedPocketHandleClose(list: Array<RedPocketHandleCloseStorages>) {
      this.redPocketHandleClose = list
    },
    /**
     * 开红包回调监听
     */
    doOpenPocketsCallBack(done?: () => void) {
      // 如果两种红包内容的长度都是0,不要阻断弹窗队列的进行.
      if (this.getAvtiveRedList.length === 0 && this.getSendList.length === 0) {
        done?.()
      }
      if (done) {
        this.openPocketsCallBack = done
      }
      if (!this.getAvtiveRedList.length && this.isOpenPocketFinish) {
        this.openPocketsCallBack && this.openPocketsCallBack()
      }

      if (!this.getSendList.length && this.isSendPocketFinish) {
        this.openPocketsCallBack && this.openPocketsCallBack()
      }
    },
    // 设置红包轮询次数
    setRedEnvelopeQueryCount(count: number) {
      this.redEnvelopeQueryCount = count
    },
    // 设置红包轮询时间 毫秒
    setRedPocketPollingTime(time: number) {
      this.redPocketPollingTime = time

      const func = async () => {
        if (this.redEnvelopeQueryCount > 0) return

        const data = await apiGetByTemplate({
          template: String(ActivityTemplate.RedPack),
          needFilterLevel: 1
        })

        if (!Array.isArray(data)) return

        // 如果没有红包活动，不请求 home/index 接口
        if (data?.length === 0) {
          // 销毁红包轮询
          GlobalManager.Polling.destroy(
            GlobalManager.Polling.LobbyPollingType.RED_POCKETS
          )
        } else {
          // 红包 3分钟请求一次
          GlobalManager.Polling.update(
            GlobalManager.Polling.LobbyPollingType.RED_POCKETS,
            {
              key: GlobalManager.Polling.LobbyPollingType.RED_POCKETS,
              callback: this.fetchRedPocketDataCheck,
              leading: false,
              interval: RedPocketPollingTimeEnum.Minutes3
            }
          )
        }
      }
      func()
    },
    /** ws触发的时候需要更新轮询速度 */
    changeRedPocketInterval(status: boolean) {
      // this.isWSMessageRedPocket = status
      if (status) {
        GlobalManager.Polling.update(
          GlobalManager.Polling.LobbyPollingType.RED_POCKETS,
          {
            key: GlobalManager.Polling.LobbyPollingType.RED_POCKETS,
            callback: this.fetchRedPocketDataCheck.bind(this),
            leading: true,
            interval: RedPocketPollingTimeEnum.Seconds10 // 10秒轮询一次
          }
        )
      }
    },
    /** 获取红包前的检查 */
    async fetchRedPocketDataCheck() {
      const {
        redEnvelopeQueryCount,
        redPocketPollingTime,
        setRedPocketPollingTime,
        getRedListData
      } = this
      // 红包查询次数为0时，红包轮询时间改为10分钟
      if (
        redEnvelopeQueryCount <= 0 &&
        redPocketPollingTime === RedPocketPollingTimeEnum.Seconds10
      ) {
        await setRedPocketPollingTime(RedPocketPollingTimeEnum.Minutes5)
      } else {
        await getRedListData()
      }
    },
    /**
     * 获取红包数据
     */
    async getRedListData() {
      const { isDemoMode, hasLogined, userInfos } = useMainStore()
      // 加个防抖,3秒内只能调用该方法更新一次.. 体验号 不需要查询红包
      if (this.apiLockerGetRedPackage || isDemoMode) return
      this.apiLockerGetRedPackage = true
      setTimeout(() => {
        this.apiLockerGetRedPackage = false
      }, 3000)
      // 未登录
      if (!hasLogined) return

      // 冻结及黑名单不调用接口
      if (
        userInfos?.user_status &&
        [
          UserStatusEnum.FreezeAbnormal,
          UserStatusEnum.FreezeManual,
          UserStatusEnum.BlackList
        ].includes(userInfos.user_status)
      ) {
        return
      }

      const [err, data] = await to(apiRedPocket())

      if (err) {
        console.error('error:', err)
        return
      }

      console.log('红包数据>>>', data)

      const avtiveRedList = compatibleId(
        data?.avtiveRedList as BaseRedPocketItem[]
      )
      const sendList = compatibleId(data?.sendList as BaseRedPocketItem[])
      const redList = compatibleId(data?.redList as BaseRedPocketItem[])

      // 更新store的数据
      this.updateRedList(
        avtiveRedList as RedPocketState['avtiveRedList'],
        redList as RedPocketState['redList'],
        sendList as RedPocketState['sendList']
      )

      // 红包接口查询优化：默认红包查询10次，如果查询没有红包，则次数减一
      if (
        avtiveRedList?.length === 0 &&
        redList?.length === 0 &&
        sendList?.length === 0 &&
        this.redEnvelopeQueryCount > 0
      ) {
        this.setRedEnvelopeQueryCount(this.redEnvelopeQueryCount - 1)
      } else {
        // 红包查询，如果查询中有红包，则红包查询恢复默认次数
        this.setRedEnvelopeQueryCount(RedPocketPollingCountEnum.Count)
      }
    },

    /** 检查并弹出红包 */
    async checkOpenRedModal() {
      // 红包打开中、游戏中、含有强制绑定 不弹出
      if (
        !this.redPockedOpening &&
        !useGameStore().isGamePlaying &&
        !useSecurity().systemMastBindList?.length
      ) {
        // console.log(
        //   'xxxxx 数据更新弹出',
        //   this.getRedList.length,
        //   this.getSendList.length,
        //   this.getAvtiveRedList.length
        // )
        // 打开弹窗
        if (this.getRedList.length) {
          const target = this.getRedList[0]
          const isSuccess = await this.getPin(target)
          if (isSuccess) {
            GlobalManager.Modal.open('redPocketModal', {
              redType: RedTypeEnum.Grab,
              inHomeQueue: false,
              redItem: target
            })
          } else {
            this.setRedPocketClose(target)
          }
        } else if (this.getSendList.length) {
          GlobalManager.Modal.open('redPocketModal', {
            redType: RedTypeEnum.Send,
            inHomeQueue: false,
            redItem: this.getSendList[0] as ApiRedPocketItem1
          })
        } else if (this.getAvtiveRedList.length) {
          GlobalManager.Modal.open('redPocketModal', {
            redType: RedTypeEnum.Open,
            inHomeQueue: false,
            redItem: this.getAvtiveRedList[0] as unknown as ApiRedPocketItem1
          })
        }
      }
    },

    /**
     * 更新开红包及抢红包的数据
     * 合并数据 并去重处理
     */
    updateRedList(
      avtiveRedList: RedPocketState['avtiveRedList'],
      redList: RedPocketState['redList'],
      sendList: RedPocketState['sendList']
    ) {
      const filterAvtiveRedList = this.avtiveRedList.filter(
        (it) => !avtiveRedList.some((red) => red.activeId === it.activeId)
      )

      const filterRedList = this.redList.filter(
        (it) => !redList.some((red) => red.activeId === it.activeId)
      )

      const filterSendListt = this.sendList.filter(
        (it) => !sendList.some((send) => send.activeId === it.activeId)
      )

      this.avtiveRedList = [...avtiveRedList, ...filterAvtiveRedList]
      this.redList = [...redList, ...filterRedList]
      this.sendList = [...sendList, ...filterSendListt]

      // 若开红包未完成 且第一次无开红包数据
      if (!this.isOpenPocketFinish && !this.avtiveRedList.length) {
        this.isOpenPocketFinish = true
      }

      // 送红包
      if (!this.isSendPocketFinish && !this.getSendList.length) {
        this.isSendPocketFinish = true
      }
      // console.log(
      //   'xxxxx 数据更新弹出 前',
      //   this.redPockedOpening,
      //   this.getRedList.length,
      //   this.getSendList.length,
      //   this.getAvtiveRedList.length,
      //   useSecurity().systemMastBindList
      // )

      if (
        this.redPockedOpening &&
        this.getRedList.length &&
        this.getRedList.every(
          (i) => this.redPockedOpenKey !== getHandleClosedKey(i)
        ) &&
        this.getSendList[0] &&
        this.getSendList.every(
          (i) =>
            this.redPockedOpenKey !== getHandleClosedKey(i as ApiRedPocketItem1)
        ) &&
        this.getAvtiveRedList[0] &&
        this.getAvtiveRedList.every(
          (i) =>
            this.redPockedOpenKey !==
            getHandleClosedKey(i as unknown as ApiRedPocketItem1)
        )
      ) {
        GlobalManager.Modal.close('redPocketModal')
      } else {
        this.checkOpenRedModal()
      }
    },

    /**
     * 当用户已领取，或者其他异常，手动移除第一个红包数据
     */
    reduceRedList(type: RedTypeEnum) {
      if (type === RedTypeEnum.Grab) {
        this.redList = this.redList.slice(1, this.redList.length)
      }

      if (type === RedTypeEnum.Open) {
        this.avtiveRedList = this.avtiveRedList.slice(
          1,
          this.avtiveRedList.length
        )
        // 当无开红包数量且开红包未完成
        if (!this.isOpenPocketFinish && !this.getAvtiveRedList.length) {
          this.isOpenPocketFinish = true
          this.doOpenPocketsCallBack()
        }
      }

      // 送红包
      if (type === RedTypeEnum.Send) {
        this.sendList = this.sendList.slice(1, this.sendList.length)
        // 当无开红包数量且开红包未完成
        if (!this.isSendPocketFinish && !this.getSendList.length) {
          this.isSendPocketFinish = true
          this.doOpenPocketsCallBack()
        }
      }
    },

    /**
     * 1. 超过用户的金额限额
     * 2. 被抢的红包
     * 3. 有领取限制的红包，如打码/充值/领取限制
     * web进行关闭，防止重复出开红包动效
     */
    updateCloseRedList(activeId: number | string) {
      this.closeRedList = [...new Set([...this.closeRedList, activeId])]
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.redPocket',
        storage: createMyPersistedStorage(),
        paths: ['redPocketClose', 'redPocketHandleClose']
      }
    ]
  }
})
