import { UserInfos } from '@/api/common/type'
import { UserLevelEnum } from '@/api/event/type'
import {
  YuebaoGetSettingResponse,
  YuebaoIndexResponse
} from '@/api/yuebao/type'
import { apiGetYuebaoIndex, apiGetYuebaoSwitchStatus } from '@/api/yuebao/index'
import { defineStore } from 'pinia'
import { useMainStore } from '@/store/index'
import to from 'await-to-js'

type State = {
  resData: Partial<YuebaoIndexResponse>
  switchData: Partial<YuebaoGetSettingResponse>
  isUpdateProfixData: boolean
  ruleText: string
  axiosError: {
    yuebaoInfo: Error | null
  }
  loadingStatus: {
    yuebaoInfo: boolean
  }
}

const useYuebaoStore = defineStore('yuebao/index', {
  state: (): State => {
    return {
      resData: {},
      switchData: {},
      isUpdateProfixData: false,
      ruleText: '',
      axiosError: {
        yuebaoInfo: null
      },
      loadingStatus: {
        yuebaoInfo: false
      }
    }
  },
  actions: {
    /**
     * @description 余额宝数据
     */
    async request(cache?: number) {
      const { userInfos, useFastLogin, isDemoMode } = useMainStore()
      if (!useFastLogin || isDemoMode) return
      this.loadingStatus.yuebaoInfo = true
      const [err, data] = await to(apiGetYuebaoIndex(cache))
      this.loadingStatus.yuebaoInfo = false
      this.axiosError.yuebaoInfo = err
      if (err) {
        return
      }
      this.ruleText = data?.data?.data?.ruleText ?? ''
      this.resData = data?.data?.data as YuebaoIndexResponse
      const { game_gold } = userInfos as UserInfos
      // 如果金额不一致，同步一次刷新
      if (
        data?.data?.data?.gameGold !== undefined &&
        game_gold !== data?.data?.data?.gameGold
      ) {
        useMainStore().updatePrize()
      }
    },
    /**
     * 查询余额宝开关 游客才需要调这个接口，会员用上面那个apiGetYuebaoIndex可以同时返回开关状态和余额值等详细信息
     */
    async requestYuebaoSwitch() {
      const { useFastLogin } = useMainStore()
      if (useFastLogin) {
        return
      }
      this.loadingStatus.yuebaoInfo = true
      const [err, data] = await to(apiGetYuebaoSwitchStatus())
      this.loadingStatus.yuebaoInfo = false
      this.axiosError.yuebaoInfo = err
      if (err) {
        return
      }
      this.ruleText = data?.data?.data?.ruleText ?? ''
      this.resData = {
        yearRate:
          (data?.data?.data?.yearRate && Number(data?.data?.data.yearRate)) ||
          0,
        interestTop: data?.data?.data?.interestTop ?? 0,
        isPop: data?.data?.data?.isPop ?? 0
      }
    },
    updateSwitchData(data: object) {
      const { useFastLogin } = useMainStore()
      if (useFastLogin) {
        return
      }
      this.switchData = data
    },
    /**
     * 利息宝记录明细数据
     */
    updateProfixData(isUpdate = false) {
      this.isUpdateProfixData = isUpdate
    }
  },
  getters: {
    /**
     * 获取
     */
    getYuebaoData(state) {
      return state.resData
    },
    /**判断利息宝（原余额宝）开关是否隐藏*/
    getYuebaoSwitchIsHide(state): boolean {
      const { useFastLogin, userInfos, yueBaoConfig } = useMainStore()
      if (useFastLogin) {
        let isHide = true
        // 查看会员层级 若会员在层级里则展示（userLevels等于'0'时则为所有层级）
        const meberLevel = userInfos?.member_level?.toString() ?? ''
        const currency = userInfos?.currency
        if (yueBaoConfig && currency) {
          Object.keys(yueBaoConfig)?.forEach((key: string) => {
            if (key === currency) {
              const userLevels = yueBaoConfig[key]?.userLevels
              // userLevels为'0'时所有层级
              isHide =
                userLevels === UserLevelEnum.All
                  ? false
                  : !userLevels?.includes(meberLevel)
            }
          })
        }
        return isHide
      } else {
        if (!userInfos?.currency) return true
        if (!state.switchData) return true

        //游客根据后台开关判断是否隐藏
        const isContent =
          state.switchData.currency == 'ALL' ||
          state.switchData.currency?.includes(userInfos.currency)
        if (state.switchData?.switchStatus && isContent) {
          return false
        } else {
          return true
        }
      }
    }
  }
})

export default useYuebaoStore
