import { CustomPlatformId } from '@/views/game/consts/platforms'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { JumpPosition, JumpPositionType } from '@/api/aside/type'
import { RouteName } from '@/router/config'
import {
  getApiCodeByJackPotCode,
  useTaskPageStore
} from '@/views/task/store/taskPageStore'
import { goEventDetail } from '@/views/event/item/utils'
import { useGameStore } from '@/views/game/store'
import { useMainStore } from '@/store/index'
import { windowOpen } from '@/utils/business-utils'
import router from '@/router'

export default class _GameNavigation {
  /**
   * 跳转游戏二级页面
   * @param {stirng | numer} gameCategoryId  - 游戏分类ID。
   * @param {stirng | numer} platformId  - 游戏平台ID。
   */
  public static goSubGamePage(
    gameCategoryId: string | number,
    platformId?: string | number
  ): void {
    const { setActiveGameCategoryId } = useGameStore()

    const categoryId = Number(gameCategoryId) // 将游戏分类ID转换为数字类型

    // 平台ID
    const finalPlatformId = platformId
      ? platformId.toString()
      : categoryId.toString()

    setActiveGameCategoryId(categoryId)
    // 跳转到子游戏页面
    router.push({
      name: RouteName.SUB_GAME,
      query: {
        gameCategoryId: categoryId.toString(),
        platformId: finalPlatformId
      }
    })
  }

  /** 跳转游戏二级热门页面 */
  public static goSubGameHotPage(): void {
    _GameNavigation.goSubGamePage(GameCategoryIds.Hot)
  }

  /** 跳转游戏二级收藏页面 */
  public static goSubGameCollenctPage(): void {
    _GameNavigation.goSubGamePage(GameCategoryIds.Collenct)
  }

  /** 跳转游戏二级最近页面 */
  public static goSubGameRecentPage(): void {
    _GameNavigation.goSubGamePage(GameCategoryIds.Recent)
  }

  /**
   * 跳转首页
   * @param {GameCategoryIds} gameCategoryId  - 游戏分类ID。
   */
  public static goHomePage(gameCategoryId = GameCategoryIds.Hot): void {
    const { setActiveGameCategoryId } = useGameStore()
    setActiveGameCategoryId(gameCategoryId)
    router.push({
      name: RouteName.GAME,
      query: {
        gameCategoryId: gameCategoryId.toString()
      }
    })
  }

  /** 跳转搜索游戏页面 */
  public static goSearchGamePage(key = GameCategoryIds.Hot): void {
    router.push({
      name: RouteName.GAME_SEARCH,
      query: { activeTabkey: key.toString() }
    })
  }

  /** 跳转游戏二级试玩页面 */
  public static goSubGameDemoPage(): void {
    const { hasLogined } = useMainStore()
    if (hasLogined) {
      return
    }

    _GameNavigation.goSubGamePage(
      GameCategoryIds.Demo,
      `${GameCategoryIds.Demo}${CustomPlatformId}`
    )
  }

  /** 跳转自定义配置外链 */
  private static _openExternalLink(url: string): void {
    // 如果 URL 不含协议，添加默认的 https 协议
    const httpsUrl = url.includes('://') ? url : 'https://' + url
    windowOpen(httpsUrl)
  }

  /**
   * 根据配置跳转活动、任务、二级菜单、外链
   * @param jumpPosition - 跳转配置
   * @param externalLinks - 外链
   */
  public static jumpCommon(
    jumpPosition?: JumpPosition,
    externalLinks?: string,
    showError = false
  ) {
    if (externalLinks) {
      this._openExternalLink(externalLinks)
      return
    }
    const { type, value, value2 } = jumpPosition || {}
    switch (type) {
      case JumpPositionType.Platform:
        if (value && value2) {
          _GameNavigation.goSubGamePage(value, value2)
        }
        break

      // 跳转活动
      case JumpPositionType.Event:
        value && goEventDetail(Number(value), showError)
        break

      // 跳转任务
      case JumpPositionType.Task:
        const apiCode = getApiCodeByJackPotCode(Number(value))
        if (apiCode) {
          useTaskPageStore().gotoTaskPage(apiCode)
        }
        break
    }
  }
}
