import { RiskSmsVerifyScene } from '@/api/common/type'
enum BaseScene {
  /**
   * 短信登录验证
   */
  LOGIN_SMS_VERIFY = 'login_sms_verify'
}
export const VerifyScene = {
  ...BaseScene,
  ...RiskSmsVerifyScene
}

export type VerifyScene = BaseScene | RiskSmsVerifyScene
