import { Base, Component } from '@/vue-property-decorator'
import ComponentsManager, {
  ConfigType,
  ThemeComponentType
} from '@/context/components-manager'
import Skin from '@/controller/Skin'

@Component<GlobalStyles>({
  name: 'GlobalStyles',
  components: (() => {
    return {
      ...ComponentsManager.register({
        namespace: ConfigType.Theme,
        moduleType: ThemeComponentType.Styles
      })
    }
  })()
})
export default class GlobalStyles extends Base {
  private get renderStylesName() {
    return ComponentsManager.getName({
      namespace: ConfigType.Theme,
      moduleType: ThemeComponentType.Styles,
      key: Skin.skinType
    })
  }
  render() {
    return this.renderStylesName ? (
      <this.renderStylesName
        {...{
          attrs: {
            'data-components-name': this.renderStylesName
          }
        }}
      />
    ) : (
      <div></div>
    )
  }
}

export { default as EmptyStyles } from '@/components/my-empty'
