import {
  AfterRechargeActivityItem,
  ApiEventCategory,
  ApiEventGet,
  ApiEventItem
} from '@/api/event/type'
import {
  CutOnceAnnouncementItem,
  MyLogsDataItem,
  UserOptTypeEnum
} from '@/api/event/cutOnce/type'
import { EventDiscountsRedDot } from '@/api/event/apiEventAndDiscountsRedDot/apiEventAndDiscountsRedDot'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '@/views/global-modal/modal/common/util/judgePopupType'

export interface CutOnceCacheItem extends ApiEventGet {
  /** 缓存用户名 */
  username?: string
  /** 语言 */
  lang?: string
}

export interface EventState extends CutOnceState {
  /** 充值后弹窗的列表数据 */
  afterRechargeActivityList: AfterRechargeActivityItem[]
  /** 展示保存桌面快捷的引导*/
  showSaveShortCutGuide: boolean
  /** 活动分类 */
  categories: ApiEventCategory[]
  /** 活动列表数据 */
  activeList: ApiEventItem[]
  /** 活动小红点消息统计 */
  eventRedDot: EventDiscountsRedDot | null
  /** 自定义的活动数据 */
  customizeEvents: Record<number, ApiEventGet>
  /** 当前活动大类 */
  currentCategoryId: number
  /** 当前活动位置 */
  scrollTop: number
  /** 当前活动索引 */
  currentActiveIndex: number
  /**  在h5模式下的活动元素高度，用于定位 */
  offsetHeight?: number
  isUpdate: boolean
  detailLoading: boolean // 详情页查询中
  showBottom: boolean // 是否展示移动端底部按钮区域
  firstLoaded: boolean // 是否 在页面初始化后请求过
  eventItemTitle: string // 游戏详情标题
  /** 集字抽卡次数 */
  collectWordsTimes: number
  /**保存快捷方式领取奖励的UUID */
  saveUrlUuid: string
  /**已经将保存桌面快捷方式的address地址保存到了后端 */
  hasAddSaveAddress: boolean
  /** 刷新中 */
  refreshing: boolean
  /** 网络超时 */
  isTimeoutError: boolean
}

/** 砍一刀类型 */
export interface CutOnceState {
  /** 砍一刀活动数据 */
  cutOnceData: ApiEventGet
  /** 砍一刀抽奖次数 */
  cutOnceTurnCount: number
  /** 活动id */
  activeId?: number
  /** 砍一刀活动公告列表 */
  cutOnceAnnouncement: CutOnceAnnouncementItem[]
  /** 砍一刀活动记录id */
  cutOnceRecordId: number
  /** 砍一刀活动规则 */
  cutOnceRuleContent: string
  /** 砍一刀抽奖记录列表 */
  cutOnceRecordList: MyLogsDataItem[]
  /** 砍一刀活动领取状态 */
  cutOnceReceiveStatus: boolean
  cutOnceCacheList: CutOnceCacheItem[]
  cutOnceSubTaskUserOptType: UserOptTypeEnum
  /**砍一刀登录前弹窗类型 */
  cutOnceBeforeLoginPopType: PopTypeBeforeLogin
  /** 砍一刀登录后弹窗类型 */
  cutOnceAfterLoginPopType: PopTypeAfterLogin
  /** 砍一刀弹窗方式：每日弹窗一次 */
  isCutOnceHaveOpenedToday: boolean
  /** 砍一刀弹窗方式：只弹窗一次 */
  isCutOnceHaveOpenedEver: boolean
  /**砍一刀首页弹窗复选框开关：本次不再显示弹窗 */
  isCutOnceThisTimeNoRemind: boolean
  /**砍一刀首页弹窗复选框开关：永不提示 */
  isCutOnceNeverPop: boolean
  /** 记录下方的tab */
  cutOnceTabsActive: number
  /** 记录抽中的奖项index */
  rotateIdxs?: RotateIdxItem[]
  /** 网络超时 */
  isTimeoutError: boolean
  /** 数据查询中 */
  isLoading: boolean
}

export interface RotateIdxItem {
  /** 用户名 */
  username: string
  /** 活动id */
  activeId: number
  /** 抽中的项 */
  rotateDegIdx: number
}

export interface categorieMap {
  categoryId: ApiEventCategory[]
}

/**
 * 红包类型
 */

export enum RedPackTypeEnum {
  // 开红包
  Open = 1,
  // 抢红包，输入密码
  Grab = 2,
  // 送红包
  Send = 3
}

//展示活动设备类型
export enum ClientDeviceTypeEnum {
  IOS = 1,
  ANDROID = 2,
  H5 = 3,
  PC = 4
}
