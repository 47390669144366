import { GoubaoErrorCode } from './type'
import { OnlinePayData } from '@/api/finance/type'
import { defineStore } from 'pinia'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import DateUtils from '@/utils/DateUtils'
import FinanceUtils from '@/utils/business-utils/finance'
import Modal from '@/controller/Modal'
import axios from 'axios'
import useFinancePayStore from '.'

interface State {
  code: string | number
  step: 0 | 1 | 2 | 3
  qrcodeContent: string
  qrcodeUrl: string
  qrcodeCountdown: number
  smsCountdown: number
  qrCodeTimer: NodeJS.Timer | null
  smsTimer: NodeJS.Timer | null
  loading: {
    getUserInfo: boolean
    bind: boolean
    refreshBind: boolean
    getMsgCode: boolean
    verifyCode: boolean
  }
}

// 二维码倒计时时间 五分钟
const QRCODE_COUNTDOWN = 5 * 60
// 短信验证码倒计时 一分钟
const SMS_COUNTDOWN = 60

const useGoubaoPayStore = defineStore('finance/goubaoPay', {
  state: (): State => {
    return {
      /**
       * 错误码
       */
      code: '',
      /**
       * 步骤
       */
      step: 0,

      /**
       * 二维码内容
       */
      qrcodeContent: '',

      /**
       * 二维码图片地址
       */
      qrcodeUrl: '',

      /**
       * 短信验证码倒计时
       */
      smsCountdown: 0,
      smsTimer: null,

      /**
       * 二维码新鲜度倒计时
       */
      qrcodeCountdown: QRCODE_COUNTDOWN,
      qrCodeTimer: null,

      /**
       * loading 状态
       */
      loading: {
        getUserInfo: false,
        bind: false,
        refreshBind: false,
        getMsgCode: false,
        verifyCode: false
      }
    }
  },

  getters: {
    isBound(state) {
      return state.code === GoubaoErrorCode.Ok
    }
  },

  actions: {
    updateStep(step: State['step']) {
      this.step = step
      if (step === 1) {
        this.startQrcodeCountdown()
      } else {
        this.clearQrcodeCountdown()
      }
    },

    /**
     * 开始二维码倒计时
     */
    startQrcodeCountdown() {
      this.clearQrcodeCountdown()

      this.qrcodeCountdown = QRCODE_COUNTDOWN
      this.qrCodeTimer = setInterval(() => {
        if (--this.qrcodeCountdown < 0) {
          this.clearQrcodeCountdown()
        }
      }, 1000)
    },

    /**
     * 清除倒计时
     */
    clearQrcodeCountdown() {
      if (this.qrCodeTimer) clearInterval(this.qrCodeTimer)
    },

    /**
     * 开始短信验证码倒计时
     */
    startSmsCountdown() {
      this.clearSmsCountdown()

      this.smsCountdown = SMS_COUNTDOWN
      this.smsTimer = setInterval(() => {
        if (--this.smsCountdown < 0) {
          this.clearSmsCountdown()
        }
      }, 1000)
    },

    clearSmsCountdown() {
      if (this.smsTimer) clearInterval(this.smsTimer)
    },

    // 在线充值 - 获取购宝钱包余额
    async getWalletBalance() {
      this.request('/pay/wallet/goubao/getUserInfo')
    },

    // 绑定购宝钱包账号 获取二维码
    async bindWallet() {
      this.request('/pay/wallet/goubao/bind', undefined, {
        code: 0,
        callback: (res: Record<string, string>) => {
          this.updateStep(1)
          this.qrcodeContent = res.qrCode
        }
      })
    },

    // 购宝刷新绑定结果
    async refreshBind() {
      this.request('/pay/wallet/goubao/refreshBind')
    },

    // 购宝钱包 发送验证码
    async sendMsgCode() {
      if (this.loading.getMsgCode || this.smsCountdown > 0) {
        return
      }
      this.request('/pay/wallet/goubao/getMsgCode', undefined, {
        code: 0,
        callback: () => {
          const { t } = useI18n()
          Modal.message({
            type: 'success',
            content: t('lobby.common.components.auth.phone.sendSuccess')
          })
          this.startSmsCountdown()
        }
      })
    },

    // 确认验证码，进行账号授权
    async verifyCode(phoneCode: string) {
      this.request('/pay/wallet/goubao/verifyCode', { phoneCode })
    },

    /**
     * 基础请求方法
     * @param path 请求路径
     * @param data 请求额外参数
     * @param checkCodeMap 需要额的检测的code和callback
     */
    async request(
      path: string,
      data: Record<string, string> = {},
      checkCodeMap?: {
        code: string | number
        callback: (res: Record<string, string>) => void
      }
    ) {
      const store = useFinancePayStore()
      const channel = store.activePlatformMethod as OnlinePayData
      const url = store.payUrl
      const { t } = useI18n()
      if (!url) {
        Modal.message({
          type: 'error',
          content: t('lobby.modal.pay.payDomainErrorToast')
        })
        return
      }

      const { siteCode, userInfos } = useMainStore()
      const doMain = `${url && new URL(url).origin}` + path

      const payload = {
        merchId: String(channel?.merch_agent_id),
        siteCode,
        time: DateUtils.timestamp(),
        userId: String(userInfos?.username),
        ...data
      }

      const sign = FinanceUtils.getWalletSign(payload, store.signKey ?? '')

      const name = path.split('/').pop() as keyof State['loading']
      try {
        this.loading[name] = true
        const res = await axios.post(doMain, {
          ...payload,
          sign
        })
        this.loading[name] = false

        const data = res?.data
        if (data?.success) {
          const code = data.data.errorCode
          this.code = code
          if (code === checkCodeMap?.code) {
            checkCodeMap?.callback(
              data.data as unknown as Record<string, string>
            )
          } else if (code === GoubaoErrorCode.NotBind) {
            this.updateStep(0)
          } else if (code === GoubaoErrorCode.Unauthorized) {
            this.updateStep(2)
          } else if (code === GoubaoErrorCode.Ok) {
            this.updateStep(3)
          } else {
            throw new Error(data.data.msg)
          }
        } else {
          throw new Error(data.msg)
        }
      } catch (err) {
        this.loading[name] = false
        Modal.message({
          type: 'error',
          content: (err as Error).message
        })
      }
    }
  }
})

export default useGoubaoPayStore
