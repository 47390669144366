export const ChannelStatusList = {
  /**打开状态1 */
  Open: 1,
  /**关闭状态0 */
  Close: 0
}

/**满足展示App提示条件的用户 */
export const UserDisplayList = {
  /**所以用户都满足 */
  AllUser: 0,
  /**仅限充值次数大于几次的用户*/
  RechargeUser: 1
}

/**展示下载内容：0 H5，1 APP */
export const ViewDownloadList = {
  H5: 0,
  APP: 1
}

/**渠道类型列表 */
export const ChannelTypeList = {
  /**Pwa2App:4 */
  Pwa2App: 4,

  /**渠道链接:0 */
  ChannelLink: 0
}

/** iOS备用下载地址类型0:系统,1:自定义 */
export const IosBackupLinkType = {
  /**系统 */
  System: 0,
  /**自定义 */
  Custom: 1
}

/** 安卓备用下载地址类型0:系统,1:自定义 */
export const AndroidBackupLinkType = {
  /**系统 */
  System: 0,
  /**自定义 */
  Custom: 1
}

/**获取条件 0:不限制，1:首充 */
export const RewardsCondition = {
  /**不限制，1 */
  All: 0,
  /**首充 */
  FirstDeposit: 1
}

/**进入后是否隐藏域名,0:否,1:是 */
export const IsHideDomain = {
  /**否 */
  Not: 0,
  /**Yes */
  Yes: 1
}
