import { AfterRechargeActivityItem } from '@/api/event/type'
import { Component } from '@/vue-property-decorator'
import { GlobalManager } from '@/context'
import { useEventStore } from '@/views/event/store'
import { useI18n } from '@/i18n'
import AfterRechargeActivityTabItem from './tabItem'
import BusinessUtils from '@/utils/business-utils'
import ModalBase from '@/mixins/business/modal/modal-base'
import ScrollTabBar from '@/components/scroll-tab-bar'
import autoModalManager from '@/context/autoModalManager/index'
import style from './style.module.scss'

type State = {
  /** 当前选中tab */
  activeTab: number | string
}

export const openAfterRechargeActivityModal = async () => {
  const { getAfterRechargeActivityList } = useEventStore()
  // console.log('xxxxxxx openAfterRechargeActivityModal')
  autoModalManager.add({
    name: 'afterRechargeActivity',
    load: async () => {
      await getAfterRechargeActivityList()
    },
    show: () => {
      const { afterRechargeActivityList } = useEventStore()
      // console.log(
      //   'xxxxxxx afterRechargeActivity show',
      //   JSON.parse(JSON.stringify(afterRechargeActivityList))
      // )
      if (+afterRechargeActivityList?.length === 0) {
        return false
      }
      GlobalManager.Modal.open('afterRechargeActivity')
      return true
    }
  })
  // await getAfterRechargeActivityList()
  // if (+afterRechargeActivityList?.length !== 0) {
  //   GlobalManager.Modal.open('afterRechargeActivity')
  // }
  console.log('xxxxxxx openAfterRechargeActivityModal after', autoModalManager)
}

@Component<AfterRechargeActivity>({ name: 'AfterRechargeActivity' })
export default class AfterRechargeActivity extends ModalBase<State> {
  state: State = {
    activeTab: 0
  }

  /** 选中数据 */
  private get activeData() {
    const eventItem = this.cateIdToActivity.find(
      (i) => +i.id === +this.state.activeTab
    )
    if (eventItem) {
      return eventItem as unknown as AfterRechargeActivityItem
    }
    return {} as AfterRechargeActivityItem
  }

  /**
   * tab切换 的列表数据
   */
  private get cateIdToActivity() {
    const { afterRechargeActivityList } = useEventStore()
    return afterRechargeActivityList
  }

  /**
   * tab切换列表的时候
   */
  private tabChange(activeTab: string) {
    // console.log('xxxxx tabChange')
    if (!activeTab) {
      return
    }
    // 设置key
    this.setState({
      activeTab
    })
    return
  }

  async open() {
    if (this.cateIdToActivity?.length) {
      this.baseOpen()
      this.tabChange(this.cateIdToActivity?.[0]?.id as unknown as string)
    }
  }

  async close() {
    autoModalManager.onModalClose('afterRechargeActivity')
    this.baseClose()
  }

  render() {
    const { t } = useI18n()
    return this.renderModal({
      modalConfig: {
        props: {
          title:
            this.cateIdToActivity.length === 1
              ? this.activeData.name
              : t('lobby.modal.event.payAfter'),
          closable: true,
          width: BusinessUtils.px2rem(710),
          wrapClassName: style.main
        }
      },
      renderContent: () => {
        // console.log(
        //   'xxxxxx cateIdToActivity',
        //   this.event.template === ActivityTemplate.DiscountCode &&
        //     this.activeData.activeType ===
        //       AfterRechargeActivityTypeEnum.CommonActivity,
        //   JSON.parse(JSON.stringify(this.cateIdToActivity))
        // )
        const { activeTab } = this.state
        return (
          <div class="event-modal-content">
            <ScrollTabBar
              value={this.state.activeTab}
              onInput={(val) => this.tabChange(val as unknown as string)}
              tabList={this.cateIdToActivity.map((i) => ({
                label: i.name,
                value: i.id as number
              }))}
              v-show={this.cateIdToActivity.length > 1}
            />
            {this.cateIdToActivity.map((i) => (
              <div class="item" key={i.id} cate-id={i.id}>
                {i.id && activeTab && String(activeTab) === String(i.id) && (
                  <AfterRechargeActivityTabItem activeTab={i.id} />
                )}
              </div>
            ))}
          </div>
        )
      }
    })
  }
}
