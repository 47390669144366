import { AnchorHotInter } from '@/service/game/entity/anchorHot'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { PlatformCategoriesItemInter } from '@/service/game/entity/gameCategorie'
import { getCurrentDevice } from '@/utils/Device'
import { osType } from '../consts'
import base64js from 'base64-js'

export const getGameOsType = (): osType => {
  if (getCurrentDevice().ios()) {
    return osType.IOS
  }
  if (getCurrentDevice().android()) {
    return osType.Android
  }
  return osType.PC
}

/** 声明getNeetArr函数的入参类型 */
type GetNeedArrParam =
  | Array<GameItemInter | AnchorHotInter>
  | Array<PlatformCategoriesItemInter>
/** 根据入参,给到相应的返回值 */
type GetNeedArrRet<T extends GetNeedArrParam> = T extends infer Inter
  ? Array<Inter>
  : Array<T>
/** 前端处理分页 */
export const getNeedArr = <TArr extends GetNeedArrParam>(
  array: TArr,
  size: number
) => {
  const length = array.length
  //判断不是数组，或者size没有设置，size小于1，就返回空数组
  if (!length || !size || size < 1) {
    return []
  }
  let index = 0 //用来表示切割元素的范围start
  let resIndex = 0 //用来递增表示输出数组的下标
  //根据length和size算出输出数组的长度，并且创建它。
  const result = new Array(Math.ceil(length / size)) as GetNeedArrRet<TArr>
  //进行循环
  while (index < length) {
    //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
    const endIndex = index + size
    result[resIndex++] = array.slice(index, endIndex)
    index = endIndex
  }
  //输出到新数组
  return result
}

export const isHtml = (str: string) => /<html[^>]*>([\s\S]*?)<\/html>/.test(str)

export const supportsSrcdoc = () => 'srcdoc' in document.createElement('iframe')

export const htmlToBase64 = (html: string) => {
  if (!isHtml(html)) {
    return
  }
  const encoder = new TextEncoder()
  const uint8Array = encoder.encode(html)
  return base64js.fromByteArray(uint8Array)
}
