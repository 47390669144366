import {
  ActivityTemplate,
  ApiEventLuckyBet,
  CategoryPlatformGame
} from '@/api/event/type'
import { AnchorHotInter } from '@/service/game/entity/anchorHot'
import { CategoriesPayload, HomeCategories, PlatformCatgoryType } from './type'
import {
  ClubOrFriend,
  GameCategoryIds,
  GamePlatformCatgory
} from '../consts/categorys'
import {
  GameCategoriesInfo,
  PlatformCategoriesItemInter
} from '@/service/game/entity/gameCategorie'
import { GameIconStyle } from '@/api/common/type'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { GameRepository } from '@/service/game/repository/game'
import { GameState, initState } from './initState'
import {
  GetFilteredGameList,
  proxyGetFilteredGameList
} from './proxyGetFilteredGameList'
import { GetGameListByCategoryAndPlatformParams } from './initState/type'
import { GetPlatformGameListParams } from '@/service/game/repository/type'
import { HotGame } from '@/api/game/type'
import { SelfOpertedId } from '../consts/platforms'
import { SelfSportId } from '@/views/selfoperated-games/sports/const'
import { SupportSelfSportLayoutList } from '@/views/game/store/type'
import { _GameItemInter } from '@/service/game/entity/gameItem/gameItemInter'
import { apiGetByTemplate } from '@/api/event'
import {
  apiGetGameCategorieExtLink,
  apiGetPlatformExchangeList
} from '@/api/game'
import { defineStore } from 'pinia'
import { get, set, setWith, uniqBy } from 'lodash'
import { getPathForGameList } from './util'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils, { createMyPersistedStorage } from '@/utils/business-utils'
import HallCustomization from '@/controller/hallCustomization'

import { AxiosError } from 'axios'
import WS, { LongConnectionEvent } from '@/controller/LongConnection'
import _GameItem from '@/service/game/entity/gameItem/gameItem'
import to from 'await-to-js'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
export const useGameStore = defineStore('game', {
  state: initState,
  getters: {
    /** 当前板式是否支持WG自营体育*/
    isSupportSelfSportLayout() {
      const { siteInfos } = useMainStore()
      const type = siteInfos?.type as number
      return Object.values(SupportSelfSportLayoutList).includes(type)
    },
    /**获取自营体育的维护状态 */
    getSelfSportMaintainStatus() {
      let isMaintainStatus = false
      this.gameCategoriesAvailable.forEach((item) => {
        if (item.gameCategoryId === GameCategoryIds.Sports) {
          const selfSport = item.list.find(
            (platformCategory) => platformCategory.gameId === SelfSportId
          )
          // WG体育平台开关被关了
          if (selfSport === undefined) {
            isMaintainStatus = true
          } else {
            // WG体育维护开关被关了
            isMaintainStatus = selfSport?.maintainStatus === true
          }
        }
      })
      return isMaintainStatus
    },
    /**
     */
    /** 游戏类别菜单 */
    gameMenuAvailable(): Array<GameCategoriesInfo> {
      const { t } = useI18n()
      const { gameIconMap, gameIconSelectMap } =
        HallCustomization.useGameShowImage()
      const { hasLogined, sabaMaintenance } = useMainStore()
      const gameCategoriesAvailable = [...this.gameCategoriesAvailable]
      return [
        {
          gameCategoryId: GameCategoryIds.Hot,
          gameCategoryName: `${t('lobby.gameMenu.menu0')}`,
          customizeIcon: gameIconMap.get(GameCategoryIds.Hot),
          customizeIconActive: gameIconSelectMap.get(GameCategoryIds.Hot)
        },
        ...gameCategoriesAvailable
          .filter((item) => {
            if (item.gameCategoryId === GameCategoryIds.Demo && hasLogined) {
              return false
            }
            return item.display
          })
          .map((item) => {
            item.gameCategoryName = t(
              `lobby.gameMenu.menu${item.gameCategoryId}`
            ) as string

            item.customizeIcon = gameIconMap.get(item.gameCategoryId)
            item.customizeIconActive = gameIconSelectMap.get(
              item.gameCategoryId
            )

            // 判断自营体育是否处于维护状态
            if (item.gameCategoryId === GameCategoryIds.Sports) {
              item.list.forEach((platformCategory) => {
                if (platformCategory.gameId === SelfSportId) {
                  // 游戏菜单中的自营体育的维护状态是根据sass活态的维护状态和saba的维护状态来展示
                  platformCategory.maintainStatus =
                    platformCategory.maintainStatus || sabaMaintenance
                }
              })
            }
            return item
          })
      ] as unknown as Array<GameCategoriesInfo>
    },
    /** 首页平台类别列表数据（包含行数配置） */
    currentPlatformGameList(): Array<HomeCategories> {
      const { isWeb, homeLayoutInfos } = useMainStore()
      const isVerticalBottom =
        homeLayoutInfos?.webGameIcon === GameIconStyle.VERTICAL_BOTTOM
      const verticalColumns = 3
      return this.gameCategoriesAvailable
        .filter((item) => {
          const squareDataNum = isWeb ? item?.pcSquare : item?.appSquare
          const verticalDataNum = isWeb ? item?.pcVertical : item?.appVertical
          const dataNum = isVerticalBottom ? verticalDataNum : squareDataNum
          // 行数配置为0，首页数据不展示
          if (
            item.display &&
            dataNum &&
            ![
              GameCategoryIds.Club,
              GameCategoryIds.ClubFriend,
              GameCategoryIds.Demo
            ].includes(item.gameCategoryId)
          ) {
            return true
          }
        })
        .map((game) => {
          const squareDataNum = isWeb
            ? 3 * (game?.pcSquare || 2)
            : 1 * (game?.appSquare || 6)
          const verticalDataNum = isWeb
            ? 6 * (game?.pcVertical || 1)
            : verticalColumns * (game?.appVertical || 2)

          const dataNum = isVerticalBottom ? verticalDataNum : squareDataNum
          const gameList = game?.list || []
          return {
            key: game.gameCategoryId,
            listData: gameList ? [...gameList].slice(0, dataNum) : [],
            dataNum,
            totalData: gameList
          }
        })
    },
    /**
     * 获取当前热门游戏列表
     * @param {string} gameCategoryId - 类别id，默认热门返回所有。否则根据游戏类别id过滤对应的游戏数据。
     * @param {boolean} isIgnoreAnchor - 是否忽略主播条件
     * @returns {Array<GameItemInter|AnchorHotInter>} - 当前热门游戏列表
     */
    currentHotGameList:
      (state) =>
      (
        gameCategoryId = GameCategoryIds.Hot,
        isIgnoreAnchor = false
      ): Array<GameItemInter | AnchorHotInter> => {
        const { isDemoMode, homeLayoutInfos } = useMainStore()
        // 获取热门游戏列表数据
        let hotGameList = useGameStore().getGameListByCategoryAndPlatform({
          gameCategoryId: GameCategoryIds.Hot
        }).list

        // 根据游戏类别ID过滤游戏列表数据
        if (gameCategoryId !== GameCategoryIds.Hot) {
          hotGameList = hotGameList.filter(
            (game) => game.gameCategoryId === gameCategoryId
          )
        }

        if (isDemoMode && !isIgnoreAnchor) {
          const isVerticalBottom =
            homeLayoutInfos?.webGameIcon === GameIconStyle.VERTICAL_BOTTOM
          // 根据类型过滤展示
          let anchorHotGameList = state.anchorHotGameList.map((item) => ({
            ...item,
            // 竖版卡片主播热门隐藏游戏名称(暂时用自定义图整图来隐藏游戏名称)
            // gameName: isVerticalBottom ? '' : item.gameName
            customLargeIcon: isVerticalBottom
          }))

          if (gameCategoryId !== GameCategoryIds.Hot) {
            anchorHotGameList = anchorHotGameList.filter(
              (game) => game.gameCategoryId === gameCategoryId
            )
          }
          // 主播热门+热门游戏最多展示100个
          const length = 100 - anchorHotGameList.length
          // 组装主播号游戏和热门游戏数据
          return [...anchorHotGameList, ...hotGameList.slice(0, length)]
        }
        return hotGameList
      },
    /**
     * 获取对应类别平台下的游戏列表，并支持分页
     * @param {GetGameListByCategoryAndPlatformParams} params 获取数据入参配置
     * @returns {Array<GameItemInter>} - 分页后的游戏列表
     */
    getGameListByCategoryAndPlatform:
      (state) =>
      (
        params: GetGameListByCategoryAndPlatformParams
      ): { list: Array<GameItemInter>; total: number } => {
        const { gameCategoryId, platformId, pageIndex, pageSize } = params
        const { isDemoMode } = useMainStore()
        const path = getPathForGameList(gameCategoryId, platformId)

        // 对应路径下的游戏列表
        let gameList: GameItemInter[] = get(
          state.gameCategoryPlatformList,
          path,
          []
        )

        // 主播号只展示支持的游戏列表
        if (isDemoMode) {
          gameList = gameList.filter(
            (filteredItem) => filteredItem.anchorSupport
          )
        }

        // 初始化返回结果
        let result = {
          list: gameList,
          total: gameList.length
        }

        // 判断是否需要分页
        if (pageSize && pageIndex) {
          // 计算起始索引和结束索引
          const startIndex = (pageIndex - 1) * pageSize
          const endIndex = startIndex + pageSize

          // 根据分页信息截取游戏列表
          const paginatedGameList = gameList.slice(startIndex, endIndex)

          result = {
            list: paginatedGameList,
            total: gameList.length
          }
        }

        return result
      },
    /**
     * 获取当前最近游戏列表
     * @param {string} gameCategoryId 类别id，默认热门返回所有。否则根据游戏类别id过滤对应的游戏数据。
     */
    currentRecentGameList:
      (state) =>
      (gameCategoryId = GameCategoryIds.Hot): Array<GameItemInter> => {
        const { isTryMode, language, userInfos } = useMainStore()
        // 试玩账号读本地缓存，否则取接口数据
        const recentGameListData: GameItemInter[] = isTryMode
          ? get(
              state.tryModeData,
              `${language}.${userInfos?.currency}.recentGameListData`
            ) || []
          : useGameStore().getGameListByCategoryAndPlatform({
              gameCategoryId: GameCategoryIds.Recent
            }).list

        const filteredGameList = recentGameListData.filter(
          (game) => game.gameCategoryId === gameCategoryId
        )

        return gameCategoryId === GameCategoryIds.Hot
          ? recentGameListData
          : filteredGameList
      },
    /**
     * 获取当前收藏游戏列表
     * @param {string} gameCategoryId 类别id，默认热门返回所有。否则根据游戏类别id过滤对应的游戏数据。
     */
    currentCollectGameList:
      (state) =>
      (gameCategoryId = GameCategoryIds.Hot): Array<GameItemInter> => {
        const { isTryMode, language, userInfos } = useMainStore()
        // 试玩账号读本地缓存，否则取接口数据
        const collectGameListData = isTryMode
          ? get(
              state.tryModeData,
              `${language}.${userInfos?.currency}.collectGameListData`
            ) || []
          : useGameStore().getGameListByCategoryAndPlatform({
              gameCategoryId: GameCategoryIds.Collenct
            }).list

        const filteredGameList = collectGameListData.filter(
          (game: GameItemInter) => game.gameCategoryId === gameCategoryId
        )

        return gameCategoryId === GameCategoryIds.Hot
          ? collectGameListData
          : filteredGameList
      },
    /**
     * 当前收藏的所有游戏id
     */
    currentAllFavoriteList: (state) => {
      const { isTryMode, language, userInfos } = useMainStore()
      const path = `${language}.${userInfos?.currency}.collectGameListData`
      const collectGameList = get(state.tryModeData, path) || []
      const gameList = isTryMode
        ? // it可能会是null,此处的null是刻意默认给的,避免收藏图标闪一下的场景
          collectGameList.map((it: HotGame) => it?.gameId)
        : state.allFavoriteList
      return gameList
    },
    /**
     * 当前游戏分类平台游戏列表
     */
    currentCategoriesPlatformList:
      (state) =>
      (gameCategoryId: GameCategoryIds): Array<PlatformCategoriesItemInter> => {
        return (
          state.gameCategoriesAvailable.find(
            (game) => game.gameCategoryId === gameCategoryId
          )?.list ?? []
        )
      },
    /**
     * 游戏菜单分类平台分页游戏列表
     */
    currentCategoriesPlatformPageGameList:
      (state) =>
      (
        gameCategoryId: GameCategoryIds,
        platformId: number | string,
        current: number
      ): Array<GameItemInter> => {
        const index = current - 1
        const path = `${gameCategoryId}.platform-${platformId}.[${index}]`
        return get(state.allCategoriesGameList, path) || []
      },
    /** 通过传入[游戏名称]或[游戏ID]匹配对应的游戏 */
    getFilteredGameList:
      (state): GetFilteredGameList =>
      (...params: Any[]): Any =>
        proxyGetFilteredGameList(state, ...params),

    showRidTypes(): number[] {
      return Object.keys(this.gameTypesShowRid)
        .map((key) => this.gameTypesShowRid[key])
        .flat()
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: createMyPersistedStorage(
          {
            getItem(key, storage) {
              /**
               * 如果版式发生变化清除缓存
               */
              if (BusinessUtils.isLayoutChange()) {
                storage.removeItem(key)
              }
              return storage.getItem(key, storage)
            }
          },
          localStorage
        ),
        paths: [
          'gameCategoriesAvailable',
          'gameCategorieExtLink',
          'allFavoriteList',
          'searchHistory',
          'allGameList',
          'anchorHotGameList',
          'allCategoriesGameList',
          'tryModeData',
          'gameCategoryPlatformList',
          'platformExchangeObj'
        ]
      },
      {
        storage: createMyPersistedStorage(null, sessionStorage),
        paths: ['activeGameCategoryId']
      }
    ]
  },
  actions: {
    setGameTypesShowRid(idsInActivity: Record<string, number[]>) {
      Object.keys(idsInActivity).forEach((activityId) => {
        this.gameTypesShowRid[activityId] = idsInActivity[activityId]
      })
    },
    setHasLukyBet(has: boolean) {
      this.hasLuckyBet = has
    },
    setGameParameters(gameParameters: GameState['gameParameters']) {
      this.gameParameters = gameParameters
    },
    setGameListLoading(val: boolean) {
      this.gameListLoading = val
    },
    /** 获取游戏菜单 */
    async fetchGameCategoriesAvailable() {
      const [err, gameCategoriesInfo = []] = await to(
        GameRepository.getGameCategorieList()
      )

      this.gameCategoriesNetError = err as AxiosError
      this.gameCategoriesNetErrorLoading = false

      const { isDemoMode } = useMainStore()
      // 初始化二级页面数据结构
      const mappedCategories = gameCategoriesInfo.map((item) => {
        // 朋友局和俱乐部下架
        if (
          [GameCategoryIds.Hot, ...ClubOrFriend].includes(item.gameCategoryId)
        ) {
          return { ...item, display: false }
        }
        // 当平台类型为体育的时候，需要过滤游戏列表通过板式
        const filteredListByLayout =
          item.gameCategoryId === GameCategoryIds.Sports
            ? this.filteredHotGameListByLayout(item.list)
            : item.list
        if (GamePlatformCatgory.includes(item.gameCategoryId)) {
          this.setGameCategoryPlatformList({
            gameCategoryId: item.gameCategoryId,
            platformId: item.gameCategoryId,
            gameList: filteredListByLayout as PlatformCategoriesItemInter[]
          })
        }
        // 主播号只显示注册的游戏平台
        const filteredList = isDemoMode
          ? filteredListByLayout.filter(
              (filteredItem) => filteredItem.anchorSupport
            )
          : filteredListByLayout
        // 平台列表数量为0, 隐藏对应类别
        const display = filteredListByLayout.length ? item.display : false
        return {
          ...item,
          display,
          list: filteredList as PlatformCategoriesItemInter[]
        }
      }) as GameCategoriesInfo[]
      this.gameCategoriesAvailable = mappedCategories
    },
    /** 获取游戏菜单三方外链 */
    async fetchGameCategorieExtLink() {
      const gameCategoriesExtLinkResponse = await apiGetGameCategorieExtLink()
      this.gameCategorieExtLink = gameCategoriesExtLinkResponse
    },
    /** 获取收藏游戏列表（只包含游戏和平台id）*/
    async fetchFavoriteIdList() {
      const { useFastLogin, hasLogined } = useMainStore()
      // 登录才调用，试玩账号走本地缓存
      if (useFastLogin || hasLogined) {
        const favoriteInfo = await GameRepository.getFavoriteGameIdList()
        this.allFavoriteList = favoriteInfo.rows.map((v) => v.game)
      } else {
        this.setTryModeCollectGameListData(this.currentCollectGameList())
      }
    },
    /** 获取收藏游戏列表 */
    async fetchFavoriteGameList() {
      const { isTryMode } = useMainStore()
      // 试玩账号读本地缓存
      if (isTryMode) {
        return
      }
      const favoriteGameList = await GameRepository.getFavoriteGameList()
      const filteredFavoriteGameList =
        this.filterFavoriteListByLayout(favoriteGameList)
      this.setGameCategoryPlatformList({
        gameCategoryId: GameCategoryIds.Collenct,
        platformId: GameCategoryIds.Collenct,
        gameList: filteredFavoriteGameList
      })
    },
    /** 获取最近游戏列表 */
    async fetchRecentGameList() {
      const { isTryMode } = useMainStore()
      // 试玩账号读本地缓存
      if (isTryMode) {
        return
      }
      const recentGameList = await GameRepository.getRecentGameList()
      const filteredRecentGameList = this.filteredHotGameListByLayout(
        recentGameList
      ) as GameItemInter[]
      this.setGameCategoryPlatformList({
        gameCategoryId: GameCategoryIds.Recent,
        platformId: GameCategoryIds.Recent,
        gameList: filteredRecentGameList
      })
    },
    /** 获取试玩游戏列表 */
    async fetchDemoGameList() {
      const demoGameList = await GameRepository.getDemoGameList()
      this.setGameCategoryPlatformList({
        gameCategoryId: GameCategoryIds.Demo,
        platformId: GameCategoryIds.Demo,
        gameList: demoGameList
      })
    },
    /** 搜索游戏 */
    async searchGameList(gameName: string) {
      // 去除游戏名称前后空格
      const trimmedGameName = gameName.trim()
      if (!trimmedGameName) {
        return
      }
      const searchGameList = await GameRepository.searchGameList(
        trimmedGameName
      )
      this.setAllGameList(searchGameList)
    },
    /** 获取特色子游戏列表 */
    async fetchSpecialGameList() {
      const specialGameList = await GameRepository.getSpecialGameList()
      this.setAllGameList(specialGameList)
    },

    /**过滤收藏列表通过板式 */
    filterFavoriteListByLayout(favoriteList: Array<GameItemInter>) {
      const filteredFavoriteList = favoriteList.filter((favorite) => {
        // 非自营体育直接返回true
        if (favorite.gameId !== SelfSportId) {
          return true
        }
        // 是自营体育，同时是支持的板式
        if (this.isSupportSelfSportLayout) {
          return true
        }
        return false
      })
      return filteredFavoriteList
    },
    /**过滤热门列表通过板式 */
    filteredHotGameListByLayout(
      hotGameList: Array<
        _GameItem | _GameItemInter | PlatformCategoriesItemInter
      >
    ) {
      const filteredHotGameList = hotGameList.filter((hotGame) => {
        // 非自营体育直接返回true
        if (hotGame.gameId !== SelfSportId) {
          return true
        }
        // 是自营体育，同时是支持的板式
        if (this.isSupportSelfSportLayout) {
          return true
        }
        return false
      })
      return filteredHotGameList
    },
    /** 获取热门游戏列表 */
    async fetchHotGameList() {
      const { isDemoMode } = useMainStore()
      const hotGameList = await GameRepository.getHotGameList()
      const filteredHotGameListData = this.filteredHotGameListByLayout(
        hotGameList
      ) as GameItemInter[]
      this.setGameCategoryPlatformList({
        gameCategoryId: GameCategoryIds.Hot,
        platformId: GameCategoryIds.Hot,
        gameList: filteredHotGameListData
      })
      this.setAllGameList(filteredHotGameListData)

      if (isDemoMode) {
        this.fetchAnchorHotList()
      }
    },
    /** 获取主播热门游戏列表 */
    async fetchAnchorHotList() {
      const anchorHotGameList = await GameRepository.getAnchorHotList()
      this.anchorHotGameList = anchorHotGameList
    },
    /** 获取对应类别平台下的游戏列表 */
    async fetchPlatformGameList(params: GetPlatformGameListParams) {
      const { gameCategoryId, platformId } = params
      /** 判断平台是否存在 */
      const judgePlatformIsExist = async () => {
        // 如果当前还没有取过游戏菜单,则优先获取一下游戏菜单项.
        if (this.gameCategoriesAvailable.length === 0) {
          await this.fetchGameCategoriesAvailable()
        }
        const platformList =
          this.gameCategoriesAvailable.find(
            (v) => v.gameCategoryId === gameCategoryId
          )?.list ?? []

        const platformInfo = platformList?.find(
          (v) => v.platformId === platformId
        )
        return Boolean(platformInfo?.platformId)
      }
      // 1.判断平台是否存在
      const isPlatformExist = await judgePlatformIsExist()
      // 2.如果当前平台存在（没有下架）才请求对应平台游戏列表
      if (isPlatformExist) {
        const gameList = await GameRepository.getPlatformGameList(params)
        this.setGameCategoryPlatformList({
          gameCategoryId,
          platformId,
          gameList
        })
        this.setAllGameList(gameList)
      }
    },
    /** 幸运注单 */
    async getByTemplate() {
      const { hasLogined } = useMainStore()
      if (!hasLogined) {
        return
      }
      const findGameTypes = (categoryPlatformGame?: CategoryPlatformGame[]) => {
        if (!categoryPlatformGame?.length) {
          return []
        }
        const gameTypesList: number[] = []
        categoryPlatformGame.forEach((item) => {
          if (
            item?.platformCategoryGame?.some(
              (item) => item.platformId === SelfOpertedId
            )
          ) {
            gameTypesList.push(item.gameCategoryId)
          }
        })
        return [...new Set(gameTypesList)]
      }

      apiGetByTemplate({
        template: ActivityTemplate.LuckyBet.toString()
      }).then((data) => {
        const typesShowRid: Record<string, number[]> = {}
        if (data?.length) {
          this.setHasLukyBet(true)
          data?.forEach((item) => {
            const activeData = (item.activeData || {}) as ApiEventLuckyBet
            const { categoryPlatformGame } = activeData
            typesShowRid[item.id] = findGameTypes(categoryPlatformGame)
          })
        }
        /**
         * 设置是否显示局号
         */
        this.setGameTypesShowRid(typesShowRid)
      })
      WS.ws.on(LongConnectionEvent.ACTIVE_DETAILS, (data) => {
        if (data.template !== ActivityTemplate.LuckyBet) return
        const activeData = (data.activeData || {}) as ApiEventLuckyBet
        const { categoryPlatformGame } = activeData
        this.setGameTypesShowRid({
          [data.id]: findGameTypes(categoryPlatformGame)
        })
      })
    },
    /** 获取三方平台汇率列表 */
    async fetchPlatformExchangeList() {
      const platformExchangeResponse = await apiGetPlatformExchangeList()
      this.platformExchangeObj = platformExchangeResponse
    },
    setGameCategoriesAvailable(
      gameCategoriesAvailable: GameState['gameCategoriesAvailable']
    ) {
      this.gameCategoriesAvailable = gameCategoriesAvailable
    },
    setAllGameList(gameListData: GameState['allGameList'], replaceAll = false) {
      if (replaceAll) {
        this.allGameList = gameListData
      } else {
        this.allGameList = uniqBy(
          [...gameListData, ...this.allGameList],
          'gameId'
        )
      }
    },
    setCategoriesPlatform(
      gameCategoryId: PlatformCatgoryType,
      payload: CategoriesPayload
    ) {
      this.allCategoriesGameList[gameCategoryId] = payload
    },
    setCategoriesPlatformGameList(
      gameCategoryId: GameCategoryIds,
      platformId: number | string,
      current = 1,
      payload: Array<GameItemInter>
    ) {
      const index = current - 1
      const object = { ...this.allCategoriesGameList }
      const path = `${gameCategoryId}.platform-${platformId}.[${index}]`
      this.allCategoriesGameList = set(object, path, payload)
    },
    /** 设置游戏类别和平台游戏列表 */
    setGameCategoryPlatformList(params: {
      gameCategoryId: GameCategoryIds
      platformId?: number
      gameList: GameItemInter[] | PlatformCategoriesItemInter[]
    }) {
      const { gameCategoryId, platformId, gameList } = params
      // 嵌套路径
      const path = getPathForGameList(gameCategoryId, platformId)
      const originData = { ...this.gameCategoryPlatformList }
      // 更新嵌套对象的游戏列表数据
      this.gameCategoryPlatformList = setWith(
        originData,
        path,
        gameList,
        Object
      )
    },
    setSelectGameCategoryId(id: GameState['selectGameCategoryId']) {
      this.selectGameCategoryId = id
    },
    setActiveTabkey(key: GameState['activeTabkey']) {
      this.activeTabkey = key
    },
    setDynamicGameCategoryId(key: GameState['activeTabkey']) {
      this.dynamicGameCategoryId = key
    },
    setAllFavoriteList(list: Array<number>) {
      this.allFavoriteList = list
    },

    setTryModeCollectGameListData(list: Array<GameItemInter>) {
      const { userInfos, language } = useMainStore()
      const path = `${language}.${userInfos?.currency}.collectGameListData`
      const object = { ...this.tryModeData }
      this.tryModeData = setWith(object, path, list, Object)
    },

    setTryModeRecentGameListData(game: GameItemInter) {
      const { userInfos, language } = useMainStore()
      const path = `${language}.${userInfos?.currency}.recentGameListData`
      const object = { ...this.tryModeData }
      const recentGameList = get(object, path) || []
      if (
        recentGameList?.findIndex(
          (it: GameItemInter) => it.gameId === game.gameId
        ) === -1
      ) {
        recentGameList.unshift(game)
        this.tryModeData = setWith(object, path, recentGameList, Object)
      }
    },

    setSearchHistory(data: GameState['searchHistory']) {
      this.searchHistory = data
    },
    setScrollBottom(data: GameState['isScrollBottom']) {
      this.isScrollBottom = data
    },
    setActiveGameCategoryId(id: GameState['activeGameCategoryId']) {
      if (
        Object.values(GameCategoryIds).includes(id) &&
        id !== GameCategoryIds.Normal
      ) {
        this.activeGameCategoryId = id
      }
    },
    setJackPotAmount(data: GameState['jackPotAmount']) {
      this.jackPotAmount = data
    },
    setPreGameEmbeddedParams(data: GameState['preGameEmbeddedParams']) {
      this.preGameEmbeddedParams = data
    },
    setGameScoring(data: GameState['isGameScoring']) {
      this.isGameScoring = data
    },
    setGamePlaying(data: GameState['isGamePlaying']) {
      this.isGamePlaying = data
    },
    setGameOpening(data: GameState['isGameOpening']) {
      this.isGameOpening = data
    },
    setFullScreen(data: GameState['isFullScreen']) {
      this.isFullScreen = data
    },
    setGameLogoutNetError(val: boolean) {
      this.isGameLogoutNetError = val
    }
  }
})
