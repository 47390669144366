import { Currency } from '@/config/currencies.config'
import { createPngSprite } from '@/utils/business-utils/assets'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
// const allCurrencyIcon = createSvgSprite('comm_icon_all_currency')
import style from './style.module.scss'

/**
 * 充值分类说明
 */
export enum RechargePaymentId {
  // 在线充值 (Type = 1)

  UNKNOWN = 0, //(自定义)
  WECHAT = 2, //(微信充值)
  UNION_BANK = 3, //(银联充值)
  BANK = 4, //(银行充值)
  QQ = 8, //(QQ钱包)
  POINT = 10, //(点卡充值)
  BAIDU = 11, //(百度钱包)
  JD = 12, //(京东支付)
  ALIPAY_ONLINE = 15, //(支付宝充值)
  ALIPAY_APP = 17, //(支付宝app)

  //转账 (Type = 2)

  ALIPAY_TRANSFER = 5, //(支付宝转账)
  WECHAT_TRANSFER = 7, //(微信转账)
  BANK_TRANSFER = 9, //(银行卡转账)
  UPI = 24, // UPI

  // 虚拟货币 (Type = 3)

  // 人工充值 (Type = 4)

  CUSTOMER = 1, //(客服充值)
  WECHAT_AGENT = 6, //(微信代理)
  QQ_AGENT = 13, //(QQ代理)
  ALIPAY_AGENT = 16, //(支付宝代理)
  OTHER_BANK = 18 //(银商充值)
}

/** 充值显示图标类型 */
export enum RechargeIconType {
  NUL = 0,
  QQ = 1, // QQ
  WX = 2, // 微信
  ZFB = 3, // 支付宝
  YH = 4, // 银行
  JD = 5, // 京东支付
  DK = 6, // 点卡支付
  BD = 7, // 百度支付
  XNHB = 8, // 虚拟货币支付
  DIY = 9, // 自定义支付
  WALLET = 10, //钱包
  KF = 11, //客服充值
  USDT = 12, // 虚拟货币支付
  PIX = 13, // PIX支付
  UPI = 14 // UPI支付
}

/**
 *
 */
export enum RechargePaymentType {
  NORMAL = 0, // 普通类型
  ZFBAPP = 1, // 支付宝APP类型
  QQDL = 2, // QQ客服
  WXDL = 3, // 微信客服
  ZFBDL = 4, // 支付宝客服
  ZXSF = 5, // 自营(第三方)客服
  WALLET = 6, // 钱包类型
  SIM = 7, // 刮刮卡充值
  ONLINE_BANK = 8, // 网银转账
  NONE_PAY_PAGE = 9 // 无支付页面
}

/** 充值通道合并枚举 */
export enum PayChannelMergeEnum {
  UN_MERGE,
  MERGE
}

/** 是否开启真实姓名验证枚举 */
export enum RealNameSwitchEnum {
  AUTOFILL_EDITOR = 1, // 自动填充可修改
  AUTOFILL_FREEZE, // 自动填充不可修改
  EDITOR // 不自动填充可任意填写
}

/** 是否开启UTR验证枚举 */
export enum UTRSwitchEnum {
  CLOSE = 0,
  NOT_REQUIRED = 1,
  REQUIRED = 2,
  NOT_REQUIRED_LAST4 = 3,
  REQUIRED_LAST4 = 4,
  NOT_REQUIRED_LAST6 = 5,
  REQUIRED_LAST6 = 6
}
/** 是否开启备注验证枚举 */
export enum RmarkSwitchEnum {
  CLOSE = 0,
  NOT_REQUIRED = 1,
  REQUIRED = 2
}
/** 是否开启钱包地址验证枚举 */
export enum PayWalletAddressEnum {
  CLOSE,
  OPEN,
  VALID
}

/** 是否开启手机号验证枚举 */
export enum PayPhoneEnum {
  CLOSE,
  OPEN,
  VALID
}

/** 是否开启邮箱验证枚举 */
export enum PayEmailEnum {
  CLOSE,
  OPEN,
  VALID
}

/** 是否开启验证码枚举 */
export enum CaptchaSwitchEnum {
  CLOSE,
  OPEN
}

/** 是否开启输入框枚举 */
export enum BalanceSwitchEnum {
  CLOSE,
  OPEN
}
/** 是特定三方枚举 */
export enum SpecialCPFSwitchEnum {
  CLOSE,
  OPEN
}
/** 是否开启转账凭证枚举 */
export enum TransferCertificateSwitchEnum {
  CLOSE,
  OPEN,
  VALID
}

/** 客服在线状态枚举 */
export enum OnlineStatusEnum {
  ONLINE = '1',
  OFFLINE = '0'
}

export enum OrderStatusEnum {
  /**
   * 等待付款 (待支付)
   */
  WAIT = 1,
  /**
   *  充值成功 (支付成功)
   */
  SUCCESS = 2,
  /**
   * 充值超时 (支付超时）
   */
  TIMEOUT = 3,
  /**
   * 充值成功 (强制入款，已作废)
   */
  X = 4,
  /**
   *  充值失败 (支付失败)
   */
  FAILED = 5,
  /**
   *  确认中   (补单审核中)
   */
  CONFIRMED = 6,
  /**
   * 待审核   (二次审核中)
   */
  NEED_TOW = 7,
  /**
   * 待审核   (待审核)
   */
  NEED_TODO = 8,
  /**
   * 充值取消
   */
  CANCEL = 9
}

/**
 * 支付方式支持的类型枚举
 */
export enum AppSupportTypeEnum {
  ANDROID = 1,
  IOS = 2,
  H5 = 4,
  WEB = 8,
  ANDROID_IOS = 3,
  ANDROID_H5 = 5,
  WEB_H5 = 12,
  ALL = 15
}

/**
 * 支付通道类型
 */
export enum ChannelTypeEnum {
  OTHER = '',
  WALLET = 'wallet',
  SIM_CARD = 'topUpCard'
}

/**
 * 钱包金额枚举
 */
export enum WalletNoPayAmountType {
  LARGE = 3,
  MIDDLE = 2,
  SMALL = 1
}

enum IconType {
  ZXCZ = 1, //在线充值
  ZZ = 2, //转账
  SZHB = 3, // 数字货币
  RGCZ = 4, //人工充值
  YS = 5 //银商
}

// USDT code
const USDT_CODE = 'USDT'

// 越南盾
const VND_CODE = 'VND'

// 印尼盾
const IDR_CODE = 'IDR'

// 人民币
const CNY_CODE = 'CNY'

export class PayModalConfigManager {
  /**
   * 随机获取一个客服头像
   */
  static getRandomKFIcon() {
    const iconList = [
      'sprite_main_img_cz_kfcz_dp1',
      'sprite_main_img_cz_kfcz_dp2',
      'sprite_main_img_cz_kfcz_dp3'
    ].sort(function () {
      return Math.random() - 0.5
    })
    return createPngSprite(iconList[0])
  }

  /**
   * 获取充值图标
   * @param type
   * @returns
   */
  static getIconByRechargeIconType(type: RechargeIconType) {
    const store = useMainStore()
    const isChina = store.language === 'zh_CN'
    switch (type) {
      case RechargeIconType.NUL:
        return createPngSprite('sprite_main_icon_cz_zdy')
      case RechargeIconType.QQ:
        return createPngSprite('sprite_main_icon_zxsf_qq')
      case RechargeIconType.WX:
        return createPngSprite('sprite_main_icon_zxsf_wx')
      case RechargeIconType.ZFB:
        return createPngSprite('sprite_main_icon_zxsf_zfb')
      case RechargeIconType.YH:
        if (isChina) {
          return createPngSprite('sprite_main_tdrk_icon_union')
        }
        return createPngSprite('sprite_main_tdrk_icon_yhk')
      case RechargeIconType.JD:
        return createPngSprite('sprite_main_tdrk_icon_jd')
      case RechargeIconType.DK:
        return createPngSprite('sprite_main_tdrk_icon_dkcz')
      case RechargeIconType.BD:
        return createPngSprite('sprite_main_tdrk_icon_bdqb')
      case RechargeIconType.XNHB:
        return createPngSprite('sprite_main_tdrk_icon_btc')
      case RechargeIconType.DIY:
        return createPngSprite('sprite_main_icon_cz_zdy')
      case RechargeIconType.WALLET:
        return createPngSprite('sprite_main_tdrk_icon_kjcz')
      case RechargeIconType.KF:
        return PayModalConfigManager.getRandomKFIcon()
      case RechargeIconType.USDT:
        return createPngSprite('sprite_main_tdrk_icon_btc')
      case RechargeIconType.PIX:
        return createPngSprite('sprite_main_tdrk_icon_pix')
      case RechargeIconType.UPI:
        return createPngSprite('sprite_main_tdrk_icon_upi')
      default:
        return createPngSprite('sprite_main_icon_cz_zdy')
    }
  }

  /**
   * 根据币种获取图标
   * @param currency
   * @returns
   */
  static getIconByRechargeCurrencyCode(currency: string) {
    switch (currency) {
      case 'BTC':
        return createPngSprite('sprite_main_tdrk_icon_btc')
      case 'ETH':
        return createPngSprite('sprite_main_tdrk_icon_eth')
      case 'USDT':
        return createPngSprite('sprite_main_tdrk_icon_szhb')
      case 'USDC':
        return createPngSprite('sprite_main_tdrk_icon_usdc')
      default:
        return createPngSprite('sprite_main_tdrk_icon_btc')
    }
  }

  /**
   * 获取订单对应的图标
   * @param type
   * @returns
   */
  static getStatusIconByIconType(type: OrderStatusEnum) {
    switch (type) {
      case OrderStatusEnum.WAIT:
        return createPngSprite('sprite_main_comm_icon_pay_3')
      case OrderStatusEnum.SUCCESS:
        return createPngSprite('sprite_main_comm_icon_pay_1')
      case OrderStatusEnum.TIMEOUT:
        return createPngSprite('sprite_main_comm_icon_pay_4')
      case OrderStatusEnum.FAILED:
        return createPngSprite('sprite_main_comm_icon_pay_2')
      case OrderStatusEnum.CONFIRMED:
        return createPngSprite('sprite_main_comm_icon_pay_3')
      case OrderStatusEnum.NEED_TOW:
        return createPngSprite('sprite_main_comm_icon_pay_3')
      case OrderStatusEnum.NEED_TODO:
        return createPngSprite('sprite_main_comm_icon_pay_3')
      default:
        return createPngSprite('sprite_main_comm_icon_pay_3')
    }
  }

  /**
   * 根据订单状态获取颜色
   * @param status
   * @returns
   */
  static getColor(status?: OrderStatusEnum) {
    let backgroundStyle
    let textStyle
    if (status === OrderStatusEnum.SUCCESS) {
      backgroundStyle = style.success
      textStyle = style.successText
    } else if (
      status === OrderStatusEnum.FAILED ||
      status === OrderStatusEnum.TIMEOUT ||
      status === OrderStatusEnum.CANCEL
    ) {
      backgroundStyle = style.failed
      textStyle = style.failedText
    } else {
      backgroundStyle = style.waring
      textStyle = style.waringText
    }

    return [backgroundStyle, textStyle]
  }

  /**
   * 分页数目
   */
  static OrderRecordDefaultSize = 8

  /**
   * 是否usdt
   * @param code
   * @returns
   */
  static isUSDT(code: string) {
    return code === USDT_CODE
  }

  /**
   * 币种是否为越南盾
   * @param code
   * @returns
   */
  static codeIsVND(code: string) {
    return code === VND_CODE
  }

  /**
   * 币种是否为印尼盾
   * @param code
   * @returns
   */
  static codeIsIDR(code: string) {
    return code === IDR_CODE
  }

  /**
   * 币种是否为人民币
   * @param code
   * @returns
   */
  static codeIsCNY(code: string) {
    return code === CNY_CODE
  }

  /**
   * 根据币种获取当前币种icon
   * @param currency
   * @returns
   */
  static getCurrencyIcon(currency: string, size: 'normal' | 'large') {
    const commonIconMap = Currency.commonIconMap()
    const icon =
      commonIconMap[currency as unknown as keyof typeof commonIconMap]
        ?.NORMAL ?? {}

    let baseScale = 28
    if (size === 'large') {
      baseScale = 28
    } else {
      baseScale = 26
    }
    const scale = baseScale / (icon.width ?? baseScale)
    switch (currency) {
      case 'USDT':
        return <icon-sprite useSkeleton sprite={icon} scale={scale} />
      case 'ETH':
        return <icon-sprite useSkeleton sprite={icon} scale={scale} />
      case 'BTC':
        return <icon-sprite useSkeleton sprite={icon} scale={scale} />
      case '':
        return (
          <icon-sprite
            sprite={createSvgSprite('comm_icon_all_currency')}
            class={style.waringText}
            useSkeleton
            style={{ 'font-size': BusinessUtils.px2rem(`${baseScale}px`) }}
            scale={scale}
          />
        )
      default:
        return <icon-sprite useSkeleton sprite={icon} scale={scale} />
    }
  }

  /**
   * 是否upi充值通道
   * @param paymentId
   * @returns
   */
  static isUPIChannel(paymentId: number) {
    return paymentId === RechargePaymentId.UPI
  }

  // 是否支付宝转账通道
  static isZFBPayChannel(paymentId: number) {
    return paymentId === RechargePaymentId.ALIPAY_TRANSFER
  }

  static getIconByType(type: IconType) {
    const { currentTheme } = useMainStore()

    switch (type) {
      case IconType.ZXCZ:
        return {
          icon: createSvgSprite('icon_cz_zxcz1'),
          iconColor: currentTheme['--theme-profile_icon_3']
        }
      case IconType.ZZ:
        return {
          icon: createSvgSprite('icon_cz_zz'),
          iconColor: currentTheme['--theme-profile_icon_2']
        }
      case IconType.SZHB:
        return {
          icon: createSvgSprite('icon_cz_xnhb'),
          iconColor: currentTheme['--theme-profile_icon_1']
        }
      case IconType.RGCZ:
        return {
          icon: createSvgSprite('icon_cz_rgcz'),
          iconColor: currentTheme['--theme-profile_icon_4']
        }
      case IconType.YS:
        return {
          icon: createSvgSprite('icon_cz_ys'),
          iconColor: currentTheme['--theme-profile_icon_3']
        }
      default:
        return {
          icon: createSvgSprite('icon_cz_zxcz1'),
          iconColor: currentTheme['--theme-profile_icon_3']
        }
    }
  }
}
