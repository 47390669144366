import { JudgePopupTypeTemplate } from './judgePopupTypeTemplate'
import { PopTypeAfterLogin, PopTypeBeforeLogin } from './enum'
import { TaskRuleStatusEnum } from '../../../tasks-modal-dialog/class/TaskRule'
import { getAllTaskRuleStatus } from './util/getAllTaskRuleStatus'
import { useFirstChargeConstantStore } from '../../../first-charge-constant/store'

/** 首充弹窗的判断类型 */
export class JudgePopupTypeFirstCharge extends JudgePopupTypeTemplate {
  protected getStatusList(): TaskRuleStatusEnum[] {
    return getAllTaskRuleStatus()
  }
  protected getIsHaveOpenedToday(): boolean {
    const { isHaveOpenedToday } = useFirstChargeConstantStore()
    return isHaveOpenedToday
  }
  protected getIsHaveOpenedEverMapper(): boolean {
    const { isHaveOpenedEver } = useFirstChargeConstantStore()
    return isHaveOpenedEver
  }
  protected getBeforeLoginType(): PopTypeBeforeLogin {
    const { beforeLoginPopType } = useFirstChargeConstantStore()
    return beforeLoginPopType
  }
  protected getAfterLoginType(): PopTypeAfterLogin {
    const { afterLoginPopType } = useFirstChargeConstantStore()
    return afterLoginPopType
  }
  protected customTypeFun = {
    [PopTypeAfterLogin.constantly]: () => {
      const { isReceivedEver } = useFirstChargeConstantStore()
      // 高频弹窗也是完成后则不弹窗,首充福利永远只能领取一次,从未领取过才弹(任务未完成,任务待领取)
      // 但是此处可能还是有问题了哦.最新的逻辑,根本 没有状态,也不知道是否有弹过.
      return !isReceivedEver
    }
  }
}
