import { ModalIsPop } from '../../api/apiNeverPopTaskModal'
import { TaskCategory } from '../../class/taskCategory'
import { TaskCategoryApiCodeEnum } from '../../api/type'
import { TaskPageStore } from '.'
import { apiGetTaskActivityStatus } from '../../api/apiGetTaskActivityStatus'
import { useMainStore } from '@/store/index'

/** 在弹框的层面上,根据code刷新数据
 * @param store 仓库数据
 * @param apiCode 当前任务code
 */
export const proxyFreshInPopupCategoryDataByCode = async (
  store: TaskPageStore,
  apiCode: TaskCategoryApiCodeEnum
) => {
  const resData = await apiGetTaskActivityStatus(apiCode)
  if (!resData) {
    return
  }
  // 试玩账号,永久不禁止(10月2号:522业主强烈要求,Life和Marshall提出前端做此兼容)
  // PS:在取得数据处修改,而未在判断处修改,是因为,用户本次如何勾选了,永不提示,那至少在他刷新页面以前,不要再重复弹窗了
  // 设置[永久禁用] 当前已登录状态+返回值是禁用状态才禁用,若未登录状态,则永久不禁用
  if (resData.disablePop === ModalIsPop.Disable && useMainStore().hasLogined) {
    store.codeNeverReminderMapper[apiCode] = true
  } else {
    store.codeNeverReminderMapper[apiCode] = false
  }
  store.codePopBefLoginMapper[apiCode] = resData.beforeLoginPopType
  store.codePopAftLoginMapper[apiCode] = resData.afterLoginPopType
  // 每当请求后,重置 若popData不存在,说明后台未开启显示弹框功能
  if (resData?.popData) {
    // 有popData,后台一定未禁用弹框
    store.codeServerDisabledMapper[apiCode] = false
    // 此处接口返回的 resData.popData 一定是已经包装过的 TaskCategory
    const taskCategory: TaskCategory = resData.popData
    // code与类型的映射,code与rule的映射
    store.codeCategoryMapper[apiCode] = taskCategory
    store.codeRulesMapper[apiCode] = taskCategory.rules
  } else {
    // 没有数据,后台有可能禁用了弹框
    store.codeServerDisabledMapper[apiCode] = true
    // 如果未请求到数据,也不重置该store数据，因为tabs中还得使用这些数据
    // store.codeCategoryMapper[apiCode] = new TaskCategory(apiCode)
    // store.codeRulesMapper[apiCode] = []
  }
}
