import { TaskCategory } from '../../class/taskCategory'
import { TaskCategoryApiCodeEnum } from '../../api/type'
import { TaskPageStore } from '.'
import { apiGetTaskActivityInTabs } from '../../api/apiGetTaskActivityInTabs'
import { freshCategoryUsable } from './common/freshCategoryUsable'
import to from 'await-to-js'

/** 在弹框的层面上,根据code刷新数据
 * @param store 仓库数据
 * @param apiCode 当前任务code
 */
export const proxyFreshInTabsCategoryDataByCode = async (
  store: TaskPageStore,
  apiCode: TaskCategoryApiCodeEnum
) => {
  // 如果未刷新过是否可用,则需要刷新一下
  await freshCategoryUsable(store, false)
  /** 分类可用状态mapper */
  const cateUsableMapper = store.categoryUsable.usableMapper
  // 如果该分类是不可用状态,则清空它的内容
  if (!cateUsableMapper[apiCode]) {
    store.codeCategoryMapper[apiCode] = new TaskCategory(apiCode)
    store.codeRulesMapper[apiCode] = []
    return
  }
  // 此处接口返回的值一定是已经包装过的 TaskCategory
  const [err, category] = await to(apiGetTaskActivityInTabs(apiCode))
  // 接口报错了，就显示超时提示
  store.setIsTimeoutError(apiCode, !!err)
  if (err) {
    console.error('task fetch error:', err)
    return
  }
  // code与类型的映射,code与rule的映射
  store.codeCategoryMapper[apiCode] = category
  store.codeRulesMapper[apiCode] = category.rules
}
