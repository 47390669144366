import { FrameBoxInfos } from '@/api/common/type'
import { FrameBoxInfosStorageType } from './types'
import { RouteName } from '@/router/config'

export enum PopupImgStyle {
  IMG_LEFT = 1,
  IMG_RIGHT = 2,
  CUSTOMIZE = 3,
  TEXT_INTRODUCTION = 4
}

export enum JumpType {
  NULL = 1,
  EXTERNAL_URL = 2,
  EVENT = 3,
  TASK = 4,
  RECHARGE = 5,
  CASHBACK = 6,
  PROMOTE = 7,
  VIP = 8,
  CLUB = 9,
  YUEBAO = 10,
  FUND = 11,
  GAME = 12
}

/**
 * 1=每次登陆,2=每日一次,3=每回大厅必弹,4=一次性
 */
export enum FrameType {
  LOGIN = 1,
  DAY = 2,
  HOME = 3,
  ONE = 4
}

export enum FrameSwitchType {
  SHOW = 1,
  HIDDEN = 2
}

export const RouterNameMap = {
  [JumpType.CASHBACK]: RouteName.CASHBACK,
  [JumpType.PROMOTE]: RouteName.PROMOTE,
  [JumpType.VIP]: RouteName.VIP,
  [JumpType.YUEBAO]: RouteName.YUEBAO,
  [JumpType.FUND]: RouteName.RECHARGE_FUND
}
// 筛选需要存储的字段
export const mapFieldLocalStorage = ({
  frameType,
  frame_type,
  not_remind_again,
  not_remind_login,
  storageExpirationTime,
  id
}: FrameBoxInfosStorageType): FrameBoxInfosStorageType => ({
  frameType,
  frame_type,
  not_remind_again,
  not_remind_login,
  storageExpirationTime,
  id
})

export interface OpenOptions {
  frameBoxInfo: FrameBoxInfos
  onClose?: () => void
  onOpen?: (frameBoxInfo: FrameBoxInfos) => void
}
