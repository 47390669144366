import { Component, Mixins } from '@/vue-property-decorator'
import { useMainStore } from '@/store/index'
import BaseMixins from '@/views/promote/components/mixin/index'
import style from '../../style.module.scss'
import useRechargeFundStore from '../../store'

interface RuleText {
  rule_text: string
  title: string
  text: string
}
@Component<RulesDesc>({ name: 'RulesDesc' })
export default class RulesDesc extends Mixins(BaseMixins) {
  private store = useRechargeFundStore()

  get ruleText() {
    const { rechargeSetting } = this.store
    const info = rechargeSetting?.translate_info
    let text: RuleText[] | string
    if (info?.is_system_translate) {
      text = JSON.parse(info.rule_text)
    } else {
      text = rechargeSetting?.translate_info?.rule_text as string
    }
    return text
  }
  get language() {
    return useMainStore().language
  }
  /**
   * 规则
   */
  renderRule() {
    const { rechargeSetting } = this.store
    const textType = rechargeSetting?.translate_info.is_system_translate
    return (
      <div class={style.ruleContent}>
        {textType ? (
          (this.ruleText as RuleText[]).map((item: RuleText, idx: number) => {
            return (
              <div key={idx}>
                <span class={style.ruleTitle}>
                  {idx + 1 + '. ' + item.title + ' '}
                </span>
                {item.text}
              </div>
            )
          })
        ) : (
          <inner-html type="div" text={this.ruleText as string} />
        )}
      </div>
    )
  }

  render() {
    return <section>{this.renderRule()}</section>
  }
}
