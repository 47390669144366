import { ApiGetChannelInfoByIdResponse } from '@/api/channel/type'
import { defineStore } from 'pinia'

export interface ChannelState {
  /**渠道相关信息 */
  channelInfo: ApiGetChannelInfoByIdResponse | null
}

export const useChannelStore = defineStore('channel', {
  state: (): ChannelState => ({
    channelInfo: null
  }),
  actions: {
    setChannelInfo(channelInfo: ApiGetChannelInfoByIdResponse) {
      this.channelInfo = channelInfo
    }
  }
})
