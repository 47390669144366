import {
  ActiveReceiveOne,
  ActivityTemplate,
  ApiActiveReceiveOneResponse,
  ApiEventRescue,
  ReceiveStatusEnum,
  ReceiveTimeRemark,
  ReceiveTimeTypeEnum,
  RewardAniType,
  RuleTypeEnum,
  orderDetail
} from '@/api/event/type'
import { AxiosResponse } from 'axios'
import { GlobalEvents, GlobalManager } from '@/context'
import { OsType } from '@/api/common/type'
import { RouteName } from '@/router/config'
import { TabKey } from '@/layouts/components/tabBar/types'
import { TranslateResult } from 'vue-i18n'
import { apiActiveReceiveOne } from '@/api/event'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { getQueryString } from '@/utils/Tool'
import { intersection, xor } from 'lodash'
import { markScrollType } from '@/router/scroll'
import { toEventItemPage } from '@/utils/Route'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
import { useEventStore } from '../store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import { useTabBarStore } from '@/layouts/components/tabBar/store/index'
import { windowConfig } from '@/utils/window'
import BusinessUtils from '@/utils/business-utils'
import Manager from '@/context/manager'
import Modal from '@/controller/Modal'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import router from '@/router'
import style from './style.module.scss'
import to from 'await-to-js'

const useMapMode = true
export const app = [
  OsType.ANDROID_APP,
  OsType.IOS_APP,
  OsType.ANDROID_H5,
  OsType.IOS_H5,
  OsType.PC_Windows
]

export const transferStrToOsType = (str: string) => {
  const { ANDROID_APP, IOS_APP, PC_Windows, ANDROID_H5, IOS_H5 } = OsType
  switch (str) {
    case String(ANDROID_APP):
      return ANDROID_APP
    case String(IOS_APP):
      return IOS_APP
    case String(PC_Windows):
      return PC_Windows
    case String(ANDROID_H5):
      return ANDROID_H5
    case String(IOS_H5):
    default:
      return IOS_H5
  }
}
export const transferStrArrToOsTypes = (arr: string[]) => {
  const { ANDROID_APP, IOS_APP, PC_Windows, ANDROID_H5, IOS_H5, PC_H5 } = OsType
  // 兼容旧的H5类型，替换为新的IOS_H5 Android_h5
  if (arr.includes(String(PC_H5))) {
    arr = [
      ...new Set(
        arr
          .map((it) =>
            it === String(PC_H5) ? [String(ANDROID_H5), String(IOS_H5)] : it
          )
          .flat()
      )
    ]
  }
  return arr.map((i) => {
    switch (i) {
      case String(ANDROID_APP):
        return ANDROID_APP
      case String(IOS_APP):
        return IOS_APP
      case String(PC_Windows):
        return PC_Windows
      case String(ANDROID_H5):
        return ANDROID_H5
      case String(IOS_H5):
      default:
        return IOS_H5
    }
  })
}

type receiveData = ApiEventRescue | orderDetail

/**
 * 判断是否可以领取
 */

export const receiveDeviceValid = (receiveDeviceType: string) => {
  const splitReceiveDevice = transferStrArrToOsTypes(
    receiveDeviceType?.split(',') ?? []
  )
  // 极速包判断
  if (windowConfig.speedPackageConfig) {
    // 当前设备类型
    const osType = transferStrToOsType(
      windowConfig.speedPackageConfig?.osType?.toString()
    )
    const canWebReceive = splitReceiveDevice.includes(osType)
    if (canWebReceive) {
      return canWebReceive
    }
  } else {
    // 判定终端类型
    const { deviceType } = useDeviceType()
    if (deviceType === +OsType.PC_Windows) {
      return splitReceiveDevice.includes(OsType.PC_Windows)
    } else if (deviceType === +OsType.IOS_H5) {
      return splitReceiveDevice.includes(OsType.IOS_H5)
    } else if (deviceType === +OsType.ANDROID_H5) {
      return splitReceiveDevice.includes(OsType.ANDROID_H5)
    } else {
      return false
    }
  }
}

/**
 * 是否可领取接口校验提示
 */
export const getMosaicValidTips = (receiveDeviceTypeStr: string) => {
  const { t } = useI18n()
  // const { isWeb } = useMainStore()
  const splitReceiveDevice = transferStrArrToOsTypes(
    receiveDeviceTypeStr?.split(',') ?? []
  )

  splitReceiveDevice.sort((a, b) => +a - +b)
  /**
   * WEB是否可以领取
   */
  let canWebReceive
  // 判定终端类型
  const { deviceType } = useDeviceType()
  // console.log(deviceType)
  if (deviceType === +OsType.PC_Windows) {
    canWebReceive = splitReceiveDevice.includes(OsType.PC_Windows)
  } else if (deviceType === +OsType.IOS_H5) {
    canWebReceive = splitReceiveDevice.includes(OsType.IOS_H5)
  } else if (deviceType === +OsType.ANDROID_H5) {
    canWebReceive = splitReceiveDevice.includes(OsType.ANDROID_H5)
  }

  // web可以领取，无错误消息
  if (canWebReceive) {
    return {
      canWebReceive,
      errorMessage: ''
    }
  }
  if (useMapMode) {
    const limitList: TranslateResult[] = []
    splitReceiveDevice.forEach((type) => {
      if (
        (type === OsType.IOS_APP &&
          splitReceiveDevice.find((el) => el === OsType.ANDROID_APP)) ||
        (type === OsType.ANDROID_APP &&
          splitReceiveDevice.find((el) => el === OsType.IOS_APP))
      ) {
        limitList.push(t(`lobby.event.validTips.limitType_1_2`))
      } else if (
        (type === OsType.IOS_H5 &&
          splitReceiveDevice.find((el) => el === OsType.ANDROID_H5)) ||
        (type === OsType.ANDROID_H5 &&
          splitReceiveDevice.find((el) => el === OsType.IOS_H5))
      ) {
        limitList.push(t(`lobby.event.validTips.limitType_5_6`))
      } else {
        limitList.push(t(`lobby.event.validTips.limitType_${type}`))
      }
    })
    const limitStr = [...new Set(limitList)].join('、')
    return {
      canWebReceive,
      errorMessage: t(`lobby.event.validTips.limitType`, { x: limitStr })
    }
  }

  /**
   * 含有H5的提示
   */
  const hasH5 =
    splitReceiveDevice.includes(OsType.IOS_H5) ||
    splitReceiveDevice.includes(OsType.ANDROID_H5)

  let reward = ''

  const map: Record<string, VueI18n.TranslateResult> = {
    [String(OsType.ANDROID_APP)]: t('lobby.event.onlyAndroid'),
    [String(OsType.IOS_APP)]: t('lobby.event.onlyIos'),
    [String(OsType.ANDROID_H5)]: t('lobby.event.valid.h5'),
    [String(OsType.IOS_H5)]: t('lobby.event.valid.h5'),
    [String(OsType.PC_Windows)]: t('lobby.event.onlyWeb')
  }

  const downloadMap = {
    hasH5: t('lobby.event.valid.hasH5'),
    hasApp: t('lobby.event.valid.hasApp')
  }

  const intersectionList = intersection(splitReceiveDevice, app)

  /**
   * android和ios都勾选上
   */
  if (intersectionList.length === app.length) {
    reward = `${downloadMap.hasApp}${t('lobby.event.onlyApp')}`
    const xorList: OsType[] = xor(splitReceiveDevice, app)

    xorList.forEach((val: OsType) => {
      reward += `、${map[val]}${downloadMap.hasH5}`
    })

    // 独自勾选
  } else {
    splitReceiveDevice.forEach((val, idx) => {
      // 仅有1个
      if (splitReceiveDevice.length === 1) {
        reward = `${hasH5 ? downloadMap.hasH5 : downloadMap.hasApp}${map[val]}`
      } else {
        const isH5 = [OsType.ANDROID_H5, OsType.IOS_H5].includes(val)
        const sign = idx === 0 ? '' : `、`
        reward +=
          sign +
          `${idx === 0 ? downloadMap.hasApp : ''}${map[val]}${
            isH5 ? downloadMap.hasH5 : ''
          }`
      }
    })
  }

  return {
    canWebReceive,
    errorMessage: t('lobby.event.valid.reward', {
      reward
    })
  }
}

/**
 * 是否能接收奖励
 * false 不能
 * true 可以
 */
export const canReceiveReward = (
  receiveDeviceType?: string,
  renderConent?: () => VueTsxSupport.JSX.Element,
  container?: string
) => {
  const { t } = useI18n()
  const { isWeb } = useMainStore()

  if (receiveDeviceType) {
    // 判断是否为极速包领取
    if (windowConfig.speedPackageConfig) {
      console.log(
        'windowConfig.speedPackageConfig 极速包 >>>> ',
        windowConfig.speedPackageConfig
      )
      console.log('osType >>>>>>>>> ', windowConfig.speedPackageConfig?.osType)
      const { canReceiveSpeedPackage, errorMessage } =
        checkSpeedPackage(receiveDeviceType)
      if (!canReceiveSpeedPackage) {
        const msg = errorMessage as string
        showSpeedPackageTips(msg, container)
      }
      return canReceiveSpeedPackage
    } else {
      const { canWebReceive, errorMessage } =
        getMosaicValidTips(receiveDeviceType)
      if (!canWebReceive) {
        const receiveDeviceTypeList = transferStrArrToOsTypes(
          receiveDeviceType.split(',') ?? []
        )

        // 是否只有h5端可领取
        const hasOnlyH5 =
          receiveDeviceType?.length === 1 &&
          [OsType.ANDROID_H5, OsType.IOS_H5, OsType.PC_H5].includes(
            receiveDeviceTypeList[0]
          )

        // 是否只有pc端可领取
        const hasOnlyPC =
          receiveDeviceType?.length === 1 &&
          receiveDeviceTypeList[0] === OsType.PC_Windows

        // 是否限制app领取
        const showDownload =
          receiveDeviceTypeList.includes(OsType.ANDROID_APP) ||
          receiveDeviceTypeList.includes(OsType.IOS_APP)

        const modal = Manager.Modal.create({
          class: style.receiveTips,
          title: t('lobby.receiveModal.kindTips'),
          width: BusinessUtils.px2rem(600),
          getContainer: () =>
            (container &&
              (document.querySelector(`${container}`) as HTMLElement)) ||
            document.body,
          content: hasOnlyH5
            ? errorMessage
            : (isWeb && renderConent) || errorMessage,
          closable:
            (hasOnlyPC && !isWeb) || (!!renderConent && isWeb) || !showDownload,
          hiddenBtns:
            (hasOnlyPC && !isWeb) || (!!renderConent && isWeb) || !showDownload,
          cancelText: t('lobby.modal.task.close').toString(),
          okText: t('lobby.gameMenu.toDownloadApp').toString(),
          onOk: async () => {
            GlobalEvents.dispatch({ type: 'download_app' })
            modal.destroy()
          }
        })
      }

      return !!canWebReceive
    }
  }
  return false
}

// 竞猜活动获取积分文案
export const getTextOfRule = (type: RuleTypeEnum) => {
  const { t } = useI18n()
  const textOfRules = {
    [RuleTypeEnum.UNCONDITIONAL]: t('lobby.event.unconditional'),
    [RuleTypeEnum.CUMULATIVECODING]: t('lobby.event.cumulativeCoding'),
    [RuleTypeEnum.CUMULATIVERECHARGE]: t('lobby.event.cumulativereCharge')
  }
  return textOfRules[type]
}

// 积分格式化
export const integralFormat = (integral?: number | string): number | string => {
  const _integral = Number(integral)
  if (!_integral) return brazilianCurrencyFormatter(0)
  if (_integral > 0) return `+${brazilianCurrencyFormatter(_integral)}`
  return _integral
}

// 处理推广活动推广链接
export const getPromoteUrlInWeb = (promoteUrl: string) => {
  const id = getQueryString('id', promoteUrl)
  return `${location.origin}/?id=${id}`
}

/**
 * 根据后台优惠中心配置 更多限制 是否符合领取条件
 * 错误提示 展示弹窗
 * @param errorMessage 消息提示
 */

export const showErrorMessageModal = (
  errorMessage: string | (() => string) | (() => VueTsxSupport.JSX.Element),
  container?: string,
  modalTitle?: string | TranslateResult,
  isHiddenBtns?: boolean
) => {
  if (!errorMessage) return
  const { t } = useI18n()
  const modal = Manager.Modal.create({
    title: modalTitle || t('lobby.notice.importantNotice'),
    width: BusinessUtils.px2rem(600),
    titleType: 'error',
    content: errorMessage,
    zIndex: 8999,
    getContainer: () =>
      (container && (document.querySelector(`${container}`) as HTMLElement)) ||
      document.body,
    closable: isHiddenBtns ?? true,
    hiddenBtns: isHiddenBtns ?? true,
    cancelButtonProps: {
      style: {
        display: 'none'
      }
    },
    onOk: async () => {
      await modal.destroy()
    }
  })
}

/**
 * 温馨提示
 * @param errorMessage 消息内容
 */

export const showSrviceModal = (errorMessage: string) => {
  const { t } = useI18n()
  const modal = Modal.create({
    title: t('lobby.common.tips.title'),
    contentPosition: 'center',
    content: errorMessage,
    class: style.showSrviceModal,
    onOk: async () => {
      try {
        await modal.destroy()
      } catch (error) {
        console.error(error)
      }
    },
    okText: t('lobby.event.discountCode.close') as string
  })
}

// 详情页返回
export const goBack = () => {
  // 定位到标记的活动，一般是当前的活动
  markScrollType(() => {
    const index = useEventStore().currentActiveIndex
    const element = document.querySelector(`[data-event-index="${index}"]`)
    if (element) {
      element.scrollIntoView({ block: 'center' })
    }
  })
  const query = router.currentRoute?.query
  if (!query?.current) {
    const { updateTabItem } = useTabBarStore()
    updateTabItem(TabKey.EVENT)
    router.push({
      name: RouteName.EVENT
    })
  } else {
    router.back()
  }
}

export const getReceiveText = (statas: ReceiveStatusEnum) => {
  const { t } = useI18n()
  const map = {
    [ReceiveStatusEnum.Processing]: t('lobby.modal.task.processing'),
    [ReceiveStatusEnum.CanReceive]: t('lobby.modal.task.collect'),
    [ReceiveStatusEnum.HasReceive]: t('lobby.modal.task.collected'),
    [ReceiveStatusEnum.Expired]: t('lobby.modal.task.expired'),
    [ReceiveStatusEnum.Distributed]: t('lobby.modal.task.distributed'),
    [ReceiveStatusEnum.Cancel]: t('lobby.modal.task.cancel'),
    [ReceiveStatusEnum.PendingCheck]: t('lobby.modal.task.collect'),
    [ReceiveStatusEnum.PendingApply]: t('lobby.modal.task.applyAward'),
    [ReceiveStatusEnum.Rejected]: t('lobby.records.rejected')
  } as Record<ReceiveStatusEnum, string>
  return map[statas] as string
}

/**
 * 校验极速包是否可领取
 * 不可领取给出错误提示
 */
export const checkSpeedPackage = (receiveDeviceType: string) => {
  const { t } = useI18n()
  const splitReceiveDevice = transferStrArrToOsTypes(
    receiveDeviceType?.split(',') ?? []
  )

  splitReceiveDevice.sort((a, b) => +a - +b)

  // 用户设备类型 1:IOS | 2: Android
  const { deviceType } = useDeviceType()

  if (
    [Number(OsType.IOS_APP), Number(OsType.ANDROID_APP)].includes(deviceType) &&
    splitReceiveDevice.includes(OsType.IOS_APP) &&
    splitReceiveDevice.includes(OsType.ANDROID_APP)
  ) {
    return {
      canReceiveSpeedPackage: true,
      errorMessage: ''
    }
  }

  if (
    deviceType === Number(OsType.ANDROID_APP) &&
    splitReceiveDevice.includes(OsType.ANDROID_APP)
  ) {
    return {
      canReceiveSpeedPackage: true,
      errorMessage: ''
    }
  }

  return {
    canReceiveSpeedPackage: false,
    errorMessage: t(
      `lobby.event.validTips.limit_${splitReceiveDevice.join('_')}`
    )
  }
}

/**
 * 极速包弹窗提示
 */
export const showSpeedPackageTips = (
  errorMessage?: string,
  container?: string
) => {
  const { t } = useI18n()
  const modal = Manager.Modal.create({
    class: style.receiveTips,
    title: t('lobby.receiveModal.kindTips'),
    width: BusinessUtils.px2rem(600),
    getContainer: () =>
      (container && (document.querySelector(`${container}`) as HTMLElement)) ||
      document.body,
    content: errorMessage,
    closable: false,
    hiddenBtns: false,
    cancelButtonProps: {
      style: {
        display: 'none'
      }
    },
    okText: t('lobby.event.discountCode.close').toString(),
    onOk: async () => {
      await modal.destroy()
    }
  })
}

/** 判断当前时间是否可以被领取 */
export const judgeIsAllowCollecting = (
  receiveDuration: number,
  receiveTimeType: ReceiveTimeTypeEnum
) => {
  // 待领取倒计时<=0(当前可领,或已过期),通过
  if (receiveDuration <= 0) {
    return true
  }
  // 当前待领取时间的类型[不是][次日|下周|下月],通过
  if (receiveTimeType === ReceiveTimeTypeEnum.Now) {
    return true
  }
  // 不可领取
  return false
}

/** 领取类型文案映射 */
export const receiveTimeTypeText = (
  receiveTimeType: ReceiveTimeTypeEnum,
  receiveTimeRemark?: string
) => {
  const { t } = useI18n()
  let receiveTimeObj: ReceiveTimeRemark | undefined
  try {
    receiveTimeObj = receiveTimeRemark ? JSON.parse(receiveTimeRemark) : {}
  } catch (error) {
    console.error('JSON 解析出错:', error)
  }

  const mapper = {
    [ReceiveTimeTypeEnum.EveryDaily]: () =>
      t('lobby.event.promote.receiveTimeEveryDaily', {
        startTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeStart ?? 0,
          'hms'
        ),
        endTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeEnd ?? 0,
          'hms'
        )
      }) as string,
    [ReceiveTimeTypeEnum.NextDay]: () =>
      t('lobby.event.promote.receiveTimeNextDay', {
        startTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeStart ?? 0,
          'hms'
        ),
        endTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeEnd ?? 0,
          'hms'
        )
      }) as string,
    [ReceiveTimeTypeEnum.NextWeek]: () =>
      t('lobby.event.promote.receiveTimeWeekly', {
        startTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeStart ?? 0,
          'hms'
        ),
        endTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeEnd ?? 0,
          'hms'
        )
      }) as string,
    [ReceiveTimeTypeEnum.NextMonth]: () =>
      t('lobby.event.promote.receiveTimeMonthly', {
        startTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeStart ?? 0,
          'hms'
        ),
        endTime: BusinessUtils.format(
          receiveTimeObj?.receiveTimeEnd ?? 0,
          'hms'
        )
      }) as string
  } as Record<ReceiveTimeTypeEnum, () => string>
  /** 提醒文案 */
  const reminderText = mapper[receiveTimeType]()
  return reminderText
}
/**
 * 跳转到指定的活动详情 如果是彩金活动则会弹出弹窗
 * 自动判断是否有可以跳转的这个活动（失效活动，过期活动不会跳转）
 * @param activeId 跳转的活动id
 * @param showError 无法跳转是否展示错误
 */
export const goEventDetail = async (activeId: number, showError = false) => {
  const { activeList, firstLoaded, freshEventCategoryAndActivity } =
    useEventStore()
  let activeListData = activeList
  // 如果没有请求过，则需要触发请求
  if (!firstLoaded) {
    activeListData = await freshEventCategoryAndActivity()
  }
  const eventItem = activeListData.find((i) => i.activeId === activeId)
  if (eventItem) {
    if (eventItem.template === ActivityTemplate.DiscountCode) {
      // 彩金活动需要弹出弹窗
      Modal.open('discountCode', {
        title: eventItem.activeName || '',
        activeId: activeId,
        isModal: true,
        onSuccess: () => Modal.close('discountCode'),
        width: 600
      })
    } else if (eventItem.template === ActivityTemplate.DailyBonus) {
      GlobalManager.Modal.open('sign', {
        activeId,
        title: eventItem.activeName || '',
        isModal: true,
        onSuccess: () => GlobalManager.Modal.close('sign'),
        width: 710
      })
    } else {
      await toEventItemPage(
        'push',
        String(eventItem.template),
        String(eventItem.activeId),
        {
          current: router.currentRoute.query['current']
        }
      )
    }
  } else if (showError) {
    const { t } = useI18n()
    GlobalManager.Modal.message({
      type: 'error',
      content: t('lobby.event.discountCode.activityDissatisfy').toString()
    })
  }
}

/**
 * 领取提示文案映射
 * [充值/打码/救援金]
 * */
export const receiveTipsMapText = (data: receiveData): string => {
  const { t } = useI18n()
  const { receiveTimeRemark, canReceiveTime } = data
  let receiveObject = {} as unknown as ReceiveTimeRemark

  try {
    receiveObject =
      typeof receiveTimeRemark === 'string' ? JSON.parse(receiveTimeRemark) : {}
  } catch (error) {}

  const { receiveTimeDay, receiveTimeStart, receiveTimeEnd, receiveTimeType } =
    receiveObject || {}

  const mapper = {
    // 实时领取
    [ReceiveTimeTypeEnum.Now]: () => {
      return t('lobby.event.listedRewards.canReceiveTip1', {
        day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
      }) as string
    },
    // 次日领取
    [ReceiveTimeTypeEnum.NextDay]: () => {
      return t('lobby.event.listedRewards.canReceiveTip1', {
        day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
      }) as string
    },
    // 下周
    [ReceiveTimeTypeEnum.NextWeek]: () => {
      return t('lobby.event.listedRewards.canReceiveTip1', {
        day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
      }) as string
    },
    // 下月
    [ReceiveTimeTypeEnum.NextMonth]: () => {
      return t('lobby.event.listedRewards.canReceiveTip1', {
        day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
      }) as string
    },
    // 每日
    [ReceiveTimeTypeEnum.EveryDaily]: () => {
      return t('lobby.event.listedRewards.canReceiveTip2', {
        startTime: getTime(receiveTimeStart),
        endTime: getTime(receiveTimeEnd)
      }) as string
    },
    // 每周
    [ReceiveTimeTypeEnum.EveryWeek]: () => {
      const mapper = validDayMappings()
      return t('lobby.event.listedRewards.canReceiveTip3', {
        week: mapper[receiveTimeDay],
        startTime: getTime(receiveTimeStart),
        endTime: getTime(receiveTimeEnd)
      }) as string
    },
    // 每月
    [ReceiveTimeTypeEnum.Monthly]: () => {
      return t('lobby.event.listedRewards.canReceiveTip4', {
        day: receiveTimeDay,
        startTime: getTime(receiveTimeStart),
        endTime: getTime(receiveTimeEnd)
      }) as string
    }
  }

  return mapper[receiveTimeType as unknown as ReceiveTimeTypeEnum]?.() ?? ''
}

export const getTime = (seconds: number) => {
  return moment().startOf('day').seconds(seconds).format('HH:mm:ss')
}

/**
 * 获取活动星期限时
 * @private
 */
export const validDayMappings = (): Record<Key, BaseResult> => {
  const { t } = useI18n()
  return {
    1: t('lobby.event.validDay.monday'),
    2: t('lobby.event.validDay.tuesday'),
    3: t('lobby.event.validDay.wednesday'),
    4: t('lobby.event.validDay.thursday'),
    5: t('lobby.event.validDay.friday'),
    6: t('lobby.event.validDay.saturday'),
    7: t('lobby.event.validDay.sunday')
  }
}

/**
 * 活动通用领取
 * @param params 领取接口参数
 * @param onSuccess 成功回调
 * @param followUp 领取动画配置
 * @param onError 失败回调
 */
export const activeReceiveOne = async ({
  params,
  onError,
  onSuccess,
  followUp,
  judgeResBefore
}: ActiveReceiveOne) => {
  const [err, data] = await to(apiActiveReceiveOne(params))
  const { t } = useI18n()

  judgeResBefore?.((data ?? {}) as ApiActiveReceiveOneResponse)

  // 错误提示
  if (err) {
    onError?.()
    const response = err as unknown as AxiosResponse
    const errorMsg: string =
      response?.data?.msg ?? t('lobby.reward.base.receivedError').toString()
    showErrorMessageModal(errorMsg)
    return
  }
  // 成功回调
  onSuccess?.(data)

  // 领取动画
  GlobalManager.Modal.open('receiveAnimation', {
    aniType:
      (data.reward || 0) >= 10 ? RewardAniType.TYPE_2 : RewardAniType.TYPE_1,
    ...followUp
  })
}
