import {
  CategoryCanReceive,
  EventDiscountsRedDot,
  apiEventAndDiscountsRedDot
} from '../../api/apiEventAndDiscountsRedDot'
import { TaskCategoryApiCodeEnum as TCACE } from '../../api/type'
import { TaskPageStore } from '.'
import { useMainStore } from '@/store/index'

/** 刷新所有小红点信息（PS：没有删除单个的功能） */
export const proxyFreshAllCodeCateReceive = async (
  store: TaskPageStore,
  isRefresh = false
) => {
  // 小红点信息
  let redDotRes = new EventDiscountsRedDot()
  // 如果登录了,则发送请求获取小红点信息
  if (useMainStore().hasLogined) {
    redDotRes = await apiEventAndDiscountsRedDot(isRefresh)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeRedDot, taskRedDot, ...rest } = redDotRes
  // 更新全局统计小红点数据
  store.taskGlobalStatistics = rest

  // 刷新tabs上的小红点
  // 每次小红点的设置，都是一个全新的对象
  const ccrMapper = {
    [TCACE.xrfl]: new CategoryCanReceive({ apiCode: TCACE.xrfl }),
    [TCACE.mrrw]: new CategoryCanReceive({ apiCode: TCACE.mrrw }),
    [TCACE.mzrw]: new CategoryCanReceive({ apiCode: TCACE.mzrw }),
    [TCACE.smrw]: new CategoryCanReceive({ apiCode: TCACE.smrw })
  }
  // 用取得的数组来更新该全新对象
  taskRedDot.forEach((redDot) => {
    const { apiCode } = redDot
    // 因为可能还会存在temp:5（宝箱）等其它的值，并不需要在此处应用，所以需要进行一下过滤
    if (store.codeCategoryOrder.includes(apiCode)) {
      ccrMapper[apiCode] = redDot as CategoryCanReceive
    }
  })
  // 然后将该对象赋值到mapper上（流程：重置->赋值）
  store.codeCateReceiveMapper = ccrMapper
}
