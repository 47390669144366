import { TaskCategoryApiCodeEnum } from '../../../../api/type'
import { type TaskModalConstant } from '../..'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type Any = any

/** 静态版的入参 */
type TaskModalConstantParams = {
  store: TaskModalConstant
  apiCode: TaskCategoryApiCodeEnum
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}

/** 通过用户选择判断是否禁用弹窗 */
export class JudgeIsForbidPopByUserChecked {
  /** 判断当前用户勾选的是否禁用打开弹窗,静态版的逻辑:新版的 */
  public judgeConstant(context: TaskModalConstantParams) {
    return this.judgeUserCheckedDisabled(context)
  }

  /** 判断用户是否勾选了禁用弹框 */
  private judgeUserCheckedDisabled = (context: TaskModalConstantParams) => {
    const { store, apiCode } = context
    const isTodayNotReminder = store.codeTodayNotReminderMapper.get(apiCode)
    // 勾选永不提示|勾选本次不提示,任何一个为true则不再弹框
    const isNeverReminder = store.codeNeverReminderMapper.get(apiCode)
    if (isTodayNotReminder || isNeverReminder) {
      return true
    } else {
      return false
    }
  }
}
