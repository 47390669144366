import { ApiRedPocketItem1, BaseRedPocketItem } from '@/api/redPocket/type'
import {
  RedPocketCloseStorages,
  RedPocketHandleCloseStorages
} from './store/type'
import { RedPocketItemType } from './components/item/type'
import { useMainStore } from '@/store/index'
import { useRedPocketStore } from './store'
import DateUtils from '@/utils/DateUtils'

export const redPocketHandleCloseStorages = () => {
  const { redPocketHandleClose } = useRedPocketStore()
  const redPocketHandleCloseStorages: Array<RedPocketHandleCloseStorages> =
    redPocketHandleClose
  const storagesList = [] as RedPocketHandleCloseStorages[]
  for (const key in redPocketHandleCloseStorages) {
    if (
      Object.prototype.hasOwnProperty.call(redPocketHandleCloseStorages, key)
    ) {
      const element = redPocketHandleCloseStorages[key]
      storagesList.push(element)
    }
  }
  return storagesList
}

/** 获取红包组合标识 */
export const getHandleClosedKey = (item: ApiRedPocketItem1) => {
  const { activeId, logId = 0, subtaskid = 0, time = 0 } = item || {}
  const nowKey = DateUtils.moment().startOf('day').unix()
  return `${+activeId}-${+subtaskid}-${+logId}-${+time || nowKey}`
}

export const isHandleClosed = (key: string) => {
  const closeList = redPocketHandleCloseStorages()
  const mainStore = useMainStore()
  const platfromid = mainStore.userInfos?.platfromid || ''
  let hasClose = false

  closeList.forEach((item) => {
    if (item.platfromid === platfromid) {
      item.data.forEach((item2) => {
        if (item2 === key) {
          hasClose = true
        }
      })
    }
  })
  return hasClose
}

export const getRedPocketCloseStorages = () => {
  const redPocketCloseStorages: Array<RedPocketCloseStorages> =
    useRedPocketStore().redPocketClose || []
  const storagesList = [] as RedPocketCloseStorages[]
  for (const key in redPocketCloseStorages) {
    if (Object.prototype.hasOwnProperty.call(redPocketCloseStorages, key)) {
      const element = redPocketCloseStorages[key]
      storagesList.push(element)
    }
  }
  // console.log(
  //   'xxxxxx getRedPocketCloseStorages',
  //   storagesList,
  //   redPocketCloseStorages
  // )
  return storagesList
}

/** 是否关闭 */
export const isClosed = (target: RedPocketItemType) => {
  const mainStore = useMainStore()
  const platfromid = mainStore.userInfos?.platfromid || ''
  let hasClose = false

  getRedPocketCloseStorages().forEach((item) => {
    if (item.platfromid === platfromid) {
      item.data.forEach((item2) => {
        if (
          item2.activeId === getHandleClosedKey(target as ApiRedPocketItem1) &&
          item2.errorMsg === target.errorMsg
        ) {
          hasClose = true
        }
      })
    }
  })
  return hasClose
}

/** 筛选是否关闭过 用于过滤 */
export const checkIsClosed = (redItem: ApiRedPocketItem1) => {
  const { closeRedList } = useRedPocketStore()
  return (
    (closeRedList as Array<number | string>).includes(
      getHandleClosedKey(redItem)
    ) ||
    isHandleClosed(getHandleClosedKey(redItem)) ||
    isClosed(redItem)
  )
}

/** 同步兼容后端字段 activeid activeId */
export const compatibleId = (list: BaseRedPocketItem[]) => {
  if (!list) {
    return []
  }
  return list.map((it) => ({
    ...it,
    activeId: it.activeid || it.activeId
  }))
}
