import { ActivityConfig } from './initState/activityConfig'
import {
  FirstChargePopConfigWeb,
  apiConstantFirstCharge
} from '../api/apiConstantFirstCharge'
import { FisrtChageConstantState, initState } from './initState'
import {
  OpenModalParams,
  proxyPreJudgeOpenFirstCharge
} from './proxyOpenFirstChargeModal'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '../../common/util/judgePopupType'
import { defineStore } from 'pinia'
import { useMainStore } from '@/store/index'
import { userRecord } from './initState/userOpeningFirstChargeRecord'
import Modal from '@/controller/Modal'
import autoModalManager from '@/context/autoModalManager'
import moment from 'moment'

/** 静态首充弹窗的store */
export type FirstChargeConstantStore = ReturnType<
  typeof useFirstChargeConstantStore
>

/** 静态首充弹窗的store */
export const useFirstChargeConstantStore = defineStore(
  'firstChargeConstantStore',
  {
    state: () => initState() as FisrtChageConstantState,
    getters: {},
    actions: {
      /** 刷新弹窗数据 */
      async freshPopData() {
        const resPopWeb = await apiConstantFirstCharge()
        /** 会员层级 未登录状态下会员的默认层级是1,不能直接使用 || 有会员的层级为 0  */
        let memberLevel = useMainStore().userInfos?.member_level as number
        if (memberLevel == undefined) {
          memberLevel = 1
        }
        /** 需要对已有数据做过滤，手动判断活动是否已经到达了展示结束时间 */
        const unixTime = moment().unix()
        const filterData = resPopWeb?.filter((item) => {
          return !(item?.endShowTime > 0 && unixTime >= item?.endShowTime)
        })
        // 使用第一个匹配到的用户层级,如果都没有选中,则填充一个不弹窗的默认值
        const userConfig =
          filterData.find((config) => {
            const configLevel = config.userLevel
            // 如果后台是空层级,或是层级包含0,则表示后台要匹配任意层级,它将要显示
            if (configLevel.length === 0 || configLevel.includes(0)) {
              return true
            }
            // 如果匹配上当前用户层级,则显示
            return configLevel.includes(memberLevel)
          }) || new FirstChargePopConfigWeb()
        // 刷新数据赋值到state
        this.afterLoginPopType = userConfig.afterLoginPopType
        this.beforeLoginPopType = userConfig.beforeLoginPopType
        this.rewardConditionList = userConfig.list
        ActivityConfig.immitPopFirstChargeConfig(
          this.activityConfig,
          userConfig
        )
      },
      /** 切换永不弹窗勾选状态 */
      switchPopIsNeverRemind(status: boolean) {
        this.isNeverRemind.set(status)
      },
      /** 切换今日不弹窗勾选状态 */
      switchPopIsTodayNotRemind(status: boolean) {
        this.isTodayNotRemind.set(status)
      },
      /** 判断弹窗是否能打开 */
      preJudgeOpenFirstCharge(
        params?: Partial<Omit<OpenModalParams, 'store'>>
      ) {
        const { isLoginTrigger = false } = params || {}
        const { isNeverRemind, isTodayNotRemind, isServerDisabled } = this
        // 前端需要手动判断活动是否已经到达了展示结束时间
        // 后端接口已经过滤了，但是由于接口静态化可能过滤不生效
        const unixTime = moment().unix()
        if (
          this.activityConfig?.endShowTime > 0 &&
          unixTime > this.activityConfig?.endShowTime
        ) {
          return false
        }
        return proxyPreJudgeOpenFirstCharge({
          isLoginTrigger,
          isNeverRemind,
          isTodayNotRemind,
          isServerDisabled
        })
      },
      /** 打开弹窗 */
      openFirstChargeModal() {
        Modal.open('firstChargeConstant').then((context) => {
          //重点,环境监听,只能使用$once,不然会重复监听越来越多的内容
          context.$once('modal:close', () => {
            autoModalManager.onModalClose('firstChargeConstant')
          })
        })
      },
      /** 关闭首充弹窗 */
      closeFirstChargeModal() {
        Modal.close('firstChargeConstant')
      },
      /** 记录用户打开过弹窗的行为 */
      recordUserOpeningRecord() {
        this.isHaveOpenedToday = true
        this.isHaveOpenedEver = true
        userRecord.recordUserOpeningRecord()
      },
      /** 重置 */
      myReset() {
        //PS:就目前的代码来看,其实就是重新new了一个对象
        const { isHaveOpenedEver, isHaveOpenedToday } =
          userRecord.getUserOpeningRecord()
        this.isHaveOpenedEver = isHaveOpenedEver
        this.isHaveOpenedToday = isHaveOpenedToday
        this.isServerDisabled = false
        this.isReceivedEver = false
        this.beforeLoginPopType = PopTypeBeforeLogin.constantly
        this.afterLoginPopType = PopTypeAfterLogin.constantly
        this.activityConfig = new ActivityConfig()
        this.isNeverRemind.set(false)
        this.isTodayNotRemind.set(false)
        this.rewardConditionList = []
      }
    }
    // persist: {
    //   enabled: true,
    //   strategies: [
    //     {
    //       key: 'web.lobby.first-charge-constant-modal',
    //       paths: [],
    //       storage: createMyPersistedStorage()
    //     }
    //   ]
    // }
  }
)
