import { GlobalService } from '@/context'
import { TaskCategoryApiCodeEnum } from './type'
import { merge } from 'lodash'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
/**
 * 获取活动/任务/优惠中心的小红点信息
 */
export const apiEventAndDiscountsRedDot = async (isRefresh = false) => {
  const response = await GlobalService.post<EventDiscountsRedDot>({
    url: '/active/getRedDotV2',
    data: {},
    customParams: {
      silentOnError: true,
      cache: isRefresh ? 0 : true,
      throttleDelay: 1000 * 2
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  })
  const eventRedDot = response.data?.data as EventDiscountsRedDot
  // 对返回的数据做一个纠错,避免出现undefiend等破坏数据结构的内容
  if (!eventRedDot.activeRedDot.activeList) {
    eventRedDot.activeRedDot.activeList = []
  }
  if (!eventRedDot.activeRedDot.categoryList) {
    eventRedDot.activeRedDot.categoryList = []
  }
  if (!eventRedDot.taskRedDot) {
    eventRedDot.taskRedDot = []
  } else {
    eventRedDot.taskRedDot = eventRedDot.taskRedDot.map((item) => {
      return merge(new CategoryCanReceive({ template: item.template }), item)
    })
  }
  return eventRedDot
}

/** 活动的小红点对象 */
export class ActivityRedDot {
  /** 活动id */
  activeId = 0
  /** 小红点数量 */
  activeCount = 0
  /** 小红点金额 */
  activeAmount = 0
}

/** 活动分类下对应的小红点对象 */
export class CategoryRedDot {
  /** 活动分类的id */
  categoryId = 0
  /** 小红点数量(该分类下所有活动小红点相加) */
  activeCount = 0
  /** 小红点金额(该分类下所有活动金额相加) */
  activeAmount = 0
}
/** 任务分类可领取的信息 */
export class CategoryCanReceive {
  constructor(
    param:
      | {
          apiCode?: TaskCategoryApiCodeEnum
          template: 1 | 2 | 3 | 4
        }
      | {
          apiCode: TaskCategoryApiCodeEnum
          template?: 1 | 2 | 3 | 4
        }
  ) {
    const { apiCode, template } = param
    if (apiCode) {
      this.apiCode = apiCode
      this.template = apiCodeTemplateMapper[apiCode]
    } else {
      this.template = template
      this.apiCode = templateApiCodeMapper[template]
    }
  }
  /** apiCode的编码 */
  apiCode: TaskCategoryApiCodeEnum
  /** 任务模板 */
  template!: 1 | 2 | 3 | 4
  /** 可以领取的数量 ，小红点数量 */
  unReceiveCount = 0
  /** 可以领取的金额 */
  unReceiveAmount = 0
  /** 可领取的ruleid 集合， 个数应该和unReceiveCount 一致 */
  receiveRuleIds: number[] = []
}
/** 任务小红点对象 */
export class TaskRedDot {
  constructor(apiCode: TaskCategoryApiCodeEnum) {
    this.apiCode = apiCode
  }
  /** apiCode的编码 */
  apiCode: TaskCategoryApiCodeEnum

  /** 未领取小红点数量 */
  unReceiveCount = 0
  /** 未领取金额 */
  unReceiveAmount = 0
  /** 可领取的ruleid 集合， 个数应该和unReceiveCount 一致 */
  receiveRuleIds: number[] = []
}

export class EventDiscountsRedDot {
  /** 活动小红点 */
  activeCount = 0
  /** 任务下红点 */
  taskCount = 0
  /** 奖励小红点 */
  rewardCount = 0
  /** 返水小红点，接口预留 */
  returnGoldCount = 0
  /** 活动金额 */
  activeAmount = 0
  /** 任务金额 */
  taskAmount = 0
  /** 奖励金额 */
  returnAmount = 0
  /** 返水金额，接口预留 */
  rewardAmount = 0
  /** 活动小红点映射 */
  activeRedDot: {
    /** 活动小红点列表 */
    activeList: ActivityRedDot[]
    /** 分类小红点列表 */
    categoryList: CategoryRedDot[]
  } = { activeList: [], categoryList: [] }
  /** 任务小红点列表 */
  taskRedDot: CategoryCanReceive[] = []
}
/** 模板与apiCode的映射关系 */
export const templateApiCodeMapper = {
  /** temp : apicode */
  1: TaskCategoryApiCodeEnum.xrfl,
  2: TaskCategoryApiCodeEnum.mrrw,
  3: TaskCategoryApiCodeEnum.mzrw,
  4: TaskCategoryApiCodeEnum.smrw
} as const
/** apiCode与模板的映射关系 */
export const apiCodeTemplateMapper = {
  /** temp : apicode */
  [TaskCategoryApiCodeEnum.xrfl]: 1,
  [TaskCategoryApiCodeEnum.mrrw]: 2,
  [TaskCategoryApiCodeEnum.mzrw]: 3,
  [TaskCategoryApiCodeEnum.smrw]: 4
} as const
