import { BindType, MUST_BIND_ITEM, RewardBindData } from '@/api/common/type'
import { PngSprite, SvgSprite } from '@/components/icon-sprite'
import { TabsEnum } from '../const'
import { defineStore } from 'pinia'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import siteConfig from '@/config/site.config'
export const CanClickWhenForceBind = 'can-click-when-force-bind' //强行绑定时可以进行点击的元素类名

export type MenuItem = {
  benefitData?: RewardBindData
  icon: PngSprite | SvgSprite
  iconClass?: string
  label:
    | NonNullable<BaseRenderContent>
    | ((item?: MenuItem) => NonNullable<BaseRenderContent>)
  value?: TabsEnum
  hidden?: boolean | (() => boolean)
  disabled?: boolean | (() => boolean)
  renderSuffix?: (item?: MenuItem) => NonNullable<BaseRenderContent>
}
type CallbackType = { hasBindBySecondVerify: boolean }
export interface SecurityState {
  /**
   * 是否是正在绑定
   */
  isBinding: boolean
  /**
   * 是否通过输入密码登录
   */
  isLoginedByPassword: boolean
  /**
   * 是否通过注册页面自动登录
   */
  isLoginedByRegister: boolean
  /**
   * 是否进行过经过二次验证的成功的绑定
   */
  hasForceBindBySecondVerifyDone: boolean
  /**
   * 存储密码供安全中心自动处理第一步密码验证
   */
  loginPass: string
  forceBindDone: {
    phone: boolean
    email: boolean
    ga: boolean
    withdrawPass: boolean
    question: boolean
    withdrawAccount: boolean
  }
  forceBindCallback: {
    phone: ((payload?: CallbackType) => void) | null
    email: ((payload?: CallbackType) => void) | null
    ga: ((payload?: CallbackType) => void) | null
    withdrawPass: ((payload?: CallbackType) => void) | null
    question: ((payload?: CallbackType) => void) | null
    withdrawAccount: ((payload?: CallbackType) => void) | null
  }

  /** 是否是绑定后的验证（密码验证或者其他验证） */
  hasSubmitNextVerify: boolean

  /** 当前正在操作处理的项 */
  activeBindItem: MenuItem | null
  systemBindConfig: Partial<Record<MUST_BIND_ITEM, BindType>>
  /** 所有需要绑定项 */
  systemAllBindList: MUST_BIND_ITEM[]
  /** 所有必需要绑定项 */
  systemMastBindList: MUST_BIND_ITEM[]
  /** 所有不必需要绑定项 */
  systemUnMastBindList: MUST_BIND_ITEM[]
}

export const useSecurity = defineStore('views/center/security', {
  state: (): SecurityState => ({
    isLoginedByPassword: false,
    isLoginedByRegister: false,
    isBinding: false,
    loginPass: '',
    hasForceBindBySecondVerifyDone: false,
    /**
     * 绑定流程状态是否走过
     */
    forceBindDone: {
      phone: false,
      email: false,
      ga: false,
      withdrawPass: false,
      question: false,
      withdrawAccount: false
    },
    forceBindCallback: {
      phone: null,
      email: null,
      ga: null,
      withdrawPass: null,
      question: null,
      withdrawAccount: null
    },

    hasSubmitNextVerify: false,
    activeBindItem: null,
    systemBindConfig: {},
    systemAllBindList: [],
    systemMastBindList: [],
    systemUnMastBindList: []
  }),
  actions: {
    setBindDone(
      name:
        | 'phone'
        | 'email'
        | 'ga'
        | 'withdrawPass'
        | 'question'
        | 'withdrawAccount',
      status: boolean
    ) {
      this.forceBindDone[name] = status
    },
    setBindCallback(
      name:
        | 'phone'
        | 'email'
        | 'ga'
        | 'withdrawPass'
        | 'question'
        | 'withdrawAccount',
      reslove: (value: unknown) => void
    ) {
      const done = (payload?: CallbackType) => {
        reslove('')
        this.setBindDone(name, true)
        payload?.hasBindBySecondVerify &&
          this.setHasForceBindBySecondVerifyDone()
      }
      this.forceBindCallback[name] = done
    },
    setHasForceBindBySecondVerifyDone() {
      this.hasForceBindBySecondVerifyDone = true
    },
    setBindingStatus(status: boolean) {
      this.isBinding = status
    },
    //从未登录切换成登录后，重置所有绑定状态。否则强制绑定弹窗不会出来
    resetBindStatus() {
      this.forceBindDone.phone = false
      this.forceBindDone.email = false
      this.forceBindDone.ga = false
      this.forceBindDone.withdrawPass = false
      this.forceBindDone.question = false
      this.forceBindDone.withdrawAccount = false
    },
    setSubmitNextVerify(value: boolean) {
      this.hasSubmitNextVerify = value
    },
    /**
     * 初始化后台配置项并格式化为业务所需要的数据格式
     */
    initBindConfig(isUpdate?: boolean) {
      const { userInfos, validateConfig, isRealLogined } = useMainStore()
      // 提现资产两步验证会手动改配置触发强制绑定 所以这里加个isUpdate字段
      if (!isUpdate && Object.keys(this.systemBindConfig).length) {
        return
      }

      /** 如果没有登录，则用户信息里没有后台的配置项，跳过 */
      if (!isRealLogined) {
        return
      }

      const defuConf = BindType.OFF
      const sysBindConfig = {
        must_bind_withdraw_pass: userInfos?.mustBindWithdrawPass ?? defuConf,
        must_bind_phone: userInfos?.must_bind_phone ?? defuConf,
        must_bind_email: userInfos?.must_bind_email ?? defuConf,
        must_bind_google_auth: userInfos?.must_bind_google_auth ?? defuConf,
        must_bind_question: userInfos?.mustBindSecurityQuestion ?? defuConf,
        must_bind_withdraw_account:
          userInfos?.mustBindWithdrawAccount ?? defuConf
      }

      /**
       * 213业主单独屏蔽掉安全中心：手机、邮箱、谷歌验证器、手势识别、三方账户绑定
       */
      if (this.is213SiteCode) {
        Reflect.deleteProperty(sysBindConfig, 'must_bind_phone')
        Reflect.deleteProperty(sysBindConfig, 'must_bind_email')
        Reflect.deleteProperty(sysBindConfig, 'must_bind_google_auth')
      }

      const allBindList: MUST_BIND_ITEM[] = []
      const mastBindList: MUST_BIND_ITEM[] = []
      const unmastBindList: MUST_BIND_ITEM[] = []

      const { bindKeyValues } = this.getBindKeyValueInfos()
      Object.entries(sysBindConfig).forEach((confObject) => {
        const key = confObject[0] as MUST_BIND_ITEM
        const value = confObject[1] as BindType
        /** 只取没有完成绑定的 */
        if (!validateConfig[bindKeyValues[key].key]) {
          if (value === BindType.REQUIRED) {
            mastBindList.push(key)
          }
          if (value === BindType.NOT_ESSENTIAL) {
            unmastBindList.push(key)
          }
          /** 三个分别push是为了保留顺序 */
          if (value >= BindType.NOT_ESSENTIAL) {
            allBindList.push(key)
          }
        }
      })
      this.systemBindConfig = sysBindConfig
      this.systemAllBindList = allBindList
      this.systemMastBindList = mastBindList
      this.systemUnMastBindList = unmastBindList
    },

    /**
     * 下一步，或者选择其他验证项之前是否忽略掉当前项
     * @param activeBindItemValue 要剔除的项，选填，不填默认是当前绑定的项
     * @returns void
     */
    omitActiveBindItem(activeBindItemValue?: TabsEnum) {
      const { activeKey } = this.getBindKeyValueInfos(activeBindItemValue)
      if (!activeKey) {
        return
      }
      const filterActive = (data: MUST_BIND_ITEM[]) => {
        return data.filter((d) => d !== activeKey)
      }

      this.systemAllBindList = filterActive(this.systemAllBindList)
      this.systemMastBindList = filterActive(this.systemMastBindList)
      this.systemUnMastBindList = filterActive(this.systemUnMastBindList)
    },

    /**
     * @param activeBindItemValue 基于 activeBindItemValue 获取对应的 key，选填，不填默认是当前绑定的项
     * @returns <bindKeyValues：key value 对照集合>; <activeValue: 当前的value>；<activeKey: 当前对应的key>
     */
    getBindKeyValueInfos(activeBindItemValue?: TabsEnum) {
      const { t } = useI18n()
      const bindKeyValues = {
        [MUST_BIND_ITEM.WITHDRAWPASS]: {
          key: 'hasWithdrawPwd' as const,
          value: TabsEnum.WITHDRAWPASS,
          label: t('lobby.center.security.withdrawalPwd.tab')
        },
        [MUST_BIND_ITEM.PHONE]: {
          key: 'hasPhoneNumber' as const,
          value: TabsEnum.PHONE,
          label: t('lobby.modal.login.form.phone.label')
        },
        [MUST_BIND_ITEM.EMAIL]: {
          key: 'hasEmail' as const,
          value: TabsEnum.EMAIL,
          label: t('lobby.center.security.email.tab')
        },
        [MUST_BIND_ITEM.GA]: {
          key: 'hasGoogleAuth' as const,
          value: TabsEnum.GA,
          label: t('lobby.center.security.ga.tab')
        },
        [MUST_BIND_ITEM.SECRETSECURITY]: {
          key: 'hasQuestion' as const,
          value: TabsEnum.QUESTION,
          label: t('lobby.common.components.auth.question.name')
        },
        [MUST_BIND_ITEM.WITHDRAWACCOUNT]: {
          key: 'hasWithdrawAct' as const,
          value: TabsEnum.WITHDRAWACCOUNT,
          label: t('lobby.center.security.WithdrawAccount.title')
        }
      }

      const activeValue = activeBindItemValue
        ? activeBindItemValue
        : this.activeBindItem?.value

      let activeKey: MUST_BIND_ITEM | undefined
      for (const [key, val] of Object.entries(bindKeyValues)) {
        if (val.value === activeValue) {
          activeKey = key as MUST_BIND_ITEM
          break
        }
      }

      return { bindKeyValues, activeKey, activeValue }
    }
  },
  getters: {
    /**
     * 213业主单独屏蔽掉安全中心：手机、邮箱、谷歌验证器、手势识别、三方账户绑定
     * 测试025 体验033 也按照213业主屏蔽，方便测试
     */
    is213SiteCode() {
      return ['025', '033', '213'].includes(siteConfig.siteCode ?? '')
    },
    /**
     * 是否可以跳过第一步的密码验证
     */
    isCanSkipPasswordValid(): boolean {
      return (
        !!this.loginPass &&
        (this.isLoginedByPassword || this.isLoginedByRegister) &&
        useMainStore().isFromBindModal &&
        this.isBinding
      )
    },
    /**
     * 是否可以跳过第二步的二次验证
     *
     */
    isCanSkipSecondVerifyWhenBind(): boolean {
      return (
        useMainStore().isFromBindModal &&
        this.hasForceBindBySecondVerifyDone &&
        this.isBinding
      )
    }
  }
})
