import { CPFDateConditionEnum } from '../type'
import {
  CurrentPeriodRewardResponse,
  RechargeFundSettingResponse,
  RechargeItem
} from '@/api/rechargeFund/type'
import { GlobalConst } from '@/context'
import {
  apiGetCurrentPeriodReward,
  apiGetRechargeFundSetting,
  apiGetRechargeList,
  rechargeFundReceive
} from '@/api/rechargeFund'
import { defineStore } from 'pinia'
import { useMainStore } from '@/store/index'
import to from 'await-to-js'

type State = {
  rechargeList: RechargeItem[]
  totalRecharge: number
  rechargeSetting?: RechargeFundSettingResponse
  currentPeriodReward?: CurrentPeriodRewardResponse
  /** 是否刷新 */
  isRefresh: boolean
  listError: Error | null
  settingError: Error | null
  rewardError: Error | null
}

const useRechargeFundStore = defineStore('rechargeFund', {
  state: (): State => {
    return {
      totalRecharge: 0,
      rechargeList: [],
      rechargeSetting: undefined,
      currentPeriodReward: undefined,
      isRefresh: false,
      listError: null,
      settingError: null,
      rewardError: null
    }
  },
  getters: {
    isError(): Error | null {
      return this.listError || this.settingError || this.rewardError
    }
  },
  actions: {
    /**
     * @description 充值明细列表
     */
    async getRechargeList(time_day: number) {
      const { useFastLogin, isDemoMode } = useMainStore()
      if (!useFastLogin || isDemoMode)
        return {
          data: [],
          total: 0
        }
      const [err, data] = await to(apiGetRechargeList({ time_day }))
      this.listError = err
      if (err) {
        this.rechargeList = []
        this.totalRecharge = 0
        return { err, data: [], total: 0 }
      }

      this.rechargeList = data.list
      this.totalRecharge = data.current_query_total_recharge
      return {
        data: data.list,
        total: data.list.length
      }
    },
    /**
     * 初始化
     */
    initRecharge() {
      const { hasLogined } = useMainStore()
      this.getRechargeSetting()
      if (hasLogined) {
        this.getCurrentPeriodReward()
        this.getRechargeList(CPFDateConditionEnum.LAST_THIRTY_DAY)
      }
    },
    /**
     * @description 活动配置
     */
    async getRechargeSetting() {
      const [err, data] = await to(apiGetRechargeFundSetting())
      this.settingError = err
      if (err) {
        return
      }
      this.rechargeSetting = data
    },
    /**
     * @description 当前周期奖励
     */
    async getCurrentPeriodReward() {
      const { useFastLogin, isDemoMode } = useMainStore()
      if (!useFastLogin || isDemoMode) return
      const [err, data] = await to(apiGetCurrentPeriodReward())
      this.rewardError = err
      if (err) {
        return
      }

      this.currentPeriodReward = data
    },
    /**
     * @description 取出公积金
     */
    async withdrawCPF() {
      const { useFastLogin, isDemoMode } = useMainStore()
      if (!useFastLogin || isDemoMode) return false
      try {
        const res = await rechargeFundReceive({
          receive_log_id: this.currentPeriodReward?.receive_log_id || 0
        })
        // 刷新余额
        if (res.code === GlobalConst.ServiceCode.SUCCESS) {
          const data = await apiGetCurrentPeriodReward()
          this.currentPeriodReward = data
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    /** 周期结束刷新数据 */
    updateTamp() {
      useRechargeFundStore().getCurrentPeriodReward()
    },
    /**
     * 更新数据
     */
    async updateData(time_day: number) {
      this.isRefresh = true
      await Promise.all([
        this.getCurrentPeriodReward(),
        this.getRechargeSetting(),
        this.getRechargeList(time_day)
      ])
      this.isRefresh = false
    }
  }
})

export default useRechargeFundStore
