import { isEqual } from 'lodash'
// import Logger from '@/utils/Logger'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MyAny = any
const map = ((window as MyAny).logsMap = new Map<string, Array<MyAny>>())
/** 收集这个日志内容 */
export const collectLogs = (key: string, val: MyAny) => {
  let arr = map.get(key)
  if (!arr) {
    arr = []
    map.set(key, arr)
  }
  const exist = arr.some((_val) => {
    return isEqual(_val, val)
  })
  if (!exist) {
    arr.push(val)
    // Logger.success(
    //   `日志信息
    //   ${key}-${key}-${key}-${key}
    // `,
    //   JSON.parse(JSON.stringify(arr))
    // )
  }
}
