import { PopTypeAfterLogin, PopTypeBeforeLogin } from './enum'
import { TaskRuleStatusEnum } from '../../../tasks-modal-dialog/class/TaskRule'
import { useMainStore } from '@/store/index'
import type {
  JudgePopupTypeCutOnce,
  JudgePopupTypeNewCutOnce
} from './judgePopupTypeCutOnce'
import type { JudgePopupTypeFirstCharge } from './judgePopupTypeFirstCharge'
import type { JudgePopupTypeSign } from './judgePopupTypeSign'
import type { JudgePopupTypeTask } from './judgePopupTypeTask'
import type { JudgePopupTypeWaitingGet } from './judgePopupTypeWaitingGet'

/** 类型与处理函数的映射 */
export type TypeFunMapper = Record<PopTypeAfterLogin, () => boolean> &
  Record<PopTypeBeforeLogin, () => boolean>

/** 判断弹窗配置类型抽象模板  */
export abstract class JudgePopupTypeTemplate {
  /** 首充实例 */
  public static firstCharge: JudgePopupTypeFirstCharge
  /** 任务实例 */
  public static task: JudgePopupTypeTask
  /** 待领取实例 */
  public static waitingGet: JudgePopupTypeWaitingGet
  /** 签到活动实例 */
  public static sign: JudgePopupTypeSign
  /** 抽奖助力活动实例 */
  public static cutOnce: JudgePopupTypeCutOnce
  /** 新砍一刀活动实例 */
  public static newCutOnce: JudgePopupTypeNewCutOnce
  /** 获得本次需要判断的状态的列表 */
  protected abstract getStatusList(): TaskRuleStatusEnum[]
  /** 获取今天是否已经打开过 */
  protected abstract getIsHaveOpenedToday(): boolean
  /** 获取曾经是否已经打开过 */
  protected abstract getIsHaveOpenedEverMapper(): boolean
  /** 获取登录前的配置类型 */
  protected abstract getBeforeLoginType(): PopTypeBeforeLogin
  /** 获取登录后的配置类型 */
  protected abstract getAfterLoginType(): PopTypeAfterLogin
  /** 自定义处理方式,用于覆盖默认的处理函数 */
  protected customTypeFun?: Partial<TypeFunMapper>

  /** 执行判断函数 */
  run(params: {
    /** 是否由登录触发 */
    isLoginTrigger: boolean
  }): boolean {
    const { isLoginTrigger } = params
    const statusList = this.getStatusList()
    // 可能用到的类型
    const { Goto, Processing, PendingReceive } = TaskRuleStatusEnum
    /** 配置类型与判断函数的映射 */
    const typeFunMapper = {
      [PopTypeAfterLogin.onceDay]: () => !this.getIsHaveOpenedToday(),
      [PopTypeAfterLogin.onceForever]: () => !this.getIsHaveOpenedEverMapper(),
      [PopTypeAfterLogin.constantly]: () =>
        this.judgeIsStatusExpected(
          statusList,
          Goto,
          Processing,
          PendingReceive
        ),
      [PopTypeAfterLogin.never]: () => false,
      [PopTypeAfterLogin.waitingComplete]: () =>
        this.judgeIsStatusExpected(statusList, Goto, Processing),
      [PopTypeAfterLogin.waitingReceive]: () =>
        this.judgeIsStatusExpected(statusList, PendingReceive),
      [PopTypeAfterLogin.everyLogining]: () => isLoginTrigger
    } as TypeFunMapper
    // 用子类自定义的处理函数覆盖掉默认的处理函数
    Object.assign(typeFunMapper, this.customTypeFun)
    /** 当前弹窗状态使用的类型 */
    const type = useMainStore().hasLogined
      ? this.getAfterLoginType()
      : this.getBeforeLoginType()
    // 返回是否打开弹窗的结果
    return typeFunMapper[type]()
  }

  /**
   * 判断状态是否如预期,只要有一个预期的状态则返回true,否则返回false
   * @param statusList 当前需要过滤的状态列表
   * @param expectedStatusList 预期的状态内容
   */
  private judgeIsStatusExpected(
    statusList: TaskRuleStatusEnum[],
    ...expectedStatusList: TaskRuleStatusEnum[]
  ) {
    /** 是否存在预期的状态 */
    const isHasExpectedStatus = statusList.some((itemStatus) => {
      if (expectedStatusList.includes(itemStatus)) {
        return true
      } else {
        return false
      }
    })
    return isHasExpectedStatus
  }
}
