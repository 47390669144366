import { FirstChargePopConfigWeb } from '../../api/apiConstantFirstCharge'

/** 活动配置 */
export class ActivityConfig {
  /** 活动id */
  activityId = 0
  /** 活动名称 */
  activityName = ''
  /** 开始时间-时间戳 */
  startTime = 0
  /** 结束时间-时间戳 */
  endTime = 0
  /** 活动模板类型 */
  template = 0
  /** 活动结束的展示时间 */
  endShowTime = 0
  /** 注入弹窗配置 */
  public static immitPopFirstChargeConfig: (
    target: ActivityConfig,
    config: FirstChargePopConfigWeb
  ) => ActivityConfig
}
/** 注入接口返回的已转换成web的数据 */
ActivityConfig.immitPopFirstChargeConfig = function (
  target = new ActivityConfig(),
  config: FirstChargePopConfigWeb
) {
  target.activityId = config.id
  target.activityName = config.name
  target.startTime = config.startTime
  target.endTime = config.endTime
  target.template = config.template
  target.endShowTime = config.endShowTime
  return target
}
