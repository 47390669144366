import {
  ActivityTemplate,
  AfterRechargeActivityItem,
  AfterRechargeActivityTypeEnum,
  ApiDepositEventGet,
  ApiEventGet,
  ApiEventListedRewards,
  ReceiveStatusEnum
} from '@/api/event/type'
import { AxiosResponse } from 'axios'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { Component, Prop } from '@/vue-property-decorator'
import { GlobalConst, GlobalManager } from '@/context'
import { PortalTarget } from 'portal-vue'
import { RouteName } from '@/router/config'
import { apiActiveReceiveOne, apiEventGet } from '@/api/event'
import {
  canReceiveReward,
  getMosaicValidTips,
  showErrorMessageModal
} from '@/views/event/item/utils'
import { useEventStore } from '@/views/event/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AutoShrinkText from '@/components/auto-shrink-text'
import DiscountCode from '@/views/event/item/component/discount-code'
import EventItemListedRewards from '@/views/event/item/component/listed-rewards'
import EventItemRedPack from '@/views/event/item/component/red-pack'
import EventItemSign from '@/views/event/item/component/sign'
import Modal from '@/controller/Modal'
import ModalBase from '@/mixins/business/modal/modal-base'
import PublicIntroduction from '@/views/event/item/component/public-introduction'
import QRCode from '@/components/business-components/qr-code'
import RechargeFund from '@/views/rechargeFund'
import router from '@/router'
import to from 'await-to-js'
import useRechargeFundStore from '@/views/rechargeFund/store'

type State = {
  /** 活动数据 */
  eventData?: ApiEventGet
  /** 是否正在加载 */
  isFetching: boolean
  /** * 是否正在一键领取 */
  isCollectAllPending: boolean
}

interface Props {
  /** 活动 id */
  activeTab: number | string
}

@Component<AfterRechargeActivityTabItem>({
  name: 'AfterRechargeActivityTabItem'
})
export default class AfterRechargeActivityTabItem extends ModalBase<
  State,
  Props
> {
  state: State = {
    isFetching: false,
    isCollectAllPending: false,
    eventData: undefined
  }

  @Prop()
  activeTab!: Props['activeTab']

  /** 选中数据 */
  private get activeData() {
    const eventItem = this.cateIdToActivity.find(
      (i) => +i.id === +this.activeTab
    )
    if (eventItem) {
      return eventItem as unknown as AfterRechargeActivityItem
    }
    return {} as AfterRechargeActivityItem
  }

  /** 详情数据
   * 一种是请求活动详情或者公积金的数据
   * 活动详情参考新人活动弹窗
   * 一种是返回的接口配置第一个数据
   */
  private get event() {
    return (this.state.eventData || {}) as ApiEventGet
  }

  /**
   * tab切换 的列表数据
   */
  private get cateIdToActivity() {
    const { afterRechargeActivityList } = useEventStore()
    return afterRechargeActivityList
  }

  mounted() {
    this.getDetail()
  }

  /**
   * tab切换列表的时候
   */

  /**
   * 请求 详情数据
   */
  private async getDetail() {
    // 请求公积金
    if (
      this.activeData.activeType ===
      AfterRechargeActivityTypeEnum.RechargeFundActive
    ) {
      // console.log('xxxxx 请求公积金')
      useRechargeFundStore().initRecharge()
    } else if (
      this.activeData.activeType ===
      AfterRechargeActivityTypeEnum.CommonActivity
    ) {
      // 请求活动详情
      const [err, eventData] = await to(
        apiEventGet(Number(this.activeTab), true)
      )
      if (err) {
        // console.log(err)
        return
      }
      console.log('xxxxx 请求活动详情', JSON.parse(JSON.stringify(eventData)))
      this.setState({
        eventData
      })
    }

    return {}
  }

  /**
   * 渲染每个类型的活动
   */
  private randerDetail() {
    // let content = <template></template>
    const { template, activeType, id } = this.activeData

    const map = new Map([
      // 渲染充值活动
      [
        template === ActivityTemplate.Deposit &&
          activeType === AfterRechargeActivityTypeEnum.CommonActivity,
        () => (
          <EventItemListedRewards
            event={this.event as ApiDepositEventGet}
            key={id}
            fetchData={() => {
              this.getDetail()
            }}
          />
        )
      ],
      // 签到活动
      [
        template === ActivityTemplate.DailyBonus &&
          activeType === AfterRechargeActivityTypeEnum.CommonActivity,
        () => (
          <EventItemSign
            event={this.event}
            key={id}
            activeId={id}
            isHomeModal={false}
          />
        )
      ],
      // 红包活动
      [
        template === ActivityTemplate.RedPack &&
          activeType === AfterRechargeActivityTypeEnum.CommonActivity,
        () => <EventItemRedPack event={this.event} key={id} />
      ],
      // 新人彩金活动
      [
        template === ActivityTemplate.DiscountCode &&
          activeType === AfterRechargeActivityTypeEnum.CommonActivity,
        () => {
          return (
            <DiscountCode
              isModal={false}
              activeId={id}
              event={this.event}
              isAfterRechargeModal
              key={id}
              onSuccess={() => {
                this.getDetail()
              }}
            />
          )
        }
      ],
      // 公积金
      [
        activeType === AfterRechargeActivityTypeEnum.RechargeFundActive,
        () => <RechargeFund isModal isPayAfter class="rechargefund-box" />
      ]
    ])

    return (
      <div class="modal-detail-content" key={this.activeData.id}>
        {map.get(true)?.()}
      </div>
    )
  }

  private renderQRCode() {
    const data = this.event
    const receiveDeviceType: string = data?.receiveDeviceType ?? ''
    const { errorMessage } = getMosaicValidTips(receiveDeviceType)
    return (
      <div>
        <div>{errorMessage}</div>
        <QRCode />
      </div>
    )
  }

  /**
   * 一键领取
   */
  async handleCollectAll() {
    const { t } = useI18n()
    const eventStore = useEventStore()
    const data = this.event
    if (!canReceiveReward(data?.receiveDeviceType, this.renderQRCode)) {
      return
    }

    const active = data?.activeData as ApiEventListedRewards
    const receiveList = active?.receiveList ?? []
    // 获取到列表中 待领取记录表id
    const receiveIds: number[] = []
    if (receiveList?.length) {
      receiveList.forEach((item) => {
        if (
          !item.receiveDuration &&
          item.receiveStatus === ReceiveStatusEnum.CanReceive
        ) {
          receiveIds.push(item?.receiveId)
        }
      })
    }

    if (receiveIds?.length === 0) {
      showErrorMessageModal(t('lobby.event.listedRewards.noRewards') as string)
      return
    }

    this.setState({
      isCollectAllPending: true
    })

    const [err] = await to(
      apiActiveReceiveOne({
        activeId: +this.activeTab,
        receiveId: receiveIds.join(',')
      })
    )

    this.setState({
      isCollectAllPending: false
    })

    // console.log('xxxxxx handleCollectAll', err)

    if (err) {
      const response = err as unknown as AxiosResponse
      const errorMsg: string = response?.data?.msg ?? ''
      showErrorMessageModal(errorMsg)
      // console.log('collectAll error:', err)
      this.getDetail()
      return
    }

    Modal.message({
      type: 'success',
      content: t('lobby.event.invest.receiveSuccess')
    })
    const mainStore = useMainStore()
    mainStore.audioReceivePlay()
    mainStore.initGetredDot(true)
    eventStore.freshEventCategoryAndActivity()
    await this.getDetail()
    mainStore.updatePrize()
  }

  render() {
    const { t } = useI18n()
    const { template, activeType } = this.activeData
    /**
     * 禁用条件需要重新梳理
     * 一键领取 是否禁用状态
     * 目前只对充值和打码活动有效
     */
    const getDisabled = () => {
      if (ActivityTemplate.Deposit === template) {
        const receiveList =
          (this.event?.activeData as ApiEventListedRewards)?.receiveList ?? []
        const canReceive = receiveList.some(
          (it) => it.receiveStatus === ReceiveStatusEnum.CanReceive
        )
        return !canReceive
      }
      return false
    }

    return (
      <div>
        <my-scroll class="event-modal-scroll" direction={'y'}>
          {this.randerDetail()}
          {/* 活动规则区域 */}
          {activeType !== AfterRechargeActivityTypeEnum.RechargeFundActive &&
            template !== ActivityTemplate.DailyBonus && (
              <PublicIntroduction
                eventData={this.event as ApiEventGet}
                isShowBottom={false}
                class="introduction"
              />
            )}
        </my-scroll>
        <div class="bottom-btns">
          {/* 用于新人彩金活动，内部传出到此处展示 */}
          <PortalTarget
            class="bottom-portal-box"
            v-show={
              template === ActivityTemplate.DiscountCode &&
              activeType === AfterRechargeActivityTypeEnum.CommonActivity
            }
            name={GlobalConst.PortalName.AfterRechargeActivityBottom}
          ></PortalTarget>
          {/* 充值活动的按钮 */}
          {template === ActivityTemplate.Deposit &&
            activeType === AfterRechargeActivityTypeEnum.CommonActivity && [
              <Button.Tsx
                onClick={() => {
                  GlobalManager.Modal.close('afterRechargeActivity')
                  router.push({ name: RouteName.RECORDS })
                }}
                type={'primary'}
              >
                <AutoShrinkText
                  limitHeight={`0.4rem`}
                  minFontSize={`0.12rem`}
                  text={<inner-html text={t('lobby.promote.pickUpRecord')} />}
                ></AutoShrinkText>
              </Button.Tsx>,
              <Button.Tsx
                loading={this.state.isCollectAllPending}
                onClick={this.handleCollectAll}
                disabled={getDisabled()}
                type={'success'}
              >
                <AutoShrinkText
                  limitHeight={`0.4rem`}
                  minFontSize={`0.14rem`}
                  text={t('lobby.modal.task.collectAll') as string}
                />
              </Button.Tsx>
            ]}
        </div>
      </div>
    )
  }
}
