import { CurrencyCode } from '@/config/currencies.config'
import { GameCategoryIds } from '../consts/categorys'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { Language } from '@/i18n'
import { PlatformCategoriesItemInter } from '@/service/game/entity/gameCategorie'

export type SearchPayload = number | string | Array<number | string>

export enum SearchType {
  GameCategorieId = 'gameCategoryId',
  PlatformId = 'platformId',
  GameName = 'gameName',
  GameId = 'gameId'
}

/** 搜索策略类型 */
export type SearchStrategy = (
  game: GameItemInter,
  payload: SearchPayload
) => boolean

export type SearchStrategies = {
  [K in SearchType]: SearchStrategy
}

export type TryModeGameDataType = Partial<
  Record<
    Language,
    Record<
      CurrencyCode,
      {
        recentGameListData: Array<GameItemInter>
        collectGameListData: Array<GameItemInter>
      }
    >
  >
>

export type CategoriesPayload = Record<number, Array<Array<GameItemInter>>>

export type PlatformCatgoryType =
  | GameCategoryIds.Chess
  | GameCategoryIds.Fish
  | GameCategoryIds.Electronic
  | GameCategoryIds.BlockChain
  | GameCategoryIds.Demo

export interface HomeCategories {
  key: GameCategoryIds
  listData: Array<PlatformCategoriesItemInter>
  isLeft?: boolean
  isRight?: boolean
  totalData?: Array<PlatformCategoriesItemInter>
  dataNum?: number
}

/**支持WG自营体育的板式
 * 亚太风
 * 欧美
 * 欧美简约
 */
export const SupportSelfSportLayoutList = {
  /**亚太风 */
  Asia: 1,
  /**欧美 */
  EuropeanAmerican: 2,
  /**欧美简约 */
  Stake: 4
}
