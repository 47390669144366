/** 任务分类对应的发送请求的Api的Code  [101:新人福利] [102:每日任务] [103:每周任务] [104:神秘任务]  */
export enum TaskCategoryApiCodeEnum {
  /** [101:新人福利] */
  xrfl = 101,
  /** [102:每日任务]  */
  mrrw = 102,
  /** [103:每周任务] */
  mzrw = 103,
  /** [104:神秘任务]  */
  smrw = 104
}

/** 活动弹框类型 [1:充值弹框] [2:首页更多奖励弹框] [101:新人福利] [102:每日任务] [103:每周任务] [104:三日任务] */
export type ActivityType = 1 | 2 | 101 | 102 | 103 | 104
