/**
 * 此文件用于处理盘口展示数据，通用业务代码逻辑
 * https://docs.google.com/spreadsheets/d/1sxyqS1D1rFSmHOyZ7znGKmQkebhf1UbZCxENwLWA7Ms/edit#gid=1004478416
 */

import {
  BetTypeValueof,
  HandicapAreas,
  HandicapAreasValueof,
  LockPrice,
  MaxParlayLenght,
  SportType,
  TeamType
} from '../const'
import {
  BetTypeView,
  IParlayBetInfo,
  Market,
  MarketSelection,
  MatchEvent,
  OddsPriceInfo
} from '@/api/selfoperated-games/sports/type'
import { GlobalManager } from '@/context'
import {
  HandicapBetTpes,
  MoneyLine,
  OverUnderBetTypes,
  WinDrawLoseBetTypes,
  getCurrentPrice,
  getPoint
} from '.'
import { cloneDeep } from 'lodash'
import { useI18n } from '@/i18n'
import { useSportsStore } from '../store'

/**
 * @param point 球头
 * @param betType 盘口类型：让球、大小、胜负
 * @param key 主队客队 和： h a 1 x 2
 * @returns 返回页面显示球头
 */
export const getCurrentPoint = (
  point: number,
  betType: number,
  key: string | number
): string => {
  const { t } = useI18n()
  const balls = getPoint(point)
  let label = ''
  if (OverUnderBetTypes.includes(betType)) {
    label =
      key?.toString() === TeamType.Home
        ? (t('lobby.selfoperatedGames.sports.big') as string)
        : (t('lobby.selfoperatedGames.sports.small') as string)
  }
  if (MoneyLine.includes(betType)) {
    label =
      key?.toString() === TeamType.Home
        ? (t('lobby.selfoperatedGames.sports.homewin') as string)
        : (t('lobby.selfoperatedGames.sports.awaywin') as string)
  }
  if (WinDrawLoseBetTypes.includes(betType)) {
    switch (key?.toString()) {
      case '1':
        label = t('lobby.selfoperatedGames.sports.homewin') as string
        break
      case 'x':
        label = t('lobby.selfoperatedGames.sports.foreven') as string
        break
      case '2':
        label = t('lobby.selfoperatedGames.sports.awaywin') as string
        break
    }
  }
  return label + balls
}

/**
 * 设置盘口最新赔率
 * @param oddsPrice 赔率选项
 * @param price 最新赔率
 */
export const setCurrentPrice = (oddsPrice: OddsPriceInfo, price: number) => {
  const { handicapAreasKey } = useSportsStore()
  oddsPrice[
    handicapAreasKey === HandicapAreas.Europe ? 'decimalPrice' : 'hongKongPrice'
  ] = price
}
/**
 * 计算赔率上升&下降
 * @param view 盘口数据
 * @param newPrice 最新赔率
 */
const caculateUpOrDown = (view: BetTypeView, newPrice: number) => {
  if (view.price !== LockPrice) {
    if (view.price < newPrice) {
      view.priceStatus = 'up'
    } else if (view.price > newPrice) {
      view.priceStatus = 'down'
    } else {
      view.priceStatus = 'equal'
    }
  } else {
    view.priceStatus = 'equal'
  }
}
/**
 * 计算让球数、大小球数，赔率
 * @param selection Selection 盘口赔率项目列表
 * @param view BetTypeView
 * @param index number
 */
export const appenBallsPrice = (
  selection: MarketSelection,
  view: BetTypeView,
  index: number
) => {
  /**
   * 让球盘索引
   */
  const HandicapIndex = 0
  /**
   * 大小盘索引
   */
  const OverUnderIndex = 1
  const newPrice = getCurrentPrice(selection)
  caculateUpOrDown(view, newPrice)
  view.price = newPrice
  if (index === HandicapIndex) {
    // 计算 0.25/0.75 的情况
    view.balls = getPoint(selection.point)
  } else if (index === OverUnderIndex) {
    // 大小数值
    view.balls = selection.point?.toString() || ''
  }
}

/**
 * 将market 和 selection 注入到 BetTypeView 对象
 * @param market Market
 * @param typeView BetTypeView[]
 */
export const appendMarkeSelection = (
  market: Market,
  typeView: BetTypeView[]
) => {
  typeView.some((view, idx) => {
    if (
      view.betTypes.includes(market.betType) &&
      market.selections.length > 0
    ) {
      view.market = market
      const exist: boolean = market.selections.some((sel) => {
        sel.eventId = market.eventId
        sel.marketId = market.marketId // 投注弹框选中使用
        if (sel.key === view.key) {
          view.selection = sel
          appenBallsPrice(sel, view, idx)
          return true
        }
      })
      return exist
    }
  })
}

/*
  获取当前区域盘口下的体育支持哪些投注类型
 * 投注类型备注：
 *
 * 欧洲盘，足球投注类型为 让球、大小、胜平负 SportType.Soccer
 * 欧洲盘，篮球投注类型为 让球、大小、胜负 SportType.Basketball
 * 欧洲盘，其他投注类型为 让球、胜负
 *
 * 香港盘,  足球投注类型为 让球、大小 SportType.Soccer
 * 香港盘   篮球投注类型为 让球、大小  SportType.Basketball
 * 香港盘   其他投注类型为 让球
 */
export const getBetTypeList = () => {
  const { handicapAreas, sportKey: sportType } = useSportsStore()
  const SoccerSportType = sportType === SportType.Soccer
  const BasketballSportType = sportType === SportType.Basketball
  const OtherSportType = !SoccerSportType && !BasketballSportType
  const IsEurope = handicapAreas === HandicapAreas.Europe
  let betTypeList: BetTypeValueof[] = []
  const filteredHandicapBetTpes = HandicapBetTpes
  const filteredOverUnderBetTypes = OtherSportType ? [] : OverUnderBetTypes
  const filteredWinDrawLoseBetTypes =
    IsEurope && SoccerSportType ? WinDrawLoseBetTypes : []
  const filteredMoneyLine = IsEurope && !SoccerSportType ? MoneyLine : []
  betTypeList = betTypeList.concat(
    filteredHandicapBetTpes,
    filteredOverUnderBetTypes,
    filteredWinDrawLoseBetTypes,
    filteredMoneyLine
  )
  return betTypeList
}

/**
 *
 * @param sportType
 * @returns: { marketTitles, equalMarket, homeMarkets, awayMarkets }
 * 投注类型备注：
 *
 * 欧洲盘，足球投注类型为 让球、大小、胜平负 SportType.Soccer
 * 欧洲盘，篮球投注类型为 让球、大小、胜负 SportType.Basketball
 * 欧洲盘，其他投注类型为 让球、胜负
 *
 * 香港盘,  足球投注类型为 让球、大小 SportType.Soccer
 * 香港盘   篮球投注类型为 让球、大小  SportType.Basketball
 * 香港盘   其他投注类型为 让球
 */
export const getMarketViewData = (param: {
  sportType: number
  handicapAreas: HandicapAreasValueof
}) => {
  const { t } = useI18n()
  let marketTitles: Array<() => string> = [],
    homeMarkets: BetTypeView[] = [],
    awayMarkets: BetTypeView[] = [],
    equalMarket: BetTypeView[] = []
  const { sportType, handicapAreas } = param
  const SoccerSportType = sportType === SportType.Soccer
  const BasketballSportType = sportType === SportType.Basketball
  const OtherSportType = !SoccerSportType && !BasketballSportType
  const IsEurope = handicapAreas === HandicapAreas.Europe
  const title = [
    {
      key: 'handicap',
      label: () => t('lobby.selfoperatedGames.sports.handicap') as string,
      hidden: false
    },
    {
      key: 'bigsmall',
      label: () => t('lobby.selfoperatedGames.sports.bigsmall') as string,
      hidden: OtherSportType
    },
    {
      key: 'winloseequal',
      label: () => t('lobby.selfoperatedGames.sports.winloseequal') as string,
      hidden: !(IsEurope && SoccerSportType)
    },
    {
      key: 'winlosee',
      label: () => t('lobby.selfoperatedGames.sports.winlosee') as string,
      hidden: !(IsEurope && !SoccerSportType)
    }
  ]
  homeMarkets = [
    {
      label: () => '',
      balls: '',
      price: LockPrice, // 首次默认值，避免影响计算当前价格变化
      market: {} as Market,
      selection: {} as MarketSelection,
      key: TeamType.Home,
      betTypes: HandicapBetTpes,
      priceStatus: 'equal', // equal up down
      hidden: false
    },
    {
      label: () => t('lobby.selfoperatedGames.sports.big') as string,
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: TeamType.Home,
      betTypes: OverUnderBetTypes,
      priceStatus: 'equal', // equal up down
      hidden: OtherSportType
    },
    {
      label: () => t('lobby.selfoperatedGames.sports.homewin') as string,
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: '1',
      betTypes: WinDrawLoseBetTypes,
      priceStatus: 'equal', // equal up down
      hidden: !(IsEurope && SoccerSportType)
    },
    {
      label: () => t('lobby.selfoperatedGames.sports.homewin') as string,
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: TeamType.Home,
      betTypes: MoneyLine,
      priceStatus: 'equal', // equal up down
      hidden: !(IsEurope && !SoccerSportType)
    }
  ]
  awayMarkets = [
    {
      label: () => '',
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: TeamType.Away,
      betTypes: HandicapBetTpes, //1：Handicap； 让球， 7：上半场让球，17：下半场让球	h : for home team (主队); a : for away team (客队)
      priceStatus: 'equal', // equal up down
      hidden: false
    },
    {
      label: () => t('lobby.selfoperatedGames.sports.small') as string,
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: TeamType.Away,
      betTypes: OverUnderBetTypes, // 3:Over_Under 大小盘，8：上半场，18下半场	h : for Over (大); a : for Under (小)
      priceStatus: 'equal', // equal up down
      hidden: OtherSportType
    },
    {
      label: () => t('lobby.selfoperatedGames.sports.awaywin') as string,
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: '2',
      betTypes: WinDrawLoseBetTypes, //5:FTp1X2, 胜平负：全场, 独赢盘	1 : for home (主队); x : for even (和局); 2 : for away (客队)
      priceStatus: 'equal', // equal up down
      hidden: !(IsEurope && SoccerSportType)
    },
    {
      label: () => t('lobby.selfoperatedGames.sports.awaywin') as string,
      balls: '',
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: TeamType.Away,
      betTypes: MoneyLine, //5:FTp1X2, 胜平负：全场, 独赢盘	1 : for home (主队); x : for even (和局); 2 : for away (客队)
      priceStatus: 'equal', // equal up down
      hidden: !(IsEurope && !SoccerSportType)
    }
  ]

  equalMarket = [
    {
      label: () => t('lobby.selfoperatedGames.sports.foreven') as string,
      balls: '', // 和
      price: LockPrice,
      market: {} as Market,
      selection: {} as MarketSelection,
      key: 'x',
      betTypes: WinDrawLoseBetTypes, //5:FTp1X2, 胜平负：全场, 独赢盘	1 : for home (主队); x : for even (和局); 2 : for away (客队)
      priceStatus: 'equal', // equal up down
      hidden: !(IsEurope && SoccerSportType)
    }
  ]

  marketTitles = title.filter((item) => !item.hidden).map((item) => item.label)
  homeMarkets = homeMarkets.filter((item) => !item.hidden)
  awayMarkets = awayMarkets.filter((item) => !item.hidden)
  equalMarket = equalMarket.filter((item) => !item.hidden) ?? []

  return { marketTitles, equalMarket, homeMarkets, awayMarkets }
}

/**
 * 串关,判断当前是否可串，数据保存到 store
 */
export const doParlay = (
  eventInfo: MatchEvent,
  market: Market,
  selection: MarketSelection
) => {
  const sportStore = useSportsStore()
  const { parlayBetList, setParlayBetList } = sportStore
  const { t } = useI18n()
  if (eventInfo.isParlay) {
    if (parlayBetList.length >= MaxParlayLenght) {
      GlobalManager.Modal.message({
        type: 'warning',
        content:
          (t('lobby.selfoperatedGames.sports.parlayTip') as string) +
          '：' +
          MaxParlayLenght
      })
      return
    }
    /**同一个赛事同一个盘口得同一个投注类型*/
    let sameMarketIndex = -1
    // 同一个赛事的不同盘口
    let sameEventIndex = -1
    // 同个盘口不可串关
    parlayBetList.forEach((bType, index) => {
      const { marketId, eventId } = market as Market
      const { key } = selection
      if (
        bType.market.marketId === marketId &&
        bType.eventInfo.eventId === eventId &&
        bType.selection.key === key
      ) {
        sameMarketIndex = index
        return
      }
      if (bType.eventInfo.eventId === eventId) {
        sameEventIndex = index
      }
    })
    if (sameMarketIndex !== -1) {
      const cloneParlayBetList = cloneDeep(parlayBetList)
      cloneParlayBetList.splice(sameEventIndex, 1)
      setParlayBetList(cloneParlayBetList)
      GlobalManager.Modal.message({
        type: 'success',
        content: t('lobby.selfoperatedGames.sports.clearBetType') as string
      })
      return
    }
    if (sameEventIndex !== -1) {
      const cloneParlayBetList = cloneDeep(parlayBetList)
      cloneParlayBetList.splice(sameEventIndex, 1, {
        eventInfo,
        market,
        selection
      } as IParlayBetInfo)
      setParlayBetList(cloneParlayBetList)
      GlobalManager.Modal.message({
        type: 'warning',
        content: t('lobby.selfoperatedGames.sports.parlayTip2') as string
      })
      return
    }
    sportStore.appendBetType({
      eventInfo,
      market,
      selection
    } as IParlayBetInfo)
    GlobalManager.Modal.message({
      type: 'success',
      content: t('lobby.selfoperatedGames.sports.addParlaySuccess') as string,
      duration: 1
    })
  } else {
    GlobalManager.Modal.message({
      type: 'warning',
      content: t('lobby.selfoperatedGames.sports.disableParlay') as string
    })
  }
}
