import { Base, Component } from '@/vue-property-decorator'
import { CPFDateConditionEnum } from '../../type'
import { ComTableColumnType } from '@/components/business-components/table/type'
import { CommonLoading, CommonTable } from '@/components/business-components'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { rechargeFundQueryFormat } from '../../util'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import DateRadio from '@/components/business-components/date-radio'
import DateUtils from '@/utils/DateUtils'
import NetRetry, {
  DisplayType
} from '@/components/business-components/net-retry'
import style from '../../style.module.scss'
import useRechargeFundStore from '../../store'

interface State {
  loading: boolean
  condition: {
    dateType: CPFDateConditionEnum
  }
}
const DEFAULT_PAGESIZE = 15

@Component<AccumulHistory>({ name: 'AccumulHistory' })
export default class AccumulHistory extends Base<State> {
  state: State = {
    loading: false,
    condition: {
      dateType: CPFDateConditionEnum.TODAY
    }
  }

  /**
   * 查询
   * @param pageNo
   * @param pageSize
   * @param condition
   * @returns
   */
  private async query(pageNo: number, pageSize: number) {
    const res = await useRechargeFundStore().getRechargeList(
      this.state.condition.dateType
    )
    const { userInfos } = useMainStore()
    res.data?.forEach((item) => {
      item.recharge_time2 = DateUtils.format(item.recharge_time)
      item.add_fund_rate2 = item.add_fund_rate + '%'
      item.recharge_amount2 = currencyFormatter(item.recharge_amount, {
        code: userInfos?.currency,
        symbol: ''
      })

      item.add_fund_amount2 = currencyFormatter(item.add_fund_amount, {
        code: userInfos?.currency,
        symbol: ''
      })
    })
    if (res.err) {
      return res
    }
    return rechargeFundQueryFormat(res, pageNo, pageSize)
  }

  get isMobile() {
    return !useMainStore().isWeb
  }

  /**
   * web 列数据渲染方式
   */
  get defaultColumns() {
    const { t } = useI18n()
    const def: Array<ComTableColumnType> = [
      {
        title: t('lobby.cpf.time'),
        dataIndex: 'recharge_time2',
        key: 'recharge_time2',
        width: BusinessUtils.px2rem(168)
      },
      {
        title: t('lobby.cpf.rechargeAmount'),
        dataIndex: 'recharge_amount2',
        key: 'recharge_amount2',
        width: BusinessUtils.px2rem(110)
      },
      {
        title: t('lobby.cpf.addFundRate'),
        dataIndex: 'add_fund_rate2',
        key: 'add_fund_rate2',
        width: BusinessUtils.px2rem(110)
      },
      {
        title: t('lobby.cpf.addFundAmount'),
        dataIndex: 'add_fund_amount2',
        key: 'add_fund_amount2',
        customRender: (text: string) => (
          <span class={style.tableSend}>{{ text }}</span>
        )
      }
    ]

    return def
  }

  get dateTypes() {
    return [
      CPFDateConditionEnum.TODAY,
      CPFDateConditionEnum.YESTERDAY,
      CPFDateConditionEnum.LAST_SEVEN_DAY,
      CPFDateConditionEnum.LAST_FIFTEEN_DAY,
      CPFDateConditionEnum.LAST_THIRTY_DAY,
      CPFDateConditionEnum.LAST_SIXTY_DAY
    ]
  }

  private refresh() {
    useRechargeFundStore().updateData(this.state.condition.dateType)
  }

  render() {
    const { t } = useI18n()
    const { totalRecharge, currentPeriodReward, isRefresh, listError } =
      useRechargeFundStore()

    return (
      <section class={style.accumulHistoryWrapper}>
        <div class={style.selectWrapper}>
          <DateRadio
            selectMode={true}
            class={style.myDateRadio}
            v-model={this.state.condition.dateType}
            radioList={this.dateTypes as number[]}
          />
          <div class={style.totalAmountWrapper}>
            {t('lobby.cpf.totalAmount')}
            <span class={style.totalAmount}>
              <NetRetry
                class={style.miniTry}
                loading={isRefresh}
                axiosError={listError}
                displayType={DisplayType.InsufficientSpace}
                onRetry={this.refresh}
              >
                {currencyFormatter(totalRecharge || 0, {
                  code: currentPeriodReward?.currency,
                  template: '%v%s',
                  symbol: ''
                })}
              </NetRetry>
            </span>
          </div>
        </div>
        <CommonLoading spinning={this.state.loading}>
          <CommonTable
            query={this.query}
            rowKey={'id'}
            emptyHeight={'auto'}
            ref="recordTable"
            columns={this.defaultColumns}
            virtualLoading={this.isMobile}
            condition={this.state.condition}
            isShowPagination
            defaultPageSize={
              useMainStore().isWeb ? undefined : DEFAULT_PAGESIZE
            }
            reverseBgInTableMode={true}
            attrs={{
              scroll: useMainStore().isWeb ? {} : { y: '8rem' }
            }}
            class={[
              style.table,
              !!listError ? style['hidden-empty-placeholder'] : ''
            ]}
          />
          <NetRetry
            loading={isRefresh}
            displayType={DisplayType.ListData}
            axiosError={listError}
            onRetry={this.refresh}
          ></NetRetry>
        </CommonLoading>
      </section>
    )
  }
}
