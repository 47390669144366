import { GlobalService } from '@/context'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '../../common/util/judgePopupType'
import { merge } from 'lodash'

/** 发送请求,取得首充弹窗的静态任务数据 */
export const apiConstantFirstCharge = async (): Promise<
  FirstChargePopConfigWeb[]
> => {
  return GlobalService.get<Array<FirstChargePopConfigResData>>({
    url: '/active/active_popRecharge',
    customParams: {
      staticKeyOmit: ['platformType'],
      keyOmit: true,
      tryStaticMode: true
    }
  }).then((res) => {
    const retList = (res?.data?.data || []).map((resData) =>
      FirstChargePopConfigWeb.immitResponse(
        new FirstChargePopConfigWeb(),
        resData
      )
    )
    return retList
  })
}
/** 充值类型枚举 */
export enum ChargeTypeEnum {
  /** 固定,0的时候属性丢失,没看懂这个丢失的意思 */
  Regular,
  /** 比例 */
  Proportion,
  /** 区间随机 */
  Interregional
}
/** 首充奖励项条件 */
export class FirstChargeCondition {
  /** 领取id(作主键) */
  receiveId!: number
  /** 需要充值的金额 >= 该值 */
  amount?: number
  /** 奖励金额 [number:234] [string:'55.00,100.00'] */
  reward?: number | string
  /** 奖励比例 */
  ratio?: number
  /** 奖励上限 */
  limitReward?: number
  /** 奖金方式[0:固定,0的时候属性丢失] [1:比例] [2:区间随机] */
  signType: ChargeTypeEnum = ChargeTypeEnum.Regular
}
/** 数据库返回的原始数据 */
type FirstChargePopConfigResData = Omit<
  FirstChargePopConfigWeb,
  'userLevel'
> & {
  userLevel: string
}
/** 首充奖励弹窗配置 */
export class FirstChargePopConfigWeb {
  /** 给对象注入response的值 */
  public static immitResponse: (
    target: FirstChargePopConfigWeb,
    data: FirstChargePopConfigResData
  ) => FirstChargePopConfigWeb
  /** 主键 */
  id = 0
  /** 登录后弹窗方式 */
  afterLoginPopType = PopTypeAfterLogin.never
  /** 登录前弹窗方式 */
  beforeLoginPopType = PopTypeBeforeLogin.never
  /** 结束时间 */
  endTime = 0
  /** 开始时间 */
  startTime = 0
  /** 首充弹窗条件列表 */
  list: FirstChargeCondition[] = []
  /** 活动结束的展示时间 */
  endShowTime = 0
  /** 名称 */
  name = ''
  /** 模板 */
  template = 0
  /** 生效用户层级 */
  userLevel: number[] = []
}
/** 给对象注入response的值 */
FirstChargePopConfigWeb.immitResponse = (
  target: FirstChargePopConfigWeb,
  data: FirstChargePopConfigResData
) => {
  if (!data) {
    return target
  }
  target = merge(target, data)
  // 如果有值,则拆分,无值,直接返回空数组
  target.userLevel = Boolean(data.userLevel)
    ? data.userLevel.split(',').map(Number)
    : []
  return target
}
