import { GlobalManager } from '@/context'
import {
  OpenModalParams,
  proxyPreJudgeOpenWaitingGet
} from './proxyOpenWaitingGetModal'
import { apiGetPopWaitingGetReward } from '../api/apiGetPopWaitingGetReward/index'
import { defineStore } from 'pinia'
import { initState, waitingGetState } from './initState'
import { userRecord } from './initState/userOpeningWaitingGetRecord'
import autoModalManager from '@/context/autoModalManager'

/** 待领取奖励的store */
export type WaitingGetStore = ReturnType<typeof useWaitingGetStore>

/** 待领取奖励的store */
export const useWaitingGetStore = defineStore('waitingGetStore', {
  state: () => initState() as waitingGetState,
  getters: {},
  actions: {
    /** 刷新弹窗数据 */
    async freshPopData() {
      const resPopWeb = await apiGetPopWaitingGetReward()
      waitingGetState.immitPopWaitingGetWeb(this, resPopWeb)
    },
    /** 切换显示弹窗的状态 */
    switchPopAppearStatus(name: 'isNeverRemind' | 'isThisTimeNoRemind') {
      this[name] = !this[name]
      userRecord.recordUserCheckDayOpen(this[name])
    },
    /** 判断弹窗是否能打开 */
    preJudgeOpenWaitingGet(params?: Partial<Omit<OpenModalParams, 'store'>>) {
      // 数据为空,不显示弹窗
      if (
        this.pendingRewardList.length === 0 &&
        this.collectTomorrowList.length === 0 &&
        this.receivedTodayList.length === 0
      )
        return false

      const { isLoginTrigger = false } = params || {}
      return proxyPreJudgeOpenWaitingGet({
        store: this,
        isLoginTrigger
      })
    },
    /** 打开弹窗 */
    openWaitingGetModal() {
      GlobalManager.Modal.open('waitingGet').then((context) => {
        //重点,环境监听,只能使用$once,不然会重复监听越来越多的内容
        context.$once('modal:close', () => {
          autoModalManager.onModalClose('waitingGet')
        })
      })
    },
    /** 记录用户打开过弹窗的行为 */
    recordUserOpeningRecord() {
      this.isHaveOpenedToday = true
      this.isHaveOpenedEver = true
      userRecord.recordUserOpeningRecord()
    },
    /** 重置 */
    myReset() {
      waitingGetState.resetState(this)
    }
  }
})
