import { throttle } from 'lodash'

/** 创建监听器,监听盒子的宽高
 * @param overSeeDom 被监听的dom元素
 * @param callback 回调函数,参数ret包含dom元素内容的宽高(不含border padding)
 * @returns 停止监听
 */
export const createSizeObserver = (
  overSeeDom: HTMLElement,
  callback: (ret: { width: number; height: number }) => void
) => {
  /** ResizeObserver监听的回调函数 */
  const resizeCallback = (entries: ResizeObserverEntry[]) => {
    const { contentRect } = entries[0]
    callback &&
      callback({
        width: contentRect.width,
        height: contentRect.height
      })
  }
  //节流需要包裹上resizeCallback,防止在上一次,还未执行完时,触发新的一次调用,避免报错 ResizeObserver loop completed with undelivered notifications.
  const _resizeCallback = throttle(resizeCallback, 100)
  const resizeObserver = new ResizeObserver(_resizeCallback)
  resizeObserver.observe(overSeeDom)
  return { stop: () => resizeObserver.disconnect() }
}
