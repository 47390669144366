/**
 * 客服类型
 */
export enum OnlineServiceTypeEnum {
  /**
   * 自营客服
   */
  Self,

  /**
   * 第三方客服
   */
  Third
}

/**
 * 其他客服
 */
export enum OtherCustomersTypeEnum {
  Line = 0,
  QQ = 1,
  Telegram = 2,
  WeChat = 3,
  WhatsApp = 4,
  Skype = 5,
  Facebook = 6,
  /**
   * 自定义
   */
  Customer = -1
}

/**
 * 常见问题和热门问题
 */
export enum QuestionTypeEnum {
  NormalQuestionType = 0,
  HotQuestionType = 1
}

/**试玩账号的级别 */
export const DemoLevel = 1
