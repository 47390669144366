import { GlobalService } from '@/context'
import { TaskCategory } from '../class/taskCategory'
import { TaskCategoryApiCodeEnum } from './type'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
// import { mockMapper } from './mock/apiGetTaskActivityInTabs'

/**  通过apiCode获取活动任务Tabs弹框状态  */
export const apiGetTaskActivityInTabs = async (
  apiCode: TaskCategoryApiCodeEnum
) => {
  // taskId与template在TaskCategory中获取
  const taskCategory = new TaskCategory(apiCode)
  const { taskId, template } = taskCategory
  const response = await GlobalService.post<TaskCategory>({
    url: '/activetask/task',
    data: { taskId, template },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
    // 通过业务逻辑实现只在特定情况下请求该接口，则不需要禁止掉它的异常信息
    // customParams: {
    // noErrorMessage: true
    // silentOnError: true
    // }
  })
  if (!response.data?.data) {
    return taskCategory
  }
  taskCategory.immitResponse(response.data.data)
  return taskCategory
}

/** （假数据）通过apiCode获取活动任务Tabs弹框状态 */
// export const _调用假数据_apiGetTaskActivityInTabs = async (
//   apiCode: TaskCategoryApiCodeEnum
// ) => {
//   // taskId与template在TaskCategory中获取
//   const taskCategory = new TaskCategory(apiCode)
//   const resData = mockMapper[apiCode]
//   if (!resData) {
//     return taskCategory
//   }
//   const ret = merge(taskCategory, resData) as TaskCategory
//   return ret
// }
