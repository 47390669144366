import { GameCategoryIds } from '../../consts/categorys'
import { GameState } from './type'
import { RouteName } from '@/router/config'
export type { GameState } from './type'
export const initState = (): GameState => ({
  anchorHotGameList: [], //主播热门游戏列表
  tryModeData: {}, //试玩账号多语言多币种最近游戏和收藏游戏列表
  gameCategoriesAvailable: [], //游戏分类列表菜单
  gameCategorieExtLink: [], // 游戏分类自定义外链菜单
  allFavoriteList: undefined, //收藏游戏列表（只存储游戏id）
  allCategoriesGameList: {
    [GameCategoryIds.Chess]: undefined,
    [GameCategoryIds.Fish]: undefined,
    [GameCategoryIds.BlockChain]: undefined,
    [GameCategoryIds.Electronic]: undefined,
    [GameCategoryIds.Demo]: undefined
  }, // 存储游戏分类的游戏列表
  gameCategoryPlatformList: {}, // 游戏类别和平台游戏列表
  allGameList: [], // 所有游戏列表
  selectGameCategoryId: GameCategoryIds.Hot, // 青蓝版
  activeTabkey: -1,
  dynamicGameCategoryId: GameCategoryIds.Hot,
  searchHistory: [],
  gameListLoading: false, // 游戏列表加载loading
  gameParameters: undefined, //游戏参数
  gameTypesShowRid: {},
  hasLuckyBet: false,
  isScrollBottom: false, // 经典版式游戏列表是否滚动到底部
  activeGameCategoryId: GameCategoryIds.Hot, // 当前选中的菜单id
  jackPotAmount: '', //彩金池金额
  // 跳转游戏全屏前的路由和参数（移动端全屏模式个别三方点返回会黑屏）
  preGameEmbeddedParams: {
    name: RouteName.GAME
  },
  platformExchangeObj: {
    platformCurrencyExchange: {},
    memberIntoThirdLimit: {}
  }, // 三方游戏平台汇率
  isGameOpening: false, // 游戏loading
  isGamePlaying: false, // 是否正在游戏中
  isGameScoring: false, // 是否游戏上分中
  isFullScreen: false, // 是否全屏
  isGameLogoutNetError: false, // 退出游戏api是否网络错误
  gameCategoriesNetError: null, // 游戏类别数据网络错误
  gameCategoriesNetErrorLoading: false // 游戏类别数据网络错误loading
})
