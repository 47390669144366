import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { TaskModalConstant } from '../index'
import { createAsyncQueue } from '@/utils/Tool'
import {
  createQueueCountingPromise,
  createTaskModalOpeningQueue,
  getModalQueueLength
} from './taskModalQueue'

/** 通过类型或Code数组打开弹框 */
export type SeekApiCode = {
  /** 类型Code或Code数组 */
  apiCode: TCACE[] | TCACE
  /** 忽略页面地址（此对象多半是不要忽略页面地址，除首页以外基本不要弹出） */
  ignorePageAddress?: boolean
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}
/** 依次打开弹框队列
 * @param store
 * @param param 需要依次打开【多个的apiCode】或者是需要针对性的打开某个【分类下的具体任务】
 */
export const proxyOpenSequentiallyTaskModal = (
  store: TaskModalConstant,
  param: SeekApiCode
) => {
  const { stopModalAndQueue, setStopModalAndQueue } = store
  // 保证每次开启新队列时,将删除掉老队列的内容
  stopModalAndQueue?.()
  setStopModalAndQueue(null)
  const queueLength = getModalQueueLength(param)
  // 创建队列计数器,用来计数当前队列是否全部执行完成
  const { allExePromise, exeOne } = createQueueCountingPromise(queueLength)
  /** 任务弹框打开队列 */
  const { openingQueue, stop } = createTaskModalOpeningQueue(
    store,
    param,
    exeOne
  )
  // 将关闭队列的函数存为静态属性,方便下一次中途开启新队列时关闭本次队列
  setStopModalAndQueue(stop)
  /** 任务弹框队列控制器 */
  const asyncQueueController = createAsyncQueue()
  // 将队列内容装入控制器
  asyncQueueController.push(...openingQueue)
  // 开始挨个执行队列
  asyncQueueController.flush()
  return allExePromise
}
