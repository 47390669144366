import {
  CalculatePolling,
  FontTypeface,
  ShowType,
  VirtualBonusPoolData
} from '@/api/aside/type'
import { FontFace, JackPotFontSize, PlaceKeyType } from '../store/type'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { GameIconStyle } from '@/api/common/type'
import { RouteName } from '@/router/config'
import {
  defaultConfigH5,
  defaultConfigH5HasTab,
  defaultConfigPC,
  footerBannerPC,
  gameCardConfigH5,
  gameCardConfigH5Big,
  gameCardConfigPC,
  gameCardConfigVerticalH5,
  gameCardConfigVerticalH5HasTab,
  gameCardEUConfigH5,
  gameCardEUConfigPC,
  stepSize
} from './config'
import { useGameStore } from '@/views/game/store'
import { useJackPotStore } from '@/views/game/components/jackpotCard/store'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import Skin, { ThemeSkinType } from '@/controller/Skin'
import moment from 'moment'
import router from '@/router'

/**
 * 将后台传回的自定义文字大小配置进行匹配
 * @param key 后台返回的key
 * @param place 展示位置
 * @returns 指定大小数字
 */
export const getFontSize = (
  key: JackPotFontSize,
  hasLeftTab: boolean,
  place?: PlaceKeyType
) => {
  const { isWeb } = useMainStore()
  let map: Record<JackPotFontSize, number> = {
    big: 0,
    middle: 0,
    small: 0
  }
  //  平台卡片配置需要单独设置
  if (place === 'platformCard') {
    map = isWeb
      ? {
          big: 15,
          middle: 15,
          small: 15
        }
      : {
          big: 22,
          middle: 22,
          small: 22
        }
  } else {
    map = isWeb
      ? {
          big: 75,
          middle: 65,
          small: 55
        }
      : {
          big: 42,
          middle: 38,
          small: 32
        }
    // console.log('xxxxxx platformTop', place, hasLeftTab)
    if (!isWeb && hasLeftTab && place === 'platformTop') {
      map = {
        big: 32,
        middle: 28,
        small: 24
      }
    }
  }
  return map[key]
}

/**
 * 将彩金池自定义文字配置转为cssvar配置
 * @param config 当前配置
 * @param place 展示位置
 * @returns cssvar配置
 */
export const getNumericStyle = (
  config: VirtualBonusPoolData,
  hasLeftTab: boolean,
  place?: PlaceKeyType
) => {
  const { numericStyle, fontTypeface = '{}' } = config
  const fontOptions = JSON.parse(fontTypeface || '{}') as FontTypeface
  const resOptions = {} as FontFace
  // 0 为自定义 则需要传入自定义的样式
  if (numericStyle === 0 && fontOptions) {
    resOptions['--jackpot-card-count-box-scale'] = '1'
    resOptions['--jackpot-font-letter-spacing'] = '0.06rem'
    for (const key in fontOptions) {
      if (Object.prototype.hasOwnProperty.call(fontOptions, key)) {
        switch (key) {
          case 'fontFamily':
            resOptions['--jackpot-font-family'] = fontOptions[key]
            break
          case 'textColor':
            resOptions['--jackpot-font-color'] = fontOptions[key]
            break
          case 'fontSize':
            resOptions['--jackpot-font-size'] = BusinessUtils.px2rem(
              getFontSize(
                fontOptions[key] as JackPotFontSize,
                hasLeftTab,
                place
              )
            )
            break
          case 'textStroke':
            const n = BusinessUtils.px2rem(Number(fontOptions[key]) * 2)
            resOptions['--jackpot-font-stroke'] = n
            break
          case 'strokeColor':
            resOptions['--jackpot-font-stroke-color'] = fontOptions[key]
            break
        }
      }
    }
  }
  return resOptions
}

/**
 * 获取 彩金池跳动字符对应的 图片链接
 * @param n 字符
 * @param config 当前配置
 * @returns 字符链接
 */
export const getNumericCharImgPath = (
  n: string,
  config: VirtualBonusPoolData
) => {
  const { numericStyle, showType } = config
  let nChar = ''
  switch (n) {
    case ',':
      nChar = 'dh'
      break
    case '.':
      nChar = 'jh'
      break
    default:
      nChar = n
      break
  }
  return showType === ShowType.onPlatform
    ? `sprite_main_cjc2_style_${numericStyle}_font_${nChar}`
    : `sprite_main_cjc1_style_${numericStyle}_font_${nChar}`
  // ? `/lobby_asset/common/common/home/cjc2_style_${numericStyle}_font_${nChar}.png`
  // : `/lobby_asset/common/common/home/cjc1_style_${numericStyle}_font_${nChar}.png`
}

/**
 * 获取虚拟彩金池背景图配置
 * @param config 彩金池配置
 * @returns 图片位置
 */
export const getBgImg = (config: VirtualBonusPoolData) => {
  let img = ''
  if (config) {
    const { showType, backgroundStyle, backgroundUrl } = config
    if (showType !== ShowType.onPlatform) {
      // 这里的确是除了0为自定义，其他都是1、2、3
      if (backgroundStyle === 0) {
        img = backgroundUrl
      } else {
        img = `/lobby_asset/common/common/home/cjc1_style_${backgroundStyle}_bg.png`
      }
    } else {
      if (backgroundStyle === 0) {
        img = backgroundUrl
      } else {
        img = `/lobby_asset/common/common/home/cjc2_style_${backgroundStyle}_bg.png`
      }
    }
  }
  // return '/lobby_asset/13-1-common/common/home/bg_dt_cjc.png'
  return img
}

/**
 * 获取默认的 cssvar 配置
 * @param place 展示位置
 * @param hasLeftTab 是否有侧边栏
 * @returns 默认cssvar配置
 */
export const getDefaultCssVar = ({
  place,
  hasLeftTab
}: {
  place?: PlaceKeyType
  hasLeftTab?: boolean
}) => {
  const { isClassicStyle } = Skin.currentTheme()
  const { homeLayoutInfos } = useMainStore()
  const isSquare = homeLayoutInfos?.webGameIcon === GameIconStyle.SQUARE_BELOW
  const { isWeb } = useMainStore()
  let cssVar = isWeb ? defaultConfigPC : defaultConfigH5
  // 经典版有侧边栏
  // if (hasLeftTab && isClassicStyle && !isWeb) {
  //   cssVar = defaultConfigH5HasTab
  // }
  // 平台卡片需要重新配置
  // if (place === 'platformCard') {
  //   // 侧边栏的状态需要其他配置
  //   if (hasLeftTab && isClassicStyle && !isWeb) {
  //     cssVar = isSquare ? gameCardConfigH5 : gameCardConfigVerticalH5
  //   } else if (isSquare) {
  //     cssVar = isWeb ? gameCardConfigPC : gameCardConfigH5Big
  //   } else if (!isSquare && !isWeb && hasLeftTab) {
  //     cssVar = gameCardConfigVerticalH5HasTab
  //   } else {
  //     cssVar = isWeb ? gameCardEUConfigPC : gameCardEUConfigH5
  //   }
  // } else if (place && ['footer', 'banner'].includes(place) && isWeb) {
  //   cssVar = footerBannerPC
  // }
  // console.log('xxxxx getDefaultCssVar', place, cssVar, hasLeftTab, isSquare)
  const map = new Map([
    [
      // 平台卡片 游戏列表含有侧边栏 经典版 h5 设置为方形卡片（此时平台卡片为横向长方形）
      place === 'platformCard' &&
        hasLeftTab &&
        isClassicStyle &&
        !isWeb &&
        isSquare,
      gameCardConfigH5
    ],
    [
      // 平台卡片 游戏列表有侧边栏 经典版 h5 设置为竖版卡片
      place === 'platformCard' &&
        hasLeftTab &&
        isClassicStyle &&
        !isWeb &&
        !isSquare,
      gameCardConfigVerticalH5
    ],
    [
      // 平台卡片 游戏列表无侧边栏 pc 设置为方形卡片（此时平台卡片为横向长方形）
      place === 'platformCard' && isWeb && isSquare,
      gameCardConfigPC
    ],
    [
      // 平台卡片 游戏列表无侧边栏 h5 设置为方形卡片（此时平台卡片为横向长方形）
      place === 'platformCard' && !isWeb && isSquare,
      gameCardConfigH5Big
    ],
    [
      // 平台卡片 游戏列表有侧边栏 h5 设置为竖直卡片
      place === 'platformCard' && hasLeftTab && !isWeb && !isSquare,
      gameCardConfigVerticalH5HasTab
    ],
    [
      // 平台卡片 游戏列表无侧边栏 h5 设置为竖直卡片
      place === 'platformCard' && !hasLeftTab && !isWeb && !isSquare,
      gameCardEUConfigH5
    ],
    [
      // 平台卡片 游戏列表无侧边栏 pc 设置为竖直卡片
      place === 'platformCard' && isWeb && !isSquare,
      gameCardEUConfigPC
    ],
    [
      // 非平台卡片 经典版有侧边栏 h5
      place !== 'platformCard' && hasLeftTab && isClassicStyle && !isWeb,
      defaultConfigH5HasTab
    ],
    [
      // 页脚 banner 位置 pc
      place && ['footer', 'banner'].includes(place) && isWeb,
      footerBannerPC
    ]
  ])
  cssVar = map.get(true) || cssVar
  return cssVar
}

/**
 * 根据传入数据，计算该数据中应该跳动的索引
 * 静态化后可能有延迟，所以可以取本地时间戳
 * @param data 当前彩金池数据项，或者在未登录的时候所有的数据也可以
 * @returns 跳动的key
 */
export const getJumpIndex = ({
  virtualPoolTimestamp,
  virtualPoolNumbers
}: CalculatePolling) => {
  const timestamp = moment().valueOf()
  // 当前时间戳 - 彩金池数字生成时间戳
  let jumpIdx = Math.floor(
    (timestamp - virtualPoolTimestamp) / (stepSize * 1000)
  )
  // 兼容数据出错，后端的时间戳，有时候有缓存会导致 服务器时间戳 - 彩金池数字生成时间戳 的数字特别大, 要么特别小
  if (jumpIdx > virtualPoolNumbers.length - 1 || jumpIdx < 0) {
    jumpIdx = jumpIdx < 0 ? 0 : virtualPoolNumbers.length - 1
  }
  return jumpIdx
}

/**
 * 平台卡片上的 虚拟彩金池 可以展示
 */
export const getPlatformCardCountCanShow = ({
  gameCategoryId,
  platformId
}: {
  gameCategoryId: number
  platformId: number
}) => {
  const { getPlaceConfig } = useJackPotStore()
  const jackpotCountConfig = getPlaceConfig(
    'platformCard',
    gameCategoryId as unknown as GameCategoryIds,
    platformId
  )
  // console.log('xxx jackpotCountConfig', platformId, jackpotCountConfig)
  return jackpotCountConfig.length > 0 && getJackpotCanShow()
}

/**
 * 虚拟彩金池 是否可以展示
 */
export const getJackpotCanShow = () => {
  const { siteInfos } = useMainStore()
  return (
    router.currentRoute.name === RouteName.GAME &&
    siteInfos &&
    siteInfos.type &&
    (
      [
        ThemeSkinType.NORMAL,
        ThemeSkinType.EUROPEAN_AMERICAN,
        ThemeSkinType.STAKE,
        ThemeSkinType.THEME_19,
        ThemeSkinType.THEME_34
      ] as Array<number>
    ).includes(siteInfos.type)
  )
}

/**
 * 设置旧数据的彩金池
 */
export const setCommonJackPotAmount = (
  calculatePolling: CalculatePolling[]
) => {
  const { setJackPotAmount } = useGameStore()
  const { virtualBonusPool } = useJackPotStore()
  const firstPolling = (calculatePolling?.filter(
    (i) =>
      virtualBonusPool.find((j) => j.id === i.id)?.showType ===
      ShowType.Independent
  ) || [])[0]
  if (firstPolling) {
    const index = getJumpIndex(firstPolling)
    setJackPotAmount(
      firstPolling.virtualPoolNumbers.length > 0 && index > -1
        ? firstPolling.virtualPoolNumbers[index]?.toString()
        : ''
    )
  }
}

/**
 * 旧彩金池的配置
 * 获取第一个平台配置的项
 */
export const getCommonJackPotConfig = () => {
  const virtualBonusPool = useJackPotStore().getPlaceConfig()
  if (virtualBonusPool && virtualBonusPool.length > 0) {
    // const {decimalPlaces} = virtualBonusPool[0]
    return virtualBonusPool[0]
  }
  return {} as VirtualBonusPoolData
}

/** 根据key获取value */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getKeyByValue = (map: Map<any, any>, targetValue: any): any => {
  for (const [key, value] of map.entries()) {
    if (value === targetValue) {
      return key
    }
  }
  return null
}
