import { State } from './type'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { useMainStore } from '@/store/index'

/** 所有用户打开过的状态,含今天和曾经 */
type AllUserOpenedRecord = {
  /** 用户id对应它的弹窗的日期 */
  [key: string]: {
    [key in TCACE]: number
  }
}
/** state中的两个mapper */
type StateMappers = {
  todayMapper: State['codeIsHaveOpenedTodayMapper']
  everMapper: State['codeIsHaveOpenedEverMapper']
}

/** store中,用户首充弹窗打开记录缓存 */
export class UserOpeningTaskModalRecord {
  /** 私有化构造函数 */
  private constructor() {
    const allUserIsHaveOpenedStr =
      localStorage.getItem(UserOpeningTaskModalRecord.storeKey) || '{}'
    this.allUserOpenedRecord = JSON.parse(
      allUserIsHaveOpenedStr
    ) as AllUserOpenedRecord
  }
  /** 静态实例 */
  private static instance: UserOpeningTaskModalRecord
  /** 获取单例 */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new UserOpeningTaskModalRecord()
    }
    return this.instance
  }

  /** 静态属性,存储的store键名 */
  private static readonly storeKey = 'allUserOpeningTaskModalRecord'
  /** 用户本地记录 */
  private allUserOpenedRecord: AllUserOpenedRecord
  /** 静态函数,专门给store使用的,一次直接获取store中需要的两个mapper */
  public static getUserOpeningRecordStateMappers: (
    ...apiCodes: TCACE[]
  ) => StateMappers
  /** 获取用户打开记录(记录,肯定是一个一个记录的,所以,取的时候还是一个一个取吧) */
  public getUserOpeningRecord(apiCode: TCACE) {
    /** 用户标识(一定存在,未登录就是unlogin) */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    const ret = {
      isHaveOpenedToday: false,
      isHaveOpenedEver: false
    }
    const recordDate = this.allUserOpenedRecord[userSign]?.[apiCode]
    // 日期存在,则以前打开过
    ret.isHaveOpenedEver = Boolean(recordDate)
    // 日期相同,则今日打开过
    const curDate = new Date().getDate()
    if (recordDate === curDate) {
      ret.isHaveOpenedToday = true
    }
    return ret
  }
  /** 记录用户打开记录 */
  public recordUserOpeningRecord(apiCode: TCACE) {
    /** 用户标识(一定存在,未登录就是unlogin) */
    const userSign = String(useMainStore()?.userInfos?.username || 'unlogin')
    if (!this.allUserOpenedRecord[userSign]) {
      this.allUserOpenedRecord[userSign] = {} as { [key in TCACE]: number }
    }
    // 记录用户打开的日期
    this.allUserOpenedRecord[userSign][apiCode] = new Date().getDate()
    const allUserIsHaveOpenedStr = JSON.stringify(this.allUserOpenedRecord)
    localStorage.setItem(
      UserOpeningTaskModalRecord.storeKey,
      allUserIsHaveOpenedStr
    )
  }
}

/** 静态函数,专门给store使用的,一次直接获取store中需要的两个mapper */
UserOpeningTaskModalRecord.getUserOpeningRecordStateMappers = function (
  ...apiCodes: TCACE[]
) {
  // 取得单例
  const userRecord = UserOpeningTaskModalRecord.getInstance()
  // 拼装state需要的mapper
  const stateMappers = apiCodes.reduce(
    (pre, code) => {
      const { isHaveOpenedToday, isHaveOpenedEver } =
        userRecord.getUserOpeningRecord(code)
      pre.todayMapper[code] = isHaveOpenedToday
      pre.everMapper[code] = isHaveOpenedEver
      return pre
    },
    {
      todayMapper: {},
      everMapper: {}
    } as StateMappers
  )
  return stateMappers
}
