import {
  ClubOrFriend,
  GameCategoryIds,
  GamePlatformCatgory,
  HotRecentCollection,
  HotRecentCollectionAndDemo
} from '../consts/categorys'
import { GameIconStyle, HomeLayoutType } from '@/api/common/type'
import { GlobalManager } from '@/context'
import { RouteName } from '@/router/config'
import { useGameStore } from '../store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'

export class GameHelper {
  private get _route() {
    return GlobalManager?.App?.$route
  }

  private get mainStore() {
    return useMainStore()
  }

  private get _webGameIcon() {
    return this.mainStore?.homeLayoutInfos?.webGameIcon
  }

  /** 类型项 */
  private get _gameCategoryItem() {
    const { gameCategoriesAvailable } = useGameStore()
    return gameCategoriesAvailable.find(
      (game) => game.gameCategoryId === this.gameCategoryId
    )
  }

  /** 根据选中的平台id返回对应平台数据 */
  private get _platformItem() {
    return this._gameCategoryItem?.list?.find(
      (game) => game.platformId === this.platformId
    )
  }

  /** 当前游戏分类id */
  get gameCategoryId(): number {
    return Number(this._route.query?.gameCategoryId || -1)
  }

  /** 是否平台游戏类别 */
  get isPlatformGameCatgory(): boolean {
    return GamePlatformCatgory.includes(this.gameCategoryId)
  }

  /** 特殊二级没有侧边栏的页面（热门、最近、收藏）或平台类别游戏（真人、体育、彩票斗鸡等） */
  get isSpecialPlatformGameCatgory(): boolean {
    return (
      this.isPlatformGameCatgory ||
      GameHelper.isHotRecentCollection(this.gameCategoryId)
    )
  }

  /** 二级分类平台类型 */
  get platformId(): number {
    return Number(this._route.query?.platformId || -1)
  }

  /** 是否自定义二级热门页面 */
  get isCustomSubHot(): boolean {
    return this.gameCategoryId === GameCategoryIds.Hot
  }

  /** 是否为中文 */
  get isChinese(): boolean {
    return ['zh_CN', 'zh_TW'].includes(useMainStore().language)
  }

  /** 分类名称 */
  get gameCategoryName(): string {
    const categoryName = (this._gameCategoryItem?.gameCategoryName ||
      '') as string
    return this.isChinese ? categoryName.replace(/\s/g, '') : categoryName
  }

  /** 平台名称 */
  get platformName(): string {
    return (this._platformItem?.platformName || '') as string
  }

  /** 当前路由是否为首页 */
  get isHomePage(): boolean {
    return this._route.name === RouteName.GAME
  }

  /** 当前路由是否二级页面 */
  get isSubGamePage(): boolean {
    return this._route.name === RouteName.SUB_GAME
  }

  /** 当前路由是否为游戏内嵌页面 */
  get isGameEmbeddedPage(): boolean {
    return this._route.name === RouteName.GAME_EMBEDDED
  }

  /** H5模式首页布局（亚太风） */
  get h5HomeLayoutStyle(): HomeLayoutType {
    const { homeLayoutInfos } = this.mainStore
    const topLayout = homeLayoutInfos?.topLayout ?? HomeLayoutType.style0
    // 后端可能会返回2，实际只有0和1
    return [HomeLayoutType.style0, HomeLayoutType.style1].includes(topLayout)
      ? topLayout
      : HomeLayoutType.style0
  }

  /** 竖版游戏卡片风格 */
  get isIconStyleVertical(): boolean {
    return this._webGameIcon === GameIconStyle.VERTICAL_BOTTOM
  }

  /** 方形游戏卡片风格*/
  get isIconStyleSquare(): boolean {
    return this._webGameIcon === GameIconStyle.SQUARE_BELOW
  }

  /**获取自营体育和saba接口的状态集合 */
  get getSelfSportAndSabaStatus() {
    const { getSelfSportMaintainStatus } = useGameStore()
    const { sabaMaintenance } = useMainStore()
    return getSelfSportMaintainStatus || sabaMaintenance
  }

  /** 获取游戏分类信息 */
  public static getGameCategoryItem(id: GameCategoryIds) {
    const { gameCategoriesAvailable } = useGameStore()
    return gameCategoriesAvailable.find((game) => game.gameCategoryId === id)
  }

  /**
   * 当前分类名称
   */
  public static getGameCategoryItemName(id: GameCategoryIds): string {
    const { t } = useI18n()
    const { gameCategoriesAvailable } = useGameStore()
    const { language } = useMainStore()
    const types = new Map([
      [GameCategoryIds.Hot, t('lobby.game.loadingHot')],
      [GameCategoryIds.Recent, t('lobby.game.loadingRecent')],
      [GameCategoryIds.Collenct, t('lobby.game.loadingCollect')],
      [GameCategoryIds.Demo, t('lobby.game.loadingDemo')]
    ])

    const gameName =
      (types.get(id) as string) ||
      (gameCategoriesAvailable.find((game) => game.gameCategoryId === id)
        ?.gameCategoryName as string) ||
      ''

    return ['zh_CN', 'zh_TW'].includes(language)
      ? gameName.replace(/\s/g, '')
      : gameName
  }

  /** 是否为热门最近收藏 */
  public static isHotRecentCollection(id: GameCategoryIds): boolean {
    return HotRecentCollection.includes(id)
  }

  /** 是否为热门最近收藏试玩 */
  public static isHotRecentCollectionAndDemo(id: GameCategoryIds): boolean {
    return HotRecentCollectionAndDemo.includes(id)
  }

  /** 是否为热门最近收藏试玩或游戏平台分类 */
  public static isHotRecentCollectionOrPlatformCategory(
    id: GameCategoryIds
  ): boolean {
    return (
      HotRecentCollectionAndDemo.includes(id) ||
      GamePlatformCatgory.includes(id)
    )
  }

  /** 是否为朋友局或俱乐部 */
  public static isClubOrFriendCategory(id: GameCategoryIds): boolean {
    return ClubOrFriend.includes(id)
  }
}

export const _instance = new GameHelper()
