import { JudgePopupTypeSign } from '@/views/global-modal/modal/common/util/judgePopupType/judgePopupTypeSign'
import { judgeIsForbidPopByPageAddress } from '@/views/global-modal/modal/common/util/judgeIsForbidPopByPageAddress'

export type OpenModalParams = {
  /** 是否登录触发 */
  isLoginTrigger: boolean
  /** 是否永不提示 */
  isNeverPop: boolean
  /** 是否本次不再提示提示 */
  isThisTimeNoPop: boolean
}

const judgePopupTypeSign = new JudgePopupTypeSign()

/** 判断签到弹窗是否可以打开 */
export const proxyPreJudgeOpenSign = (params: OpenModalParams) => {
  const { isLoginTrigger, isNeverPop, isThisTimeNoPop } = params

  // 判断是否永不提示 或者本次不再提示弹窗
  if (isNeverPop || isThisTimeNoPop) {
    return false
  }

  // 根据当前页面地址判断是否需要禁用弹窗
  const isDisable = judgeIsForbidPopByPageAddress()
  if (isDisable) {
    return false
  }

  // 根据后台配置的弹窗类型来判断当前是否弹出
  const isForbid = !judgePopupTypeSign.run({ isLoginTrigger })
  if (isForbid) {
    return false
  }

  return true
}
