import { JudgePopupType } from '@/views/global-modal/modal/common/util/judgePopupType'
import { RouteName } from '@/router/config'
import { TaskCategoryApiCodeEnum } from '../../../api/type'
import { TaskPageStore } from '..'
import { judgeUserIsAllolwPop } from '../common/judgeUserIsAllolwPop'
import { useMainStore } from '@/store/index'
import router from '@/router'
export type ProxyJudgePopupParams = {
  store: TaskPageStore
  apiCode: TaskCategoryApiCodeEnum
  /** 是否刷新store中的数据 */
  isFreshData: boolean
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}
/** 判断弹框是否可以打开 通过用户勾选的checked和rules数据
 *  用户未勾选禁用,并存在需要展示的任务,则需要弹出
 * @param params 环境参数
 * @returns  {Boolean} [true:可以打开] [false:不能打开]
 */
export const proxyJudgeIsOpenableByCheckedAndRules = async (
  params: ProxyJudgePopupParams
) => {
  /** 是否在允许的路由下 */
  const isInAllowRoute = judgeIsInAllowRoute()
  if (!isInAllowRoute) {
    return false
  }
  /** 是否用户允许弹窗 */
  const isUserAllowPop = await judgeUserIsAllolwPop(params)
  if (!isUserAllowPop) {
    return false
  }
  /** 后台总配置是否显示任务弹框 */
  const isShowTaskModal = useMainStore().getActivesAuthInfos.taskCount
  if (!isShowTaskModal) {
    return false
  }
  const { apiCode, store } = params
  /** 判断当前任务规则是否为空,若为空,则不进行弹窗 */
  const isRuleEmpty = store.codeRulesMapper[apiCode].length === 0
  if (isRuleEmpty) {
    return false
  }
  /** 判断当前弹窗的列表状态是否如预期 */
  const isOpenByType = JudgePopupType.task.run({
    store,
    apiCode: params.apiCode,
    isLoginTrigger: !!params.isLoginTrigger
  })
  if (!isOpenByType) {
    return false
  }
  return true
}

/** 判断是否是在特定的路由下 */
const judgeIsInAllowRoute = () => {
  // 允许的特定路由
  const allowRoutes = [RouteName.GAME, RouteName.ROOT]
  // 如果不在指定的路由下,则不显示弹款内容
  if (!allowRoutes.includes(router.currentRoute.name as RouteName)) {
    return false
  }
  return true
}
