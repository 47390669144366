export enum NoticeType {
  ANNOUNCEMENT = 2,
  NOTICE = 1,
  MARQUEE = 3,
  SERVICE = 4,
  FEEDBACK = 5
}

export enum ForceType {
  NOTICE = 1,
  ANNOUNCEMENT = 2,
  PUBLICIZE = 4
}
