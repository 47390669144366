import {
  CategoryCanReceive,
  EventDiscountsRedDot
} from '../../../api/apiEventAndDiscountsRedDot'
import { CategoryUsableMapper } from '../../../api/apiGetCategoryUsable'
import {
  PopTypeAfterLogin as PTAL,
  PopTypeBeforeLogin as PTBL
} from '@/views/global-modal/modal/common/util/judgePopupType/index'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { TaskCategory } from '@/views/task/class/taskCategory'
import { TaskRule } from '@/views/task/class/taskRule'
/** 类型可用状态对象 */
export class CategoryUsable {
  /** 数据是否抓取过后台 */
  isFechted = false
  /** 分类可用状态的mapper */
  usableMapper = new CategoryUsableMapper()
}
/** 任务弹框的state */
export interface State {
  /** 任务调用接口时的loading,用于初始化和重试 */
  isLoading: boolean
  /** 任务接口错误集合，目前主要用于接口超时提示时判断是否需要重试 */
  timeoutErrorMapper: Record<TCACE | 'canUsable', boolean>
  /** 数据更新-随机数 */
  dataChangeFreshView: number
  /** 当前选中的tab项 */
  curTabApiCode: TCACE
  /** 请求任务的状态 */
  isFetching: boolean
  /** store是否已经加载过 */
  isFetched: boolean
  /** 当前全量数据所属语言(当多语言改变时,需要重新进行全量更新) */
  fetchedDataLanguage: string
  /** 当前全量数据所属用户(当用户改变时,需要重新进行全量更新) */
  fetchedDataUserId: string
  /** 分类code的可用状态 */
  categoryUsable: CategoryUsable
  /** 分类code的排序 */
  codeCategoryOrder: readonly [TCACE.xrfl, TCACE.mrrw, TCACE.mzrw, TCACE.smrw]
  /** code对应分类map的弹框 */
  codeCategoryMapper: Record<TCACE, TaskCategory>
  /** code对应分类下可领取内容map的弹框*/
  codeCateReceiveMapper: Record<TCACE, CategoryCanReceive>
  /** code与[后台禁用弹框]的映射  [true:禁用] [false:未禁用] */
  codeServerDisabledMapper: Record<TCACE, boolean>
  /** code与[弹框本次是否不再提示]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codeThisTimeNoReminderMapper: Record<TCACE, boolean>
  /** code与[弹框永久是否不再提示]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codeNeverReminderMapper: Record<TCACE, boolean>
  /** code与[弹框业务逻辑控制是否不再提示]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codeIsHideByBusinessMapper: Record<TCACE, boolean>
  /** code与[今天是否已经弹出过该弹框]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codeIsHaveOpenedTodayMapper: Record<TCACE, boolean>
  /** code与[(曾经)是否已经弹出过该弹框]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codeIsHaveOpenedEverMapper: Record<TCACE, boolean>
  /** code与[登录【前】弹窗展示方案]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codePopBefLoginMapper: Record<TCACE, PTBL>
  /** code与[登录【后】弹窗展示方案]的映射 (需要存入store,是因为全局弹框队列需要使用它)  */
  codePopAftLoginMapper: Record<TCACE, PTAL>
  /** code与需要渲染的字段的映射 */
  codeRulesMapper: Record<TCACE, TaskRule[]>
  /** 任务数量全局统计，（TODO66 此属性在此处也并不是非常合适，后期仍会废弃掉） */
  taskGlobalStatistics: Omit<
    EventDiscountsRedDot,
    'activeRedDot' | 'taskRedDot'
  >
  /** 关闭弹窗队列的函数 */
  stopModalAndQueue: null | (() => void)
}
