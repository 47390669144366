import { GameCategoryIds } from '../../consts/categorys'
import { GlobalManager } from '@/context'
import { RouteName } from '@/router/config'
import { useGameStore } from '../../store'

export default class _GameCategoryHelper {
  private static get _route() {
    return GlobalManager?.App?.$route
  }

  /** 检查当前路由是否是有效的游戏路由 */
  private static get _isValidGameRoute(): boolean {
    const validRouteNames = [
      RouteName.SUB_GAME,
      RouteName.GAME,
      RouteName.GAME_SEARCH,
      RouteName.GAME_EMBEDDED
    ]
    return validRouteNames.includes(this._route?.name as RouteName)
  }

  private static get _getGameCategoryId(): number {
    const defaultCategoryId = GameCategoryIds.Hot
    return Number(this._route?.query.gameCategoryId ?? defaultCategoryId)
  }

  /** 取当前游戏分类ID（路由上） */
  public static get currentGameCategoryId(): number {
    return this._isValidGameRoute
      ? this._getGameCategoryId
      : GameCategoryIds.Normal
  }

  /** 获取当前游戏分类ID（Tab栏或侧边栏菜单、中部导航选中状态） */
  public static get activeGameCategoryId(): number {
    return this._isValidGameRoute
      ? useGameStore().activeGameCategoryId
      : GameCategoryIds.Normal
  }
}
