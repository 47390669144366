import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { useMainStore } from '@/store/index'
import AutoShrinkText from '@/components/auto-shrink-text'
import BusinessUtils from '@/utils/business-utils'
import style from './style.module.scss'
type Props = {
  /** 是否已签到 */
  hasSign: boolean
  /** 文案 */
  text: string
}
/** 奖励的描述 */
@Component<RewardDescription>({
  name: 'RewardDescription'
})
export default class RewardDescription extends Base<unknown, Props> {
  @Prop()
  private readonly hasSign!: Props['hasSign']
  @Prop()
  private readonly text!: Props['text']
  render() {
    const { isWeb } = useMainStore()
    return (
      <div class={style['reward-description']}>
        <div class={style['text-wrap']}>
          <AutoShrinkText
            class={{
              [style['text']]: true,
              [GlobalConst.CssClassName.Lobby.Color.Yellow]: true
            }}
            text={this.text}
            rowCount={2}
            limitHeight={BusinessUtils.px2rem(isWeb ? 47 : 40)}
          ></AutoShrinkText>
        </div>
        <div class={style['divider']}></div>
      </div>
    )
  }
}
