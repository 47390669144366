import { GlobalService } from '@/context'
import { TaskCategoryApiCodeEnum } from '../type'
import { TaskRuleConstant } from '../../class/TaskRuleConstant'
import {
  _TaskRuleConstantServerRes as TaskRuleConstantServerRes,
  _TaskRuleConstantWebRes as TaskRuleConstantWebRes
} from './taskRuleConstantResponse'
/** 请求apicode与requestUrl的映射 */
const codeUrlMapper = {
  [TaskCategoryApiCodeEnum.xrfl]: '/activetask/pop_newcomerBenefit',
  [TaskCategoryApiCodeEnum.mrrw]: '/activetask/pop_taskDay',
  [TaskCategoryApiCodeEnum.mzrw]: '/activetask/pop_taskWeek',
  [TaskCategoryApiCodeEnum.smrw]: '/activetask/pop_taskThreeDay'
}

/** 发送请求,取得多个任务弹窗的静态数据映射
 * @param {TaskCategoryApiCodeEnum} apiCodes  传入一或多个apiCode
 * @returns 返回对应apiCode的mapper映射
 */
export const apiConstantTaskPopCluster = async (
  ...apiCodes: TaskCategoryApiCodeEnum[]
) => {
  const proList = apiCodes.map(apiConstantTaskPopSingle)
  const retList = await Promise.all(proList)
  const retMapper = retList.reduce((pre, cur) => {
    pre[cur.apiCode] = cur
    return pre
  }, {} as Record<TaskCategoryApiCodeEnum, TaskRuleConstantWebRes>)
  return retMapper
}

/** 发送请求,取得静态任务数据 */
const apiConstantTaskPopSingle = async (apiCode: TaskCategoryApiCodeEnum) => {
  const url = codeUrlMapper[apiCode]
  return GlobalService.get<TaskRuleConstantServerRes>({
    url,
    customParams: {
      staticKeyOmit: ['platformType'],
      keyOmit: true,
      tryStaticMode: true,
      //加上15秒的接口缓存,避免网终请求里面多次触发(虽然触发问题也不是太大,因为是静态化json文件,但是能不触发还是优先考虑不触发)
      cache: 15 * 1000
    }
  }).then((res) => {
    /** 服务端返回的数据 */
    const serverResData = res.data?.data
    if (!serverResData) {
      return new TaskRuleConstantWebRes()
    }
    // 将服务端返回数据转为web端使用数据
    const webTaskRuleConstant = TaskRuleConstantWebRes.immitServerResponse(
      new TaskRuleConstantWebRes(),
      serverResData,
      apiCode
    )
    try {
      // 将rules规则也转换成web端使用的格式
      webTaskRuleConstant.rules = webTaskRuleConstant.rules.map((rule) =>
        TaskRuleConstant.immitResponse(new TaskRuleConstant(), rule, apiCode)
      )
    } catch (error) {
      console.error(
        `-----------------------------------apiConstantTaskPopSingle遇到异常了------------------------------------`,
        error
      )
    }
    return webTaskRuleConstant
  })
}
