// 相关位置的配置

import { JackPotStyle } from '../store/type'
import { ShowPositionType } from '@/api/aside/type'

// 步长 目前前后端协议为 5秒 即5秒跳动一次
export const stepSize = 5

/** 数据组数 总数据量长度 */
export const jackpotTotalCount = 60

/** 当前 展示位置 映射的 后台配置 */
export const showPositionMap = new Map([
  ['banner', ShowPositionType.Banner],
  ['marquee', ShowPositionType.Marquee],
  ['middlMenu', ShowPositionType.MiddlMenu],
  ['popular', ShowPositionType.Popular],
  ['footer', ShowPositionType.Footer],
  ['platformTop', ShowPositionType.PlatformTop],
  ['platformCard', ShowPositionType.PlatformCard]
])

// 首页 PC banner 顶部 页脚
export const footerBannerPC: JackPotStyle = {
  '--jackpot-card-count-box-scale': '1',
  '--jackpot-card-count-box-height': '1.18rem',
  '--jackpot-card-count-to-bottom': '0.43rem',
  '--jackpot-card-width': '12rem',
  '--jackpot-card-height': '2.71rem',
  '--jackpot-card-margin': `0 auto`,
  '--jackpot-font-letter-spacing': `0.0413rem`,
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.75rem'
}

// 经典版 欧美版 首页 PC
export const defaultConfigPC: JackPotStyle = {
  '--jackpot-card-count-box-scale': '1',
  '--jackpot-card-count-box-height': '1.18rem',
  '--jackpot-card-count-to-bottom': '0.43rem',
  '--jackpot-card-width': '100%',
  '--jackpot-card-height': '2.71rem',
  '--jackpot-card-margin': `0 0 0.2rem`,
  '--jackpot-font-letter-spacing': `0.0413rem`,
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.75rem'
}

// 经典版 欧美版 首页 h5
export const defaultConfigH5: JackPotStyle = {
  '--jackpot-card-count-box-scale': '0.6',
  '--jackpot-card-count-box-height': '0.76rem',
  '--jackpot-card-count-to-bottom': '0.245rem',
  '--jackpot-card-width': '100%',
  '--jackpot-card-height': '1.64rem',
  '--jackpot-font-letter-spacing': `0.007rem`,
  '--jackpot-card-margin': `0 0 0.2rem`,
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.48rem'
}

// 经典版 首页 h5 有侧边栏菜单在一起
export const defaultConfigH5HasTab: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.52rem',
  '--jackpot-card-count-box-scale': '0.42',
  '--jackpot-card-count-to-bottom': '0.21rem',
  '--jackpot-card-width': '5.20rem',
  '--jackpot-card-height': '1.24rem',
  '--jackpot-font-letter-spacing': `0.007rem`,
  '--jackpot-card-margin': `0 0 0.2rem`,
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.33rem'
}

// 经典版 用在平台卡片上面 pc 无侧边栏
export const gameCardConfigPC: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.15rem',
  '--jackpot-card-count-box-scale': '0.18',
  '--jackpot-card-count-to-bottom': '0.07rem',
  '--jackpot-card-width': '1.60rem',
  '--jackpot-card-height': '0.50rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.15rem'
}
// 经典版 用在平台卡片上面 h5
export const gameCardConfigH5: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.24rem',
  '--jackpot-card-count-box-scale': '0.25',
  '--jackpot-card-count-to-bottom': '0.11rem',
  '--jackpot-card-width': '2.25rem',
  '--jackpot-card-height': '0.70rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.12rem'
}
// 竖版 用在平台卡片上面 h5
export const gameCardConfigVerticalH5: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.24rem',
  '--jackpot-card-count-box-scale': '0.22',
  '--jackpot-card-count-to-bottom': '0.055rem',
  '--jackpot-card-width': '1.9rem',
  '--jackpot-card-height': '0.6rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.12rem'
}

// 竖版 用在平台卡片上面 h5 有侧边栏菜单在一起
export const gameCardConfigVerticalH5HasTab: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.24rem',
  '--jackpot-card-count-box-scale': '0.18',
  '--jackpot-card-count-to-bottom': '0.055rem',
  '--jackpot-card-width': '1.7rem',
  '--jackpot-card-height': '0.6rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.12rem'
}

// 经典版 用在平台卡片上面 无侧边栏 h5
export const gameCardConfigH5Big: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.28rem',
  '--jackpot-card-count-box-scale': '0.3',
  '--jackpot-card-count-to-bottom': '0.12rem',
  '--jackpot-card-width': '2.75rem',
  '--jackpot-card-height': '0.86rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.18rem'
}

// 欧美版 用在平台卡片上面 pc
export const gameCardEUConfigPC: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.18rem',
  '--jackpot-card-count-box-scale': '0.2',
  '--jackpot-card-count-to-bottom': '0.07rem',
  '--jackpot-card-width': '1.70rem',
  '--jackpot-card-height': '0.53rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.18rem'
}

// 欧美版 用在平台卡片上面 h5
export const gameCardEUConfigH5: JackPotStyle = {
  '--jackpot-card-count-box-height': '0.22rem',
  '--jackpot-card-count-box-scale': '0.22',
  '--jackpot-card-count-to-bottom': '0.08rem',
  '--jackpot-card-width': '2.11rem',
  '--jackpot-card-height': '0.66rem',
  '--jackpot-font-color': 'var(--theme-text-color)',
  '--jackpot-font-size': '0.12rem'
}
