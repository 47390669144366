import { TaskCategoryApiCodeEnum } from '../../api/type'
import { TaskRule, TaskRuleStatusEnum } from '../../class/taskRule'

/** 规则的状态排序映射 */
const ruleOrderMap = {
  [TaskRuleStatusEnum.PendingReceive]: 1, //待领取
  [TaskRuleStatusEnum.PendingApprove]: 2, //待审批
  [TaskRuleStatusEnum.Processing]: 3, //进行中
  [TaskRuleStatusEnum.Goto]: 4, //前往
  [TaskRuleStatusEnum.UnOpen]: 5, //尚未开启
  [TaskRuleStatusEnum.Finish]: 6, //已领取
  [TaskRuleStatusEnum.HasSend]: 7, //已派发
  [TaskRuleStatusEnum.Expired]: 8, //已过期
  [TaskRuleStatusEnum.ServerCancel]: 9, //后台取消
  [TaskRuleStatusEnum.PendingApply]: 10, //待申请
  [TaskRuleStatusEnum.ServerReject]: 11 //后台拒绝
} as const

//比较规则 a-b小在前.  b-a大在前
/** 将规则按钮ruleid的顺序进行排序(PS: 因为后台返回给前端的顺序是排机的...不知道后台什么时候搞出来的奇怪问题) */
export const orderRulesDependId = (...rules: TaskRule[]) => {
  return rules.sort((aRule, bRule) => {
    return bRule.ruleid - aRule.ruleid
  })
}

/** 将规则列表按照status进行排序后的规则列表 */
const orderRulesDependStatus = (...rules: TaskRule[]) => {
  return rules.sort((aRule, bRule) => {
    return ruleOrderMap[aRule.status] - ruleOrderMap[bRule.status]
  })
}

/** 对待领取时间进行排序,时间短的排前面 */
const orderRulesDependReceiveDuration = (...rules: TaskRule[]) => {
  return rules.sort((aRule, bRule) => {
    if (
      aRule.status !== TaskRuleStatusEnum.PendingReceive ||
      bRule.status !== TaskRuleStatusEnum.PendingReceive
    ) {
      return 0
    }
    // 如果领取时间均为负,则两边是有过期时间提醒的,则谁的时间更短谁在前
    if (aRule.receiveDuration < 0 && bRule.receiveDuration < 0) {
      return Math.abs(aRule.receiveDuration) - Math.abs(bRule.receiveDuration)
    }
    // 如果是 没有倒计时的(0) 和 负数比较,有倒计时的排在没有倒计时的前面也符合预期
    // 如果都为正数 则两个正数,比较,谁的待领取时间更短,谁在前面,也符合预期
    // PS:此处有一个注意点,倒计时为负的,倒计时到0后,状态整个都需要改变,而不是进行0领取状态.
    return aRule.receiveDuration - bRule.receiveDuration
  })
}
/** 计算属性,生成排序后的规则列表映射 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const proxyCodeOrderRulesMapper = (store: any) => {
  const orderMapper = {} as Record<TaskCategoryApiCodeEnum, TaskRule[]>
  store.codeCategoryOrder.map((code: TaskCategoryApiCodeEnum) => {
    let rules = store.codeRulesMapper[code]
    // 后端返回结果是有顺的了...大厅这边去除掉id排序兼容这一步
    // rules = orderRulesDependId(...rules)
    rules = orderRulesDependStatus(...rules)
    rules = orderRulesDependReceiveDuration(...rules)
    orderMapper[code] = rules
  })
  return orderMapper
}
