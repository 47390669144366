import { GameItemInter } from '@/service/game/entity/gameItem'
import { GameState } from '../initState'
import { _ProxyGetFilteredGameList as ProxyGetFilteredGameList } from './index.type'
import { generalFilteredByAnchor } from './generalFilteredByAnchor'
import { getFilteredByCategary } from './getFilteredByCategary'
import { getFilteredGameListById } from './getFilteredGameListById'
import { getFilteredGameListByName } from './getFilteredGameListByName'
export type { _GetFilteredGameList as GetFilteredGameList } from './index.type'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 过滤类型 */
type FilterType = 'gameId' | 'gameName' | 'gameCategoryId'

/** 通过传入[游戏名称]或[游戏ID]匹配对应的游戏 */
export const proxyGetFilteredGameList: ProxyGetFilteredGameList = function (
  state: GameState,
  ...condations: Any[]
): Any {
  /** 处理函数的映射 */
  const runMapper: Record<
    FilterType,
    (oriList: GameItemInter[], params: Any) => Any
  > = {
    gameId: getFilteredGameListById,
    gameName: getFilteredGameListByName,
    gameCategoryId: getFilteredByCategary
  }
  /** 所有游戏列表数据 */
  let gameList = state.allGameList.filter(Boolean)
  // 根据传入的过滤条件,多重过滤,筛选出最终结果
  for (let index = 0; index < condations.length; index++) {
    const condation = condations[index]
    /** 类型 */
    const type: FilterType = condation.type || 'gameId'
    // 异常捕获,万一因为种种原因导致出错了,直接返回空数组,但不阻断程序运行
    try {
      gameList = runMapper[type](gameList, condation)
    } catch (error) {
      gameList = []
      console.error(error)
    }
  }
  // 条件筛选的结果再进行一次必要的主播号过滤
  gameList = generalFilteredByAnchor(gameList)
  return gameList
}

// 测试返回值结果类型
// const ret = proxyGetFilteredGameList({ state: {} as any, matches: [], type: 'gameId',isContainsNull:false })
// const { getFilteredGameList } = useGameStore()
// //data入参写出来不报错,不直接写到方法中会报错
// const data = { matches: [], type: 'gameId' as 'gameId', isContainsNull: false as false, name: '3338888' }
// const ret1_1 = getFilteredGameList(data)
// const ret1_2 = getFilteredGameList({ matches: [], type: 'gameId' as 'gameId', isContainsNull: false as false, name: '3338888' })
// const ret2 = getFilteredGameList({ matches: [], type: 'gameId' })
// const ret3 = getFilteredGameList({ matches: [], type: 'gameName' })

// setTimeout(() => {
//     const { allGameList, getFilteredGameList } = useGameStore()
//     const ret0 = getFilteredGameList(
//       {
//         type: 'gameId',
//         matches: 8130
//       },
//       {
//         type: 'gameCategoryId',
//         matches: 1
//       }
//     )
//     const ret1 = getFilteredGameList({
//       type: 'gameId',
//       matches: [2000098, 999999, 400047],
//       isContainsNull: true
//     })
//     const ret7 = getFilteredGameList(
//       {
//         type: 'gameId',
//         matches: [400032, 400033, 400092, 888888]
//       },
//       {
//         type: 'gameCategoryId',
//         matches: [2, 3]
//       }
//     )
//     const ret2 = getFilteredGameList({ type: 'gameCategoryId', matches: 2 })
//     const ret9 = getFilteredGameList({ type: 'gameName', matches: '' })
// }, 5000)
