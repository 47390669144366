import { TaskCategoryApiCodeEnum } from '../../../api/type'
import { TaskPageStore } from '..'
/** 判断用户是否允许弹框入参 */
export type ProxyJudgePopupParams = {
  store: TaskPageStore
  apiCode: TaskCategoryApiCodeEnum
  /** 是否刷新store中的数据 */
  isFreshData: boolean
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}
/** 判断用户是否允许弹框 */
export const judgeUserIsAllolwPop = async (params: ProxyJudgePopupParams) => {
  const { store, apiCode, isFreshData } = params
  /** 是否禁用弹出 */
  let isDisablePopup = judgeUserCheckedDisabled(store, apiCode)
  if (isDisablePopup) {
    return false
  }
  // 如果选中了需要请求数据刷新,则拿到数据后再进行一轮判断
  if (isFreshData) {
    await store.freshInPopupCategoryDataByCode(apiCode)
    // 更新数据后,需要再次判断,因为后台可能会返回永久禁用的状况
    isDisablePopup = judgeUserCheckedDisabled(store, apiCode)
    if (isDisablePopup) {
      return false
    }
  }
  return true
}

/** 判断用户是否勾选了禁用弹框 */
const judgeUserCheckedDisabled = (
  store: TaskPageStore,
  apiCode: TaskCategoryApiCodeEnum
) => {
  // 后台禁用本项|勾选永不提示|勾选本次不提示|业务层面要求只弹一次,任何一个为true则不再弹框
  const isServerDisable = store.codeServerDisabledMapper[apiCode]
  const isNeverReminder = store.codeNeverReminderMapper[apiCode]
  const isThisTimeNoReminder = store.codeThisTimeNoReminderMapper[apiCode]
  const isHideByBusiness = store.codeIsHideByBusinessMapper[apiCode]
  if (
    isThisTimeNoReminder ||
    isNeverReminder ||
    isServerDisable ||
    isHideByBusiness
  ) {
    return true
  } else {
    return false
  }
}
