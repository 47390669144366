import { CustomPlatformId } from '@/views/game/consts/platforms'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { GameCategoryWithExtLink } from '@/api/common/type'
import { GameHelper, gameHelper } from '@/views/game/services'
import { GameNavigation } from '../'
import { GlobalEvents } from '@/context'
import { Route } from 'vue-router'
import { RouteName } from '@/router/config'
import { merge } from 'lodash'
import { useGameStore } from '@/views/game/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import Modal from '@/controller/Modal'
import Skin from '@/controller/Skin'
import router from '@/router'

export default class _GameMenuActionHandler {
  /**  如果当前路由不是首页，将跳转到到首页，否则在当前路由上替换查询参数。 */
  private static _navigateToGamePage(id: GameCategoryIds): void {
    const { setActiveGameCategoryId } = useGameStore()
    const route: Route = router.currentRoute
    // 若当前路由不是首页，则跳转到首页
    if (route?.name !== RouteName.GAME) {
      GameNavigation.goHomePage(id)
    } else {
      // 否则在当前路由上替换查询参数
      router.replace({
        name: RouteName.GAME,
        query: merge({}, route?.query, {
          gameCategoryId: id.toString()
        })
      })
      setActiveGameCategoryId(id)
    }
  }

  /**
   * 从菜单跳转到子游戏界面
   * @param {GameCategoryIds} id - 游戏分类ID。
   */
  public static jumpToSubGameFromMenu(id: GameCategoryIds): void {
    // 如果是俱乐部或朋友局类别，显示下载提示
    if (GameHelper.isClubOrFriendCategory(id)) {
      this.displayDownloadPrompt(id)
      return
    }

    // 检查当前类别是否为热门、最近、收藏类别
    if (GameHelper.isHotRecentCollection(id)) {
      // 跳转到首页
      this._navigateToGamePage(id)
      return
    }

    // 跳转二级页面
    GameNavigation.goSubGamePage(id, `${id}${CustomPlatformId}`)
  }

  /**
   * 在菜单中滚动到特定的游戏类别
   * @param {GameCategoryIds} id - 游戏分类ID。
   */
  public static scrollMenuToGameCategory(id: GameCategoryIds): void {
    const { isTheme25 } = Skin.currentTheme()
    // 如果是俱乐部或朋友局类别，显示下载提示
    if (GameHelper.isClubOrFriendCategory(id)) {
      this.displayDownloadPrompt(id)
      return
    }

    const { gameCategoriesAvailable } = useGameStore()
    const { isWeb } = useMainStore()

    const item = gameCategoriesAvailable.find(
      (game) => game.gameCategoryId === id
    )
    const squareDataNum = isWeb ? item?.pcSquare : item?.appSquare
    const verticalDataNum = isWeb ? item?.pcVertical : item?.appVertical
    const dataNum = gameHelper.isIconStyleVertical
      ? verticalDataNum
      : squareDataNum

    // 如果该类别没有配置数据行数，或该类别是试玩类别，直接跳转到子游戏界面
    // Theme25试玩不需要跳转
    if (
      (!dataNum || (id === GameCategoryIds.Demo && !isTheme25)) &&
      // 热门、最近收藏，在首页需要跳转定位过去
      !GameHelper.isHotRecentCollection(id)
    ) {
      GameNavigation.goSubGamePage(id, `${id}${CustomPlatformId}`)
      return
    }

    // 跳转到首页
    this._navigateToGamePage(id)
  }

  /**
   * 是外链或自定义跳转
   * @param {GameCategoryWithExtLink} item - 游戏类别对象。
   */
  public static isExternalLinkOrJumpPosition(
    item: GameCategoryWithExtLink
  ): boolean {
    return !!item?.externalLinks || !!item?.jumpPosition?.type
  }

  /**
   * 游戏类别三方外链跳转 + 在菜单中滚动到对应类别
   * @param {GameCategoryWithExtLink} item - 包含外链的游戏类别对象。
   * @param {boolean} shouldScroll 决定在没有外链和跳转位置信息时，是否滚动到菜单类别还是跳转到子游戏界面。
   */
  public static performLinkAction(
    item: GameCategoryWithExtLink,
    shouldScroll = true
  ): void {
    if (this.isExternalLinkOrJumpPosition(item)) {
      // 自定义外链接口未返回平台类型数据，下方代码会自行处理
      GameNavigation.jumpCommon(item.jumpPosition, item.externalLinks, true)
      return
    }

    // 如果没有外链信息和跳转位置信息，根据 shouldScroll 的值决定是滚动菜单还是跳转子游戏界面。
    if (shouldScroll) {
      this.scrollMenuToGameCategory(item.gameCategoryId)
    } else {
      this.jumpToSubGameFromMenu(item.gameCategoryId)
    }
  }

  /**
   * 根据游戏类别执行不同的跳转行为
   * @param {GameCategoryWithExtLink} item - 游戏分类
   * @param {boolean} shouldScroll - 是否首页滚动，默认跳二级游戏
   */
  public static performGameCategoryAction(
    item: GameCategoryWithExtLink,
    shouldScroll = false
  ): void {
    if (item.gameCategoryId === GameCategoryIds.Hot) {
      GameNavigation.goSubGameHotPage()
    } else {
      _GameMenuActionHandler.performLinkAction(item, shouldScroll)
    }
  }

  /**
   * 根据游戏类别执行不同的跳转行为
   * @param {GameCategoryIds} id - 游戏分类ID
   */
  public static performGameCategoryActionById(id: GameCategoryIds): void {
    if (id === GameCategoryIds.Hot) {
      GameNavigation.goSubGameHotPage()
    } else {
      _GameMenuActionHandler.jumpToSubGameFromMenu(id)
    }
  }

  /**
   * 朋友局|俱乐部下载弹框
   *  @param {GameCategoryIds} id - 游戏分类ID。
   */
  public static displayDownloadPrompt(id: GameCategoryIds): void {
    const { t } = useI18n()
    const { isWeb } = useMainStore()
    const name =
      id === GameCategoryIds.Club
        ? t('lobby.gameMenu.menuClub')
        : t('lobby.gameMenu.menuFriend')
    Modal.create({
      class: 'clubTipsModal',
      title: t('lobby.receiveModal.kindTips'),
      width: isWeb ? BusinessUtils.px2rem(550) : BusinessUtils.px2rem(600),
      content: () => `${name} ${t('lobby.gameMenu.tipsClub')}`,
      cancelText: t('lobby.gameMenu.NotToDownloadApp').toString(),
      okText: t('lobby.gameMenu.toDownloadApp').toString(),
      onOk: () => {
        GlobalEvents.dispatch({ type: 'download_app' })
      }
    })
  }
}
