import { RouteName } from '@/router/config'
import { SeekPendingReceiveRules, isSeekPendingReceiveRules } from '.'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
import { TabKey } from '@/layouts/components/tabBar/types'
import { TaskPageStore } from '..'
import { useTabBarStore } from '@/layouts/components/tabBar/store'
import router from '@/router'
/** 是否可打开状态记录 */
type OpenableRecord = {
  isOpenable: boolean
  apiCode: TCACE
}

/** 判断是否可以打开弹窗的入参 */
type Param = {
  /** store */
  store: TaskPageStore
  /** 主要入参，支持两种格式 */
  mainParam: TCACE | SeekPendingReceiveRules
  /** 忽略页面地址 [若为true,打开弹窗前则不进行页面判断] */
  ignorePageAddress?: boolean
  /** 是否由登录事件触发 */
  isLoginTrigger?: boolean
}

/** 判断是否可以打开弹窗 */
export const judgeIsModalOpenable = async (param: Param) => {
  const {
    store,
    mainParam,
    ignorePageAddress = false,
    isLoginTrigger = false
  } = param
  /** 判断是否打开弹狂函数的mapper,参数不同，不同的处理方式 */
  const judgeFunMapper = {
    apiCodeEnum: async (_par_: TCACE) => {
      const isOpenable = await store.judgeIsOpenableByCheckedAndRules({
        apiCode: _par_,
        isFreshData: true,
        isLoginTrigger
      })
      return {
        isOpenable,
        apiCode: _par_
      } as OpenableRecord
    },
    pendingReceiveRules: async (_par_: SeekPendingReceiveRules) => {
      // 以rule的角度:判断某个弹框是否能够弹起
      const { apiCode, ruleIds } = _par_
      const localRuleIds = Array.isArray(ruleIds) ? ruleIds : [ruleIds]
      const isOpenable = await store.judgeIsOpenableByPendingReceiveRules(
        apiCode,
        localRuleIds,
        true
      )
      return {
        isOpenable,
        apiCode
      } as OpenableRecord
    }
  }
  let ret: OpenableRecord
  // 根据不同参数类型，调用不同的函数处理
  if (isSeekPendingReceiveRules(mainParam)) {
    ret = await judgeFunMapper['pendingReceiveRules'](mainParam)
  } else {
    ret = await judgeFunMapper['apiCodeEnum'](mainParam)
  }
  // 如果没有忽略地址的校验，则需要在打开弹框前验证当前页面地址是否正确
  if (!ignorePageAddress) {
    // 如果当前已不在首页则阻止弹窗
    if (router.currentRoute.name !== RouteName.GAME) {
      ret.isOpenable = false
    } else {
      // 如果当前在【我的】页面，则阻止弹窗出现
      if (useTabBarStore().currentTabItem === TabKey.MINE) {
        ret.isOpenable = false
      }
    }
  }
  return ret
}
