import {
  ApiEventCutOnce,
  ApiEventCutOnceNew,
  ReceiveStatusEnum
} from '@/api/event/type'
import {
  JudgePopupTypeCutOnce,
  JudgePopupTypeNewCutOnce
} from '@/views/global-modal/modal/common/util/judgePopupType/judgePopupTypeCutOnce'
import { judgeIsForbidPopByPageAddress } from '@/views/global-modal/modal/common/util/judgeIsForbidPopByPageAddress'

export type OpenModalParams = {
  /** 是否登录触发 */
  isLoginTrigger: boolean
  activeData: ApiEventCutOnceNew | ApiEventCutOnce
  /** 是否永不提示 */
  isNeverPop: boolean
  /** 是否本次不再提示提示 */
  isThisTimeNoPop: boolean
}

const judgePopupTypeCutOnce = new JudgePopupTypeCutOnce()

/** 判断抽奖助力弹窗是否能打开 */
export const proxyPreJudgeOpenCutOnce = (params: OpenModalParams) => {
  const { isLoginTrigger, activeData, isNeverPop, isThisTimeNoPop } = params

  // 判断活动是否已领取/已派发，已领取不用弹窗了
  if (
    activeData?.SubTaskData?.receiveStatus &&
    [ReceiveStatusEnum.HasReceive, ReceiveStatusEnum.Distributed].includes(
      activeData.SubTaskData?.receiveStatus
    )
  ) {
    return false
  }

  // 判断是否永不提示 或者本次不再提示弹窗
  if (isNeverPop || isThisTimeNoPop) {
    return false
  }

  // 根据当前页面地址判断是否需要禁用弹窗
  const isDisable = judgeIsForbidPopByPageAddress()
  if (isDisable) {
    return false
  }

  // 根据后台配置的弹窗类型来判断当前是否弹出
  const judgeResult = judgePopupTypeCutOnce.run({ isLoginTrigger })
  return judgeResult
}

const judgePopupTypeNewCutOnce = new JudgePopupTypeNewCutOnce()

/** 判断新版砍一刀弹窗是否能打开 */
export const proxyPreJudgeOpenNewCutOnce = (params: OpenModalParams) => {
  const { isLoginTrigger, activeData, isNeverPop, isThisTimeNoPop } = params

  // 判断活动是否已领取/已派发，已领取不用弹窗了
  if (
    activeData?.SubTaskData?.receiveStatus &&
    [ReceiveStatusEnum.HasReceive, ReceiveStatusEnum.Distributed].includes(
      activeData.SubTaskData?.receiveStatus
    )
  ) {
    return false
  }

  // 判断是否永不提示 或者本次不再提示弹窗
  if (isNeverPop || isThisTimeNoPop) {
    return false
  }

  // 根据当前页面地址判断是否需要禁用弹窗
  const isOpenable = judgeIsForbidPopByPageAddress()
  if (isOpenable) return false

  // 根据后台配置的弹窗类型来判断当前是否弹出
  const judgeResult = judgePopupTypeNewCutOnce.run({ isLoginTrigger })
  return judgeResult
}
