import { ApiEventGet, ApiEventSign, SignTypeEnum } from '@/api/event/type'
import { GlobalManager } from '@/context'
import { PopSignResponse } from '@/api/event/sign/type'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '@/views/global-modal/modal/common/util/judgePopupType/enum'
import { SignCacheItem, SignState } from './type'
import { apiSignGet } from '@/api/event/sign'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { proxyPreJudgeOpenSign } from './util/proxyOpenSignModal'
import { useMainStore } from '@/store/index'
import autoModalManager from '@/context/autoModalManager'
import moment from 'moment'
import to from 'await-to-js'

/** 签到活动的store */
export const useSignStore = defineStore('sign', {
  state: (): SignState => ({
    /** 活动id  */
    activeId: 0,
    /** 签到活动数据  */
    signData: {} as ApiEventGet,
    /** 签到活动缓存数据 */
    signCacheData: [] as ApiEventGet[],
    /** 签到登录前弹窗状态  */
    signBeforeLoginPopType: PopTypeBeforeLogin.never,
    /** 签到登录后弹窗状态  */
    signAfterLoginPopType: PopTypeAfterLogin.never,
    /** 签到弹窗方式：每日弹窗一次 */
    isSignHaveOpenedToday: false,
    /** 签到弹窗方式：只弹窗一次 */
    isSignHaveOpenedEver: false,
    /**签到首页弹窗复选框开关：本次不再显示弹窗 */
    isSignThisTimeNoRemind: false,
    /**签到首页弹窗复选框开关：永不提示 */
    isSignNeverPop: false,
    /**签到弹窗标题 */
    signTitle: '',
    /** 错误信息 */
    errorInfo: null
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.event.sign',
        storage: createMyPersistedStorage(null, localStorage),
        paths: [
          'signCacheData',
          'isSignHaveOpenedToday',
          'isSignHaveOpenedEver',
          'isSignNeverPop'
        ]
      }
    ]
  },
  getters: {
    getSignData(): ApiEventGet {
      return (
        this.signCacheData.find((it) => it.id === this.activeId) ||
        ({} as ApiEventGet)
      )
    }
  },
  actions: {
    /** 刷新弹窗数据 */
    async freshPopData() {
      const [error, data] = await to(apiSignGet())
      if (error) return
      /** 会员层级 未登录状态下会员的默认层级是1,不能直接使用 || 有会员的层级为 0  */
      let memberLevel = useMainStore().userInfos?.member_level as number
      if (memberLevel == undefined) {
        memberLevel = 1
      }

      /** 需要对已有数据做过滤，手动判断活动是否已经到达了展示结束时间 */
      const unixTime = moment().unix()
      const filterData = data?.filter((item: PopSignResponse) => {
        return !(item?.endShowTime > 0 && unixTime >= item?.endShowTime)
      })
      const userConfig = filterData?.find((item: PopSignResponse) => {
        const configLevel =
          (item?.userLevel && item?.userLevel?.split(',')) || []

        // 如果后台是空层级,或是层级包含0,则表示后台要匹配任意层级,它将要显示
        if (configLevel.length === 0 || configLevel.includes('0')) {
          return true
        }
        // 如果匹配上当前用户层级,则显示
        return configLevel.includes(String(memberLevel))
      })

      if (userConfig) {
        userConfig.username = String(useMainStore()?.userInfos?.username)
      }

      // 有缓存数据，查看活动id和当前缓存数据中的活动id是否一致，一致则使用缓存数据
      if (this.signCacheData.length > 0 && userConfig) {
        // 缓存的接口数据会比静态化的数据准确，因此使用缓存的数据来存
        const { language, userInfos } = useMainStore()
        const cacheDataItem = this.signCacheData.find(
          (it) =>
            it.id === userConfig.id &&
            it.username === String(userInfos?.username) &&
            it.lang === language
        ) as SignCacheItem

        // 如果无缓存数据，需要重新缓存
        if (!cacheDataItem) {
          this.signCacheData = []
          this.updateSignCacheData(userConfig)
          return
        }
        const { preSignTime } = cacheDataItem
        if (preSignTime) {
          const { monthReset, signDay } =
            cacheDataItem.activeData as ApiEventSign

          // 判断连续签到有无中断情况，如果中断则清除缓存
          if (
            (cacheDataItem.activeData as ApiEventSign)?.signType ===
            SignTypeEnum.Continuous
          ) {
            const timeZone = useMainStore().siteInfos?.timeZone || ''
            // 签到成功第二天
            const nextSign = moment(cacheDataItem.preSignTime)
              .utcOffset(timeZone)
              .add(1, 'd')
            if (moment().utcOffset(timeZone).isAfter(nextSign)) {
              this.signCacheData = []
              this.updateSignCacheData(userConfig)
              return
            }
          }

          // 计算上次签到时间是否在重置时间之前
          if (monthReset) {
            // 上次签到所在周期的重置时间
            const resetTime = moment(preSignTime).date(monthReset).valueOf()
            // 上次签到时间在重置时间前 && 当前时间在重置时间后 需要重置
            const isReset =
              resetTime - preSignTime > 0 && moment().valueOf() - resetTime > 0 // 如果需要重置,则需要重新缓存，
            if (isReset) {
              this.signCacheData = []
              this.updateSignCacheData(userConfig)
              return
            }
          }

          // 判定上次签到时间是否是今天
          const isToday = moment(preSignTime).isSame(moment(), 'day')
          const activeData = cacheDataItem.activeData as ApiEventSign
          if (!isToday) {
            activeData.signDay = +signDay + 1
          } else {
            // 不是重置当日充值、打码数据清零
            activeData.haveBet = 0
            activeData.havePay = 0
          }
        }
      } else {
        // 第一次且没有缓存的情况
        userConfig && this.updateSignCacheData(userConfig)
      }

      this.activeId = userConfig?.id ?? 0
      this.signTitle = userConfig?.name
      const activeData = userConfig?.activeData as ApiEventSign
      this.signBeforeLoginPopType =
        activeData?.beforeLoginPopType ?? PopTypeBeforeLogin.never
      this.signAfterLoginPopType =
        activeData?.afterLoginPopType ?? PopTypeAfterLogin.never
    },
    updateSignData(data: ApiEventGet) {
      this.signData = data
    },
    updateIsSignHaveOpenedToday(status: boolean) {
      this.isSignHaveOpenedToday = status
    },
    updateIsSignHaveOpenedEver(status: boolean) {
      this.isSignHaveOpenedEver = status
    },
    updateSignCacheData(signData: SignCacheItem) {
      if (signData) {
        const { language, userInfos } = useMainStore()
        signData.username = String(userInfos?.username)
        signData.lang = language
      }
      // 替换旧数据
      const index = this.signCacheData.findIndex(
        (it: SignCacheItem) =>
          it.id === signData.id &&
          it.username === signData.username &&
          it.lang === signData.lang
      )
      // 保留旧数据签到时间
      const { preSignTime } = this.signCacheData[index] || {}
      if (preSignTime) {
        signData.preSignTime = preSignTime
      }

      if (index !== -1) {
        this.signCacheData.splice(index, 1, signData)
      } else {
        this.signCacheData.unshift(signData)
      }
    },
    // 更新上次签到时间
    updatePreSignTime(time: number, activeId: number) {
      const { language, userInfos } = useMainStore()
      const signData = this.signCacheData.find((it) => {
        return it.id === activeId
      })
      if (
        signData?.username === String(userInfos?.username) &&
        signData?.lang === language
      ) {
        signData.preSignTime = time
      }
    },
    /** 判断弹窗是否可以打开 */
    preJudgeOpenSign(params: { isLoginTrigger: boolean }) {
      const { isLoginTrigger = false } = params || {}
      if (!this.activeId) return false
      const signData = this.signCacheData.find((it) => {
        return it.id === this.activeId
      }) as SignCacheItem
      // 前端需要手动判断活动是否已经到达了展示结束时间
      // 后端接口已经过滤了，但是由于接口静态化可能过滤不生效
      const unixTime = moment().unix()
      if (
        !signData ||
        (signData?.endShowTime > 0 && unixTime > signData?.endShowTime)
      ) {
        return false
      }
      return proxyPreJudgeOpenSign({
        isLoginTrigger,
        isNeverPop: this.isSignNeverPop,
        isThisTimeNoPop: this.isSignThisTimeNoRemind
      })
    },
    /** 打开签到弹窗 */
    openSignModal() {
      GlobalManager.Modal.open('sign', {
        activeId: this.activeId,
        title: this.signTitle || '',
        isHomeModal: true
      }).then((context) => {
        context.$once('modal:close', () => {
          autoModalManager.onModalClose('sign')
        })
      })
    },
    switchSignPopStatus(name: 'isSignNeverPop' | 'isSignThisTimeNoRemind') {
      this[name] = !this[name]
    }
  }
})
