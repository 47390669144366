import { NameExtType, TaskRuleStatusEnum } from './TaskRule'
import { TaskCategoryApiCodeEnum } from '../api/type'
import { merge } from 'lodash'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 静态化的任务规则(不含神秘任务特殊项) 文档地址:https://docs.cg.xxx/web/#/40/9785 */
export class TaskRuleConstant {
  /** 类型守卫,判断元素是否为TaskRuleConstant */
  public static isTaskRuleConstant: (data: Any) => data is TaskRuleConstant
  /** 生成唯一webId(唯一id) */
  public static generateWebId: (taskRule: TaskRuleConstant) => string
  /** 注入response数据 */
  public static immitResponse: (
    target: TaskRuleConstant,
    data: Partial<TaskRuleConstant>,
    apiCode: TaskCategoryApiCodeEnum
  ) => TaskRuleConstant
  /** webid,web端自己拼接出来的唯一id,防止后端不返回唯一ID */
  webId = ''
  /** 本规则所属于的类型 */
  apiCode!: TaskCategoryApiCodeEnum
  /** 任务规则id */
  ruleid = 0
  /** 任务规则图标(同时它也负责nameExt的前缀) */
  icon: NameExtType = NameExtType.Nothing
  /** 任务规则 名称 */
  name = ''
  /** 名称扩展信息，[新人福利:空] [每日/每周/神秘:可能有值] 充值用语系对应的逗号分割，打码如示例，前端需要冒号分割 */
  nameExt = ''
  /** 任务规则奖金(对应完整字段中的prize) */
  reward = 0
  /** 任务规则活跃度(对应完整字段中的brisk) */
  activity = 0
  /** 状态,大多数情况下,它是不存在的,只有神秘任务有一个尚未开启,需要用到它 */
  status?: TaskRuleStatusEnum.UnOpen
}

/** 类型守卫,判断元素是否为TaskRuleConstant */
TaskRuleConstant.isTaskRuleConstant = (data: Any): data is TaskRuleConstant => {
  if (data instanceof TaskRuleConstant) {
    return true
  }
  if (Reflect.has(data, 'reward') && Reflect.has(data, 'activity')) {
    return true
  }
  return false
}
/** 注入response */
TaskRuleConstant.immitResponse = (target, data, apiCode) => {
  target = merge(target, data)
  target.apiCode = apiCode
  // 此处后端的字段名用错了,它就是写的ruleId,写个||,避免哪一天它突然悄悄改回来了,我却不知道
  target.ruleid = (data as Any).ruleId || data.ruleid || 0
  target.webId = TaskRuleConstant.generateWebId(target)
  return target
}

/** 就目前来讲,它生成的暂时应该是唯一ID,若之后种种原因导致它不是唯一ID了,到时候再做调整 */
TaskRuleConstant.generateWebId = (taskRuleConstant: TaskRuleConstant) => {
  const { apiCode, ruleid } = taskRuleConstant
  return `${apiCode}_${ruleid}`
}
