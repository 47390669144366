export enum TabsKeyEnum {
  /**
   * 我的推广
   */
  myPromote = 'myExtension',
  /**
   * 我的佣金
   */
  myCommission = 'myCommissionPage',
  /**
   * 我的业绩
   */
  myPerformance = 'myPerformancePage',
  /**
   * 全部数据
   */
  myTeamDataDetail = 'promoteMakeMoneyAllDataPage',
  /**
   * 返佣比例
   */
  myProportion = 'rebateRatio',
  /**
   * 推广教程
   */
  myTutorial = 'extensionCourse',
  /**
   * 直属投注
   */
  directBetting = 'directBetPage',
  /**
   * 直属数据
   */
  directData = 'directDataPage',
  /**
   * 直属财务
   */
  directFinance = 'directFinancialPage',
  /**
   * 直属领取
   */
  directReceive = 'directTake'
}

export enum TabsTypeEnum {
  /**
   * 我的推广
   */
  myPromote = 'promote',
  /** 推广分享 */
  promoteShare = 'promoteShare',
  /**
   * 我的数据
   */
  myData = 'myData',
  /**
   * 我的佣金
   */
  myCommission = 'commission',
  /**
   * 我的业绩
   */
  myPerformance = 'performance',
  /**
   * 全部数据
   */
  myTeamDataDetail = 'teamDataDetail',
  /**
   * 返佣比例
   */
  myProportion = 'proportion',
  /**
   * 推广教程
   */
  myTutorial = 'tutorial',
  /**
   * 直属开户
   */
  myCreateAccount = 'createAccount',
  /**
   * 代理转账
   */
  myProxyTransfer = 'proxyTransfer',
  /**
   * 转账记录
   */
  myTransferRecord = 'transferRecord',
  /**
   * 领取记录
   */
  myPickUpRecord = 'receiceRecord',

  /**
   * 直属投注
   */
  directBetting = 'directBetting',
  /**
   * 直属数据
   */
  directData = 'directData',
  /**
   * 直属财务
   */
  directFinance = 'directFinance',
  /**
   * 直属领取
   */
  directReceive = 'directReceive',
  /**
   * 代理转账
   */
  proxyTransfer = 'proxyTransfer',
  /**
   * 未知页面
   */
  None = 'none'
}
