import { ReceiveDeviceTypeStr } from '@/views/global-modal/modal/common/type/receiveDeviceType'
import { ReceiveTimeType } from './taskCategory'
import { TaskCategoryApiCodeEnum } from '../api/type'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 任务规则状态枚举(11-2配合go修改的最新版枚举)  */
export enum TaskRuleStatusEnum {
  /** 未完成(前往) */
  Goto = 0,
  /** 待领取 */
  PendingReceive = 1,
  /** 已领取(已完成) */
  Finish = 2,
  /** 已过期 */
  Expired = 3,
  /** 已派发 */
  HasSend = 4,
  /** 后台取消 */
  ServerCancel = 5,
  /** 待审批(申请) */
  PendingApprove = 6,
  /** 待申请 */
  PendingApply = 7,
  /** 后台拒绝 */
  ServerReject = 8,
  /** 进行中(后台没有该字段,业务需求上有该字段,前端自己添加,需要通过计算获得) */
  Processing = 11,
  /** 尚未开启 */
  UnOpen = 99
}

/** 额外名称显示方式 */
export enum NameExtType {
  /** 默认名称[温馨提示] */
  Nothing = 0,
  /** 充值 */
  Recharge = 1,
  /** 累计投注 */
  BettingTotal = 2,
  /** 单局投注 */
  BettingOne = 3,
  /** 累计盈利 */
  ProfitTotal = 4,
  /** 累计亏损 */
  LossTotal = 5,
  /** 单笔盈利 */
  ProfitOne = 6,
  /** 单笔亏损 */
  LossOne = 7
}
/** 神秘任务的规则枚举(它要特殊一点,其它的都改了,就它暂时还未改) */
export enum MysteryTaskRuleStatusEnum {
  /** 待领取 */
  PendingReceive = 0,
  /** 已完成 */
  Finish = 1,
  /** 已过期 */
  Expired = 2,
  /** 前往 */
  Goto = 3,
  /** 已派发 */
  HasSend = 4,
  /** 进行中(后台没有该字段,业务需求上有该字段,前端自己添加,需要通过计算获得) */
  Processing = 11,
  /** 尚未开启 */
  UnOpen = 99
}

/** 弹框任务相关的rule对象 后端接口文档地址:https://docs.cg.xxx/web/#/40/10912 */
export class TaskRule {
  /** 生成唯一webId(唯一id) */
  public static generateWebId: (taskRule: TaskRule) => string
  /** 类型守卫,判断元素是否为TaskRule */
  public static isTaskRule: (data: Any) => data is TaskRule
  /** 是否为TaskRule数组 */
  public static isTaskRuleArray: (data: Any) => data is Array<TaskRule>

  /** 主键webId,由web端自己生成(apiCode + ruleid + status + receiveLogId),就目前来讲,它暂时应该是唯一ID */
  webId = ''
  /** 规则在待领取状态的唯一id标识 */
  receiveLogId!: number
  /** 本规则所属于的类型 */
  apiCode!: TaskCategoryApiCodeEnum
  /** 任务规则活跃度 文本 */
  BriskName = ''
  /** 任务规则活跃度 */
  brisk = 0
  /** 计算方式 [0:啥也没有(新人福利中会存在)] [1:累计充值] [2:累计充值] [3:单局投注]  每日每周三日任务有效,切割nameExt属性需要使用它
   * @deprecated 已废弃,它和icon完全相同
   */
  calculateType!: NameExtType
  /** 任务规则循环方式(name) */
  content = ''
  /** 任务规则循环方式(code) [0:无法重复完成]  [1:每天00:00重置] [2:每周一00:00重置] [3:每三日00:00重置(神秘任务)] */
  cycleType!: 0 | 1 | 2 | 3
  /** 任务规则图标(同时它也负责nameExt的前缀) */
  icon: NameExtType = NameExtType.Nothing
  /** 任务规则 要求的值 */
  max = 0
  /** 任务规则 名称 */
  name = ''
  /** 名称扩展信息，[新人福利:空] [每日/每周/神秘:可能有值] 充值用语系对应的逗号分割，打码如示例，前端需要冒号分割 */
  nameExt = ''
  /** 任务规则 奖金 */
  prize = 0
  /** 任务规则奖金 文本 */
  prizename = ''
  /** 任务规则 完成的值 */
  progress = 0
  /** 任务规则id */
  ruleid = 0
  /** 任务规则状态 [0:已完成,未领取] [1:已完成|已领取] [2:完成过期]  [3:没有完成-前往] [4:已派发(仅出现于新人福利)] [99:神秘日任务尚未开启(目前仅会出现在神秘任务中)] */
  status!: TaskRuleStatusEnum
  /** 领取时间类型 */
  receiveTimeType = ReceiveTimeType.Immediately
  /** 领取具体日子. 每周几(1-7),或每月几号(1-31),与receiveTimeType组合使用 */
  receiveTimeDay!: number
  /** 当前是否领取loading状态 [界面按钮loading状态的业务使用] */
  isReceiveLoading?: boolean
  /** 可以领取的设备类型,多选,逗号隔开 [1:IOS] [2:Android ] [3:H5] [4:Web] */
  receiveDeviceType: ReceiveDeviceTypeStr | '' = ''
  /**
   * @deprecated 10月20号标记,过几天删除
   * 可以领取的时间-时间戳 */
  canReceiveTime = 0
  /** 可以领取时间的倒计时-时间戳 */
  receiveDuration = 0
}

/** 就目前来讲,它生成的暂时应该是唯一ID,若之后种种原因导致它不是唯一ID了,到时候再做调整 */
TaskRule.generateWebId = (taskRule: TaskRule) => {
  //是否考虑使用 statuc和receiveDuration 字段待定,因为它可能会被其它条件触发,而改变
  //如果它俩有了改变,则webId也必须同步改变,或者后面再进行匹配,可能就不一定准了.
  //如果webId改变,则相应的生成的mapper也需要同步改变,一系列操作就复杂了.
  const { apiCode, ruleid, receiveLogId } = taskRule
  return `${apiCode}_${ruleid}_${receiveLogId}`
}
/** 类型守卫,判断元素是否为TaskRule */
TaskRule.isTaskRule = (data: Any): data is TaskRule => {
  if (!data) {
    return false
  }
  if (data instanceof TaskRule) {
    return true
  }
  if (Reflect.has(data, 'brisk') && Reflect.has(data, 'prize')) {
    return true
  }
  return false
}
/** 是否为TaskRule数组 */
TaskRule.isTaskRuleArray = (data: Any): data is Array<TaskRule> => {
  if (!Array.isArray(data)) {
    return false
  }
  return TaskRule.isTaskRule(data[0])
}
