// 定义用于在浏览器中处理全屏功能的特有属性和方法
interface CustomDocument extends Document {
  fullscreen: boolean
  mozFullScreen: boolean
  webkitIsFullScreen: boolean
  webkitFullScreen: boolean
  msFullscreen: boolean
  exitFullscreen: () => Promise<void>
  mozCancelFullScreen?: () => void
  webkitExitFullscreen?: () => void
}

interface CustomElement extends HTMLElement {
  requestFullscreen: () => Promise<void>
  mozRequestFullScreen?: () => void
  webkitRequestFullscreen?: () => void
  msRequestFullscreen?: () => void
}

export default class _GameFullScreen {
  private static _handleNotSupported() {
    console.log("Your browser doesn't support fullscreen features.")
  }

  /** 是否为全屏模式 */
  public static get isFullScreen(): boolean {
    const element = document as CustomDocument
    return !!(
      element.fullscreen ||
      element.mozFullScreen ||
      element.webkitIsFullScreen ||
      element.webkitFullScreen ||
      element.msFullscreen
    )
  }

  /** 进入全屏模式 */
  public static requestFullScreen(): void {
    const element = document.documentElement as CustomElement
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    } else {
      this._handleNotSupported()
    }
  }
  /** 退出全屏模式 */
  public static exitFullscreen(): void {
    if (this.isFullScreen) {
      const dm = document as CustomDocument
      if (dm?.exitFullscreen) {
        dm?.exitFullscreen()
      } else if (dm?.mozCancelFullScreen) {
        dm?.mozCancelFullScreen()
      } else if (dm?.webkitExitFullscreen) {
        dm?.webkitExitFullscreen()
      } else {
        this._handleNotSupported()
      }
    }
  }

  /** 添加全屏状态改变的监听事件 */
  public static addFullscreenEvent(callback: () => void): void {
    document.addEventListener('fullscreenchange', callback)
    document.addEventListener('webkitfullscreenchange', callback)
    document.addEventListener('mozfullscreenchange', callback)
    document.addEventListener('MSFullscreenChange', callback)
  }

  /** 移除全屏状态改变的监听事件 */
  public static removeFullscreenEvent(callback: () => void): void {
    document.removeEventListener('fullscreenchange', callback)
    document.removeEventListener('webkitfullscreenchange', callback)
    document.removeEventListener('mozfullscreenchange', callback)
    document.removeEventListener('MSFullscreenChange', callback)
  }
}
