import { ActivityType } from './type'
import { GlobalConst, GlobalService } from '@/context'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
/** 模态框是否弹出 */
export enum ModalIsPop {
  /** 禁用 */
  Disable = 1,
  /** 启用 */
  Usable = 0
}
/** 禁用弹框请求的参数 */
type NeverPopTaskModalParam = { popType: ActivityType; popStatus: ModalIsPop }
/** 发送请求永不弹框 */
export const apiNeverPopTaskModal = async (
  params: NeverPopTaskModalParam
): Promise<boolean> => {
  return GlobalService.post<boolean>({
    url: '/active/disable_active_pop',
    data: params,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => {
    if (res.data?.code === GlobalConst.ServiceCode.SUCCESS) {
      return true
    } else {
      return false
    }
  })
}

// 开启所有弹窗.方便将做测试时永久关闭的弹窗重新打开.
// setTimeout(() => {
//   ;[101, 102, 103, 104].forEach((apiCode) => {
//     apiNeverPopTaskModal({
//       popType: apiCode as ActivityType,
//       popStatus: ModalIsPop.Usable
//     })
//   })
// }, 1000)
