import {
  ActivityChest,
  TaskChestGather
} from '../../api/apiGetActivityChestList'
type TaskChestConfig = Omit<TaskChestGather, 'box'>

/** 任务弹框的 */
export interface _State {
  /** 宝箱调用接口时的loading,用于初始化和重试 */
  isLoading: boolean
  /** 宝箱是否有接口超时 */
  isTimeoutError: boolean
  /** 宝箱是否加载完成 */
  isFetched: boolean
  /** 宝箱的配置 */
  taskChestConfig: TaskChestConfig
  /** 宝箱列表 */
  activityChestList: ActivityChest[]
}
/** 初始化state 若切换了多语言,所有接口也是需要重新请求的,所以,所有数据都需要重新初始化一遍 */
export const initState = (): _State => {
  return {
    isLoading: false,
    isTimeoutError: false,
    isFetched: false,
    taskChestConfig: new TaskChestGather(),
    activityChestList: []
  }
}
